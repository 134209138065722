import {EVENT} from '../actionTypes/index'

// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  manageEventsData: [],
  changeEventsStatus: {},
  countries: [],
  states: [],
  eventsSearhData: [],
  eventDetail: {},
  error: {},
  purchaseMembership: null,
  searchedPurchaseMembership: [],
  participationRequest: [],
  searchParticipationRequest: [],
  eventTypesData: null
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENT.MANAGE_EVENTS_DATA:
      return {
        ...state,
        manageEventsData: action.manageEventsData
      }
    case EVENT.CHANGE_EVENTS_DATA:
      return {
        ...state,
        changeEventsStatus: action.changeEventsStatus
      }
    case EVENT.COUNTRIES_DATA:
      return {
        ...state,
        countries: action.countries
      }
    case EVENT.STATES_DATA:
      return {
        ...state,
        states: action.states
      }
    case EVENT.EVENTS_SEARCH_DATA:
      return {
        ...state,
        eventsSearhData: action.eventsSearhData
      }
    case EVENT.EVENTS_DETAIL_DATA:
      return {
        ...state,
        eventDetail: action.eventDetails
      }
    case EVENT.EVENT_TYPES:
      return {
        ...state,
        eventTypesData: action.eventTypesData
      }
    case EVENT.BRAND_PURCHASE_MEMBERSHIP:
      return {
        ...state,
        purchaseMembership: action.purchaseMemberships
      }
    case EVENT.BRAND_PURCHASE_MEMBERSHIP_SEARCH:
      return {
        ...state,
        searchedPurchaseMembership: action.searchedPurchaseMemberships
      }
    case EVENT.BRAND_PARTICIPATION_REQUEST:
      return {
        ...state,
        participationRequest: action.participationRequests
      }
    case EVENT.BRAND_PARTICIPATION_REQUEST_SEARCH:
      return {
        ...state,
        searchParticipationRequest: action.searchParticipationRequests
      }
    case EVENT.EXPORT_EVENTS:
      return {
        ...state,
        exportManageEventsData: action.exportManageEventsData
      }
    case EVENT.ERROR:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export default DataTablesReducer
