import {
  RD_NOTIFICATION_SETTING,
  RD_NOTIFICATION_SETTING_UPDATE
} from '../actionTypes/index'

const getRDNotificationSettingDefState = {
  getRDNotificationSettingLoading: false,
  getRDNotificationSettingData: {}
}

export const getRDNotificationSettingReducer = (state = getRDNotificationSettingDefState, action) => {
  switch (action.type) {
    case RD_NOTIFICATION_SETTING.REQUEST:
      return {
        getRDNotificationSettingLoading: true,
        getRDNotificationSettingData: {}
      }
    case RD_NOTIFICATION_SETTING.SUCCESS:
      return {
        getRDNotificationSettingLoading: false,
        getRDNotificationSettingData: action.payload
      }
    case RD_NOTIFICATION_SETTING.ERROR:
      return {
        getRDNotificationSettingLoading: false,
        getRDNotificationSettingError: action.payload
      }
    default:
      return state
  }
}

const rdNotificationSettingUpdateDefState = {
  rdNotificationSettingUpdateLoading: false,
  rdNotificationSettingUpdateData: {}
}

export const rdNotificationSettingUpdateReducer = (state = rdNotificationSettingUpdateDefState, action) => {
  switch (action.type) {
    case RD_NOTIFICATION_SETTING_UPDATE.REQUEST:
      return {
        rdNotificationSettingUpdateLoading: true,
        rdNotificationSettingUpdateData: {}
      }
    case RD_NOTIFICATION_SETTING_UPDATE.SUCCESS:
      return {
        rdNotificationSettingUpdateLoading: false,
        rdNotificationSettingUpdateData: action.payload
      }
    case RD_NOTIFICATION_SETTING_UPDATE.ERROR:
      return {
        rdNotificationSettingUpdateLoading: false,
        rdNotificationSettingUpdateError: action.payload
      }
    case RD_NOTIFICATION_SETTING_UPDATE.RESET:
      return {
        rdNotificationSettingUpdateLoading: false,
        rdNotificationSettingUpdateData: {}
      }
    default:
      return state
  }
}