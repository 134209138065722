import { lazy } from 'react'

const FormRoutes = [
  {
    path: '/dashboard/rd/opportunities-rdform',
    component: lazy(() => import('../../views/forms/opportunities-rdForm/index')),
    layout: 'VerticalLayoutRd',
    meta: {
      action:"manage",
      resource:"rd"
    }
  },
  {
    path: '/dashboard/rd/edit-opportunities-rdform/:id',
    component: lazy(() => import('../../views/forms/opportunities-rdForm/editOpportunity')),
    layout: 'VerticalLayoutRd',
    meta: {
      action:"manage",
      resource:"rd"
    }
  }
]

export default FormRoutes
