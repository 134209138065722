import { MANAGE_ACH } from '../actionType'
// ** Initial State
const initialState = {
  countries: [],
  submitBankCard: null,
  error: null,
  bankAccountDetail: null,
  accountVerification: null,
  accountDelete: null
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case MANAGE_ACH.COUNTRIES:
      return {
        ...state,
        countries: action.countries
      }
    case MANAGE_ACH.SUBMIT_BANK_CARD:
      return {
        ...state,
        submitBankCard: action.submitBankCard
      }
    case MANAGE_ACH.BANK_ACCOUNT_DETAILS:
      return {
        ...state,
        bankAccountDetail: action.bankAccountDetail
      }
    case MANAGE_ACH.VERIFY_ACCOUNT:
      return {
        ...state,
        accountVerification: action.accountVerification
      }
    case MANAGE_ACH.DELETE_ACCOUNT:
      return {
        ...state,
        accountDelete: action.accountDelete
      }
    case MANAGE_ACH.ERROR:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export default DataTablesReducer
