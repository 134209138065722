// Main
const API_URL = process.env.REACT_APP_BASEURL

// RD 
const RD = {
    BRANDS: '[RD] GET_BRANDS',
    DETAIL: '[RD] BRANDS_DETAIL',
    MANAGE_PARTNERSHIP: '[RD] MANAGE_PARTNERSHIP',
    STATUS: '[RD] STATUS',
    SEND_INVOICE: '[RD] SEND_INVOICE',
    FILTERS: '[RD] FILTERS',
    SEARCH_DATA: '[RD] SEARCH_DATA',
    ERROR: '[RD] RD_ERROR'
}

const NULL = {
    BRANDS: '[RD] GET_BRANDS',
    DETAIL: '[RD] BRANDS_DETAIL',
    MANAGE_PARTNERSHIP: '[RD] MANAGE_PARTNERSHIP',
    STATUS: '[RD] STATUS',
    SEND_INVOICE: '[RD] SEND_INVOICE',
    FILTERS: '[RD] FILTERS',
    SEARCH_DATA: '[RD] SEARCH_DATA',
    ERROR: '[RD] RD_ERROR'
}

const CHANGE_PARTNERSHIP_STATUS = {
    REQUEST: "CHANGE_PARTNERSHIP_STATUS_REQUEST",
    SUCCESS: "CHANGE_PARTNERSHIP_STATUS_SUCCESS",
    ERROR: "CHANGE_PARTNERSHIP_STATUS_ERROR",
    RESET: "CHANGE_PARTNERSHIP_STATUS_RESET"
}

const SEND_INVOICE = {
    REQUEST: "SEND_INVOICE_REQUEST",
    SUCCESS: "SEND_INVOICE_SUCCESS",
    ERROR: "SEND_INVOICE_ERROR",
    RESET: "SEND_INVOICE_RESET"
}

const RD_MANAGE_PARTNERSHIPS = {
    REQUEST: "RD_MANAGE_PARTNERSHIPS_REQUEST",
    SUCCESS: "RD_MANAGE_PARTNERSHIPS_SUCCESS",
    ERROR: "RD_MANAGE_PARTNERSHIPS_ERROR"
}

export { API_URL, RD, NULL, CHANGE_PARTNERSHIP_STATUS, SEND_INVOICE, RD_MANAGE_PARTNERSHIPS }
