import {Fragment, useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import Avatar from '@components/avatar'
import {isUserLoggedIn} from '@utils'
import {useDispatch} from 'react-redux'
import {handleLogout} from '@store/actions/auth'
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem
} from 'reactstrap'
import {
    User,
    Users,
    Mail,
    CheckSquare,
    MessageSquare,
    Settings,
    CreditCard,
    Power,
    PhoneCall,
    List
} from 'react-feather'

import BlankUser from "@src/assets/images/avatars/avatar-blank.png"

const UserDropdown = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [userData, setUserData] = useState(null)

    useEffect(() => {
        if (isUserLoggedIn() !== null) {
            setUserData(JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`)))
        }
    }, [])

    const userLogo = (userData && userData?.logo) || BlankUser

    const logout = () => {
        dispatch(handleLogout())
        history.push('/login?target=web')
    }

    return (
        <UncontrolledDropdown tag='li' className='dropdown-user nav-item brand-profile-tour'>
            <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
                <div className='user-nav d-sm-flex d-none'>
                    <span className='user-name font-weight-bold'>{userData?.name} <br/> </span>
                </div>
                <Avatar img={userLogo} imgHeight='40' imgWidth='40' status='online' className="avatar-img-fit-contain"/>
            </DropdownToggle>
            <DropdownMenu right>
                {!userData?.parent &&
                    <Fragment>
                        <DropdownItem tag={Link} to='/dashboard/brands/profile'>
                            <User size={14} className='mr-75'/>
                            <span className='align-middle'>View Profile</span>
                        </DropdownItem>
                        <DropdownItem divider/>
                        <DropdownItem tag={Link} to='/dashboard/brands/subscription-plan'>
                            <Mail size={14} className='mr-75'/>
                            <span className='align-middle'>Subscription Plan</span>
                        </DropdownItem>
                        <DropdownItem divider/>
                        <DropdownItem tag={Link} to='/dashboard/brands/manage-ach'>
                            <CheckSquare size={14} className='mr-75'/>
                            <span className='align-middle'>Manage ACH</span>
                        </DropdownItem>
                        <DropdownItem divider/>
                        <DropdownItem tag={Link} to='/dashboard/brands/manage-users'>
                            <Users size={14} className='mr-75'/>
                            <span className='align-middle'>Manage Users</span>
                        </DropdownItem>
                        <DropdownItem divider/>
                        <DropdownItem tag={Link} to='/dashboard/brands/manage-card'>
                            <MessageSquare size={14} className='mr-75'/>
                            <span className='align-middle'>Manage Card</span>
                        </DropdownItem>
                        <DropdownItem divider/>
                        <a className="text-body" href="mailto:hello@therdlink.com">
                            <DropdownItem>
                                <PhoneCall size={14} className='mr-75'/>
                                <span className='align-middle'>Contact Us</span>
                            </DropdownItem>
                        </a>
                        <DropdownItem divider/>
                        <DropdownItem tag={Link} to='/dashboard/brands/payment-history'>
                            <List size={14} className='mr-75'/>
                            <span className='align-middle'>Payment History</span>
                        </DropdownItem>
                    </Fragment>
                }
                <DropdownItem divider/>
                <DropdownItem tag={Link} to='/dashboard/brands/notification-setting'>
                    <Settings size={14} className='mr-75'/>
                    <span className='align-middle'>Notification Settings</span>
                </DropdownItem>
                <DropdownItem divider/>
                <DropdownItem tag={Link} to='/dashboard/brands/change-password'>
                    <CreditCard size={14} className='mr-75'/>
                    <span className='align-middle'>Change Password</span>
                </DropdownItem>
                <DropdownItem divider/>
                <DropdownItem onClick={logout}>
                    <Power size={14} className='mr-75'/>
                    <span className='align-middle'>Logout</span>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default UserDropdown