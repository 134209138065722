import {Fragment, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import classnames from 'classnames'
import {useSelector, useDispatch} from 'react-redux'
import {handleContentWidth, handleMenuCollapsed, handleMenuHidden} from '@store/actions/layout'
import 'animate.css/animate.css'
import { useIdleTimer } from 'react-idle-timer'
import { handleLogout } from '@store/actions/auth'

const LayoutWrapper = props => {
    const {layout, children, appLayout, wrapperClass, transition, routeMeta} = props

    const dispatch = useDispatch()
    const history = useHistory()
    const store = useSelector(state => state)
    const navbarStore = store.navbar
    const contentWidth = store.layout.contentWidth

    const Tag = layout === 'HorizontalLayout' && !appLayout ? 'div' : Fragment

    // ** Clean Up Function
    const cleanUp = () => {
        if (routeMeta) {
            if (routeMeta.contentWidth) {
                dispatch(handleContentWidth('full'))
            }
            if (routeMeta.menuCollapsed) {
                dispatch(handleMenuCollapsed(!routeMeta.menuCollapsed))
            }
            if (routeMeta.menuHidden) {
                dispatch(handleMenuHidden(!routeMeta.menuHidden))
            }
        }
    }

    // ** ComponentDidMount
    useEffect(() => {
        if (routeMeta) {
            if (routeMeta.contentWidth) {
                dispatch(handleContentWidth(routeMeta.contentWidth))
            }
            if (routeMeta.menuCollapsed) {
                dispatch(handleMenuCollapsed(routeMeta.menuCollapsed))
            }
            if (routeMeta.menuHidden) {
                dispatch(handleMenuHidden(routeMeta.menuHidden))
            }
        }
        return () => cleanUp()
    }, [])

    const onIdle = () => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        if (userData) {
            const data = {path: window.location.href, role: userData?.role}
            localStorage.setItem('redirect_url', JSON.stringify(data))
            dispatch(handleLogout())
            setTimeout(() => {
                window.location.href = "/login?target=web"
            }, 2000)
        }
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        timeout: 1800000, // 30 Minutes
        throttle: 500
    })

    return (
        <div
            className={classnames('app-content content overflow-hidden', {
                [wrapperClass]: wrapperClass,
                'show-overlay': navbarStore.query.length
            })}
        >
            <div className='content-overlay'></div>
            <div className='header-navbar-shadow'/>
            <div
                className={classnames({
                    'content-wrapper': !appLayout,
                    'content-area-wrapper': appLayout,
                    'container p-0': contentWidth === 'boxed',
                    [`animate__animated animate__${transition}`]: transition !== 'none' && transition.length
                })}
            >
                <Tag
                    /*eslint-disable */
                    {...(layout === 'HorizontalLayout' && !appLayout
                        ? {className: classnames({'content-body': !appLayout})}
                        : {})}
                    /*eslint-enable */
                >
                    {children}
                </Tag>
            </div>
        </div>
    )
}

export default LayoutWrapper
