import {FAQ} from '../actionsTypes/index'

const initialState = {
  data: [],
  total: 1,
  params: {},
  faqsData: null,
  searchFaqsData: [],
  error: null
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAQ.FAQ_DATA:
      return {
        ...state,
        faqsData: action.allData
      }
    case FAQ.SEARCH_FAQ_DATA:
      return {
        ...state,
        searchFaqsData: action.searchData
      }
    case FAQ.ERROR:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export default DataTablesReducer
