const API_URL = process.env.REACT_APP_BASEURL 

const OPPORTUNITIES = {
  OPPORTUNITIES_LISTING: "[Brand] OPPORTUNITIES_LISTING",
  UPCOMMING_LISTING: "[Brand] UPCOMMING_LISTING",
  OPPORTUNITY_DETAIL: "[Brand] OPPORTUNITY_DETAIL",
  UPDATE_OPPORTUNITY: "[Brand] UPDATE_OPPORTUNITY",
  UPLOAD_AGREEMENT: "[Brand] BRAND_OPPORTUNITIES_UPLOAD_AGREEMENT",
  EDIT_DETAIL: "[BRANDS] OPPORTUNITY_EDIT_DETAIL",
  DELETE_EDIT_FILE: "[Brand] DELETE_EDIT_FILE",
  DELETE_LISTING_OPPORTUNITY: "[Brand] DELETE_LISTING_OPPORTUNITY",
  RD_DETAIL: "[Brand] RD_DETAIL",
  PARTICIPATE_IN_OPPORTUNITY: "[Brand] PARTICIPATE_IN_OPPORTUNITY",
  COUNTRIES_LIST: "[Brand] COUNTRIES_LIST",
  REGIONS_LIST: "[Brand] REGIONS_LIST",
  STATES_LIST: "[Brand] STATES_LIST",
  CITIES_LIST: "[Brand] CITIES_LIST",
  EVENT_TYPE_LIST: "[Brand] EVENT_TYPE_LIST",
  AUDIENCE_TYPE_LIST: "[Brand] AUDIENCE_TYPE_LIST",
  CREATING: "[Brand] CREATING",
  PAST_EVENT_LISTING: "[Brand] PAST_EVENT_LISTING",
  EVENT_BRANDS: "[Brand] EVENT_BRANDS",
  EVENT_BRANDS_DETAIL: "[Brand] EVENT_BRANDS_DETAIL",
  PROVIDE_FEEDBACK: "[Brand] PROVIDE_FEEDBACK",
  FEEDBACK_DETAIL: "[Brand] FEEDBACK_DETAIL",
  SUBMIT_BANK_CARD: "[Brand] SUBMIT_BANK_CARD",
  CONTACT_US: "[Brand] CONTACT_US",
  BANK_ACCOUNT_DETAILS: "[Brand] BANK_ACCOUNT_DETAILS",
  ON_VERIFICAITON_SUCCESS: "[RD] ON_VERIFICAITON_SUCCESS",
  ON_VERIFICATION_ERROR: "[RD] ON_VERIFICATION_ERROR",
  VERIFICATION_LINK: "[RD] VERIFICATION_LINK",
  DELETE_ACCOUNT: "[Brand] DELETE_ACCOUNT",
  CAMPAIGN_LISTINGS: "[Brand] CAMPAIGN_LISTINGS",
  CAMPAIGN_EDIT_DATA: "[Brand] CAMPAIGN_EDIT_DATA",
  CAMPAIGN_UPDATE_DATA: "[Brand] CAMPAIGN_UPDATE_DATA",
  CAMPAIGN_DELETE_DATA: "[Brand] CAMPAIGN_DELETE_DATA",
  CAMPAIGN_SEARCH: "[Brand] CAMPAIGN_SEARCH",
  FORM_OPTIONS: "[Brand] FORM_OPTIONS",
  CREATING_CAMPAIGN: "[Brand] CREATING_CAMPAIGN",
  UPCOMMING_PARTNERSHIPS: "[Brand] UPCOMMING_PARTNERSHIPS",
  PAST_PARTNERSHIPS: "[Brand] PAST_PARTNERSHIPS",
  PAYMENT_DETAIL: "[Brand] PAYMENT_DETAIL",
  SUBMIT_PAYMENT: "[Brand] SUBMIT_PAYMENT",
  INVOICE_DETAIL: "[Brand] INVOICE_DETAIL",
  ADD_NOTE: "[Brand] ADD_NOTE",
  CREATE_CAMPAIGN_FORM_QUESTIONS: "[BRAND] CREATE_CAMPAIGN_FORM_QUESTIONS",
  CAMPAIGN_STATUS: "[BRAND] CAMPAIGN_STATUS",
  CAMPAIGN_IS_LIVE:"[BRAND] CAMPAIGN_IS_LIVE",
  ERROR: "[Brand] OPPORTUNITIES_ERROR",
  CAMPAIGN_EXPORT: "[Brand] CAMPAIGN_EXPORT"
}

const NULL = {
  ON_VERIFICAITON_SUCCESS: '[BRANDS] [NULL] ON_VERIFICAITON_SUCCESS',
  ON_VERIFICATION_ERROR: '[BRANDS] [NULL] ON_VERIFICATION_ERROR',
  VERIFICATION_LINK: '[BRANDS] [NULL] VERIFICATION_LINK',
  SUBMIT_PAYMENT: '[BRANDS] [NULL] SUBMIT_PAYMENT',
  CREATING_CAMPAIGN: '[BRANDS] [NULL] CREATING_CAMPAIGN',
  FORM_OPTIONS: '[BRANDS] [NULL] FORM_OPTIONS',
  CAMPAIGN_LISTINGS: '[BRANDS] [NULL] CAMPAIGN_LISTINGS',
  CAMPAIGN_EDIT_DATA: '[BRANDS] [NULL] CAMPAIGN_EDIT_DATA',
  CAMPAIGN_UPDATE_DATA: '[BRANDS] [NULL] CAMPAIGN_UPDATE_DATA',
  CAMPAIGN_DELETE_DATA: '[BRANDS] [NULL] CAMPAIGN_DELETE_DATA',
  UPLOAD_AGREEMENT: '[BRANDS] [NULL] UPLOAD_AGREEMENT',
  DELETE_ACCOUNT: "[Brand] DELETE_ACCOUNT",
  SUBMIT_BANK_CARD: "[Brand] SUBMIT_BANK_CARD",
  ADD_NOTE: "[Brand] ADD_NOTE",
  UPDATE_OPPORTUNITY: "[Brand] UPDATE_OPPORTUNITY",
  OPPO_VERIFICATION_LINK: '[BRANDS] [NULL] OPPO_VERIFICATION_LINK',
  EDIT_OPPORTUNITY_DETAIL: "[BRANDS] [NULL] OPPORTUNITY_EDIT_DETAIL",
  BRAND_OPPORTUNITIES_UPLOAD_AGREEMENT: "[Brand] [NULL] BRAND_OPPORTUNITIES_UPLOAD_AGREEMENT",
  BRAND_OPPORTUNITIES_PROVIDE_FEEDBACK: '[Brand] [NULL] BRAND_OPPORTUNITIES_PROVIDE_FEEDBACK'
}

const NULL_OPPO_BRANDS = NULL

const LOCATION_FROM_ZIP = {
  REQUEST: "LOCATION_FROM_ZIP_REQUEST",
  SUCCESS: "LOCATION_FROM_ZIP_SUCCESS",
  ERROR: "LOCATION_FROM_ZIP_ERROR",
  RESET: "LOCATION_FROM_ZIP_RESET"
}

const CAMPAIGN_MSG = {
  REQUEST: "CAMPAIGN_MSG_REQUEST",
  SUCCESS: "CAMPAIGN_MSG_SUCCESS",
  ERROR: "CAMPAIGN_MSG_ERROR",
  RESET: "CAMPAIGN_MSG_RESET"
}

const CAMPAIGN_ADD_TO_FAV = {
  REQUEST: "CAMPAIGN_ADD_TO_FAV_REQUEST",
  SUCCESS: "CAMPAIGN_ADD_TO_FAV_SUCCESS",
  ERROR: "CAMPAIGN_ADD_TO_FAV_ERROR",
  RESET: "CAMPAIGN_ADD_TO_FAV_RESET"
}

const RD_CARD_INFO_SAVE = {
  REQUEST: "RD_CARD_INFO_SAVE_REQUEST",
  SUCCESS: "RD_CARD_INFO_SAVE_SUCCESS",
  ERROR: "RD_CARD_INFO_SAVE_ERROR",
  RESET: "RD_CARD_INFO_SAVE_RESET"
}

const RD_CREDIT_CARD_INFO = {
  REQUEST: "RD_CREDIT_CARD_INFO_REQUEST",
  SUCCESS: "RD_CREDIT_CARD_INFO_SUCCESS",
  ERROR: "RD_CREDIT_CARD_INFO_ERROR",
  RESET: "RD_CREDIT_CARD_INFO_RESET"
}

const RD_CREDIT_CARD_SET_DEFAULT = {
  REQUEST: "RD_CREDIT_CARD_SET_DEFAULT_REQUEST",
  SUCCESS: "RD_CREDIT_CARD_SET_DEFAULT_SUCCESS",
  ERROR: "RD_CREDIT_CARD_SET_DEFAULT_ERROR",
  RESET: "RD_CREDIT_CARD_SET_DEFAULT_RESET"
}

const RD_CREDIT_CARD_DELETE = {
  REQUEST: "RD_CREDIT_CARD_DELETE_REQUEST",
  SUCCESS: "RD_CREDIT_CARD_DELETE_SUCCESS",
  ERROR: "RD_CREDIT_CARD_DELETE_ERROR",
  RESET: "RD_CREDIT_CARD_DELETE_RESET"
}

const RD_MASTER_FEATURES = {
  REQUEST: "RD_MASTER_FEATURES_REQUEST",
  SUCCESS: "RD_MASTER_FEATURES_SUCCESS",
  ERROR: "RD_MASTER_FEATURES_ERROR"
}

const RD_GET_PLAN = {
  REQUEST: "RD_GET_PLAN_REQUEST",
  SUCCESS: "RD_GET_PLAN_SUCCESS",
  ERROR: "RD_GET_PLAN_ERROR",
  RESET: "RD_GET_PLAN_RESET"
}

const RD_ACTIVE_PLAN = {
  REQUEST: "RD_ACTIVE_PLAN_REQUEST",
  SUCCESS: "RD_ACTIVE_PLAN_SUCCESS",
  ERROR: "RD_ACTIVE_PLAN_ERROR"
}

const RD_CANCEL_PLAN = {
  REQUEST: "RD_CANCEL_PLAN_REQUEST",
  SUCCESS: "RD_CANCEL_PLAN_SUCCESS",
  ERROR: "RD_CANCEL_PLAN_ERROR",
  RESET: "RD_CANCEL_PLAN_RESET"
}

const RD_RESOURCES = {
  REQUEST: "RD_RESOURCES_REQUEST",
  SUCCESS: "RD_RESOURCES_SUCCESS",
  ERROR: "RD_RESOURCES_ERROR",
  RESET: "RD_RESOURCES_RESET"
}

const RD_RESOURCE_CONTENT = {
  REQUEST: "RD_RESOURCES_CONTENT_REQUEST",
  SUCCESS: "RD_RESOURCES_CONTENT_SUCCESS",
  ERROR: "RD_RESOURCES_CONTENT_ERROR"
}

const RD_CHECK_CREDIT_CARD = {
  REQUEST: "RD_CHECK_CREDIT_CARD_REQUEST",
  SUCCESS: "RD_CHECK_CREDIT_CARD_SUCCESS",
  ERROR: "RD_CHECK_CREDIT_CARD_ERROR",
  RESET: "RD_CHECK_CREDIT_CARD_RESET"
}

const RD_SAVE_PLAN_INFO = {
  REQUEST: "RD_SAVE_PLAN_INFO_REQUEST",
  SUCCESS: "RD_SAVE_PLAN_INFO_SUCCESS",
  ERROR: "RD_SAVE_PLAN_INFO_ERROR",
  RESET: "RD_SAVE_PLAN_INFO_RESET"
}

const RD_LOAD_UPCOMING_EVENTS = {
  REQUEST: "RD_LOAD_UPCOMING_EVENTS_REQUEST",
  SUCCESS: "RD_LOAD_UPCOMING_EVENTS_SUCCESS",
  ERROR: "RD_LOAD_UPCOMING_EVENTS_ERROR"
}

const RD_LOAD_PAST_EVENTS = {
  REQUEST: "RD_LOAD_PAST_EVENTS_REQUEST",
  SUCCESS: "RD_LOAD_PAST_EVENTS_SUCCESS",
  ERROR: "RD_LOAD_PAST_EVENTS_ERROR"
}

const VERIFY_EMAIL = {
  REQUEST: "VERIFY_EMAIL_REQUEST",
  SUCCESS: "VERIFY_EMAIL_SUCCESS",
  ERROR: "VERIFY_EMAIL_ERROR",
  RESET: "VERIFY_EMAIL_RESET"
}

const BRAND_INVOICES = {
  REQUEST: "BRAND_INVOICES_REQUEST",
  SUCCESS: "BRAND_INVOICES_SUCCESS",
  ERROR: "BRAND_INVOICES_ERROR",
  RESET: "BRAND_INVOICES_RESET"
}

const PAY_NEW_INVOICE = {
  REQUEST: "PAY_NEW_INVOICE_REQUEST",
  SUCCESS: "PAY_NEW_INVOICE_SUCCESS",
  ERROR: "PAY_NEW_INVOICE_ERROR",
  RESET: "PAY_NEW_INVOICE_RESET"
}

const RD_ACCOUNT_SUCCESS = {
  REQUEST: "RD_ACCOUNT_SUCCESS_REQUEST",
  SUCCESS: "RD_ACCOUNT_SUCCESS_SUCCESS",
  ERROR: "RD_ACCOUNT_SUCCESS_ERROR",
  RESET: "RD_ACCOUNT_SUCCESS_RESET"
}

const RD_ACCOUNT_FAIL = {
  REQUEST: "RD_ACCOUNT_FAIL_REQUEST",
  SUCCESS: "RD_ACCOUNT_FAIL_SUCCESS",
  ERROR: "RD_ACCOUNT_FAIL_ERROR",
  RESET: "RD_ACCOUNT_FAIL_RESET"
}

const RD_ACCOUNT_DELETE = {
  REQUEST: "RD_ACCOUNT_DELETE_REQUEST",
  SUCCESS: "RD_ACCOUNT_DELETE_SUCCESS",
  ERROR: "RD_ACCOUNT_DELETE_ERROR",
  RESET: "RD_ACCOUNT_DELETE_RESET"
}

const RD_ACCOUNT_CREATE = {
  REQUEST: "RD_ACCOUNT_CREATE_REQUEST",
  SUCCESS: "RD_ACCOUNT_CREATE_SUCCESS",
  ERROR: "RD_ACCOUNT_CREATE_ERROR",
  RESET: "RD_ACCOUNT_CREATE_RESET"
}

const RD_ACCOUNT_DETAILS = {
  REQUEST: "RD_ACCOUNT_DETAILS_REQUEST",
  SUCCESS: "RD_ACCOUNT_DETAILS_SUCCESS",
  ERROR: "RD_ACCOUNT_DETAILS_ERROR"
}

const RD_ACCOUNT_VERIFY = {
  REQUEST: "RD_ACCOUNT_VERIFY_REQUEST",
  SUCCESS: "RD_ACCOUNT_VERIFY_SUCCESS",
  ERROR: "RD_ACCOUNT_VERIFY_ERROR",
  RESET: "RD_ACCOUNT_VERIFY_RESET"
}

const RD_ACCOUNT_MARK_DEFAULT = {
  REQUEST: "RD_ACCOUNT_MARK_DEFAULT_REQUEST",
  SUCCESS: "RD_ACCOUNT_MARK_DEFAULT_SUCCESS",
  ERROR: "RD_ACCOUNT_MARK_DEFAULT_ERROR",
  RESET: "RD_ACCOUNT_MARK_DEFAULT_RESET"
}

const UPLOAD_BRAND_AGREEMENT = {
  REQUEST: "UPLOAD_BRAND_AGREEMENT_REQUEST",
  SUCCESS: "UPLOAD_BRAND_AGREEMENT_SUCCESS",
  ERROR: "UPLOAD_BRAND_AGREEMENT_ERROR",
  RESET: "UPLOAD_BRAND_AGREEMENT_ERROR"
}

const BRAND_CREDIT_CARDS = {
  REQUEST: "BRAND_CREDIT_CARDS_REQUEST",
  SUCCESS: "BRAND_CREDIT_CARDS_SUCCESS",
  ERROR: "BRAND_CREDIT_CARDS_ERROR"
}

const BRAND_BANK_ACCOUNTS = {
  REQUEST: "BRAND_BANK_ACCOUNTS_REQUEST",
  SUCCESS: "BRAND_BANK_ACCOUNTS_SUCCESS",
  ERROR: "BRAND_BANK_ACCOUNTS_ERROR"
}

const BRAND_INVOICE_DETAILS = {
  REQUEST: "BRAND_INVOICE_DETAILS_REQUEST",
  SUCCESS: "BRAND_INVOICE_DETAILS_SUCCESS",
  ERROR: "BRAND_INVOICE_DETAILS_ERROR"
}

const BRAND_SUBMIT_INVOICE = {
  REQUEST: "BRAND_SUBMIT_INVOICE_REQUEST",
  SUCCESS: "BRAND_SUBMIT_INVOICE_SUCCESS",
  ERROR: "BRAND_SUBMIT_INVOICE_ERROR",
  RESET: "BRAND_SUBMIT_INVOICE_RESET"
}

const BRAND_UPCOMING_PARTNERSHIPS = {
  REQUEST: "BRAND_UPCOMING_PARTNERSHIPS_REQUEST",
  SUCCESS: "BRAND_UPCOMING_PARTNERSHIPS_SUCCESS",
  ERROR: "BRAND_UPCOMING_PARTNERSHIPS_ERROR"
}

const BRAND_PAST_PARTNERSHIPS = {
  REQUEST: "BRAND_PAST_PARTNERSHIPS_REQUEST",
  SUCCESS: "BRAND_PAST_PARTNERSHIPS_SUCCESS",
  ERROR: "BRAND_PAST_PARTNERSHIPS_ERROR"
}

const RD_CREATE_OPPORTUNITY = {
  REQUEST: "RD_CREATE_OPPORTUNITY_REQUEST",
  SUCCESS: "RD_CREATE_OPPORTUNITY_SUCCESS",
  ERROR: "RD_CREATE_OPPORTUNITY_ERROR",
  RESET: "RD_CREATE_OPPORTUNITY_RESET"
}

const RD_GET_OPPORTUNITY = {
  REQUEST: "RD_GET_OPPORTUNITY_REQUEST",
  SUCCESS: "RD_GET_OPPORTUNITY_SUCCESS",
  ERROR: "RD_GET_OPPORTUNITY_ERROR",
  RESET: "RD_GET_OPPORTUNITY_RESET"
}

const RD_UPDATE_OPPORTUNITY = {
  REQUEST: "RD_UPDATE_OPPORTUNITY_REQUEST",
  SUCCESS: "RD_UPDATE_OPPORTUNITY_SUCCESS",
  ERROR: "RD_UPDATE_OPPORTUNITY_ERROR",
  RESET: "RD_UPDATE_OPPORTUNITY_RESET"
}

const RD_DELETE_OPPORTUNITY_DOC = {
  REQUEST: "RD_DELETE_OPPORTUNITY_DOC_REQUEST",
  SUCCESS: "RD_DELETE_OPPORTUNITY_DOC_SUCCESS",
  ERROR: "RD_DELETE_OPPORTUNITY_DOC_ERROR",
  RESET: "RD_DELETE_OPPORTUNITY_DOC_RESET"
}

const BRAND_CAMPAIGNS_LISTING = {
  REQUEST: "BRAND_CAMPAIGNS_LISTING_REQUEST",
  SUCCESS: "BRAND_CAMPAIGNS_LISTING_SUCCESS",
  ERROR: "BRAND_CAMPAIGNS_LISTING_ERROR"
}

const BRAND_CREATE_CAMPAIGN = {
  REQUEST: "BRAND_CREATE_CAMPAIGN_REQUEST",
  SUCCESS: "BRAND_CREATE_CAMPAIGN_SUCCESS",
  ERROR: "BRAND_CREATE_CAMPAIGN_ERROR",
  RESET: "BRAND_CREATE_CAMPAIGN_RESET"
}

const BRAND_EDIT_CAMPAIGN = {
  REQUEST: "BRAND_EDIT_CAMPAIGN_REQUEST",
  SUCCESS: "BRAND_EDIT_CAMPAIGN_SUCCESS",
  ERROR: "BRAND_EDIT_CAMPAIGN_ERROR",
  RESET: "BRAND_EDIT_CAMPAIGN_RESET"
}

const BRAND_CAMPAIGN_QUESTIONS = {
  REQUEST: "BRAND_CAMPAIGN_QUESTIONS_REQUEST",
  SUCCESS: "BRAND_CAMPAIGN_QUESTIONS_SUCCESS",
  ERROR: "BRAND_CAMPAIGN_QUESTIONS_ERROR",
  RESET: "BRAND_CAMPAIGN_QUESTIONS_RESET"
}

const BRAND_CAMPAIGN_UPDATE = {
  REQUEST: "BRAND_CAMPAIGN_UPDATE_REQUEST",
  SUCCESS: "BRAND_CAMPAIGN_UPDATE_SUCCESS",
  ERROR: "BRAND_CAMPAIGN_UPDATE_ERROR",
  RESET: "BRAND_CAMPAIGN_UPDATE_RESET"
}

const BRAND_CAMPAIGN_DELETE = {
  REQUEST: "BRAND_CAMPAIGN_DELETE_REQUEST",
  SUCCESS: "BRAND_CAMPAIGN_DELETE_SUCCESS",
  ERROR: "BRAND_CAMPAIGN_DELETE_ERROR",
  RESET: "BRAND_CAMPAIGN_DELETE_RESET"
}

const BRAND_CAMPAIGN_STATUS = {
  REQUEST: "BRAND_CAMPAIGN_STATUS_REQUEST",
  SUCCESS: "BRAND_CAMPAIGN_STATUS_SUCCESS",
  ERROR: "BRAND_CAMPAIGN_STATUS_ERROR",
  RESET: "BRAND_CAMPAIGN_STATUS_RESET"
}

const BRAND_CAMPAIGN_LIVE = {
  REQUEST: "BRAND_CAMPAIGN_LIVE_REQUEST",
  SUCCESS: "BRAND_CAMPAIGN_LIVE_SUCCESS",
  ERROR: "BRAND_CAMPAIGN_LIVE_ERROR",
  RESET: "BRAND_CAMPAIGN_LIVE_RESET"
}

const BRAND_CAMPAIGN_FORM = {
  REQUEST: "BRAND_CAMPAIGN_FORM_REQUEST",
  SUCCESS: "BRAND_CAMPAIGN_FORM_SUCCESS",
  ERROR: "BRAND_CAMPAIGN_FORM_ERROR",
  RESET: "BRAND_CAMPAIGN_FORM_RESET"
}

const BRAND_OPP_DETAILS = {
  REQUEST: "BRAND_OPP_DETAILS_REQUEST",
  SUCCESS: "BRAND_OPP_DETAILS_SUCCESS",
  ERROR: "BRAND_OPP_DETAILS_ERROR"
}

const BRAND_OPP_PARTICIPATE = {
  REQUEST: "BRAND_OPP_PARTICIPATE_REQUEST",
  SUCCESS: "BRAND_OPP_PARTICIPATE_SUCCESS",
  ERROR: "BRAND_OPP_PARTICIPATE_ERROR",
  RESET: "BRAND_OPP_PARTICIPATE_RESET"
}

const BRAND_CAMPAIGNS_ALL_LISTING = {
  REQUEST: "BRAND_CAMPAIGNS_ALL_LISTING_REQUEST",
  SUCCESS: "BRAND_CAMPAIGNS_ALL_LISTING_SUCCESS",
  ERROR: "BRAND_CAMPAIGNS_ALL_LISTING_ERROR"
}

const INVITE_CAMPAIGN_ON_EMAIL = {
  REQUEST: "INVITE_CAMPAIGN_ON_EMAIL_REQUEST",
  SUCCESS: "INVITE_CAMPAIGN_ON_EMAIL_SUCCESS",
  ERROR: "INVITE_CAMPAIGN_ON_EMAIL_ERROR",
  RESET: "INVITE_CAMPAIGN_ON_EMAIL_RESET"
}

const INVITE_CAMPAIGN_ON_MSG = {
  REQUEST: "INVITE_CAMPAIGN_ON_MSG_REQUEST",
  SUCCESS: "INVITE_CAMPAIGN_ON_MSG_SUCCESS",
  ERROR: "INVITE_CAMPAIGN_ON_MSG_ERROR",
  RESET: "INVITE_CAMPAIGN_ON_MSG_RESET"
}

const CAMP_APPLICANT_LIST = {
  REQUEST: "CAMP_APPLICANT_LIST_REQUEST",
  SUCCESS: "CAMP_APPLICANT_LIST_SUCCESS",
  ERROR: "CAMP_APPLICANT_LIST_ERROR",
  RESET: "CAMP_APPLICANT_LIST_RESET"
}

export {
  API_URL,
  OPPORTUNITIES,
  NULL,
  NULL_OPPO_BRANDS,
  LOCATION_FROM_ZIP,
  CAMPAIGN_MSG,
  CAMPAIGN_ADD_TO_FAV,
  RD_CARD_INFO_SAVE,
  RD_CREDIT_CARD_INFO,
  RD_CREDIT_CARD_SET_DEFAULT,
  RD_CREDIT_CARD_DELETE,
  RD_GET_PLAN,
  RD_MASTER_FEATURES,
  RD_ACTIVE_PLAN,
  RD_CANCEL_PLAN,
  RD_RESOURCES,
  RD_RESOURCE_CONTENT,
  RD_CHECK_CREDIT_CARD,
  RD_SAVE_PLAN_INFO,
  RD_LOAD_UPCOMING_EVENTS,
  RD_LOAD_PAST_EVENTS,
  VERIFY_EMAIL,
  BRAND_INVOICES,
  PAY_NEW_INVOICE,
  RD_ACCOUNT_SUCCESS,
  RD_ACCOUNT_FAIL,
  RD_ACCOUNT_DELETE,
  RD_ACCOUNT_CREATE,
  RD_ACCOUNT_DETAILS,
  RD_ACCOUNT_VERIFY,
  RD_ACCOUNT_MARK_DEFAULT,
  UPLOAD_BRAND_AGREEMENT,
  BRAND_CREDIT_CARDS,
  BRAND_BANK_ACCOUNTS,
  BRAND_INVOICE_DETAILS,
  BRAND_SUBMIT_INVOICE,
  BRAND_UPCOMING_PARTNERSHIPS,
  BRAND_PAST_PARTNERSHIPS,
  RD_CREATE_OPPORTUNITY,
  RD_GET_OPPORTUNITY,
  RD_UPDATE_OPPORTUNITY,
  RD_DELETE_OPPORTUNITY_DOC,
  BRAND_CAMPAIGNS_LISTING,
  BRAND_CREATE_CAMPAIGN,
  BRAND_EDIT_CAMPAIGN,
  BRAND_CAMPAIGN_QUESTIONS,
  BRAND_CAMPAIGN_UPDATE,
  BRAND_CAMPAIGN_DELETE,
  BRAND_CAMPAIGN_STATUS,
  BRAND_CAMPAIGN_LIVE,
  BRAND_CAMPAIGN_FORM,
  BRAND_OPP_DETAILS,
  BRAND_OPP_PARTICIPATE,
  BRAND_CAMPAIGNS_ALL_LISTING,
  INVITE_CAMPAIGN_ON_EMAIL,
  INVITE_CAMPAIGN_ON_MSG,
  CAMP_APPLICANT_LIST
}
