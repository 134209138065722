import { BRAND } from '../actionTypes/index'
// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  brandsData: [],
  searchingBrandsData: [],
  countries: [],
  states: [],
  industries: [],
  specialities: [],
  deleteBrandData: {},
  changeBrandStatus: {},
  participationRequest: [],
  searchedParticipationRequest: [],
  error: {},
  brandProfile: {},
  assigningPlan: null,

  secondaryUserRequest: false,
  secondaryUserData: null,
  secondaryUserFail: null,

  makePrimaryRequest: false,
  makePrimaryData: null,
  makePrimaryFail: null,

  profileViewsRequest: false,
  profileViewsData: null,
  profileViewsFail: null,

  campaignDataRequest: false,
  campaignDataData: null,
  campaignDataFail: null,

  profileLinksRequest: false,
  profileLinksData: null,
  profileLinksFail: null,

  recoViewsRequest: false,
  recoViewsData: null,
  recoViewsFail: null,

  activeBrandsRequest: false,
  activeBrandsData: null,
  activeBrandsFail: null,

  brandTotalImpRequest: false,
  brandTotalImpData: null,
  brandTotalImpFail: null,

  brandMassMsgRequest: false,
  brandMassMsgData: null,
  brandMassMsgFail: null
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case BRAND.MANAGE_BRANDS_DATA:
      return {
        ...state,
        brandsData: action.brandsData
      }
    case BRAND.MANAGE_BRANDS_DATA_NULL:
      return {
        ...state,
        brandsData: []
      }
    case BRAND.COUNTRIES_DATA:
      return {
        ...state,
        countries: action.countries
      }
    case BRAND.STATES_DATA:
      return {
        ...state,
        states: action.states
      }
    case BRAND.INDUSTRIES_DATA:
      return {
        ...state,
        industries: action.industries
      }
    case BRAND.SPECIALITIES_DATA:
      return {
        ...state,
        specialities: action.specialities
      }
    case BRAND.DELETE_MANAGE_BRAND_GET_DATA:
      return {
        ...state,
        deleteBrandData: action.deleteBrandData
      }
    case BRAND.UPDATE_BRAND_STATUS:
      return {
        ...state,
        changeBrandStatus: action.changeBrandStatus
      }
    case BRAND.PARTICIPATION_REQUESTS:
      return {
        ...state,
        participationRequest: action.participationRequests
      }
    case BRAND.SEARCHED_PARTICIPATION_REQUESTS:
      return {
        ...state,
        searchedParticipationRequest: action.searchedParticipationRequests
      }
    case BRAND.BRAND_PROFILE_DETAIL:
      return {
        ...state,
        brandProfile: action.brandProfileData
      }
    case BRAND.EXPORT_BRANDS:
      return {
        ...state,
        exportBrandsData: action.exportBrandsData
      }
    case BRAND.ASSIGN_PLAN:
      return { ...state, assigningPlan: action.assigningPlan }
    case BRAND.ERROR:
      return {
        ...state,
        error: action.error
      }

    case BRAND.SECONDARY_USER_REQUEST:
      return {
        ...state,
        secondaryUserRequest: true,
        secondaryUserData: null,
        secondaryUserFail: null
      }
    case BRAND.SECONDARY_USER_SUCCESS:
      return {
        ...state,
        secondaryUserRequest: false,
        secondaryUserData: action?.payload?.data,
        secondaryUserFail: null
      }
    case BRAND.SECONDARY_USER_FAIL:
      return {
        ...state,
        secondaryUserRequest: false,
        secondaryUserData: null,
        secondaryUserFail: action?.payload
      }

    case BRAND.MAKE_PRIMARY_REQUEST:
      return {
        ...state,
        makePrimaryRequest: true,
        makePrimaryData: null,
        makePrimaryFail: null
      }
    case BRAND.MAKE_PRIMARY_SUCCESS:
      return {
        ...state,
        makePrimaryRequest: false,
        makePrimaryData: action?.payload?.data,
        makePrimaryFail: null
      }
    case BRAND.MAKE_PRIMARY_FAIL:
      return {
        ...state,
        makePrimaryRequest: false,
        makePrimaryData: null,
        makePrimaryFail: action?.payload
      }
    case BRAND.MAKE_PRIMARY_RESET:
      return {
        ...state,
        makePrimaryRequest: false,
        makePrimaryData: null,
        makePrimaryFail: null
      }

    case BRAND.PROFILE_VIEWS_REQUEST:
      return {
        ...state,
        profileViewsRequest: true,
        profileViewsData: null,
        profileViewsFail: null
      }
    case BRAND.PROFILE_VIEWS_SUCCESS:
      return {
        ...state,
        profileViewsRequest: false,
        profileViewsData: action?.payload?.data,
        profileViewsFail: null
      }
    case BRAND.PROFILE_VIEWS_FAIL:
      return {
        ...state,
        profileViewsRequest: false,
        profileViewsData: null,
        profileViewsFail: action?.payload
      }

    case BRAND.CAMPAIGN_DATA_REQUEST:
      return {
        ...state,
        campaignDataRequest: true,
        campaignDataData: null,
        campaignDataFail: null
      }
    case BRAND.CAMPAIGN_DATA_SUCCESS:
      return {
        ...state,
        campaignDataRequest: false,
        campaignDataData: action?.payload?.data,
        campaignDataFail: null
      }
    case BRAND.CAMPAIGN_DATA_FAIL:
      return {
        ...state,
        campaignDataRequest: false,
        campaignDataData: null,
        campaignDataFail: action?.payload
      }

    case BRAND.PROFILE_LINKS_REQUEST:
      return {
        ...state,
        profileLinksRequest: true,
        profileLinksData: null,
        profileLinksFail: null
      }
    case BRAND.PROFILE_LINKS_SUCCESS:
      return {
        ...state,
        profileLinksRequest: false,
        profileLinksData: action?.payload?.data,
        profileLinksFail: null
      }
    case BRAND.PROFILE_LINKS_FAIL:
      return {
        ...state,
        profileLinksRequest: false,
        profileLinksData: null,
        profileLinksFail: action?.payload
      }

    case BRAND.RECO_VIEWS_REQUEST:
      return {
        ...state,
        recoViewsRequest: true,
        recoViewsData: null,
        recoViewsFail: null
      }
    case BRAND.RECO_VIEWS_SUCCESS:
      return {
        ...state,
        recoViewsRequest: false,
        recoViewsData: action?.payload?.data,
        recoViewsFail: null
      }
    case BRAND.RECO_VIEWS_FAIL:
      return {
        ...state,
        recoViewsRequest: false,
        recoViewsData: null,
        recoViewsFail: action?.payload
      }

    case BRAND.ACTIVE_BRANDS_REQUEST:
      return {
        ...state,
        activeBrandsRequest: true,
        activeBrandsData: null,
        activeBrandsFail: null
      }
    case BRAND.ACTIVE_BRANDS_SUCCESS:
      return {
        ...state,
        activeBrandsRequest: false,
        activeBrandsData: action?.payload?.data,
        activeBrandsFail: null
      }
    case BRAND.ACTIVE_BRANDS_FAIL:
      return {
        ...state,
        activeBrandsRequest: false,
        activeBrandsData: null,
        activeBrandsFail: action?.payload
      }

    case BRAND.BRAND_TOTAL_IMP_REQUEST:
      return {
        ...state,
        brandTotalImpRequest: true,
        brandTotalImpData: null,
        brandTotalImpFail: null
      }
    case BRAND.BRAND_TOTAL_IMP_SUCCESS:
      return {
        ...state,
        brandTotalImpRequest: false,
        brandTotalImpData: action?.payload?.data,
        brandTotalImpFail: null
      }
    case BRAND.BRAND_TOTAL_IMP_FAIL:
      return {
        ...state,
        brandTotalImpRequest: false,
        brandTotalImpData: null,
        brandTotalImpFail: action?.payload
      }

    case BRAND.BRAND_MASS_MSG_REQUEST:
      return {
        ...state,
        brandMassMsgRequest: true,
        brandMassMsgData: null,
        brandMassMsgFail: null
      }
    case BRAND.BRAND_MASS_MSG_SUCCESS:
      return {
        ...state,
        brandMassMsgRequest: false,
        brandMassMsgData: action?.payload?.data,
        brandMassMsgFail: null
      }
    case BRAND.BRAND_MASS_MSG_FAIL:
      return {
        ...state,
        brandMassMsgRequest: false,
        brandMassMsgData: null,
        brandMassMsgFail: action?.payload
      }
    default:
      return state
  }
}

export default DataTablesReducer
