import React, {useEffect, Fragment, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import classnames from 'classnames'
import Select from 'react-select'
import Avatar from "@components/avatar"
import blankImage from "@src/assets/images/avatars/avatar-blank.png"
import {ArrowRight, File, Trash, Trash2, Video, X, Eye} from "react-feather"
import PDF from "@src/assets/images/icons/pdf1.png"
import {
    Card,
    CardBody,
    Row,
    Col,
    Button,
    Spinner,
    Label,
    FormGroup,
    Input,
    Form,
    CustomInput,
    Alert, Table
} from "reactstrap"
import * as yup from "yup"
import {useFormik} from "formik"
import {
    getCountries,
    getRegions,
    getStates,
    getCities,
    locationFromZipCodeAction,
    locationFromZipCodeActionReset
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import {
    getPrimarilyGoals,
    getBrandCompanySelectValues,
    getBrandGoalsSelectValues,
    handleLogout
} from '@store/actions/auth'
import {
    getProfile,
    getProfileReset,
    updateProfile,
    updateProfileReset,
    removeMediaFromProfile,
    deleteAccount,
    deleteAccountReset
} from '@store/actions/RDActions'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useHistory} from 'react-router'
import Uploader from "@src/views/components/Uploader"
import ImageViewer from "@src/views/components/ImageViewer"
import PdfViewer from "@src/views/components/PdfViewer"
import VideoPlayer from "@src/views/components/VideoPlayer"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from 'html-to-draftjs'
import {ContentState, convertToRaw, EditorState} from "draft-js"
import {Editor} from 'react-draft-wysiwyg'
import '@styles/react/libs/editor/editor.scss'
import '@styles/base/plugins/forms/form-quill-editor.scss'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"

const RDEditProfile = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()

    const genderOption = [
        {value: 'Male', label: 'Male'},
        {value: 'Female', label: 'Female'},
        {value: 'Non-Binary', label: 'Non-Binary'},
        {value: 'Transgender', label: 'Transgender'}
    ]

    const rdOption = [
        {value: 'RD', label: 'RD'},
        {value: 'RD2Be', label: 'RD2Be'},
        {value: 'Other', label: 'Other'}
    ]

    const hearAboutOption = [
        {value: 'SocialMedia', label: 'Social Media'},
        {value: 'Colleague', label: 'Colleague'},
        {value: 'Other', label: 'Other'}
    ]

    const licenceOption = [
        {value: 'CDR', label: 'CDR Number'},
        {value: 'DOC', label: 'Proof of Certification'}
    ]

    const onlyCharRegex = /^[aA-zZ\s]+$/

    const urlRegex = /([hH][tT]{2}[pP][sS]?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/

    const CERTIFICATE_FORMATS = ["image/png", "image/jpeg", "image/jpg", "application/pdf"]

    const CERTIFICATE_MAX_SIZE = 5 * 1024 * 1024

    const ALLOWED_PROFILE_IMAGES = ['png', 'jpeg', 'jpg']

    const PROFILE_PIC_FORMATS = ["image/png", "image/jpeg", "image/jpg"]

    const PROFILE_PIC_MAX_SIZE = 2 * 1024 * 1024

    const sweetAlert = withReactContent(Swal)

    const history = useHistory()

    const userData = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))

    const [country, setCountry] = useState([])
    const [region, setRegion] = useState([])
    const [state, setState] = useState([])
    const [city, setCity] = useState([])
    const [profileViewLink, setProfileViewLink] = useState('')
    const [certificateViewLink, setCertificateViewLink] = useState('')
    const [mediaDocs, setMediaDocs] = useState([])
    const [showUploader, setShowUploader] = useState(false)
    const [attachments, setAttachments] = useState([])
    const [imageViewer, setImageViewer] = useState({show: false, src: ''})
    const [pdfViewer, setPdfViewer] = useState({show: false, url: '', filename: ''})
    const [videoPlayer, setVideoPlayer] = useState({show: false, url: '', filename: ''})

    const dispatch = useDispatch()

    const {
        brandGoalsSelectValues,
        brandCompanySelectValues,
        rdPrimarilyGoalsValues
    } = useSelector(state => state.auth)

    const {
        countries,
        regions,
        states,
        cities
    } = useSelector((store) => store.brandsOppoprunities)

    const {
        getProfileLoading,
        getProfileData,
        getProfileError,

        updateProfileLoading,
        updateProfileData,
        updateProfileError,

        deleteAccountLoading,
        deleteAccountData,
        deleteAccountError
    } = useSelector((store) => store.rdReducer)

    const {
        locationFromZipCodeLoading,
        locationFromZipCodeData
    } = useSelector((store) => store.locationFromZipCodeReducer)

    const updateProfileForm = useFormik({
        initialValues: {
            email: '',
            firstLastName: '',
            profilePicture: '',
            registeredDietitian: '',
            otherRD: '',
            licenceType: '',
            gender: '',
            cdrNo: '',
            proofOfCertification: '',
            aboutEditor: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(""))),
            about: '',
            attributes: '',
            workIn: '',
            specializeIn: '',
            website: '',
            facebook: '',
            secondaryFacebook: '',
            instagram: '',
            secondaryInstagram: '',
            twitter: '',
            pinterest: '',
            linkedIn: '',
            tiktok: '',
            postalCode: '',
            country: '',
            regions: '',
            state: '',
            city: '',
            mainGoal: '',
            companyValues: '',
            specialDiet: '',
            foodCategory: '',
            primarilyInterested: '',
            hearAbout: '',
            otherPlatform: ''
        },
        validationSchema: yup.object().shape({
            email: yup.string().email("Email format is invalid.").required("This field is required"),
            firstLastName: yup.string().trim().required("This field is required").matches(onlyCharRegex, "Only alphabets are allowed."),
            profilePicture: yup.mixed().test(
                "fileFormat",
                "Picture is empty or invalid. Only PNG, JPEG, JPG are allowed.",
                value => !value || (value && PROFILE_PIC_FORMATS.includes(value.type))
            ).test(
                "fileSize",
                "Picture size too large. Max 2MB.",
                value => !value || (value && value.size <= PROFILE_PIC_MAX_SIZE)
            ),
            registeredDietitian: yup.object().required("This field is required"),
            otherRD: yup.string().when('registeredDietitian', {
                is: (e) => e && e.value === 'Other',
                then: yup.string().trim().required('This field is required')
            }),
            licenceType: yup.object().required("This field is required"),
            gender: yup.object().required("This field is required"),
            cdrNo: yup.string().when('licenceType', {
                is: (e) => e?.value === 'CDR',
                then: yup.string().trim().required('CDR Number is required.'),
                otherwise: yup.string()
            }),
            proofOfCertification: yup.mixed().when('licenceType', (licenceType, schema, certificate) => {
                if ((licenceType?.value === 'DOC' && !certificateViewLink) || certificate.value) {
                    return schema.required("Certificate is required.").test(
                        "fileFormat",
                        "Certificate is empty or invalid. Only allowed PNG, JPG, JPEG, PDF",
                        value => (value && CERTIFICATE_FORMATS.includes(value.type))
                    ).test(
                        "fileSize",
                        "Certificate size too large. Max 5MB.",
                        value => (value && value.size <= CERTIFICATE_MAX_SIZE)
                    )
                }
            }),
            about: yup.string().trim().required("This field is required"),
            workIn: yup.array().max(3, 'Selection should be up to 3.').required("This field is required"),
            specializeIn: yup.array().max(3, 'Selection should be up to 3.').required("This field is required"),
            website: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            facebook: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            secondaryFacebook: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            instagram: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            secondaryInstagram: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            twitter: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            pinterest: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            linkedIn: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            tiktok: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            country: yup.object().required("This field is required"),
            regions: yup.object().required("This field is required"),
            state: yup.object().required("This field is required"),
            city: yup.object().required("This field is required"),
            mainGoal: yup.string().trim().required("This field is required"),
            primarilyInterested: yup.object().required("This field is required"),
            companyValues: yup.array().required("This field is required").typeError("This field is required"),
            specialDiet: yup.array().required("This field is required").typeError("This field is required"),
            foodCategory: yup.array().required("This field is required").typeError("This field is required"),
            hearAbout: yup.object().required("This field is required"),
            otherPlatform: yup.string().when('hearAbout', {
                is: (e) => e && e.value === 'Other',
                then: yup.string().trim().required('This field is required.'),
                otherwise: yup.string()
            })
        }, ['cdrNo', 'proofOfCertification']),
        onSubmit: async (values) => {
            const token = await executeRecaptcha('rd_edit_profile')
            if (token) {
                const formData = new FormData()
                formData.append("email", values.email)
                formData.append("name", values.firstLastName)
                if (values.profilePicture) {
                    formData.append("profile_pic", values.profilePicture)
                }
                formData.append("is_rd", values.registeredDietitian.value === 'RD' ? 1 : 0)
                formData.append("is_dietetic_intern", values.registeredDietitian.value === 'RD2Be' ? 1 : 0)
                formData.append("is_other_rd", values.otherRD)
                formData.append("gender", values.gender.value)
                if (values.licenceType.value === 'CDR') {
                    formData.append("cdr_number", values.cdrNo)
                } else {
                    formData.append("cdr_number", '')
                }
                if (values.proofOfCertification) {
                    formData.append("proof_of_certification", values.proofOfCertification)
                }
                formData.append("about_me", values.about)
                if (values.attributes) {
                    values.attributes.forEach((item) => {
                        formData.append("personal_attributes_id[]", item.id)
                    })
                }
                if (values.workIn) {
                    values.workIn.forEach((item) => {
                        formData.append("industry_id[]", item.id)
                    })
                }
                if (values.specializeIn) {
                    values.specializeIn.forEach((item) => {
                        formData.append("specialty_id[]", item.id)
                    })
                }
                formData.append("website", values.website)
                formData.append("facebook_url[]", values.facebook)
                formData.append("facebook_url[]", values.secondaryFacebook)
                formData.append("instagram_url[]", values.instagram)
                formData.append("instagram_url[]", values.secondaryInstagram)
                formData.append("twitter_url[]", values.twitter)
                formData.append("pinterest_url[]", values.pinterest)
                formData.append("linkedin_url[]", values.linkedIn)
                formData.append("tiktok_url[]", values.tiktok)
                formData.append("postal_code", values.postalCode)
                formData.append("country_id", values.country.id)
                formData.append("region_id", values.regions.id)
                formData.append("state_id", values.state.id)
                formData.append("city_id", values.city.id)
                formData.append("goals", values.mainGoal)
                if (values.companyValues) {
                    values.companyValues.forEach((item) => {
                        formData.append("values_id[]", item.id)
                    })
                }
                if (values.specialDiet) {
                    values.specialDiet.forEach((item) => {
                        formData.append("special_diet_id[]", item.id)
                    })
                }
                if (values.foodCategory) {
                    values.foodCategory.forEach((item) => {
                        formData.append("category_id[]", item.id)
                    })
                }
                formData.append("primarily_interest", values.primarilyInterested.id)
                formData.append("here_about", values.hearAbout.value)
                formData.append("is_other_hear_about", values.otherPlatform)
                if (attachments?.length > 0) {
                    attachments?.forEach(item => formData.append("media_documents[]", JSON.stringify(item)))
                }
                formData.append("re_captcha_token", token)
                dispatch(updateProfile(formData))
            } else {
                sweetAlert.fire({
                    title: 'Captcha Error',
                    icon: 'error',
                    html: '<p>Execute recaptcha not yet available.</p>',
                    confirmButtonText: 'Reload',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                })
            }
        }
    })

    useEffect(() => {
        if (countries) {
            setCountry(countries)
            dispatch({type: 'CLEAR_LOCATION_DATA_ON_SIGNUP'})
        }
        if (regions) {
            setRegion(regions)
            dispatch({type: 'CLEAR_LOCATION_DATA_ON_SIGNUP'})
        }
        if (states) {
            setState(states)
            dispatch({type: 'CLEAR_LOCATION_DATA_ON_SIGNUP'})
        }
        if (cities) {
            setCity(cities)
            dispatch({type: 'CLEAR_LOCATION_DATA_ON_SIGNUP'})
        }
    }, [countries, regions, states, cities])

    useEffect(() => {
        if (locationFromZipCodeData?.data?.city_id) {
            const countryObj = {
                id: locationFromZipCodeData.data.country_id,
                value: locationFromZipCodeData.data.country_name,
                label: locationFromZipCodeData.data.country_name
            }
            updateProfileForm.setFieldValue('country', countryObj)

            const regionObj = {
                id: locationFromZipCodeData.data.region_id,
                value: locationFromZipCodeData.data.region_name,
                label: locationFromZipCodeData.data.region_name
            }
            updateProfileForm.setFieldValue('regions', regionObj)

            const stateObj = {
                id: locationFromZipCodeData.data.state_id,
                value: locationFromZipCodeData.data.state_name,
                label: locationFromZipCodeData.data.state_name
            }
            updateProfileForm.setFieldValue('state', stateObj)

            const cityObj = {
                id: locationFromZipCodeData.data.city_id,
                value: locationFromZipCodeData.data.city_name,
                label: locationFromZipCodeData.data.city_name
            }
            updateProfileForm.setFieldValue('city', cityObj)
            dispatch(locationFromZipCodeActionReset())
        }
    }, [locationFromZipCodeData])

    useEffect(() => {
        if (getProfileData?.data) {
            const {data} = getProfileData
            updateProfileForm.setFieldValue('email', data?.email)
            updateProfileForm.setFieldValue('firstLastName', data?.name)
            if (data?.profile_img) {
                setProfileViewLink(data?.profile_img)
            }
            if (data?.is_rd === 1) {
                updateProfileForm.setFieldValue('registeredDietitian', {value: 'RD', label: 'RD'})
            } else if (data?.is_dietetic_intern === 1) {
                updateProfileForm.setFieldValue('registeredDietitian', {value: 'RD2Be', label: 'RD2Be'})
            } else if (data?.is_other_rd) {
                updateProfileForm.setFieldValue('registeredDietitian', {value: 'Other', label: 'Other'})
                updateProfileForm.setFieldValue('otherRD', data?.other_rd)
            }
            updateProfileForm.setFieldValue('gender', {value: data?.gender, label: data?.gender})
            if (data?.cdr_number) {
                updateProfileForm.setFieldValue('licenceType', {value: 'CDR', label: 'CDR Number'})
            } else if (data?.certification) {
                updateProfileForm.setFieldValue('licenceType', {value: 'DOC', label: 'Proof of Certification'})
            }
            updateProfileForm.setFieldValue('cdrNo', data?.cdr_number ?? '')
            setCertificateViewLink(data?.certification)
            updateProfileForm.setFieldValue('aboutEditor', EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(data?.about_me))))
            updateProfileForm.setFieldValue('about', data?.about_me)
            updateProfileForm.setFieldValue('attributes', data?.attributes?.personal_attributes)
            updateProfileForm.setFieldValue('workIn', data?.attributes?.industry)
            updateProfileForm.setFieldValue('specializeIn', data?.attributes?.speciality)
            updateProfileForm.setFieldValue('website', data?.website ?? '')
            updateProfileForm.setFieldValue('facebook', data?.facebook ?? '')
            updateProfileForm.setFieldValue('secondaryFacebook', data?.secondary_facebook_url ?? '')
            updateProfileForm.setFieldValue('instagram', data?.instagram ?? '')
            updateProfileForm.setFieldValue('secondaryInstagram', data?.secondary_instagram_url ?? '')
            updateProfileForm.setFieldValue('twitter', data?.twitter ?? '')
            updateProfileForm.setFieldValue('pinterest', data?.pinterest ?? '')
            updateProfileForm.setFieldValue('linkedIn', data?.linkedin ?? '')
            updateProfileForm.setFieldValue('tiktok', data?.tiktok ?? '')
            updateProfileForm.setFieldValue('postalCode', data?.postal_code)
            updateProfileForm.setFieldValue('country', data?.location?.country ?? '')
            updateProfileForm.setFieldValue('regions', data?.location?.region ?? '')
            updateProfileForm.setFieldValue('state', data?.location?.state[0] ?? '')
            updateProfileForm.setFieldValue('city', data?.location?.city[0] ?? '')
            updateProfileForm.setFieldValue('mainGoal', data?.goals ?? '')
            updateProfileForm.setFieldValue('companyValues', data?.interested_brands?.values)
            updateProfileForm.setFieldValue('specialDiet', data?.interested_brands?.special_diet)
            updateProfileForm.setFieldValue('foodCategory', data?.interested_brands?.category)
            if (data?.primarily_brands?.id) {
                updateProfileForm.setFieldValue('primarilyInterested', data?.primarily_brands ?? '')
            }
            if (data?.hear_about) {
                updateProfileForm.setFieldValue('hearAbout', {value: data?.hear_about, label: data?.hear_about})
            }
            if (data?.is_other_hear_about) {
                updateProfileForm.setFieldValue('otherPlatform', data?.is_other_hear_about)
            }
            setMediaDocs(data?.docs)
            dispatch(getProfileReset())
        }
    }, [getProfileData])

    useEffect(() => {
        if (updateProfileError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (updateProfileError?.response?.data?.email) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${updateProfileError?.response?.data?.email[0]}</li>`)
            }
            if (updateProfileError?.response?.data?.name) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${updateProfileError?.response?.data?.name[0]}</li>`)
            }
            if (updateProfileError?.response?.data?.profile_pic) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${updateProfileError?.response?.data?.profile_pic[0]}</li>`)
            }
            if (updateProfileError?.response?.data?.proof_of_certification) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${updateProfileError?.response?.data?.proof_of_certification[0]}</li>`)
            }
            if (updateProfileError?.response?.data?.re_captcha_token) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${updateProfileError?.response?.data?.re_captcha_token[0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(updateProfileReset())
            updateProfileForm.setFieldValue('captchaToken', '')
        }
        if (updateProfileData?.success) {
            userData.name = updateProfileData?.data.name
            userData.profile_image = updateProfileData?.data.profile_image
            localStorage.setItem(`${process.env.REACT_APP_USERDATA}`, JSON.stringify(userData))
            sweetAlert.fire({
                title: 'Congratulations!',
                icon: 'success',
                html: '<p class="font-small-3 mt-2 mb-0 font-weight-bold">Your profile updated successfully.</p> <p class="font-small-3 mb-5">Please wait a moment we are loading your changes.</p>',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false
            })
            dispatch(updateProfileReset())
            setTimeout(() => {
                window.location.reload()
            }, 5000)
        }
    }, [updateProfileError, updateProfileData])

    useEffect(() => {
        if (deleteAccountError?.response?.status) {
            sweetAlert.fire({
                title: 'Error!',
                icon: 'error',
                html: `<p>${deleteAccountError?.response?.data?.message}</p>`,
                confirmButtonText: 'OK'
            })
            dispatch(deleteAccountReset())
        }
        if (deleteAccountData?.success) {
            sweetAlert.fire({
                title: 'See you!',
                icon: 'success',
                html: '<p>Your account has been deleted successfully.</p>',
                showCancelButton: false,
                showConfirmButton: true,
                allowOutsideClick: false
            })
            dispatch(deleteAccountReset())
            dispatch(handleLogout())
            history.push('/login')
        }
    }, [deleteAccountData, deleteAccountError])

    useEffect(() => {
        dispatch(getCountries())
        dispatch(getBrandCompanySelectValues())
        dispatch(getBrandGoalsSelectValues())
        dispatch(getPrimarilyGoals())
        dispatch(getProfile())
    }, [])

    const getLocationFromPostalCode = (e) => {
        updateProfileForm.setFieldValue('country', '')
        updateProfileForm.setFieldValue('regions', '')
        updateProfileForm.setFieldValue('state', '')
        updateProfileForm.setFieldValue('city', '')
        dispatch(locationFromZipCodeAction({
            zip_code: e.target.value
        }))
    }

    const getRegionsById = (e) => {
        setRegion([])
        setState([])
        setCity([])
        dispatch(getRegions(e.id))
    }

    const getStatesById = (e) => {
        setState([])
        setCity([])
        dispatch(getStates(e.id))
    }

    const getCitiesById = (e) => {
        setCity([])
        dispatch(getCities(e.id))
    }

    const getProfilePic = (value) => {
        if (value.files[0]) {
            if (ALLOWED_PROFILE_IMAGES.includes(value.files[0].name?.split('.').pop().toLowerCase())) {
                const readerProfile = new FileReader()
                readerProfile.readAsDataURL(value.files[0])
                readerProfile.onload = (e) => {
                    setProfileViewLink(e.target.result)
                }
            } else {
                setProfileViewLink(blankImage)
            }
        }
    }

    const deleteDocument = (doc) => {
        sweetAlert.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                const removeDoc = mediaDocs.filter((item) => item.id !== doc.id)
                setMediaDocs(removeDoc)
                dispatch(removeMediaFromProfile({
                    doc_id: doc.id
                }))
            }
        })
    }

    const deleteUserAccount = () => {
        sweetAlert.fire({
            title: 'Delete Account?',
            icon: 'question',
            html: '<p>Are you sure you want to delete this account?</p>',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteAccount())
            }
        })
    }

    const deleteUploads = (index) => {
        sweetAlert.fire({
            title: 'Confirm!',
            icon: 'warning',
            html: '<p>Do you really want to delete?</p>',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonText: 'No',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                setAttachments(prevAttachments => {
                    return prevAttachments.filter((_, i) => i !== index)
                })
            }
        })
    }

    const onSuccessUploads = (data) => {
        setAttachments(prevAttachments => [...prevAttachments, data])
        sweetAlert.fire({
            title: 'Success!',
            icon: 'success',
            html: '<p>File uploaded successfully.</p>',
            confirmButtonText: 'OK'
        })
        setShowUploader(false)
    }

    const onErrorUploads = (error) => {
        sweetAlert.fire({
            title: 'Error!',
            icon: 'error',
            html: `<p>${JSON.stringify(error)}</p>`,
            confirmButtonText: 'OK'
        })
        setShowUploader(false)
    }

    return (
        <Fragment>
            <h3>Edit Profile</h3>
            <Card>
                <CardBody>
                    {getProfileLoading ? (
                        <Alert className="mx-2 mt-2" color='primary'>
                            <div className='alert-body text-center'>
                                <Spinner style={{width: '18px', height: '18px'}}/> Please wait...
                            </div>
                        </Alert>
                    ) : (
                        <Form onSubmit={updateProfileForm.handleSubmit} autoComplete="off">
                            <Row>
                                <FormGroup tag={Col} md='12'>
                                    <div className="item-img text-center mx-auto">
                                        <Avatar img={profileViewLink || blankImage} imgHeight="200" imgWidth="200" className="avatar-img-fit-contain"/>
                                    </div>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <hr/>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='email'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.email && updateProfileForm.errors.email,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Email <span className="is-invalid">*</span>
                                    </Label>
                                    <Input type='email'
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           value={updateProfileForm.values.email}
                                           name='email'
                                           id='email'
                                           placeholder='Email'
                                           className={classnames({'is-invalid': updateProfileForm.touched.email && updateProfileForm.errors.email})}
                                           autoComplete="off"
                                           disabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.email && updateProfileForm.errors.email}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='firstLastName'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.firstLastName && updateProfileForm.errors.firstLastName,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        First and Last Name <span className="is-invalid">*</span>
                                    </Label>
                                    <Input name='firstLastName'
                                           id='firstLastName'
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           value={updateProfileForm.values.firstLastName}
                                           placeholder='First and Last Name'
                                           className={classnames({'is-invalid': updateProfileForm.touched.firstLastName && updateProfileForm.errors.firstLastName})}
                                           disabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.firstLastName && updateProfileForm.errors.firstLastName}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='profilePicture'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.profilePicture && updateProfileForm.errors.profilePicture,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Change Photo
                                    </Label>
                                    <CustomInput accept=".png, .jpg, .jpeg"
                                                 type="file"
                                                 className="form-control-file"
                                                 id="profilePicture"
                                                 onBlur={updateProfileForm.handleBlur}
                                                 onChange={(e) => {
                                                     updateProfileForm.setFieldValue('profilePicture', e.target.files[0])
                                                     getProfilePic(e.target)
                                                 }}
                                                 className={classnames({
                                                     'input-file-error': updateProfileForm.touched.profilePicture && updateProfileForm.errors.profilePicture,
                                                     'form-control-file': true
                                                 })}
                                                 disabled={getProfileLoading || updateProfileLoading}
                                                 label="Choose file (PNG, JPG, JPEG - Max 2MB)"
                                    />
                                    <div className="d-flex justify-content-between">
                                        <div className="validation-err">{updateProfileForm.touched.profilePicture && updateProfileForm.errors.profilePicture}</div>
                                    </div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='registeredDietitian'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.registeredDietitian && updateProfileForm.errors.registeredDietitian,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Are you a Registered Dietitian? <span className="is-invalid">*</span>
                                    </Label>
                                    <Select options={rdOption}
                                            className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Please Select..."
                                            value={updateProfileForm.values.registeredDietitian}
                                            onChange={(e) => updateProfileForm.setFieldValue('registeredDietitian', e)}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: updateProfileForm.touched.registeredDietitian && updateProfileForm.errors.registeredDietitian ? 'red' : baseStyles.borderColor
                                                })
                                            }}
                                            isDisabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.registeredDietitian && updateProfileForm.errors.registeredDietitian}</div>
                                </FormGroup>
                                {updateProfileForm.values.registeredDietitian.value === 'Other' && (
                                    <FormGroup tag={Col} md='4'>
                                        <Label htmlFor='otherRD'
                                               className={classnames({
                                                   'is-invalid': updateProfileForm.touched.otherRD && updateProfileForm.errors.otherRD,
                                                   'form-label': true,
                                                   'font-weight-bold': true
                                               })}
                                        >
                                            Other <span className="is-invalid">*</span>
                                        </Label>
                                        <Input name='otherRD'
                                               id='otherRD'
                                               onBlur={updateProfileForm.handleBlur}
                                               onChange={updateProfileForm.handleChange}
                                               value={updateProfileForm.values.otherRD}
                                               placeholder='Other'
                                               className={classnames({'is-invalid': updateProfileForm.touched.otherRD && updateProfileForm.errors.otherRD})}
                                               disabled={getProfileLoading || updateProfileLoading}
                                        />
                                        <div className="validation-err">{updateProfileForm.touched.otherRD && updateProfileForm.errors.otherRD}</div>
                                    </FormGroup>
                                )}
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='registeredDietitian'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.licenceType && updateProfileForm.errors.licenceType,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Licence Type <span className="is-invalid">*</span>
                                    </Label>
                                    <Select options={licenceOption}
                                            className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Please Select..."
                                            value={updateProfileForm.values.licenceType}
                                            onChange={(e) => updateProfileForm.setFieldValue('licenceType', e)}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: updateProfileForm.touched.licenceType && updateProfileForm.errors.licenceType ? 'red' : baseStyles.borderColor
                                                })
                                            }}
                                            isDisabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.licenceType && updateProfileForm.errors.licenceType}</div>
                                </FormGroup>
                                {updateProfileForm.values.licenceType.value === 'CDR' && (
                                    <FormGroup tag={Col} md='4'>
                                        <Label htmlFor='cdrNo'
                                               className={classnames({
                                                   'is-invalid': updateProfileForm.touched.cdrNo && updateProfileForm.errors.cdrNo,
                                                   'form-label': true,
                                                   'font-weight-bold': true
                                               })}
                                        >
                                            CDR Number <span className="is-invalid">*</span>
                                        </Label>
                                        <Input name='cdrNo'
                                               id='cdrNo'
                                               onBlur={updateProfileForm.handleBlur}
                                               onChange={updateProfileForm.handleChange}
                                               value={updateProfileForm.values.cdrNo}
                                               placeholder='CDR Number'
                                               className={classnames({'is-invalid': updateProfileForm.touched.cdrNo && updateProfileForm.errors.cdrNo})}
                                               disabled={getProfileLoading || updateProfileLoading}
                                        />
                                        <div className="validation-err">{updateProfileForm.touched.cdrNo && updateProfileForm.errors.cdrNo}</div>
                                    </FormGroup>
                                )}
                                {updateProfileForm.values.licenceType.value === 'DOC' && (
                                    <FormGroup tag={Col} md='4'>
                                        <Label htmlFor='proofOfCertification'
                                               className={classnames({
                                                   'is-invalid': updateProfileForm.touched.proofOfCertification && updateProfileForm.errors.proofOfCertification,
                                                   'form-label': true,
                                                   'd-flex justify-content-between': true,
                                                   'font-weight-bold': true
                                               })}
                                        >
                                            <span>Proof of Certification <span className="is-invalid">*</span></span>
                                            {certificateViewLink && (
                                                <a className="view-certificate-link cursor-pointer primary" href={certificateViewLink} target="_blank">
                                                    View Certificate
                                                </a>
                                            )}
                                        </Label>
                                        <CustomInput accept=".png, .jpg, .jpeg, .pdf"
                                                     type="file"
                                                     name="proofOfCertification"
                                                     id='proofOfCertification'
                                                     onBlur={updateProfileForm.handleBlur}
                                                     onChange={(e) => updateProfileForm.setFieldValue('proofOfCertification', e.target.files[0])}
                                                     className={classnames({
                                                         'input-file-error': updateProfileForm.touched.proofOfCertification && updateProfileForm.errors.proofOfCertification,
                                                         'form-control-file': true
                                                     })}
                                                     label='Choose file (PNG, JPG, JPEG, PDF - Max 5MB)'
                                                     disabled={getProfileLoading || updateProfileLoading}
                                        />
                                        <div className="validation-err">{updateProfileForm.touched.proofOfCertification && updateProfileForm.errors.proofOfCertification}</div>
                                    </FormGroup>
                                )}
                                <FormGroup tag={Col} md='12'>
                                    <Label htmlFor='about'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.about && updateProfileForm.errors.about,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        About Me <span className="is-invalid">*</span>
                                    </Label>
                                    <Editor editorState={updateProfileForm.values.aboutEditor}
                                            onEditorStateChange={(e) => {
                                                updateProfileForm.setFieldValue('aboutEditor', e)
                                                const contentState = e.getCurrentContent()
                                                if (contentState.hasText()) {
                                                    updateProfileForm.setFieldValue('about', draftToHtml(convertToRaw(contentState)))
                                                } else {
                                                    updateProfileForm.setFieldValue('about', "")
                                                }
                                            }}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'link', 'remove', 'history'],
                                                inline: {
                                                    options: ['bold', 'italic', 'underline']
                                                },
                                                list: {
                                                    options: ['unordered', 'ordered']
                                                },
                                                blockType: {
                                                    options: ['Normal', 'H1', 'H2', 'H3']
                                                },
                                                link: {
                                                    showOpenOptionOnHover: false
                                                },
                                                fontSize: {
                                                    options: [12, 14, 16, 18, 24, 36]
                                                }
                                            }}
                                            stripPastedStyles={true}
                                            editorStyle={{minHeight: '250px'}}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.about && updateProfileForm.errors.about}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='name'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.gender && updateProfileForm.errors.gender,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Gender <span className="is-invalid">*</span>
                                    </Label>
                                    <Select
                                        options={genderOption}
                                        className='react-select'
                                        classNamePrefix='select'
                                        placeholder="Please Select..."
                                        value={updateProfileForm.values.gender}
                                        onChange={(e) => updateProfileForm.setFieldValue('gender', e)}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderColor: updateProfileForm.touched.gender && updateProfileForm.errors.gender ? 'red' : baseStyles.borderColor
                                            })
                                        }}
                                        isDisabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.gender && updateProfileForm.errors.gender}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='attributes' className="'font-weight-bold': true">
                                        Attributes
                                    </Label>
                                    <Select className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Please Select..."
                                            options={brandGoalsSelectValues?.personal_attributes}
                                            isMulti={true}
                                            isClearable={false}
                                            value={updateProfileForm.values.attributes}
                                            onChange={(e) => {
                                                updateProfileForm.setFieldValue('attributes', e)
                                            }}
                                            disabled={getProfileLoading || updateProfileLoading}
                                    />
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='workIn'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.workIn && updateProfileForm.errors.workIn,
                                               'form-label': true,
                                               'd-flex justify-content-between': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        <span>I work in <span className="is-invalid">*</span></span>
                                        <span className="font-weight-bold text-dark">(Select up to 3)</span>
                                    </Label>
                                    <Select className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Please Select..."
                                            options={brandGoalsSelectValues?.industry}
                                            value={updateProfileForm.values.workIn}
                                            onChange={(e) => {
                                                updateProfileForm.setFieldValue('workIn', e)
                                            }}
                                            isMulti={true}
                                            isClearable={false}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: updateProfileForm.touched.workIn && updateProfileForm.errors.workIn ? 'red' : baseStyles.borderColor
                                                })
                                            }}
                                            disabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.workIn && updateProfileForm.errors.workIn}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='specializeIn'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.specializeIn && updateProfileForm.errors.specializeIn,
                                               'form-label': true,
                                               'd-flex justify-content-between': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        <span>I specialize in <span className="is-invalid">*</span></span>
                                        <span className="font-weight-bold text-dark">(Select up to 3)</span>
                                    </Label>
                                    <Select className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Please Select..."
                                            options={brandGoalsSelectValues?.speciality}
                                            isMulti={true}
                                            isClearable={false}
                                            value={updateProfileForm.values.specializeIn}
                                            onChange={(e) => updateProfileForm.setFieldValue('specializeIn', e)}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: updateProfileForm.touched.specializeIn && updateProfileForm.errors.specializeIn ? 'red' : baseStyles.borderColor
                                                })
                                            }}
                                            disabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.specializeIn && updateProfileForm.errors.specializeIn}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <hr/>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='website'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.website && updateProfileForm.errors.website,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Website
                                    </Label>
                                    <Input name='website'
                                           id='website'
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           value={updateProfileForm.values.website}
                                           className={classnames({'is-invalid': updateProfileForm.touched.website && updateProfileForm.errors.website})}
                                           placeholder='URL'
                                           disabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.website && updateProfileForm.errors.website}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='facebook'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.website && updateProfileForm.errors.website,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Facebook
                                    </Label>
                                    <Input name='facebook'
                                           id='facebook'
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           value={updateProfileForm.values.facebook}
                                           className={classnames({'is-invalid': updateProfileForm.touched.facebook && updateProfileForm.errors.facebook})}
                                           placeholder='URL'
                                           disabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.facebook && updateProfileForm.errors.facebook}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='secondaryFacebook'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.secondaryFacebook && updateProfileForm.errors.secondaryFacebook,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Secondary Facebook
                                    </Label>
                                    <Input name='secondaryFacebook'
                                           id='secondaryFacebook'
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           value={updateProfileForm.values.secondaryFacebook}
                                           className={classnames({'is-invalid': updateProfileForm.touched.secondaryFacebook && updateProfileForm.errors.secondaryFacebook})}
                                           placeholder='URL'
                                           disabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.secondaryFacebook && updateProfileForm.errors.secondaryFacebook}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='instagram'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.instagram && updateProfileForm.errors.instagram,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Instagram
                                    </Label>
                                    <Input name='instagram'
                                           id='instagram'
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           value={updateProfileForm.values.instagram}
                                           className={classnames({'is-invalid': updateProfileForm.touched.instagram && updateProfileForm.errors.instagram})}
                                           placeholder='URL'
                                           disabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.instagram && updateProfileForm.errors.instagram}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='secondaryInstagram'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.secondaryInstagram && updateProfileForm.errors.secondaryInstagram,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Secondary Instagram
                                    </Label>
                                    <Input name='secondaryInstagram'
                                           id='secondaryInstagram'
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           value={updateProfileForm.values.secondaryInstagram}
                                           className={classnames({'is-invalid': updateProfileForm.touched.secondaryInstagram && updateProfileForm.errors.secondaryInstagram})}
                                           placeholder='URL'
                                           disabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.secondaryInstagram && updateProfileForm.errors.secondaryInstagram}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='twitter'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.twitter && updateProfileForm.errors.twitter,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Twitter
                                    </Label>
                                    <Input name='twitter'
                                           id='twitter'
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           value={updateProfileForm.values.twitter}
                                           className={classnames({'is-invalid': updateProfileForm.touched.twitter && updateProfileForm.errors.twitter})}
                                           placeholder='URL'
                                           disabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.twitter && updateProfileForm.errors.twitter}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='pinterest'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.pinterest && updateProfileForm.errors.pinterest,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Pinterest
                                    </Label>
                                    <Input name='pinterest'
                                           id='pinterest'
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           value={updateProfileForm.values.pinterest}
                                           className={classnames({'is-invalid': updateProfileForm.touched.pinterest && updateProfileForm.errors.pinterest})}
                                           placeholder='URL'
                                           disabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.pinterest && updateProfileForm.errors.pinterest}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='linkedIn'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.linkedIn && updateProfileForm.errors.linkedIn,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Linked In
                                    </Label>
                                    <Input name='linkedIn'
                                           id='linkedIn'
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           value={updateProfileForm.values.linkedIn}
                                           className={classnames({'is-invalid': updateProfileForm.touched.linkedIn && updateProfileForm.errors.linkedIn})}
                                           placeholder='URL'
                                           disabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.linkedIn && updateProfileForm.errors.linkedIn}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='tiktok'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.tiktok && updateProfileForm.errors.tiktok,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Tiktok
                                    </Label>
                                    <Input name='tiktok'
                                           id='tiktok'
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           value={updateProfileForm.values.tiktok}
                                           className={classnames({'is-invalid': updateProfileForm.touched.tiktok && updateProfileForm.errors.tiktok})}
                                           placeholder='URL'
                                           disabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.tiktok && updateProfileForm.errors.tiktok}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <hr/>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='postalCode' className="d-flex justify-content-between font-weight-bold">
                                        <span>Postal Code</span>
                                        {locationFromZipCodeLoading && (
                                            <span className="text-success font-weight-bold">
                                                        <Spinner style={{width: '14px', height: '14px'}}/> Finding Location...
                                                    </span>
                                        )}
                                    </Label>
                                    <Input type='text'
                                           value={updateProfileForm.values.postalCode}
                                           name='postalCode'
                                           id='postalCode'
                                           placeholder='Postal Code'
                                           onChange={updateProfileForm.handleChange}
                                           onBlur={(e) => {
                                               updateProfileForm.handleBlur(e)
                                               getLocationFromPostalCode(e)
                                           }}
                                           disabled={getProfileLoading || updateProfileLoading || locationFromZipCodeLoading}
                                    />
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='country'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.country && updateProfileForm.errors.country,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Country <span className="is-invalid">*</span>
                                    </Label>
                                    <Select className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Please Select..."
                                            options={country}
                                            value={updateProfileForm.values.country}
                                            onChange={(e) => {
                                                updateProfileForm.setFieldValue('country', e)
                                                updateProfileForm.setFieldValue('regions', '')
                                                updateProfileForm.setFieldValue('state', '')
                                                updateProfileForm.setFieldValue('city', '')
                                                getRegionsById(e)
                                            }}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: updateProfileForm.touched.country && updateProfileForm.errors.country ? 'red' : baseStyles.borderColor
                                                })
                                            }}
                                            isDisabled={getProfileLoading || updateProfileLoading || locationFromZipCodeLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.country && updateProfileForm.errors.country}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='regions'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.regions && updateProfileForm.errors.regions,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Regions <span className="is-invalid">*</span>
                                    </Label>
                                    <Select className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Please Select..."
                                            value={updateProfileForm.values.regions}
                                            options={region}
                                            onChange={(e) => {
                                                updateProfileForm.setFieldValue('regions', e)
                                                updateProfileForm.setFieldValue('state', '')
                                                updateProfileForm.setFieldValue('city', '')
                                                getStatesById(e)
                                            }}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: updateProfileForm.touched.regions && updateProfileForm.errors.regions ? 'red' : baseStyles.borderColor
                                                })
                                            }}
                                            isDisabled={getProfileLoading || updateProfileLoading || locationFromZipCodeLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.regions && updateProfileForm.errors.regions}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='state'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.state && updateProfileForm.errors.state,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        State <span className="is-invalid">*</span>
                                    </Label>
                                    <Select className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Please Select..."
                                            value={updateProfileForm.values.state}
                                            options={state}
                                            onChange={(e) => {
                                                updateProfileForm.setFieldValue('state', e)
                                                updateProfileForm.setFieldValue('city', '')
                                                getCitiesById(e)
                                            }}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: updateProfileForm.touched.state && updateProfileForm.errors.state ? 'red' : baseStyles.borderColor
                                                })
                                            }}
                                            isDisabled={getProfileLoading || updateProfileLoading || locationFromZipCodeLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.state && updateProfileForm.errors.state}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='city'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.city && updateProfileForm.errors.city,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        City <span className="is-invalid">*</span>
                                    </Label>
                                    <Select className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Please Select..."
                                            value={updateProfileForm.values.city}
                                            options={city}
                                            onChange={(e) => {
                                                updateProfileForm.setFieldValue('city', e)
                                            }}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: updateProfileForm.touched.city && updateProfileForm.errors.city ? 'red' : baseStyles.borderColor
                                                })
                                            }}
                                            isDisabled={getProfileLoading || updateProfileLoading || locationFromZipCodeLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.city && updateProfileForm.errors.city}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <hr/>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <Label htmlFor='mainGoal'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.mainGoal && updateProfileForm.errors.mainGoal,
                                               'form-label': true,
                                               'font-weight-bold': true

                                           })}
                                    >
                                        What is your main goal for joining The RD Link? <span className="is-invalid">*</span>
                                    </Label>
                                    <Input type='textarea'
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           value={updateProfileForm.values.mainGoal}
                                           name='mainGoal'
                                           id='mainGoal'
                                           placeholder='Your Answer...'
                                           rows="5"
                                           className={classnames({'is-invalid': updateProfileForm.touched.mainGoal && updateProfileForm.errors.mainGoal})}
                                           disabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.mainGoal && updateProfileForm.errors.mainGoal}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <hr/>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <p className="font-weight-bold">Are there certain types of brands that you are particularly interested in connecting with?</p>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='companyValues'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.companyValues && updateProfileForm.errors.companyValues,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Company Values <span className="is-invalid">*</span>
                                    </Label>
                                    <Select className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Please Select..."
                                            options={brandCompanySelectValues?.values}
                                            isMulti={true}
                                            isClearable={false}
                                            value={updateProfileForm.values.companyValues}
                                            onChange={(e) => {
                                                updateProfileForm.setFieldValue('companyValues', e)
                                            }}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: updateProfileForm.touched.companyValues && updateProfileForm.errors.companyValues ? 'red' : baseStyles.borderColor
                                                })
                                            }}
                                            isDisabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.companyValues && updateProfileForm.errors.companyValues}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='specialDiet'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.specialDiet && updateProfileForm.errors.specialDiet,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Special Diet <span className="is-invalid">*</span>
                                    </Label>
                                    <Select className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Please Select..."
                                            options={brandCompanySelectValues?.special_diet}
                                            isMulti={true}
                                            isClearable={false}
                                            value={updateProfileForm.values.specialDiet}
                                            onChange={(e) => {
                                                updateProfileForm.setFieldValue('specialDiet', e)
                                            }}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: updateProfileForm.touched.specialDiet && updateProfileForm.errors.specialDiet ? 'red' : baseStyles.borderColor
                                                })
                                            }}
                                            isDisabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.specialDiet && updateProfileForm.errors.specialDiet}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='foodCategory'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.foodCategory && updateProfileForm.errors.foodCategory,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Food Category <span className="is-invalid">*</span>
                                    </Label>
                                    <Select className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Please Select..."
                                            options={brandCompanySelectValues?.category}
                                            isMulti={true}
                                            isClearable={false}
                                            value={updateProfileForm.values.foodCategory}
                                            onChange={(e) => {
                                                updateProfileForm.setFieldValue('foodCategory', e)
                                            }}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: updateProfileForm.touched.foodCategory && updateProfileForm.errors.foodCategory ? 'red' : baseStyles.borderColor
                                                })
                                            }}
                                            isDisabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.foodCategory && updateProfileForm.errors.foodCategory}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='primarilyInterested'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.primarilyInterested && updateProfileForm.errors.primarilyInterested,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        Are you primarily interested in? <span className="is-invalid">*</span>
                                    </Label>
                                    <Select className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Please Select..."
                                            options={rdPrimarilyGoalsValues?.primarily_interest}
                                            isMulti={false}
                                            isClearable={false}
                                            value={updateProfileForm.values.primarilyInterested}
                                            onChange={(e) => {
                                                updateProfileForm.setFieldValue('primarilyInterested', e)
                                            }}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: updateProfileForm.touched.primarilyInterested && updateProfileForm.errors.primarilyInterested ? 'red' : baseStyles.borderColor
                                                })
                                            }}
                                            isDisabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.primarilyInterested && updateProfileForm.errors.primarilyInterested}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor='hearAbout'
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.hearAbout && updateProfileForm.errors.hearAbout,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}
                                    >
                                        How did you hear about The RD Link? <span className="is-invalid">*</span>
                                    </Label>
                                    <Select className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Please Select..."
                                            value={updateProfileForm.values.hearAbout}
                                            onChange={(e) => {
                                                updateProfileForm.setFieldValue('hearAbout', e)
                                                updateProfileForm.setFieldValue('otherPlatform', '')
                                            }}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: updateProfileForm.touched.hearAbout && updateProfileForm.errors.hearAbout ? 'red' : baseStyles.borderColor
                                                })
                                            }}
                                            options={hearAboutOption}
                                            isDisabled={getProfileLoading || updateProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.hearAbout && updateProfileForm.errors.hearAbout}</div>
                                </FormGroup>
                                {updateProfileForm.values.hearAbout.value === 'Other' && (
                                    <FormGroup tag={Col} md='4'>
                                        <Label htmlFor='otherPlatform'
                                               className={classnames({
                                                   'is-invalid': updateProfileForm.touched.otherPlatform && updateProfileForm.errors.otherPlatform,
                                                   'form-label': true,
                                                   'font-weight-bold': true
                                               })}
                                        >
                                            Where did you hear about us? <span className="is-invalid">*</span>
                                        </Label>
                                        <Input type='text'
                                               onBlur={updateProfileForm.handleBlur}
                                               onChange={updateProfileForm.handleChange}
                                               value={updateProfileForm.values.otherPlatform}
                                               name='otherPlatform'
                                               id='otherPlatform'
                                               placeholder='Your answer...'
                                               className={classnames({'is-invalid': updateProfileForm.touched.otherPlatform && updateProfileForm.errors.otherPlatform})}
                                               disabled={getProfileLoading || updateProfileLoading}
                                        />
                                        <div className="validation-err">{updateProfileForm.touched.otherPlatform && updateProfileForm.errors.otherPlatform}</div>
                                    </FormGroup>
                                )}
                                <FormGroup tag={Col} md='12'>
                                    <hr/>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <h6 className="font-weight-bolder bg-light p-1">
                                        Upload Image, PDF or Video
                                    </h6>
                                </FormGroup>
                                <FormGroup tag={Col} md="12">
                                    <Button.Ripple color='primary'
                                                   type="button"
                                                   disabled={getProfileLoading || updateProfileLoading}
                                                   onClick={() => setShowUploader(true)}>
                                        <File size='16'/> Choose File
                                    </Button.Ripple>
                                    {attachments?.length > 0 && (
                                        <>
                                            <Table className='mt-1 mb-1 table-bordered'>
                                                <thead>
                                                <tr>
                                                    <th width={1}>Sr.</th>
                                                    <th>Uploads</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {attachments.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.title}</td>
                                                        <td>
                                                            <Button.Ripple color='danger' type="button" size='sm'
                                                                           onClick={() => deleteUploads(index)} disabled={getProfileLoading || updateProfileLoading}>
                                                                <Trash size='14'/> Delete
                                                            </Button.Ripple>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </>
                                    )}
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <hr/>
                                </FormGroup>
                                {mediaDocs.length > 0 && (
                                    <>
                                        <FormGroup tag={Col} md='12'>
                                            <div className="p-1 bg-light">
                                                <strong>Documents</strong>
                                            </div>
                                            <div className="mt-3 d-flex flex-wrap">
                                                {mediaDocs.map((item, index) => (
                                                    <div key={index}>
                                                        {(item?.document_type === 'png' || item?.document_type === 'jpg' || item?.document_type === 'jpeg') && (
                                                            <div className='media-img media-file'>
                                                                <div className="remove-icon cursor-pointer" onClick={() => deleteDocument(item)}>
                                                                    <X size={16}/>
                                                                </div>
                                                                <div className="download-icon cursor-pointer">
                                                                    <a href='javascript:;' onClick={() => setImageViewer({show: true, src: item.document_url})}>
                                                                        <Eye size={16}/>
                                                                    </a>
                                                                </div>
                                                                <div className="name-wrap">
                                                                    <img className='rounded img-fit-contain' src={item.document_url} alt='Card image' style={{height: '100px', width: '100px'}}/>
                                                                    <p className="text-truncate mt-1">
                                                                        <strong>{item.document_name}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {item?.document_type === 'pdf' && (
                                                            <div className='media-img media-file'>
                                                                <div className="remove-icon cursor-pointer" onClick={() => deleteDocument(item)}>
                                                                    <X size={16}/>
                                                                </div>
                                                                <div className="download-icon cursor-pointer">
                                                                    <a href='javascript:;'
                                                                       onClick={() => setPdfViewer({show: true, url: item.document_url, filename: item.document_name})}>
                                                                        <Eye size={16}/>
                                                                    </a>
                                                                </div>
                                                                <div className="name-wrap">
                                                                    <img className='rounded png-icon-img' src={PDF} alt='PDF Icon'/>
                                                                    <p className="text-truncate mt-1">
                                                                        <strong>{item.document_name}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(item?.document_type === 'mp4') && (
                                                            <div className='media-img media-file'>
                                                                <div className="remove-icon cursor-pointer" onClick={() => deleteDocument(item)}>
                                                                    <X size={16}/>
                                                                </div>
                                                                <div className="download-icon cursor-pointer">
                                                                    <a href='javascript:;'
                                                                       onClick={() => setVideoPlayer({show: true, url: item.document_url, filename: item.document_name})}>
                                                                        <Eye size={16}/>
                                                                    </a>
                                                                </div>
                                                                <div className="name-wrap text-center">
                                                                    <Video className="text-primary" size={90}/>
                                                                    <p className="text-truncate">
                                                                        <strong>{item.document_name}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <hr/>
                                        </FormGroup>
                                    </>
                                )}
                            </Row>
                            <Row>
                                <FormGroup tag={Col} md='12' className="align-items-center d-flex justify-content-end">
                                    <div>
                                        <Button.Ripple type='submit' color='primary' className='btn btn-success' disabled={getProfileLoading || updateProfileLoading}>
                                            {updateProfileLoading ? (
                                                <span>
                                            <Spinner style={{width: '16px', height: '16px'}}/> Updating Profile...
                                        </span>
                                            ) : (
                                                <span>
                                            Update Profile <ArrowRight size="18"/>
                                        </span>
                                            )}
                                        </Button.Ripple>
                                    </div>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup tag={Col} md='12'>
                                    <hr/>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <div className="d-flex bg-light p-2">
                                        <div className="mr-2 text-danger">
                                            <Trash2 size="80"/>
                                        </div>
                                        <div>
                                            <p className="mb-0 text-primary"><strong>Unhappy?</strong></p>
                                            <p>Want to delete this account? All your information will be lost and unrecoverable.</p>
                                            <p className="text-danger cursor-pointer" onClick={deleteUserAccount}><strong>Delete my account.</strong></p>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Row>
                        </Form>
                    )}
                </CardBody>
            </Card>
            <Uploader
                show={showUploader}
                hide={() => setShowUploader(false)}
                onSuccess={(data) => onSuccessUploads(data)}
                onError={(error) => onErrorUploads(error)}
            />
            <ImageViewer
                show={imageViewer.show}
                hide={() => setImageViewer({show: false, src: ''})}
                images={[{src: imageViewer.src}]}
            />
            <PdfViewer show={pdfViewer.show}
                       url={pdfViewer.url}
                       filename={pdfViewer.filename}
                       hide={() => setPdfViewer({show: false, url: '', filename: ''})}
            />
            <VideoPlayer show={videoPlayer.show}
                         url={videoPlayer.url}
                         filename={videoPlayer.filename}
                         hide={() => setVideoPlayer({show: false, url: '', filename: ''})}
            />
        </Fragment>
    )
}

export default RDEditProfile
