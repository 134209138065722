import {NOTIFICATION_SETTING, NOTIFICATION_SETTING_UPDATE} from '../actionTypes/index'

const getNotificationSettingDefState = {
  getNotificationSettingLoading: false,
  getNotificationSettingData: {}
}

export const getNotificationSettingReducer = (state = getNotificationSettingDefState, action) => {
  switch (action.type) {
    case NOTIFICATION_SETTING.REQUEST:
      return {
        getNotificationSettingLoading: true,
        getNotificationSettingData: {}
      }
    case NOTIFICATION_SETTING.SUCCESS:
      return {
        getNotificationSettingLoading: false,
        getNotificationSettingData: action.payload
      }
    case NOTIFICATION_SETTING.ERROR:
      return {
        getNotificationSettingLoading: false,
        getNotificationSettingError: action.payload
      }
    default:
      return state
  }
}

const notificationSettingUpdateDefState = {
  notificationSettingUpdateLoading: false,
  notificationSettingUpdateData: {}
}

export const notificationSettingUpdateReducer = (state = notificationSettingUpdateDefState, action) => {
  switch (action.type) {
    case NOTIFICATION_SETTING_UPDATE.REQUEST:
      return {
        notificationSettingUpdateLoading: true,
        notificationSettingUpdateData: {}
      }
    case NOTIFICATION_SETTING_UPDATE.SUCCESS:
      return {
        notificationSettingUpdateLoading: false,
        notificationSettingUpdateData: action.payload
      }
    case NOTIFICATION_SETTING_UPDATE.ERROR:
      return {
        notificationSettingUpdateLoading: false,
        notificationSettingUpdateError: action.payload
      }
    case NOTIFICATION_SETTING_UPDATE.RESET:
      return {
        notificationSettingUpdateLoading: false,
        notificationSettingUpdateData: {}
      }
    default:
      return state
  }
}