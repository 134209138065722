import React from 'react'
import style from './style.module.css'
import CreateAccount from '../../../assets/images/CreateAccount.png'
import TickIcon from '../../../assets/images/TickIcon.png'
import CompleteProfile from '../../../assets/images/CompleteProfile.png'
import BeginConnecting from '../../../assets/images/BeginConnecting.png'

const HowItWorks = () => {
  return (
    <div className={style.HIWWrap}>
      <div className={style.innerWrap}>
        <div className={style.heading}>How It Works</div>
        <div className={style.HIWContentWrap}>
          <div className={style.hiwsec}>
            <img className={style.HIWImg} src={CreateAccount} alt='img' />
            <div className={style.Contentheading}>
              <img src={TickIcon} alt='tick' />
              <div>Create Account</div>
            </div>
            <div className={style.Content}>
              Get started with no risk - we offer a 30 day money back guarantee.
            </div>
          </div>
          <div className={style.hiwsec}>
            <img className={style.HIWImg} src={CompleteProfile} alt='img' />
            <div className={style.Contentheading}>
              <img src={TickIcon} alt='tick' />
              <div> Complete Your Profile</div>
            </div>
            <div className={style.Content}>
              Link offers easy access and ongoing visibility in front of
              hundreds of nutrition experts.
            </div>
          </div>
          <div className={style.hiwsec}>
            <img className={style.HIWImg} src={BeginConnecting} alt='img' />
            <div className={style.Contentheading}>
              <img src={TickIcon} alt='tick' />
              <div>Begin Connecting</div>
            </div>
            <div className={style.Content}>
              Instantly begin reaching the right nutrition experts through
              product trial, direct messaging, partnerships, campaigns, and
              more.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
