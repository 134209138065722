// Main
const API_URL = process.env.REACT_APP_BASEURL

// EVENT 
const EVENT = {
    MANAGE_EVENTS_DATA: '[ADMIN] MANAGE_EVENTS_DATA',
    EVENT_TYPES: '[ADMIN] EVENT_TYPES',
    EVENTS_SEARCH_DATA: '[ADMIN] EVENTS_SEARCH_DATA',
    CHANGE_EVENTS_DATA: '[ADMIN] CHANGE_EVENTS_DATA',
    COUNTRIES_DATA: '[ADMIN] COUNTRIES_DATA',
    STATES_DATA: '[ADMIN] STATES_DATA',
    EVENTS_DETAIL_DATA: '[ADMIN] EVENTS_DETAIL_DATA',
    BRAND_PURCHASE_MEMBERSHIP: "[ADMIN] BRAND_PURCHASE_MEMBERSHIP",
    BRAND_PARTICIPATION_REQUEST: "[ADMIN] BRAND_PARTICIPATION_REQUEST",
    BRAND_PURCHASE_MEMBERSHIP_SEARCH: "[ADMIN] BRAND_PURCHASE_MEMBERSHIP_SEARCH",
    BRAND_PARTICIPATION_REQUEST_SEARCH: "[ADMIN] BRAND_PARTICIPATION_REQUEST_SEARCH",
    EXPORT_EVENTS: "[ADMIN] EXPORT_EVENTS",
    ERROR: '[ADMIN] EVENT_ERROR'
}

export { API_URL, EVENT }