
const API_URL = process.env.REACT_APP_BASEURL
const BRANDS = {
    CARDS_LISTING: '[BRAND] CARDS_LISTING',
    CREATE_NEW_CARD: '[BRAND] CREATE_NEW_CARD',
    DELETE_CARD: '[BRAND] DELETE_CARD',
    SET_CARD_AS_DEFAULT: '[BRAND] SET_CARD_AS_DEFAULT',
    ERROR: '[BRAND] MANAGE_RD_ERROR'
}

const NULL = {
    CARDS_LISTING: '[BRAND] [NULL] CARDS_LISTING',
    CREATE_NEW_CARD: '[BRAND] [NULL] CREATE_NEW_CARD',
    DELETE_CARD: '[BRAND] [NULL] DELETE_CARD',
    SET_CARD_AS_DEFAULT: '[BRAND] [NULL] SET_CARD_AS_DEFAULT',
    ERROR: '[BRAND] MANAGE_RD_ERROR'
}

export { API_URL, BRANDS, NULL }
