// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  manageCmsListing: []
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'MANAGE_CMS_DATA':
      return {
        ...state,
        manageCmsListing: action.allData
      }
    case 'UPDATE_CMS_DATA':
      return {
        ...state,
        updatedCmsData: action.allData
      }
    default:
      return state
  }
}

export default DataTablesReducer
