import React, {useState, useEffect} from 'react'
import {
    Card,
    CardBody
} from 'reactstrap'
import Header from '../Header'

const SubscriptionPlans = () => {

    const [user, setUser] = useState(null)
    const [host, setHost] = useState("")
    const testHost = ['localhost', '127.0.0.1', 'frontend.therdlink.com']

    useEffect(() => {
        setHost(window.location.hostname)
        setUser(JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA)))
    }, [])

    return (
        <>
            <Header/>
            <Card className='mt-1 bg-light'>
                <div className="font-large-2 font-weight-bold text-dark text-center mt-2">Plans & Pricing</div>
                <div className="font-medium-4 font-weight-bold text-primary text-center">Please select your desired subscription plan from the options below</div>
                <CardBody>
                    {testHost.includes(host) ? (
                        <stripe-pricing-table pricing-table-id="prctbl_1PQ7ERIgylPJBQXhS5GI5q0p"
                                              publishable-key="pk_test_51J0RjVIgylPJBQXhwWjTXM7b0IvoTvhg3gbpsXOPS1sRbu7l98E8L925CCufAtL1dUpVklC5iz73qAVWzv4dzcn500Jws8KJaH"
                                              customer-email={user?.parent_email ? user?.parent_email : user?.email}
                        >
                        </stripe-pricing-table>
                    ) : (
                        <stripe-pricing-table pricing-table-id="prctbl_1P5xW5JaYauMi320PAudyiHV"
                                              publishable-key="pk_live_AszmJDHaci0zOdI1nJ9aA7U4"
                                              customer-email={user?.parent_email ? user?.parent_email : user?.email}
                        >
                        </stripe-pricing-table>
                    )}
                </CardBody>
            </Card>
        </>
    )
}

export default SubscriptionPlans
