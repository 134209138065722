import {
    RESOURCES_CONTENT,
    RESOURCES_CONTENT_DELETE,
    RESOURCES_CONTENT_STATUS,
    UPLOAD_DOCS, UPLOAD_LINK, UPLOAD_MEDIA
} from "../actionsTypes"

const uploadLinkResourceDefState = {
    uploadLinkResourceLoading: false,
    uploadLinkResourceData: {},
    uploadLinkResourceError: {}
}

export const uploadLinkResourceReducer = (state = uploadLinkResourceDefState, action) => {
    switch (action.type) {
        case UPLOAD_LINK.REQUEST:
            return {
                uploadLinkResourceLoading: true,
                uploadLinkResourceData: {}
            }
        case UPLOAD_LINK.SUCCESS:
            return {
                uploadLinkResourceLoading: false,
                uploadLinkResourceData: action.payload
            }
        case UPLOAD_LINK.ERROR:
            return {
                uploadLinkResourceLoading: false,
                uploadLinkResourceError: action.payload
            }
        case UPLOAD_LINK.RESET:
            return {
                uploadLinkResourceLoading: false,
                uploadLinkResourceData: {}
            }
        default:
            return state
    }
}

const resourcesContentDefState = {
    resourcesContentLoading: false,
    resourcesContentData: {},
    resourcesContentError: {}
}

export const resourcesContentReducer = (state = resourcesContentDefState, action) => {
    switch (action.type) {
        case RESOURCES_CONTENT.REQUEST:
            return {
                resourcesContentLoading: true,
                resourcesContentData: {}
            }
        case RESOURCES_CONTENT.SUCCESS:
            return {
                resourcesContentLoading: false,
                resourcesContentData: action.payload
            }
        case RESOURCES_CONTENT.ERROR:
            return {
                resourcesContentLoading: false,
                resourcesContentError: action.payload
            }
        default:
            return state
    }
}

const resourceContentStatusDefState = {
    resourceContentStatusLoading: false,
    resourceContentStatusData: {},
    resourceContentStatusError: {}
}

export const resourceContentStatusReducer = (state = resourceContentStatusDefState, action) => {
    switch (action.type) {
        case RESOURCES_CONTENT_STATUS.REQUEST:
            return {
                resourceContentStatusLoading: true,
                resourceContentStatusData: {}
            }
        case RESOURCES_CONTENT_STATUS.SUCCESS:
            return {
                resourceContentStatusLoading: false,
                resourceContentStatusData: action.payload
            }
        case RESOURCES_CONTENT_STATUS.ERROR:
            return {
                resourceContentStatusLoading: false,
                resourceContentStatusError: action.payload
            }
        case RESOURCES_CONTENT_STATUS.RESET:
            return {
                resourceContentStatusLoading: false,
                resourceContentStatusData: {}
            }
        default:
            return state
    }
}

const resourceContentDeleteDefState = {
    resourceContentDeleteLoading: false,
    resourceContentDeleteData: {},
    resourceContentDeleteError: {}
}

export const resourceContentDeleteReducer = (state = resourceContentDeleteDefState, action) => {
    switch (action.type) {
        case RESOURCES_CONTENT_DELETE.REQUEST:
            return {
                resourceContentDeleteLoading: true,
                resourceContentDeleteData: {}
            }
        case RESOURCES_CONTENT_DELETE.SUCCESS:
            return {
                resourceContentDeleteLoading: false,
                resourceContentDeleteData: action.payload
            }
        case RESOURCES_CONTENT_DELETE.ERROR:
            return {
                resourceContentDeleteLoading: false,
                resourceContentDeleteError: action.payload
            }
        case RESOURCES_CONTENT_DELETE.RESET:
            return {
                resourceContentDeleteLoading: false,
                resourceContentDeleteData: {}
            }
        default:
            return state
    }
}

const uploadDocsDefState = {
    uploadDocsLoading: false,
    uploadDocsData: {},
    uploadDocsError: {}
}

export const uploadDocsReducer = (state = uploadDocsDefState, action) => {
    switch (action.type) {
        case UPLOAD_DOCS.REQUEST:
            return {
                uploadDocsLoading: true,
                uploadDocsData: {}
            }
        case UPLOAD_DOCS.SUCCESS:
            return {
                uploadDocsLoading: false,
                uploadDocsData: action.payload
            }
        case UPLOAD_DOCS.ERROR:
            return {
                uploadDocsLoading: false,
                uploadDocsError: action.payload
            }
        case UPLOAD_DOCS.RESET:
            return {
                uploadDocsLoading: false,
                uploadDocsData: {}
            }
        default:
            return state
    }
}

const uploadMediaDefState = {
    uploadMediaLoading: false,
    uploadMediaData: {},
    uploadMediaError: {}
}

export const uploadMediaReducer = (state = uploadMediaDefState, action) => {
    switch (action.type) {
        case UPLOAD_MEDIA.REQUEST:
            return {
                uploadMediaLoading: true,
                uploadMediaData: {}
            }
        case UPLOAD_MEDIA.SUCCESS:
            return {
                uploadMediaLoading: false,
                uploadMediaData: action.payload
            }
        case UPLOAD_MEDIA.ERROR:
            return {
                uploadMediaLoading: false,
                uploadMediaError: action.payload
            }
        case UPLOAD_MEDIA.RESET:
            return {
                uploadMediaLoading: false,
                uploadMediaData: {}
            }
        default:
            return state
    }
}
