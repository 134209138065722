import { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { getBlogList } from '@src/redux/actions/outerPages'
import { Spinner } from "reactstrap"
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import style from './style.module.css'
import Header from '../Header'
import TrustedBrands from '../TrustedBrands'
import Footer from '../Footer'
import { Link } from 'react-router-dom'
import DummyBlogImage from '@src/assets/images/banner/dummy-blog.jpg'

const blog = () => {

  const [isPageLoading, setIsPageLoading] = useState(false)
  const [paginationInfo, setPaginationInfo] = useState(null)
  const dispatch = useDispatch()
  const outerPagesStoreSlice = useSelector(store => store.outerPages)

  useEffect(() => {
    if (!!outerPagesStoreSlice?.blogs) {
      const { links, meta, pages } = outerPagesStoreSlice.blogs
      setPaginationInfo({ ...links, ...meta, pages })
      setIsPageLoading(false)
    }
  }, [outerPagesStoreSlice])

  useEffect(() => {
    window.scroll(0, 0)
    setIsPageLoading(true)
    dispatch(getBlogList(false))
  }, [])

  const handleCustomPagination = pageUrl => {
    window.scroll(0, 0)
    setIsPageLoading(true)
    dispatch(getBlogList(true, pageUrl))
  }

  return (
    <Fragment>
      <Header />
      <div className={style.blogswrap}>
        <div className={style.heading}>Blog</div>
        {isPageLoading && (
          <div className="w-100 my-4 text-center">
            <Spinner color='primary' />
          </div>)}
        {!isPageLoading && (
          <div className={style.blogs}>
            {outerPagesStoreSlice?.blogs?.data?.map((blog, index) => (
              <Link to={`/blog/${blog.slug}`} key={index}>
                <div className={style.blog}>
                  <div className={style.imgWrap}>
                    <div className={style.bgImg} style={{ background: "white"}}>
                      <img className="blog-list-img" src={blog.img || DummyBlogImage} alt='blog'/>
                    </div>
                    <div className={style.dateWrap}>{blog?.created_at}</div>
                  </div>
                  <div className={style.title}>{blog.title}</div>
                  <div className={style.desc} dangerouslySetInnerHTML={{ __html: blog.description }}></div>
                </div>
              </Link>
            ))}
            <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination} />
          </div>)}
      </div>
      <TrustedBrands />
      <Footer />
    </Fragment >
  )
}

export default blog
