import {ADMIN_DASHBOARD, RD, BRAND, UPLOAD_AGREEMENT, SEND_AGREEMENT} from '../actionType'

const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    manageRdsGeneralInfo: null,
    manageBrandsGeneralInfo: null,
    rdPartnershipPayments: null,
    paymentHistoryData: null,
    rdPaymentHistoryData: null,
    invoiceDetailData: null,
    sendRdAgreementResponse: null,
    rdInvoiceDetailData: null,
    error: null,

    uploadAgreementLoading: false,
    uploadAgreementFullFill: null,
    uploadAgreementError: null,

    sendAgreementLoading: false,
    sendAgreementFullFill: null,
    sendAgreementError: null
}

const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DATA':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        case 'DELETE_INVOICE':
            return {...state}
        case ADMIN_DASHBOARD.MANAGE_RD_LISTING:
            return {
                ...state,
                manageRdsGeneralInfo: action.manageRdsGeneralInfo
            }
        case ADMIN_DASHBOARD.MANAGE_BRANDS_LISTING:
            return {
                ...state,
                manageBrandsGeneralInfo: action.manageBrandsGeneralInfo
            }
        case RD.PARTNERSHIP_PAYMENT_LISTING:
            return {...state, rdPartnershipPayments: action.rdPartnershipPayments}
        case BRAND.PAYMENT_HISTORY:
            return {...state, paymentHistoryData: action.paymentHistoryData}
        case BRAND.RD_PAYMENT_HISTORY:
            return {...state, rdPaymentHistoryData: action.rdPaymentHistoryData}
        case BRAND.INVOICE_DETAIL:
            return {...state, invoiceDetailData: action.invoiceDetailData}
        case BRAND.RD_INVOICE_DETAIL:
            return {...state, rdInvoiceDetailData: action.rdInvoiceDetailData}
        case RD.SEND_AGREEMENT:
            return {...state, sendRdAgreementResponse: action.sendRdAgreementResponse}
        case ADMIN_DASHBOARD.ERROR:
            return {
                ...state,
                error: action.error
            }
        // --- Upload Agreement ---
        case UPLOAD_AGREEMENT.REQUEST:
            return {
                ...state,
              uploadAgreementLoading: true,
              uploadAgreementFullFill: null,
              uploadAgreementError: null
            }
        case UPLOAD_AGREEMENT.SUCCESS:
            return {
                ...state,
              uploadAgreementLoading: false,
              uploadAgreementFullFill: action.payload,
              uploadAgreementError: null
            }
        case UPLOAD_AGREEMENT.ERROR:
            return {
                ...state,
              uploadAgreementLoading: false,
              uploadAgreementFullFill: null,
              uploadAgreementError: action.payload
            }
        case UPLOAD_AGREEMENT.RESET:
            return {
                ...state,
              uploadAgreementLoading: false,
              uploadAgreementFullFill: null,
              uploadAgreementError: null
            }
        // --- Send Agreement ---
        case SEND_AGREEMENT.REQUEST:
            return {
                ...state,
                sendAgreementLoading: true,
                sendAgreementFullFill: null,
                sendAgreementError: null
            }
        case SEND_AGREEMENT.SUCCESS:
            return {
                ...state,
                sendAgreementLoading: false,
                sendAgreementFullFill: action.payload,
                sendAgreementError: null
            }
        case SEND_AGREEMENT.ERROR:
            return {
                ...state,
                sendAgreementLoading: false,
                sendAgreementFullFill: null,
                sendAgreementError: action.payload
            }
        case SEND_AGREEMENT.RESET:
            return {
                ...state,
                sendAgreementLoading: false,
                sendAgreementFullFill: null,
                sendAgreementError: null
            }
        default:
            return {...state}
    }
}

export default invoiceReducer
