import {Fragment, useState, useEffect} from 'react'
import navigation from '@src/navigation/vertical'
import classnames from 'classnames'
import VerticalMenuHeader from './VerticalMenuHeader'
import VerticalNavMenuItems from './VerticalNavMenuItems'

const Sidebar = props => {
    const {menuCollapsed, routerProps, menu, currentActiveItem, skin} = props

    const [groupOpen, setGroupOpen] = useState([])
    const [groupActive, setGroupActive] = useState([])
    const [activeItem, setActiveItem] = useState(null)
    const [menuItems, setMenuItems] = useState([])
    const [menuHover, setMenuHover] = useState(false)

    const onMouseEnter = () => {
        if (menuCollapsed) {
            setMenuHover(true)
        }
    }

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        if (userData && userData.role === 'sub_admin') {
            const permissions = userData?.permissions
            const filteredNavbarMenu = navigation.filter(item => {
                return permissions.some(permission => permission === item.id)
            })
            setMenuItems(filteredNavbarMenu)
        } else {
            setMenuItems(navigation)
        }
    }, [])

    return (
        <Fragment>
            <div
                className={classnames('main-menu menu-fixed menu-accordion menu-shadow', {
                    expanded: menuHover || menuCollapsed === false,
                    'menu-light': skin !== 'semi-dark' && skin !== 'dark',
                    'menu-dark': skin === 'semi-dark' || skin === 'dark'
                })}
                onMouseEnter={onMouseEnter}
                onMouseLeave={() => setMenuHover(false)}
            >
                {menu ? (
                    menu
                ) : (
                    <Fragment>
                        <VerticalMenuHeader setGroupOpen={setGroupOpen} menuHover={menuHover} {...props} />
                        <ul className='navigation navigation-main'>
                            <VerticalNavMenuItems
                                items={menuItems}
                                groupActive={groupActive}
                                setGroupActive={setGroupActive}
                                activeItem={activeItem}
                                setActiveItem={setActiveItem}
                                groupOpen={groupOpen}
                                setGroupOpen={setGroupOpen}
                                routerProps={routerProps}
                                menuCollapsed={menuCollapsed}
                                menuHover={menuHover}
                                currentActiveItem={currentActiveItem}
                            />
                        </ul>
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}

export default Sidebar