const initialState = {
  chats: [],
  contacts: [],
  userProfile: {},
  selectedUser: {},
  chatUserProfile: {},
  singleUserChat: {},
  singleUserChatDetail: {}
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CHAT_USER_PROFILE':
      return { ...state, chatUserProfile: action.chatUserProfiles }
    case 'GET_SINGLE_USER_CHATS':
      return { ...state, singleUserChat: action.singleUserChats }
    case 'GET_SINGLE_USER_CHATS_DETAIL':
      return { ...state, singleUserChatDetail: action.singleUserChatDetails }
    case 'GET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile }
    case 'GET_CHAT_CONTACTS':
      return { ...state, chats: action.data.chatsContacts, contacts: action.data.contacts }
    case 'SELECT_CHAT':
      return { ...state, selectedUser: action.data }
    case 'CLEAR_ADMIN_CHAT_FIRST':
      return { ...state, singleUserChat: {} }
    case 'CLEAR_ADMIN_CHAT_DETAILS_FIRST':
      return { ...state, singleUserChatDetail: {} }
    case 'SEND_MSG':
      // ** Add new msg to chat
      const newMsg = action.data.response.chat
      return { ...state, selectedUser: { ...state.selectedUser, chat: newMsg } }
    default:
      return state
  }
}

export default chatReducer
