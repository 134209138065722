import {
    RD,
    GENERAL,
    RD_MY_PARTNERSHIP,
    RD_STATS,
    REC_BRANDS
} from '../../actionTypes'

const initialState = {
    rdOpportunitiesList: null,
    favoriteFoldersRes: null,
    createfavoriteFolderRes: null,
    updatefavoriteFolderRes: null,
    deletefavoriteFolderRes: null,
    favoriteBrandsProfilesRes: null,
    brandProductAttributesRes: null,
    rdNotifications: null,
    countries: null,
    regionsByCountryID: null,
    statesByRegionID: null,
    citiesByStateID: null,
    allbrandsAtRd: null,
    myBrands: null,
    brandProfileAtRd: null,
    addToFavroiteRes: null,
    viewRdProfile: null,
    updateRdProfile: null,
    removeMediaAtProfile: null,
    upcommingOpportunitiesAtProfile: null,
    pastOpportunitiesAtProfile: null,
    brandCampaignsAtProfile: null,
    brandsByPastEventId: null,
    error: null,
    rdUnreadMessages: null,
    rdUnreadNotifications: null,

    myPartnershipLoading: false,
    myPartnershipFullFill: null,
    myPartnershipError: null,

    rdDashboardStatsLoading: false,
    rdDashboardStatsFullFill: null,
    rdDashboardStatsError: null,

    rdRecommendedBrandsLoading: false,
    rdRecommendedBrandsFullFill: null,
    rdRecommendedBrandsError: null
}

const rdReducer = (state = initialState, action) => {
    switch (action.type) {
        case RD.COUNTRIES:
            return {...state, countries: action.countries}
        case RD.REGIONS_BY_COUNTRY_ID:
            return {
                ...state,
                regionsByCountryID: action.regionsByCountryID
            }
        case RD.STATES_BY_REGIONS_ID:
            return {
                ...state,
                statesByRegionID: action.statesByRegionID
            }
        case RD.CITIES_BY_STATES_ID:
            return {
                ...state,
                citiesByStateID: action.citiesByStateID
            }
        case RD.BRANDS_PRODUCT_ATTRIBUTES:
            return {
                ...state, brandProductAttributesRes: action.brandProductAttributesRes
            }
        case RD.FAVORITE_BRANDS_PROFILES:
            return {
                ...state, favoriteBrandsProfilesRes: action.favoriteBrandsProfilesRes
            }
        case RD.OPPORTUNITIES_LIST:
            return {
                ...state,
                rdOpportunitiesList: action.response
            }
        case RD.FAVORITE_FOLDERS:
            return {
                ...state,
                favoriteFoldersRes: action.favoriteFoldersRes
            }
        case RD.CREATE_FAVORITE_FOLDER:
            return {
                ...state,
                createfavoriteFolderRes: action.createfavoriteFolderRes
            }
        case RD.UPDATE_FAVORITE_FOLDER:
            return {
                ...state,
                updatefavoriteFolderRes: action.updatefavoriteFolderRes
            }
        case RD.DELETE_FAVORITE_FOLDER:
            return {
                ...state,
                deletefavoriteFolderRes: action.deletefavoriteFolderRes
            }
        case RD.RD_NOTIFICATIONS:
            return {
                ...state,
                rdNotifications: action.rdNotifications
            }
        case RD.ALL_BRANDS:
            return {
                ...state,
                allbrandsAtRd: action.allbrandsAtRd
            }
        case RD.MY_BRANDS:
            return {
                ...state,
                myBrands: action.myBrands
            }
        case RD.BRAND_PROFILE:
            return {
                ...state,
                brandProfileAtRd: action.brandProfileAtRd
            }
        case RD.VIEW_PROFILE:
            return {
                ...state,
                viewRdProfile: action.viewRdProfile
            }
        case RD.UPDATE_PROFILE:
            return {
                ...state,
                updateRdProfile: action.updateRdProfile
            }
        case RD.ADD_TO_FAVORITE:
            return {...state, addToFavroiteRes: action.addToFavroiteRes}
        case RD.REMOVE_MEDIA_ON_PROFILE:
            return {...state, removeMediaAtProfile: action.removeMediaAtProfile}
        case RD.RD_UNREAD_NOTIFICATIONS:
            return {...state, rdUnreadNotifications: action.rdUnreadNotifications}
        case RD.UPCOMMING_OPPORTUNITIES_LIST_AT_PROFILE:
            return {...state, upcommingOpportunitiesAtProfile: action.upcommingOpportunitiesAtProfile}
        case RD.PAST_OPPORTUNITIES_LIST_AT_PROFILE:
            return {...state, pastOpportunitiesAtProfile: action.pastOpportunitiesAtProfile}
        case RD.BRAND_CAMPAIGNS_AT_PROFILE:
            return {...state, brandCampaignsAtProfile: action.brandCampaignsAtProfile}
        case RD.BRANDS_BY_PAST_EVENT_ID:
            return {...state, brandsByPastEventId: action.brandsByPastEventId}
        case GENERAL.RD_DASHBOARD_ERROR:
            return {
                ...state,
                error: action.error
            }
        case RD.RD_UNREAD_MESSAGES:
            return {
                ...state,
                rdUnreadMessages: action.rdUnreadMessages
            }
        case "REMOVE_NOTIFICATION_COUNT_ON_CLICK":
            return {...state, rdUnreadNotifications: null}
        // --- RD My Partnerships ---
        case RD_MY_PARTNERSHIP.REQUEST:
            return {
                ...state,
                myPartnershipLoading: true,
                myPartnershipFullFill: null,
                myPartnershipError: null
            }
        case RD_MY_PARTNERSHIP.SUCCESS:
            return {
                ...state,
                myPartnershipLoading: false,
                myPartnershipFullFill: action.payload,
                myPartnershipError: null
            }
        case RD_MY_PARTNERSHIP.ERROR:
            return {
                ...state,
                myPartnershipLoading: false,
                myPartnershipFullFill: null,
                myPartnershipError: action.payload
            }
        // --- RD Dashboard Stats ---
        case RD_STATS.REQUEST:
            return {
                ...state,
                rdDashboardStatsLoading: true,
                rdDashboardStatsFullFill: null,
                rdDashboardStatsError: null
            }
        case RD_STATS.SUCCESS:
            return {
                ...state,
                rdDashboardStatsLoading: false,
                rdDashboardStatsFullFill: action.payload,
                rdDashboardStatsError: null
            }
        case RD_STATS.ERROR:
            return {
                ...state,
                rdDashboardStatsLoading: false,
                rdDashboardStatsFullFill: null,
                rdDashboardStatsError: action.payload
            }
        // --- RD Recommended Brands ---
        case REC_BRANDS.REQUEST:
            return {
                ...state,
                rdRecommendedBrandsLoading: true,
                rdRecommendedBrandsFullFill: null,
                rdRecommendedBrandsError: null
            }
        case REC_BRANDS.SUCCESS:
            return {
                ...state,
                rdRecommendedBrandsLoading: false,
                rdRecommendedBrandsFullFill: action.payload,
                rdRecommendedBrandsError: null
            }
        case REC_BRANDS.ERROR:
            return {
                ...state,
                rdRecommendedBrandsLoading: false,
                rdRecommendedBrandsFullFill: null,
                rdRecommendedBrandsError: action.payload
            }
        default:
            return state
    }
}

export default rdReducer