import React, { useEffect, useState } from 'react'
import style from './style.module.css'
import TextTransition, { presets } from 'react-text-transition'
import { ReactComponent as Strategicoutreach } from '../../../assets/images/icons_uses/strategic-outreach.svg'
import { ReactComponent as Productseeding } from '../../../assets/images/icons_uses/product-seeding.svg'
import { ReactComponent as Visibilityawareness } from '../../../assets/images/icons_uses/visibility&awareness.svg'
import { ReactComponent as Influencer } from '../../../assets/images/icons_uses/influencer.svg'
import { ReactComponent as Brandambassador } from '../../../assets/images/icons_uses/brand-ambassador.svg'
import { ReactComponent as Supportkeymarket } from '../../../assets/images/icons_uses/support-keymarket.svg'
import { ReactComponent as Directmessaging } from '../../../assets/images/icons_uses/direct-messaging.svg'
import { ReactComponent as Easyaccess } from '../../../assets/images/icons_uses/easyaccess.svg'

import { ReactComponent as Nutritionexpertdatabase } from '../../../assets/images/icons_features/nutrition_expert_database.svg'
import { ReactComponent as Sponsorship_opportunities } from '../../../assets/images/icons_features/sponsorship_opportunities.svg'
import { ReactComponent as Direct_messaging } from '../../../assets/images/icons_features/direct_messaging.svg'
import { ReactComponent as Brand_campaigns } from '../../../assets/images/icons_features/brand_campaigns.svg'
import { ReactComponent as Metricstracking } from '../../../assets/images/icons_features/metrics&tracking.svg'
import { ReactComponent as Brandprofile } from '../../../assets/images/icons_features/brand_profile.svg'
import { ReactComponent as Sharenews_advertisement } from '../../../assets/images/icons_features/sharenews_advertisement.svg'
import { ReactComponent as Easy_partner_payments } from '../../../assets/images/icons_features/easy_partner_payments.svg'

const UsesandFeatures = () => {
  const TEXTS = ['Credibility', 'Trust', 'Trial', 'Awareness']

  const [index, setIndex] = useState(0)

  useEffect(() => {
    window.scroll(0, 0)
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000
    )
    return () => clearTimeout(intervalId)
  }, [])

  return (
    <div className={style.UsesandFeaturesWrap}>
      <div className={style.heading}>
        Drive{' '}
        <TextTransition
          className={style.trans}
          text={TEXTS[index % TEXTS.length]}
          springConfig={presets.wobbly}
        />{' '}
      </div>

      <div className={style.wrapp}>
        <div className={style.subheading}>Uses</div>

        <div className={style.featuresOuterWrap}>
          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Strategicoutreach />
            </div>
            <div className={style.featureHeading}>Strategic Outreach</div>
            <div className={style.featureText}>
              Find the right experts to represent your brand
            </div>
          </div>

          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Productseeding />
            </div>
            <div className={style.featureHeading}>Product Seeding</div>
            <div className={style.featureText}>
              Access to sampling and demo opportunities
            </div>
          </div>

          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Visibilityawareness />
            </div>
            <div className={style.featureHeading}>Visibility & Awareness</div>
            <div className={style.featureText}>
              Among influential experts
            </div>
          </div>

          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Influencer />
            </div>
            <div className={style.featureHeading}>Influencers, Media & PR</div>
            <div className={style.featureText}>
              Content creation and national promotion
            </div>
          </div>

          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Brandambassador />
            </div>
            <div className={style.featureHeading}>
              Spokespeople & Brand Ambassadors
            </div>
            <div className={style.featureText}>
              Credible experts recommending your product
            </div>
          </div>

          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Supportkeymarket />
            </div>
            <div className={style.featureHeading}>
              Support Key Markets & Retailers
            </div>
            <div className={style.featureText}>Retail RDs and more</div>
          </div>

          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Directmessaging />
            </div>
            <div className={style.featureHeading}>
              Exclusive Partnership Opportunities
            </div>
            <div className={style.featureText}>
              Podcasts, tv, cooking demos, and more
            </div>
          </div>

          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Easyaccess />
            </div>
            <div className={style.featureHeading}>Easy Access</div>
            <div className={style.featureText}>
              Saves your team hours of scrolling social media
            </div>
          </div>
        </div>
      </div>

      <div className={style.wrapp}>
        <div className={style.subheading}>Features</div>

        <div className={style.featuresOuterWrap}>
          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Nutritionexpertdatabase />
            </div>
            <div className={style.featureHeading}>
              Nutrition Expert Database
            </div>
            <div className={style.featureText}>
              Hundreds of RDs at your fingertips
            </div>
          </div>

          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Sponsorship_opportunities />
            </div>
            <div className={style.featureHeading}>
              Exclusive Sponsorship Opportunities
            </div>
            <div className={style.featureText}>
              All backed by credible experts
            </div>
          </div>

          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Direct_messaging />
            </div>
            <div className={style.featureHeading}>
              Direct Messaging & Mass Outreach
            </div>
            <div className={style.featureText}>
              Connect with experts 1:1 or reach out to many at once
            </div>
          </div>

          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Brand_campaigns />
            </div>
            <div className={style.featureHeading}>Brand Campaigns</div>
            <div className={style.featureText}>
              Post your marketing campaigns for RDs to sign up or apply
            </div>
          </div>

          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Metricstracking />
            </div>
            <div className={style.featureHeading}>Metrics & Tracking</div>
            <div className={style.featureText}>
              Complete dashboards to show results
            </div>
          </div>

          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Brandprofile />
            </div>
            <div className={style.featureHeading}>Brand Profile</div>
            <div className={style.featureText}>
              Stand out and get seen by nutrition experts
            </div>
          </div>

          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Sharenews_advertisement />
            </div>
            <div className={style.featureHeading}>
              Share News & Announcements
            </div>
            <div className={style.featureText}>
              Boost your profile & share announcements on RD dashboards
            </div>
          </div>

          <div className={style.featureWrap}>
            <div className={style.icon}>
              <Easy_partner_payments />
            </div>
            <div className={style.featureHeading}>Easy Partner Payments</div>
            <div className={style.featureText}>
              1-click payments via ACH or credit card
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UsesandFeatures
