import {
    CAMPAIGN_ADD_TO_FAV,
    CAMPAIGN_MSG,
    RD_MASTER_FEATURES,
    LOCATION_FROM_ZIP,
    OPPORTUNITIES,
    RD_CARD_INFO_SAVE,
    RD_CREDIT_CARD_DELETE,
    RD_CREDIT_CARD_INFO,
    RD_CREDIT_CARD_SET_DEFAULT,
    RD_GET_PLAN,
    RD_ACTIVE_PLAN,
    RD_CANCEL_PLAN,
    RD_RESOURCES,
    RD_RESOURCE_CONTENT,
    RD_CHECK_CREDIT_CARD,
    RD_SAVE_PLAN_INFO,
    RD_LOAD_UPCOMING_EVENTS,
    RD_LOAD_PAST_EVENTS,
    VERIFY_EMAIL,
    BRAND_INVOICES,
    PAY_NEW_INVOICE,
    RD_ACCOUNT_SUCCESS,
    RD_ACCOUNT_FAIL,
    RD_ACCOUNT_DELETE,
    RD_ACCOUNT_CREATE,
    RD_ACCOUNT_DETAILS,
    RD_ACCOUNT_VERIFY,
    RD_ACCOUNT_MARK_DEFAULT,
    UPLOAD_BRAND_AGREEMENT,
    BRAND_CREDIT_CARDS,
    BRAND_BANK_ACCOUNTS,
    BRAND_INVOICE_DETAILS,
    BRAND_SUBMIT_INVOICE,
    BRAND_UPCOMING_PARTNERSHIPS,
    BRAND_PAST_PARTNERSHIPS,
    RD_CREATE_OPPORTUNITY,
    RD_GET_OPPORTUNITY,
    RD_UPDATE_OPPORTUNITY,
    RD_DELETE_OPPORTUNITY_DOC,
    BRAND_CAMPAIGNS_LISTING,
    BRAND_CREATE_CAMPAIGN,
    BRAND_EDIT_CAMPAIGN,
    BRAND_CAMPAIGN_QUESTIONS,
    BRAND_CAMPAIGN_UPDATE,
    BRAND_CAMPAIGN_DELETE,
    BRAND_CAMPAIGN_STATUS,
    BRAND_CAMPAIGN_LIVE,
    BRAND_CAMPAIGN_FORM,
    BRAND_OPP_DETAILS,
    BRAND_OPP_PARTICIPATE,
    BRAND_CAMPAIGNS_ALL_LISTING,
    INVITE_CAMPAIGN_ON_EMAIL,
    INVITE_CAMPAIGN_ON_MSG,
    CAMP_APPLICANT_LIST
} from '../actionType/index'

const initialState = {
    products: [],
    wishlist: [],
    cart: [],
    productDetail: {},
    params: {},
    totalProducts: 0,
    opportunitiesData: null,
    opportunityRdDetail: null,
    countries: null,
    regions: null,
    states: null,
    cities: null,
    eventTypes: null,
    audienceType: null,
    pastEvents: null,
    eventBrands: null,
    eventBrandsDetail: null,
    providedFeedback: null,
    feedbackDetail: null,
    contactUs: null,
    submitBankCard: null,
    bankAccountDetail: null,
    accountDelete: null,
    upcommingOpportunitiesData: null,
    delListingOpportunity: null,
    campaingsListingData: null,
    campaingsSearchData: null,
    invoiceDetailData: null,
    bankAccountVerificationLink: null,
    bankAccountVerificationSuccess: null,
    bankAccountVerificationError: null,
    addedNote: null,
    campaignDetailExport: null,

    rdAccountSuccessLoading: false,
    rdAccountSuccessFullFill: null,
    rdAccountSuccessError: null,

    rdAccountFailLoading: false,
    rdAccountFailFullFill: null,
    rdAccountFailError: null,

    rdAccountDeleteLoading: false,
    rdAccountDeleteFullFill: null,
    rdAccountDeleteError: null,

    rdAccountCreateLoading: false,
    rdAccountCreateFullFill: null,
    rdAccountCreateError: null,

    rdAccountDetailsLoading: false,
    rdAccountDetailsFullFill: null,
    rdAccountDetailsError: null,

    rdAccountVerifyLoading: false,
    rdAccountVerifyFullFill: null,
    rdAccountVerifyError: null,

    rdAccountMarkDefLoading: false,
    rdAccountMarkDefFullFill: null,
    rdAccountMarkDefError: null,

    uploadBrandAgreementLoading: false,
    uploadBrandAgreementFullFill: null,
    uploadBrandAgreementError: null,

    brandCreditCardsLoading: false,
    brandCreditCardsFullFill: null,
    brandCreditCardsError: null,

    brandBankAccountsLoading: false,
    brandBankAccountsFullFill: null,
    brandBankAccountsError: null,

    brandInvoiceDetailsLoading: false,
    brandInvoiceDetailsFullFill: null,
    brandInvoiceDetailsError: null,

    brandInvoiceSubmitLoading: false,
    brandInvoiceSubmitFullFill: null,
    brandInvoiceSubmitError: null,

    upcomingPartnershipsLoading: false,
    upcomingPartnershipsFullFill: null,
    upcomingPartnershipsError: null,

    pastPartnershipsLoading: false,
    pastPartnershipsFullFill: null,
    pastPartnershipsError: null,

    rdCreateOpportunityLoading: false,
    rdCreateOpportunityFullFill: null,
    rdCreateOpportunityError: null,

    rdGetOpportunityLoading: false,
    rdGetOpportunityFullFill: null,
    rdGetOpportunityError: null,

    rdUpdateOpportunityLoading: false,
    rdUpdateOpportunityFullFill: null,
    rdUpdateOpportunityError: null,

    rdDeleteOpportunityDocLoading: false,
    rdDeleteOpportunityDocFullFill: null,
    rdDeleteOpportunityDocError: null,

    brandCampaignsListingLoading: false,
    brandCampaignsListingFullFill: null,
    brandCampaignsListingError: null,

    brandCreateCampaignLoading: false,
    brandCreateCampaignFullFill: null,
    brandCreateCampaignError: null,

    brandEditCampaignLoading: false,
    brandEditCampaignFullFill: null,
    brandEditCampaignError: null,

    brandCampaignQuestionsLoading: false,
    brandCampaignQuestionsFullFill: null,
    brandCampaignQuestionsError: null,

    brandCampaignUpdateLoading: false,
    brandCampaignUpdateFullFill: null,
    brandCampaignUpdateError: null,

    brandCampaignDeleteLoading: false,
    brandCampaignDeleteFullFill: null,
    brandCampaignDeleteError: null,

    brandCampaignStatusLoading: false,
    brandCampaignStatusFullFill: null,
    brandCampaignStatusError: null,

    brandCampaignLiveLoading: false,
    brandCampaignLiveFullFill: null,
    brandCampaignLiveError: null,

    brandCampaignFormLoading: false,
    brandCampaignFormFullFill: null,
    brandCampaignFormError: null,

    brandOppDetailLoading: false,
    brandOppDetailFullFill: null,
    brandOppDetailError: null,

    brandOppParticipateLoading: false,
    brandOppParticipateFullFill: null,
    brandOppParticipateError: null,

    brandCampaignsAllListingLoading: false,
    brandCampaignsAllListingFullFill: null,
    brandCampaignsAllListingError: null,

    inviteOnEmailLoading: false,
    inviteOnEmailFullFill: null,
    inviteOnEmailError: null,

    inviteOnMsgLoading: false,
    inviteOnMsgFullFill: null,
    inviteOnMsgError: null,

    campaignApplicantsLoading: false,
    campaignApplicantsFullFill: null,
    campaignApplicantsError: null
}

const ecommerceReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPPORTUNITIES.OPPORTUNITIES_LISTING:
            return {...state, opportunitiesData: action.opportunitiesData}
        case OPPORTUNITIES.UPCOMMING_LISTING:
            return {...state, upcommingOpportunitiesData: action.upcommingOpportunitiesData}
        case OPPORTUNITIES.RD_DETAIL:
            return {...state, opportunityRdDetail: action.opportunityRdDetail}
        case OPPORTUNITIES.DELETE_LISTING_OPPORTUNITY:
            return {...state, delListingOpportunity: action.delListingOpportunity}
        case OPPORTUNITIES.CAMPAIGN_SEARCH:
                return {...state, campaingsSearchData: action.campaingsSearchData}
        case OPPORTUNITIES.COUNTRIES_LIST:
            return {
                ...state,
                countries: action.countries
            }
        case "CLEAR_LOCATION_DATA_ON_SIGNUP":
            return {
                ...state,
                countries: null,
                regions: null,
                states: null,
                cities: null
            }
        case OPPORTUNITIES.REGIONS_LIST:
            return {...state, regions: action.regions}
        case OPPORTUNITIES.STATES_LIST:
            return {...state, states: action.states}
        case OPPORTUNITIES.CITIES_LIST:
            return {...state, cities: action.cities}
        case OPPORTUNITIES.EVENT_TYPE_LIST:
            return {...state, eventTypes: action.eventTypes}
        case OPPORTUNITIES.AUDIENCE_TYPE_LIST:
            return {...state, audienceType: action.audienceType}
        case OPPORTUNITIES.PAST_EVENT_LISTING:
            return {...state, pastEvents: action.pastEvents}
        case OPPORTUNITIES.EVENT_BRANDS:
            return {...state, eventBrands: action.eventBrands}
        case OPPORTUNITIES.EVENT_BRANDS_DETAIL:
            return {...state, eventBrandsDetail: action.eventBrandsDetail}
        case OPPORTUNITIES.PROVIDE_FEEDBACK:
            return {...state, providedFeedback: action.providedFeedback}
        case OPPORTUNITIES.FEEDBACK_DETAIL:
            return {...state, feedbackDetail: action.feedbackDetail}
        case OPPORTUNITIES.CONTACT_US:
            return {...state, contactUs: action.contactUs}
        case OPPORTUNITIES.ERROR:
            return {...state, error: action.error}
        case OPPORTUNITIES.INVOICE_DETAIL:
            return {...state, invoiceDetailData: action.invoiceDetailData}
        case OPPORTUNITIES.ADD_NOTE:
            return {...state, addedNote: action.addedNote}
        case OPPORTUNITIES.CAMPAIGN_EXPORT:
            return {
                ...state,
                campaignDetailExport: action.campaignDetailExport
            }
        case "CLEAR_CAMPAIGN_EXPORTED_DATA":
            return {
                ...state,
                campaignDetailExport: null
            }
        // --- RD Bank Account Verification Success ---
        case RD_ACCOUNT_SUCCESS.REQUEST:
            return {
                ...state,
                rdAccountSuccessLoading: true,
                rdAccountSuccessFullFill: null,
                rdAccountSuccessError: null
            }
        case RD_ACCOUNT_SUCCESS.SUCCESS:
            return {
                ...state,
                rdAccountSuccessLoading: false,
                rdAccountSuccessFullFill: action.payload,
                rdAccountSuccessError: null
            }
        case RD_ACCOUNT_SUCCESS.ERROR:
            return {
                ...state,
                rdAccountSuccessLoading: false,
                rdAccountSuccessFullFill: null,
                rdAccountSuccessError: action.payload
            }
        case RD_ACCOUNT_SUCCESS.RESET:
            return {
                ...state,
                rdAccountSuccessLoading: false,
                rdAccountSuccessFullFill: null,
                rdAccountSuccessError: null
            }
        // --- RD Bank Account Verification Fail---
        case RD_ACCOUNT_FAIL.REQUEST:
            return {
                ...state,
                rdAccountFailLoading: true,
                rdAccountFailFullFill: null,
                rdAccountFailError: null
            }
        case RD_ACCOUNT_FAIL.SUCCESS:
            return {
                ...state,
                rdAccountFailLoading: false,
                rdAccountFailFullFill: action.payload,
                rdAccountFailError: null
            }
        case RD_ACCOUNT_FAIL.ERROR:
            return {
                ...state,
                rdAccountFailLoading: false,
                rdAccountFailFullFill: null,
                rdAccountFailError: action.payload
            }
        case RD_ACCOUNT_FAIL.RESET:
            return {
                ...state,
                rdAccountFailLoading: false,
                rdAccountFailFullFill: null,
                rdAccountFailError: null
            }
        // --- RD Bank Account Delete ---
        case RD_ACCOUNT_DELETE.REQUEST:
            return {
                ...state,
                rdAccountDeleteLoading: true,
                rdAccountDeleteFullFill: null,
                rdAccountDeleteError: null
            }
        case RD_ACCOUNT_DELETE.SUCCESS:
            return {
                ...state,
                rdAccountDeleteLoading: false,
                rdAccountDeleteFullFill: action.payload,
                rdAccountDeleteError: null
            }
        case RD_ACCOUNT_DELETE.ERROR:
            return {
                ...state,
                rdAccountDeleteLoading: false,
                rdAccountDeleteFullFill: null,
                rdAccountDeleteError: action.payload
            }
        case RD_ACCOUNT_DELETE.RESET:
            return {
                ...state,
                rdAccountDeleteLoading: false,
                rdAccountDeleteFullFill: null,
                rdAccountDeleteError: null
            }
        // --- RD Bank Account Create ---
        case RD_ACCOUNT_CREATE.REQUEST:
            return {
                ...state,
                rdAccountCreateLoading: true,
                rdAccountCreateFullFill: null,
                rdAccountCreateError: null
            }
        case RD_ACCOUNT_CREATE.SUCCESS:
            return {
                ...state,
                rdAccountCreateLoading: false,
                rdAccountCreateFullFill: action.payload,
                rdAccountCreateError: null
            }
        case RD_ACCOUNT_CREATE.ERROR:
            return {
                ...state,
                rdAccountCreateLoading: false,
                rdAccountCreateFullFill: null,
                rdAccountCreateError: action.payload
            }
        case RD_ACCOUNT_CREATE.RESET:
            return {
                ...state,
                rdAccountCreateLoading: false,
                rdAccountCreateFullFill: null,
                rdAccountCreateError: null
            }
        // --- RD Bank Account Details ---
        case RD_ACCOUNT_DETAILS.REQUEST:
            return {
                ...state,
                rdAccountDetailsLoading: true,
                rdAccountDetailsFullFill: null,
                rdAccountDetailsError: null
            }
        case RD_ACCOUNT_DETAILS.SUCCESS:
            return {
                ...state,
                rdAccountDetailsLoading: false,
                rdAccountDetailsFullFill: action.payload,
                rdAccountDetailsError: null
            }
        case RD_ACCOUNT_DETAILS.ERROR:
            return {
                ...state,
                rdAccountDetailsLoading: false,
                rdAccountDetailsFullFill: null,
                rdAccountDetailsError: action.payload
            }
        // --- RD Bank Account Verify ---
        case RD_ACCOUNT_VERIFY.REQUEST:
            return {
                ...state,
                rdAccountVerifyLoading: true,
                rdAccountVerifyFullFill: null,
                rdAccountVerifyError: null
            }
        case RD_ACCOUNT_VERIFY.SUCCESS:
            return {
                ...state,
                rdAccountVerifyLoading: false,
                rdAccountVerifyFullFill: action.payload,
                rdAccountVerifyError: null
            }
        case RD_ACCOUNT_VERIFY.ERROR:
            return {
                ...state,
                rdAccountVerifyLoading: false,
                rdAccountVerifyFullFill: null,
                rdAccountVerifyError: action.payload
            }
        case RD_ACCOUNT_VERIFY.RESET:
            return {
                ...state,
                rdAccountVerifyLoading: false,
                rdAccountVerifyFullFill: null,
                rdAccountVerifyError: null
            }
        // --- RD Bank Account Mark Default ---
        case RD_ACCOUNT_MARK_DEFAULT.REQUEST:
            return {
                ...state,
                rdAccountMarkDefLoading: true,
                rdAccountMarkDefFullFill: null,
                rdAccountMarkDefError: null
            }
        case RD_ACCOUNT_MARK_DEFAULT.SUCCESS:
            return {
                ...state,
                rdAccountMarkDefLoading: false,
                rdAccountMarkDefFullFill: action.payload,
                rdAccountMarkDefError: null
            }
        case RD_ACCOUNT_MARK_DEFAULT.ERROR:
            return {
                ...state,
                rdAccountMarkDefLoading: false,
                rdAccountMarkDefFullFill: null,
                rdAccountMarkDefError: action.payload
            }
        case RD_ACCOUNT_MARK_DEFAULT.RESET:
            return {
                ...state,
                rdAccountMarkDefLoading: false,
                rdAccountMarkDefFullFill: null,
                rdAccountMarkDefError: null
            }
        // --- Brand Upload Agreement ---
        case UPLOAD_BRAND_AGREEMENT.REQUEST:
            return {
                ...state,
                uploadBrandAgreementLoading: true,
                uploadBrandAgreementFullFill: null,
                uploadBrandAgreementError: null
            }
        case UPLOAD_BRAND_AGREEMENT.SUCCESS:
            return {
                ...state,
                uploadBrandAgreementLoading: false,
                uploadBrandAgreementFullFill: action.payload,
                uploadBrandAgreementError: null
            }
        case UPLOAD_BRAND_AGREEMENT.ERROR:
            return {
                ...state,
                uploadBrandAgreementLoading: false,
                uploadBrandAgreementFullFill: null,
                uploadBrandAgreementError: action.payload
            }
        case UPLOAD_BRAND_AGREEMENT.RESET:
            return {
                ...state,
                uploadBrandAgreementLoading: false,
                uploadBrandAgreementFullFill: null,
                uploadBrandAgreementError: null
            }
        // --- Brand Credit Cards ---
        case BRAND_CREDIT_CARDS.REQUEST:
            return {
                ...state,
                brandCreditCardsLoading: true,
                brandCreditCardsFullFill: null,
                brandCreditCardsError: null
            }
        case BRAND_CREDIT_CARDS.SUCCESS:
            return {
                ...state,
                brandCreditCardsLoading: false,
                brandCreditCardsFullFill: action.payload,
                brandCreditCardsError: null
            }
        case BRAND_CREDIT_CARDS.ERROR:
            return {
                ...state,
                brandCreditCardsLoading: false,
                brandCreditCardsFullFill: null,
                brandCreditCardsError: action.payload
            }
        // --- Brand Bank Accounts ---
        case BRAND_BANK_ACCOUNTS.REQUEST:
            return {
                ...state,
                brandBankAccountsLoading: true,
                brandBankAccountsFullFill: null,
                brandBankAccountsError: null
            }
        case BRAND_BANK_ACCOUNTS.SUCCESS:
            return {
                ...state,
                brandBankAccountsLoading: false,
                brandBankAccountsFullFill: action.payload,
                brandBankAccountsError: null
            }
        case BRAND_BANK_ACCOUNTS.ERROR:
            return {
                ...state,
                brandBankAccountsLoading: false,
                brandBankAccountsFullFill: null,
                brandBankAccountsError: action.payload
            }
        // --- Brand Invoice Details ---
        case BRAND_INVOICE_DETAILS.REQUEST:
            return {
                ...state,
                brandInvoiceDetailsLoading: true,
                brandInvoiceDetailsFullFill: null,
                brandInvoiceDetailsError: null
            }
        case BRAND_INVOICE_DETAILS.SUCCESS:
            return {
                ...state,
                brandInvoiceDetailsLoading: false,
                brandInvoiceDetailsFullFill: action.payload,
                brandInvoiceDetailsError: null
            }
        case BRAND_INVOICE_DETAILS.ERROR:
            return {
                ...state,
                brandInvoiceDetailsLoading: false,
                brandInvoiceDetailsFullFill: null,
                brandInvoiceDetailsError: action.payload
            }
        // --- Brand Invoice Submit ---
        case BRAND_SUBMIT_INVOICE.REQUEST:
            return {
                ...state,
                brandInvoiceSubmitLoading: true,
                brandInvoiceSubmitFullFill: null,
                brandInvoiceSubmitError: null
            }
        case BRAND_SUBMIT_INVOICE.SUCCESS:
            return {
                ...state,
                brandInvoiceSubmitLoading: false,
                brandInvoiceSubmitFullFill: action.payload,
                brandInvoiceSubmitError: null
            }
        case BRAND_SUBMIT_INVOICE.ERROR:
            return {
                ...state,
                brandInvoiceSubmitLoading: false,
                brandInvoiceSubmitFullFill: null,
                brandInvoiceSubmitError: action.payload
            }
        case BRAND_SUBMIT_INVOICE.RESET:
            return {
                ...state,
                brandInvoiceSubmitLoading: false,
                brandInvoiceSubmitFullFill: null,
                brandInvoiceSubmitError: null
            }
        // --- Upcoming Partnerships ---
        case BRAND_UPCOMING_PARTNERSHIPS.REQUEST:
            return {
                ...state,
                upcomingPartnershipsLoading: true,
                upcomingPartnershipsFullFill: null,
                upcomingPartnershipsError: null
            }
        case BRAND_UPCOMING_PARTNERSHIPS.SUCCESS:
            return {
                ...state,
                upcomingPartnershipsLoading: false,
                upcomingPartnershipsFullFill: action.payload,
                upcomingPartnershipsError: null
            }
        case BRAND_UPCOMING_PARTNERSHIPS.ERROR:
            return {
                ...state,
                upcomingPartnershipsLoading: false,
                upcomingPartnershipsFullFill: null,
                upcomingPartnershipsError: action.payload
            }
        // --- Past Partnerships ---
        case BRAND_PAST_PARTNERSHIPS.REQUEST:
            return {
                ...state,
                pastPartnershipsLoading: true,
                pastPartnershipsFullFill: null,
                pastPartnershipsError: null
            }
        case BRAND_PAST_PARTNERSHIPS.SUCCESS:
            return {
                ...state,
                pastPartnershipsLoading: false,
                pastPartnershipsFullFill: action.payload,
                pastPartnershipsError: null
            }
        case BRAND_PAST_PARTNERSHIPS.ERROR:
            return {
                ...state,
                pastPartnershipsLoading: false,
                pastPartnershipsFullFill: null,
                pastPartnershipsError: action.payload
            }
        // --- RD Create Opportunity ---
        case RD_CREATE_OPPORTUNITY.REQUEST:
            return {
                ...state,
                rdCreateOpportunityLoading: true,
                rdCreateOpportunityFullFill: null,
                rdCreateOpportunityError: null
            }
        case RD_CREATE_OPPORTUNITY.SUCCESS:
            return {
                ...state,
                rdCreateOpportunityLoading: false,
                rdCreateOpportunityFullFill: action.payload,
                rdCreateOpportunityError: null
            }
        case RD_CREATE_OPPORTUNITY.ERROR:
            return {
                ...state,
                rdCreateOpportunityLoading: false,
                rdCreateOpportunityFullFill: null,
                rdCreateOpportunityError: action.payload
            }
        case RD_CREATE_OPPORTUNITY.RESET:
            return {
                ...state,
                rdCreateOpportunityLoading: false,
                rdCreateOpportunityFullFill: null,
                rdCreateOpportunityError: null
            }
        // --- RD Get Opportunity ---
        case RD_GET_OPPORTUNITY.REQUEST:
            return {
                ...state,
                rdGetOpportunityLoading: true,
                rdGetOpportunityFullFill: null,
                rdGetOpportunityError: null
            }
        case RD_GET_OPPORTUNITY.SUCCESS:
            return {
                ...state,
                rdGetOpportunityLoading: false,
                rdGetOpportunityFullFill: action.payload,
                rdGetOpportunityError: null
            }
        case RD_GET_OPPORTUNITY.ERROR:
            return {
                ...state,
                rdGetOpportunityLoading: false,
                rdGetOpportunityFullFill: null,
                rdGetOpportunityError: action.payload
            }
        case RD_GET_OPPORTUNITY.RESET:
            return {
                ...state,
                rdGetOpportunityLoading: false,
                rdGetOpportunityFullFill: null,
                rdGetOpportunityError: null
            }
        // --- RD Update Opportunity ---
        case RD_UPDATE_OPPORTUNITY.REQUEST:
            return {
                ...state,
                rdUpdateOpportunityLoading: true,
                rdUpdateOpportunityFullFill: null,
                rdUpdateOpportunityError: null
            }
        case RD_UPDATE_OPPORTUNITY.SUCCESS:
            return {
                ...state,
                rdUpdateOpportunityLoading: false,
                rdUpdateOpportunityFullFill: action.payload,
                rdUpdateOpportunityError: null
            }
        case RD_UPDATE_OPPORTUNITY.ERROR:
            return {
                ...state,
                rdUpdateOpportunityLoading: false,
                rdUpdateOpportunityFullFill: null,
                rdUpdateOpportunityError: action.payload
            }
        case RD_UPDATE_OPPORTUNITY.RESET:
            return {
                ...state,
                rdUpdateOpportunityLoading: false,
                rdUpdateOpportunityFullFill: null,
                rdUpdateOpportunityError: null
            }
        // --- RD Delete Opportunity Doc---
        case RD_DELETE_OPPORTUNITY_DOC.REQUEST:
            return {
                ...state,
                rdDeleteOpportunityDocLoading: true,
                rdDeleteOpportunityDocFullFill: null,
                rdDeleteOpportunityDocError: null
            }
        case RD_DELETE_OPPORTUNITY_DOC.SUCCESS:
            return {
                ...state,
                rdDeleteOpportunityDocLoading: false,
                rdDeleteOpportunityDocFullFill: action.payload,
                rdDeleteOpportunityDocError: null
            }
        case RD_DELETE_OPPORTUNITY_DOC.ERROR:
            return {
                ...state,
                rdDeleteOpportunityDocLoading: false,
                rdDeleteOpportunityDocFullFill: null,
                rdDeleteOpportunityDocError: action.payload
            }
        case RD_DELETE_OPPORTUNITY_DOC.RESET:
            return {
                ...state,
                rdDeleteOpportunityDocLoading: false,
                rdDeleteOpportunityDocFullFill: null,
                rdDeleteOpportunityDocError: null
            }
        // --- Brand Campaigns Listing ---
        case BRAND_CAMPAIGNS_LISTING.REQUEST:
            return {
                ...state,
                brandCampaignsListingLoading: true,
                brandCampaignsListingFullFill: null,
                brandCampaignsListingError: null
            }
        case BRAND_CAMPAIGNS_LISTING.SUCCESS:
            return {
                ...state,
                brandCampaignsListingLoading: false,
                brandCampaignsListingFullFill: action.payload,
                brandCampaignsListingError: null
            }
        case BRAND_CAMPAIGNS_LISTING.ERROR:
            return {
                ...state,
                brandCampaignsListingLoading: false,
                brandCampaignsListingFullFill: null,
                brandCampaignsListingError: action.payload
            }
        // --- Brand Create Campaign ---
        case BRAND_CREATE_CAMPAIGN.REQUEST:
            return {
                ...state,
                brandCreateCampaignLoading: true,
                brandCreateCampaignFullFill: null,
                brandCreateCampaignError: null
            }
        case BRAND_CREATE_CAMPAIGN.SUCCESS:
            return {
                ...state,
                brandCreateCampaignLoading: false,
                brandCreateCampaignFullFill: action.payload,
                brandCreateCampaignError: null
            }
        case BRAND_CREATE_CAMPAIGN.ERROR:
            return {
                ...state,
                brandCreateCampaignLoading: false,
                brandCreateCampaignFullFill: null,
                brandCreateCampaignError: action.payload
            }
        case BRAND_CREATE_CAMPAIGN.RESET:
            return {
                ...state,
                brandCreateCampaignLoading: false,
                brandCreateCampaignFullFill: null,
                brandCreateCampaignError: null
            }
        // --- Brand Edit Campaign ---
        case BRAND_EDIT_CAMPAIGN.REQUEST:
            return {
                ...state,
                brandEditCampaignLoading: true,
                brandEditCampaignFullFill: null,
                brandEditCampaignError: null
            }
        case BRAND_EDIT_CAMPAIGN.SUCCESS:
            return {
                ...state,
                brandEditCampaignLoading: false,
                brandEditCampaignFullFill: action.payload,
                brandEditCampaignError: null
            }
        case BRAND_EDIT_CAMPAIGN.ERROR:
            return {
                ...state,
                brandEditCampaignLoading: false,
                brandEditCampaignFullFill: null,
                brandEditCampaignError: action.payload
            }
        case BRAND_EDIT_CAMPAIGN.RESET:
            return {
                ...state,
                brandEditCampaignLoading: false,
                brandEditCampaignFullFill: null,
                brandEditCampaignError: null
            }
        // --- Brand Campaign Questions ---
        case BRAND_CAMPAIGN_QUESTIONS.REQUEST:
            return {
                ...state,
                brandCampaignQuestionsLoading: true,
                brandCampaignQuestionsFullFill: null,
                brandCampaignQuestionsError: null
            }
        case BRAND_CAMPAIGN_QUESTIONS.SUCCESS:
            return {
                ...state,
                brandCampaignQuestionsLoading: false,
                brandCampaignQuestionsFullFill: action.payload,
                brandCampaignQuestionsError: null
            }
        case BRAND_CAMPAIGN_QUESTIONS.ERROR:
            return {
                ...state,
                brandCampaignQuestionsLoading: false,
                brandCampaignQuestionsFullFill: null,
                brandCampaignQuestionsError: action.payload
            }
        case BRAND_CAMPAIGN_QUESTIONS.RESET:
            return {
                ...state,
                brandCampaignQuestionsLoading: false,
                brandCampaignQuestionsFullFill: null,
                brandCampaignQuestionsError: null
            }
        // --- Brand Campaign Update ---
        case BRAND_CAMPAIGN_UPDATE.REQUEST:
            return {
                ...state,
                brandCampaignUpdateLoading: true,
                brandCampaignUpdateFullFill: null,
                brandCampaignUpdateError: null
            }
        case BRAND_CAMPAIGN_UPDATE.SUCCESS:
            return {
                ...state,
                brandCampaignUpdateLoading: false,
                brandCampaignUpdateFullFill: action.payload,
                brandCampaignUpdateError: null
            }
        case BRAND_CAMPAIGN_UPDATE.ERROR:
            return {
                ...state,
                brandCampaignUpdateLoading: false,
                brandCampaignUpdateFullFill: null,
                brandCampaignUpdateError: action.payload
            }
        case BRAND_CAMPAIGN_UPDATE.RESET:
            return {
                ...state,
                brandCampaignUpdateLoading: false,
                brandCampaignUpdateFullFill: null,
                brandCampaignUpdateError: null
            }
        // --- Brand Campaign Delete ---
        case BRAND_CAMPAIGN_DELETE.REQUEST:
            return {
                ...state,
                brandCampaignDeleteLoading: true,
                brandCampaignDeleteFullFill: null,
                brandCampaignDeleteError: null
            }
        case BRAND_CAMPAIGN_DELETE.SUCCESS:
            return {
                ...state,
                brandCampaignDeleteLoading: false,
                brandCampaignDeleteFullFill: action.payload,
                brandCampaignDeleteError: null
            }
        case BRAND_CAMPAIGN_DELETE.ERROR:
            return {
                ...state,
                brandCampaignDeleteLoading: false,
                brandCampaignDeleteFullFill: null,
                brandCampaignDeleteError: action.payload
            }
        case BRAND_CAMPAIGN_DELETE.RESET:
            return {
                ...state,
                brandCampaignDeleteLoading: false,
                brandCampaignDeleteFullFill: null,
                brandCampaignDeleteError: null
            }
        // --- Brand Campaign Status ---
        case BRAND_CAMPAIGN_STATUS.REQUEST:
            return {
                ...state,
                brandCampaignStatusLoading: true,
                brandCampaignStatusFullFill: null,
                brandCampaignStatusError: null
            }
        case BRAND_CAMPAIGN_STATUS.SUCCESS:
            return {
                ...state,
                brandCampaignStatusLoading: false,
                brandCampaignStatusFullFill: action.payload,
                brandCampaignStatusError: null
            }
        case BRAND_CAMPAIGN_STATUS.ERROR:
            return {
                ...state,
                brandCampaignStatusLoading: false,
                brandCampaignStatusFullFill: null,
                brandCampaignStatusError: action.payload
            }
        case BRAND_CAMPAIGN_STATUS.RESET:
            return {
                ...state,
                brandCampaignStatusLoading: false,
                brandCampaignStatusFullFill: null,
                brandCampaignStatusError: null
            }
        // --- Brand Campaign Live ---
        case BRAND_CAMPAIGN_LIVE.REQUEST:
            return {
                ...state,
                brandCampaignLiveLoading: true,
                brandCampaignLiveFullFill: null,
                brandCampaignLiveError: null
            }
        case BRAND_CAMPAIGN_LIVE.SUCCESS:
            return {
                ...state,
                brandCampaignLiveLoading: false,
                brandCampaignLiveFullFill: action.payload,
                brandCampaignLiveError: null
            }
        case BRAND_CAMPAIGN_LIVE.ERROR:
            return {
                ...state,
                brandCampaignLiveLoading: false,
                brandCampaignLiveFullFill: null,
                brandCampaignLiveError: action.payload
            }
        case BRAND_CAMPAIGN_LIVE.RESET:
            return {
                ...state,
                brandCampaignLiveLoading: false,
                brandCampaignLiveFullFill: null,
                brandCampaignLiveError: null
            }
        // --- Brand Campaign Form ---
        case BRAND_CAMPAIGN_FORM.REQUEST:
            return {
                ...state,
                brandCampaignFormLoading: true,
                brandCampaignFormFullFill: null,
                brandCampaignFormError: null
            }
        case BRAND_CAMPAIGN_FORM.SUCCESS:
            return {
                ...state,
                brandCampaignFormLoading: false,
                brandCampaignFormFullFill: action.payload,
                brandCampaignFormError: null
            }
        case BRAND_CAMPAIGN_FORM.ERROR:
            return {
                ...state,
                brandCampaignFormLoading: false,
                brandCampaignFormFullFill: null,
                brandCampaignFormError: action.payload
            }
        case BRAND_CAMPAIGN_FORM.RESET:
            return {
                ...state,
                brandCampaignFormLoading: false,
                brandCampaignFormFullFill: null,
                brandCampaignFormError: null
            }
        // --- Brand Opportunity Detail ---
        case BRAND_OPP_DETAILS.REQUEST:
            return {
                ...state,
                brandOppDetailLoading: true,
                brandOppDetailFullFill: null,
                brandOppDetailError: null
            }
        case BRAND_OPP_DETAILS.SUCCESS:
            return {
                ...state,
                brandOppDetailLoading: false,
                brandOppDetailFullFill: action.payload,
                brandOppDetailError: null
            }
        case BRAND_OPP_DETAILS.ERROR:
            return {
                ...state,
                brandOppDetailLoading: false,
                brandOppDetailFullFill: null,
                brandOppDetailError: action.payload
            }
        // --- Brand Event Participate ---
        case BRAND_OPP_PARTICIPATE.REQUEST:
            return {
                ...state,
                brandOppParticipateLoading: true,
                brandOppParticipateFullFill: null,
                brandOppParticipateError: null
            }
        case BRAND_OPP_PARTICIPATE.SUCCESS:
            return {
                ...state,
                brandOppParticipateLoading: false,
                brandOppParticipateFullFill: action.payload,
                brandOppParticipateError: null
            }
        case BRAND_OPP_PARTICIPATE.ERROR:
            return {
                ...state,
                brandOppParticipateLoading: false,
                brandOppParticipateFullFill: null,
                brandOppParticipateError: action.payload
            }
        case BRAND_OPP_PARTICIPATE.RESET:
            return {
                ...state,
                brandOppParticipateLoading: false,
                brandOppParticipateFullFill: null,
                brandOppParticipateError: null
            }
        // --- Brand Campaigns All Listing ---
        case BRAND_CAMPAIGNS_ALL_LISTING.REQUEST:
            return {
                ...state,
                brandCampaignsAllListingLoading: true,
                brandCampaignsAllListingFullFill: null,
                brandCampaignsAllListingError: null
            }
        case BRAND_CAMPAIGNS_ALL_LISTING.SUCCESS:
            return {
                ...state,
                brandCampaignsAllListingLoading: false,
                brandCampaignsAllListingFullFill: action.payload,
                brandCampaignsAllListingError: null
            }
        case BRAND_CAMPAIGNS_ALL_LISTING.ERROR:
            return {
                ...state,
                brandCampaignsAllListingLoading: false,
                brandCampaignsAllListingFullFill: null,
                brandCampaignsAllListingError: action.payload
            }
        // --- Brand Invite Campaigns On Email ---
        case INVITE_CAMPAIGN_ON_EMAIL.REQUEST:
            return {
                ...state,
                inviteOnEmailLoading: true,
                inviteOnEmailFullFill: null,
                inviteOnEmailError: null
            }
        case INVITE_CAMPAIGN_ON_EMAIL.SUCCESS:
            return {
                ...state,
                inviteOnEmailLoading: false,
                inviteOnEmailFullFill: action.payload,
                inviteOnEmailError: null
            }
        case INVITE_CAMPAIGN_ON_EMAIL.ERROR:
            return {
                ...state,
                inviteOnEmailLoading: false,
                inviteOnEmailFullFill: null,
                inviteOnEmailError: action.payload
            }
        case INVITE_CAMPAIGN_ON_EMAIL.RESET:
            return {
                ...state,
                inviteOnEmailLoading: false,
                inviteOnEmailFullFill: null,
                inviteOnEmailError: null
            }
        // --- Brand Invite Campaigns On Email ---
        case INVITE_CAMPAIGN_ON_MSG.REQUEST:
            return {
                ...state,
                inviteOnMsgLoading: true,
                inviteOnMsgFullFill: null,
                inviteOnMsgError: null
            }
        case INVITE_CAMPAIGN_ON_MSG.SUCCESS:
            return {
                ...state,
                inviteOnMsgLoading: false,
                inviteOnMsgFullFill: action.payload,
                inviteOnMsgError: null
            }
        case INVITE_CAMPAIGN_ON_MSG.ERROR:
            return {
                ...state,
                inviteOnMsgLoading: false,
                inviteOnMsgFullFill: null,
                inviteOnMsgError: action.payload
            }
        case INVITE_CAMPAIGN_ON_MSG.RESET:
            return {
                ...state,
                inviteOnMsgLoading: false,
                inviteOnMsgFullFill: null,
                inviteOnMsgError: null
            }
        // --- Brand Campaign Applicants Listing ---
        case CAMP_APPLICANT_LIST.REQUEST:
            return {
                ...state,
                campaignApplicantsLoading: true,
                campaignApplicantsFullFill: null,
                campaignApplicantsError: null
            }
        case CAMP_APPLICANT_LIST.SUCCESS:
            return {
                ...state,
                campaignApplicantsLoading: false,
                campaignApplicantsFullFill: action.payload,
                campaignApplicantsError: null
            }
        case CAMP_APPLICANT_LIST.ERROR:
            return {
                ...state,
                campaignApplicantsLoading: false,
                campaignApplicantsFullFill: null,
                campaignApplicantsError: action.payload
            }
        default:
            return state
    }
}

const locationFromZipCodeDefState = {
    locationFromZipCodeLoading: false,
    locationFromZipCodeData: {}
}

export const locationFromZipCodeReducer = (state = locationFromZipCodeDefState, action) => {
    switch (action.type) {
        case LOCATION_FROM_ZIP.REQUEST:
            return {
                locationFromZipCodeLoading: true,
                locationFromZipCodeData: {}
            }
        case LOCATION_FROM_ZIP.SUCCESS:
            return {
                locationFromZipCodeLoading: false,
                locationFromZipCodeData: action.payload
            }
        case LOCATION_FROM_ZIP.ERROR:
            return {
                locationFromZipCodeLoading: false,
                locationFromZipCodeError: action.payload
            }
        case LOCATION_FROM_ZIP.RESET:
            return {
                locationFromZipCodeLoading: false,
                locationFromZipCodeData: {}
            }
        default:
            return state
    }
}

const campaignMsgDefState = {
    campaignMsgLoading: false,
    campaignMsgData: {}
}

export const campaignMsgReducer = (state = campaignMsgDefState, action) => {
    switch (action.type) {
        case CAMPAIGN_MSG.REQUEST:
            return {
                campaignMsgLoading: true,
                campaignMsgData: {}
            }
        case CAMPAIGN_MSG.SUCCESS:
            return {
                campaignMsgLoading: false,
                campaignMsgData: action.payload
            }
        case CAMPAIGN_MSG.ERROR:
            return {
                campaignMsgLoading: false,
                campaignMsgError: action.payload
            }
        case CAMPAIGN_MSG.RESET:
            return {
                campaignMsgLoading: false,
                campaignMsgData: {}
            }
        default:
            return state
    }
}

const campaignAddToFavDefState = {
    campaignAddToFavLoading: false,
    campaignAddToFavMsgData: {}
}

export const campaignAddToFavReducer = (state = campaignAddToFavDefState, action) => {
    switch (action.type) {
        case CAMPAIGN_ADD_TO_FAV.REQUEST:
            return {
                campaignAddToFavLoading: true,
                campaignAddToFavMsgData: {}
            }
        case CAMPAIGN_ADD_TO_FAV.SUCCESS:
            return {
                campaignAddToFavLoading: false,
                campaignAddToFavMsgData: action.payload
            }
        case CAMPAIGN_ADD_TO_FAV.ERROR:
            return {
                campaignAddToFavLoading: false,
                campaignAddToFavError: action.payload
            }
        case CAMPAIGN_ADD_TO_FAV.RESET:
            return {
                campaignAddToFavLoading: false,
                campaignAddToFavMsgData: {}
            }
        default:
            return state
    }
}

const saveCardInfoRDDefState = {
    saveCardInfoRDLoading: false,
    saveCardInfoRDData: {}
}

export const saveCardInfoRDReducer = (state = saveCardInfoRDDefState, action) => {
    switch (action.type) {
        case RD_CARD_INFO_SAVE.REQUEST:
            return {
                saveCardInfoRDLoading: true,
                saveCardInfoRDData: {}
            }
        case RD_CARD_INFO_SAVE.SUCCESS:
            return {
                saveCardInfoRDLoading: false,
                saveCardInfoRDData: action.payload
            }
        case RD_CARD_INFO_SAVE.ERROR:
            return {
                saveCardInfoRDLoading: false,
                saveCardInfoRDError: action.payload
            }
        case RD_CARD_INFO_SAVE.RESET:
            return {
                saveCardInfoRDLoading: false,
                saveCardInfoRDData: {}
            }
        default:
            return state
    }
}

const creditCardInfoRDDefState = {
    creditCardInfoRDLoading: false,
    creditCardInfoRDData: {}
}

export const creditCardInfoRDReducer = (state = creditCardInfoRDDefState, action) => {
    switch (action.type) {
        case RD_CREDIT_CARD_INFO.REQUEST:
            return {
                creditCardInfoRDLoading: true,
                creditCardInfoRDData: {}
            }
        case RD_CREDIT_CARD_INFO.SUCCESS:
            return {
                creditCardInfoRDLoading: false,
                creditCardInfoRDData: action.payload
            }
        case RD_CREDIT_CARD_INFO.ERROR:
            return {
                creditCardInfoRDLoading: false,
                creditCardInfoRDError: action.payload
            }
        default:
            return state
    }
}

const creditCardSetDefaultRDDefState = {
    creditCardSetDefaultRDLoading: false,
    creditCardSetDefaultRDData: {}
}

export const creditCardSetDefaultRDReducer = (state = creditCardSetDefaultRDDefState, action) => {
    switch (action.type) {
        case RD_CREDIT_CARD_SET_DEFAULT.REQUEST:
            return {
                creditCardSetDefaultRDLoading: true,
                creditCardSetDefaultRDData: {}
            }
        case RD_CREDIT_CARD_SET_DEFAULT.SUCCESS:
            return {
                creditCardSetDefaultRDLoading: false,
                creditCardSetDefaultRDData: action.payload
            }
        case RD_CREDIT_CARD_SET_DEFAULT.ERROR:
            return {
                creditCardSetDefaultRDLoading: false,
                creditCardSetDefaultRDError: action.payload
            }
        case RD_CREDIT_CARD_SET_DEFAULT.RESET:
            return {
                creditCardSetDefaultRDLoading: false,
                creditCardSetDefaultRDData: {}
            }
        default:
            return state
    }
}

const creditCardDeleteRDDefState = {
    creditCardDeleteRDLoading: false,
    creditCardDeleteRDData: {}
}

export const creditCardDeleteRDReducer = (state = creditCardDeleteRDDefState, action) => {
    switch (action.type) {
        case RD_CREDIT_CARD_DELETE.REQUEST:
            return {
                creditCardDeleteRDLoading: true,
                creditCardDeleteRDData: {}
            }
        case RD_CREDIT_CARD_DELETE.SUCCESS:
            return {
                creditCardDeleteRDLoading: false,
                creditCardDeleteRDData: action.payload
            }
        case RD_CREDIT_CARD_DELETE.ERROR:
            return {
                creditCardDeleteRDLoading: false,
                creditCardDeleteRDError: action.payload
            }
        case RD_CREDIT_CARD_DELETE.RESET:
            return {
                creditCardDeleteRDLoading: false,
                creditCardDeleteRDData: {}
            }
        default:
            return state
    }
}

const masterFeaturesRDDefState = {
    masterFeaturesRDLoading: false,
    masterFeaturesRDData: {}
}

export const masterFeaturesRDReducer = (state = masterFeaturesRDDefState, action) => {
    switch (action.type) {
        case RD_MASTER_FEATURES.REQUEST:
            return {
                masterFeaturesRDLoading: true,
                masterFeaturesRDData: {}
            }
        case RD_MASTER_FEATURES.SUCCESS:
            return {
                masterFeaturesRDLoading: false,
                masterFeaturesRDData: action.payload
            }
        case RD_MASTER_FEATURES.ERROR:
            return {
                masterFeaturesRDLoading: false,
                masterFeaturesRDError: action.payload
            }
        default:
            return state
    }
}

const getPlanRDDefState = {
    getPlanRDLoading: false,
    getPlanRDData: {}
}

export const getPlanRDReducer = (state = getPlanRDDefState, action) => {
    switch (action.type) {
        case RD_GET_PLAN.REQUEST:
            return {
                getPlanRDLoading: true,
                getPlanRDData: {}
            }
        case RD_GET_PLAN.SUCCESS:
            return {
                getPlanRDLoading: false,
                getPlanRDData: action.payload
            }
        case RD_GET_PLAN.ERROR:
            return {
                getPlanRDLoading: false,
                getPlanRDError: action.payload
            }
        case RD_GET_PLAN.RESET:
            return {
                getPlanRDLoading: false,
                getPlanRDData: {}
            }
        default:
            return state
    }
}

const getActivePlanRDDefState = {
    getActivePlanRDLoading: false,
    getActivePlanRDData: {}
}

export const getActivePlanRReducer = (state = getActivePlanRDDefState, action) => {
    switch (action.type) {
        case RD_ACTIVE_PLAN.REQUEST:
            return {
                getActivePlanRDLoading: true,
                getActivePlanRDData: {}
            }
        case RD_ACTIVE_PLAN.SUCCESS:
            return {
                getActivePlanRDLoading: false,
                getActivePlanRDData: action.payload
            }
        case RD_ACTIVE_PLAN.ERROR:
            return {
                getActivePlanRDLoading: false,
                getActivePlanRDError: action.payload
            }
        default:
            return state
    }
}

const getCancelPlanRDDefState = {
    getCancelPlanRDLoading: false,
    getCancelPlanRDData: {}
}

export const getCancelPlanRReducer = (state = getCancelPlanRDDefState, action) => {
    switch (action.type) {
        case RD_CANCEL_PLAN.REQUEST:
            return {
                getCancelPlanRDLoading: true,
                getCancelPlanRDData: {}
            }
        case RD_CANCEL_PLAN.SUCCESS:
            return {
                getCancelPlanRDLoading: false,
                getCancelPlanRDData: action.payload
            }
        case RD_CANCEL_PLAN.ERROR:
            return {
                getCancelPlanRDLoading: false,
                getCancelPlanRDError: action.payload
            }
        case RD_CANCEL_PLAN.RESET:
            return {
                getCancelPlanRDLoading: false,
                getCancelPlanRDData: {}
            }
        default:
            return state
    }
}

const resourcesRDDefState = {
    resourcesRDLoading: false,
    resourcesRDData: {},
    resourcesRDError: {}
}

export const resourcesRReducer = (state = resourcesRDDefState, action) => {
    switch (action.type) {
        case RD_RESOURCES.REQUEST:
            return {
                resourcesRDLoading: true,
                resourcesRDData: {}
            }
        case RD_RESOURCES.SUCCESS:
            return {
                resourcesRDLoading: false,
                resourcesRDData: action.payload
            }
        case RD_RESOURCES.ERROR:
            return {
                resourcesRDLoading: false,
                resourcesRDError: action.payload
            }
        case RD_RESOURCES.RESET:
            return {
                resourcesRDLoading: false,
                resourcesRDData: {}
            }
        default:
            return state
    }
}

const resourceContentRDDefState = {
    resourceContentRDLoading: false,
    resourceContentRDData: {},
    resourceContentRDError: {}
}

export const resourceContentRReducer = (state = resourceContentRDDefState, action) => {
    switch (action.type) {
        case RD_RESOURCE_CONTENT.REQUEST:
            return {
                resourceContentRDLoading: true,
                resourceContentRDData: {}
            }
        case RD_RESOURCE_CONTENT.SUCCESS:
            return {
                resourceContentRDLoading: false,
                resourceContentRDData: action.payload
            }
        case RD_RESOURCE_CONTENT.ERROR:
            return {
                resourceContentRDLoading: false,
                resourceContentRDError: action.payload
            }
        default:
            return state
    }
}

const checkCreditCardRDDefState = {
    checkCreditCardRDLoading: false,
    checkCreditCardRDData: {},
    checkCreditCardRDError: {}
}

export const checkCreditCardRDReducer = (state = checkCreditCardRDDefState, action) => {
    switch (action.type) {
        case RD_CHECK_CREDIT_CARD.REQUEST:
            return {
                checkCreditCardRDLoading: true,
                checkCreditCardRDData: {}
            }
        case RD_CHECK_CREDIT_CARD.SUCCESS:
            return {
                checkCreditCardRDLoading: false,
                checkCreditCardRDData: action.payload
            }
        case RD_CHECK_CREDIT_CARD.ERROR:
            return {
                checkCreditCardRDLoading: false,
                checkCreditCardRDError: action.payload
            }
        case RD_CHECK_CREDIT_CARD.RESET:
            return {
                checkCreditCardRDLoading: false,
                checkCreditCardRDData: {}
            }
        default:
            return state
    }
}

const saveSelectedPlanRDDefState = {
    saveSelectedPlanRDLoading: false,
    saveSelectedPlanRDData: {},
    saveSelectedPlanRDError: {}
}

export const saveSelectedPlanRDReducer = (state = saveSelectedPlanRDDefState, action) => {
    switch (action.type) {
        case RD_SAVE_PLAN_INFO.REQUEST:
            return {
                saveSelectedPlanRDLoading: true,
                saveSelectedPlanRDData: {}
            }
        case RD_SAVE_PLAN_INFO.SUCCESS:
            return {
                saveSelectedPlanRDLoading: false,
                saveSelectedPlanRDData: action.payload
            }
        case RD_SAVE_PLAN_INFO.ERROR:
            return {
                saveSelectedPlanRDLoading: false,
                saveSelectedPlanRDError: action.payload
            }
        case RD_SAVE_PLAN_INFO.RESET:
            return {
                saveSelectedPlanRDLoading: false,
                saveSelectedPlanRDData: {}
            }
        default:
            return state
    }
}

const loadUpcomingEventsRDDefState = {
    loadUpcomingEventsRDLoading: false,
    loadUpcomingEventsRDData: {},
    loadUpcomingEventsRDError: {}
}

export const loadUpcomingEventsRDReducer = (state = loadUpcomingEventsRDDefState, action) => {
    switch (action.type) {
        case RD_LOAD_UPCOMING_EVENTS.REQUEST:
            return {
                loadUpcomingEventsRDLoading: true,
                loadUpcomingEventsRDData: {}
            }
        case RD_LOAD_UPCOMING_EVENTS.SUCCESS:
            return {
                loadUpcomingEventsRDLoading: false,
                loadUpcomingEventsRDData: action.payload
            }
        case RD_LOAD_UPCOMING_EVENTS.ERROR:
            return {
                loadUpcomingEventsRDLoading: false,
                loadUpcomingEventsRDError: action.payload
            }
        default:
            return state
    }
}

const loadPastEventsRDDefState = {
    loadPastEventsRDLoading: false,
    loadPastEventsRDData: {},
    loadPastEventsRDError: {}
}

export const loadPastEventsRDReducer = (state = loadPastEventsRDDefState, action) => {
    switch (action.type) {
        case RD_LOAD_PAST_EVENTS.REQUEST:
            return {
                loadPastEventsRDLoading: true,
                loadPastEventsRDData: {}
            }
        case RD_LOAD_PAST_EVENTS.SUCCESS:
            return {
                loadPastEventsRDLoading: false,
                loadPastEventsRDData: action.payload
            }
        case RD_LOAD_PAST_EVENTS.ERROR:
            return {
                loadPastEventsRDLoading: false,
                loadPastEventsRDError: action.payload
            }
        default:
            return state
    }
}

const verifyEmailDefState = {
    verifyEmailLoading: false,
    verifyEmailData: {},
    verifyEmailError: {}
}

export const verifyEmailReducer = (state = loadPastEventsRDDefState, action) => {
    switch (action.type) {
        case VERIFY_EMAIL.REQUEST:
            return {
                verifyEmailLoading: true,
                verifyEmailData: {},
                verifyEmailError: {}
            }
        case VERIFY_EMAIL.SUCCESS:
            return {
                verifyEmailLoading: false,
                verifyEmailData: action.payload,
                verifyEmailError: {}
            }
        case VERIFY_EMAIL.ERROR:
            return {
                verifyEmailLoading: false,
                verifyEmailData: {},
                verifyEmailError: action.payload
            }
        case VERIFY_EMAIL.RESET:
            return {
                ...verifyEmailDefState
            }
        default:
            return state
    }
}

const brandInvoicesDefState = {
    brandInvoicesLoading: false,
    brandInvoicesData: [],
    brandInvoicesError: {}
}

export const brandInvoicesReducer = (state = brandInvoicesDefState, action) => {
    switch (action.type) {
        case BRAND_INVOICES.REQUEST:
            return {
                brandInvoicesLoading: true,
                brandInvoicesData: [],
                brandInvoicesError: {}
            }
        case BRAND_INVOICES.SUCCESS:
            return {
                brandInvoicesLoading: false,
                brandInvoicesData: action.payload,
                brandInvoicesError: {}
            }
        case BRAND_INVOICES.ERROR:
            return {
                brandInvoicesLoading: false,
                brandInvoicesData: [],
                brandInvoicesError: action.payload
            }
        default:
            return state
    }
}

const payNewInvoiceDefState = {
    payNewInvoiceLoading: false,
    payNewInvoiceData: {},
    payNewInvoiceError: {}
}

export const payNewInvoiceReducer = (state = payNewInvoiceDefState, action) => {
    switch (action.type) {
        case PAY_NEW_INVOICE.REQUEST:
            return {
                payNewInvoiceLoading: true,
                payNewInvoiceData: {},
                payNewInvoiceError: {}
            }
        case PAY_NEW_INVOICE.SUCCESS:
            return {
                payNewInvoiceLoading: false,
                payNewInvoiceData: action.payload,
                payNewInvoiceError: {}
            }
        case PAY_NEW_INVOICE.ERROR:
            return {
                payNewInvoiceLoading: false,
                payNewInvoiceData: {},
                payNewInvoiceError: action.payload
            }
        case PAY_NEW_INVOICE.RESET:
            return {
                payNewInvoiceLoading: false,
                payNewInvoiceData: {},
                payNewInvoiceError: {}
            }
        default:
            return state
    }
}

export default ecommerceReducer
