import {ADD_RESOURCE, DELETE_RESOURCE, LIST_RESOURCE, STATUS_RESOURCE} from "../actionsTypes"

const addResourceDefState = {
    addResourceLoading: false,
    addResourceData: {},
    addResourceError: {}
}

export const addResourcesReducer = (state = addResourceDefState, action) => {
    switch (action.type) {
        case ADD_RESOURCE.REQUEST:
            return {
                addResourceLoading: true,
                addResourceData: {}
            }
        case ADD_RESOURCE.SUCCESS:
            return {
                addResourceLoading: false,
                addResourceData: action.payload
            }
        case ADD_RESOURCE.ERROR:
            return {
                addResourceLoading: false,
                addResourceError: action.payload
            }
        case ADD_RESOURCE.RESET:
            return {
                addResourceLoading: false,
                addResourceData: {}
            }
        default:
            return state
    }
}

const listResourceDefState = {
    listResourceLoading: false,
    listResourceData: {},
    listResourceError: {}
}

export const listResourcesReducer = (state = listResourceDefState, action) => {
    switch (action.type) {
        case LIST_RESOURCE.REQUEST:
            return {
                listResourceLoading: true,
                listResourceData: {}
            }
        case LIST_RESOURCE.SUCCESS:
            return {
                listResourceLoading: false,
                listResourceData: action.payload
            }
        case LIST_RESOURCE.ERROR:
            return {
                listResourceLoading: false,
                listResourceError: action.payload
            }
        default:
            return state
    }
}

const statusResourceDefState = {
    statusResourceLoading: false,
    statusResourceData: {},
    statusResourceError: {}
}

export const statusResourcesReducer = (state = statusResourceDefState, action) => {
    switch (action.type) {
        case STATUS_RESOURCE.REQUEST:
            return {
                statusResourceLoading: true,
                statusResourceData: {}
            }
        case STATUS_RESOURCE.SUCCESS:
            return {
                statusResourceLoading: false,
                statusResourceData: action.payload
            }
        case STATUS_RESOURCE.ERROR:
            return {
                statusResourceLoading: false,
                statusResourceError: action.payload
            }
        case STATUS_RESOURCE.RESET:
            return {
                statusResourceLoading: false,
                statusResourceData: {}
            }
        default:
            return state
    }
}

const deleteResourceDefState = {
    deleteResourceLoading: false,
    deleteResourceData: {},
    deleteResourceError: {}
}

export const deleteResourcesReducer = (state = deleteResourceDefState, action) => {
    switch (action.type) {
        case DELETE_RESOURCE.REQUEST:
            return {
                deleteResourceLoading: true,
                deleteResourceData: {}
            }
        case DELETE_RESOURCE.SUCCESS:
            return {
                deleteResourceLoading: false,
                deleteResourceData: action.payload
            }
        case DELETE_RESOURCE.ERROR:
            return {
                deleteResourceLoading: false,
                deleteResourceError: action.payload
            }
        case DELETE_RESOURCE.RESET:
            return {
                deleteResourceLoading: false,
                deleteResourceData: {}
            }
        default:
            return state
    }
}
