const NOTIFICATION_SETTING = {
    REQUEST: 'NOTIFICATION_SETTING_REQUEST',
    SUCCESS: 'NOTIFICATION_SETTING_SUCCESS',
    ERROR: 'NOTIFICATION_SETTING_ERROR'
}

const NOTIFICATION_SETTING_UPDATE = {
    REQUEST: 'NOTIFICATION_SETTING_UPDATE_REQUEST',
    SUCCESS: 'NOTIFICATION_SETTING_UPDATE_SUCCESS',
    ERROR: 'NOTIFICATION_SETTING_UPDATE_ERROR',
    RESET: 'NOTIFICATION_SETTING_UPDATE_RESET'
}

export {NOTIFICATION_SETTING, NOTIFICATION_SETTING_UPDATE}