import { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import RDUpgradeMemberShip from '../../views/outerPages/RdPurchasePackages'
import RDEditProfile from '../../views/pages/rdProfile/RdEditProfile'

const RDRoutes = [
    {
        path: '/pending-account-msg',
        component: lazy(() => import('../../views/pages/misc/PendingRDAccountMessage')),
        layout: 'BlankLayout',
        meta: {
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/rd/upgrade-membership',
        component: RDUpgradeMemberShip,
        layout: 'BlankLayout',
        meta: {
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/view-profile',
        component: lazy(() => import('../../views/pages/rdProfile/RdViewProfile')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/edit-profile',
        component: RDEditProfile,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/payment-settings',
        component: lazy(() => import('../../views/pages/rdProfile/RdPaymentSettings')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/manage-cards',
        component: lazy(() => import('../../views/pages/rdProfile/RdManageCards')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/subscription-plan',
        component: lazy(() => import('../../views/pages/subscriptionPlanRD')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/verification-success/:token',
        component: lazy(() => import('../../views/pages/rdProfile/verificationSuccess')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/verification-error/:token',
        component: lazy(() => import('../../views/pages/rdProfile/verificationError')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/change-password',
        component: lazy(() => import('../../views/pages/rdProfile/RDChangePasswordForm')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/opportunities',
        component: lazy(() => import('../../views/pages/opportunities-rd')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/campaigns',
        component: lazy(() => import('../../views/pages/campaigns-rd/index')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/partnerships/manage',
        component: lazy(() => import('../../views/pages/managePartnershipsRd')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/partnerships/payment',
        component: lazy(() => import('../../views/pages/paymentsPartnershipsRd')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/contact-us',
        component: lazy(() => import('../../views/pages/rdContactUs/index')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/partnerships/feedback',
        component: lazy(() => import('../../views/pages/feedbackPartnershipsRd')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/partnerships/:eventId/provide-event-feedback/:brandId',
        component: lazy(() => import('../../views/pages/feedbackPartnershipsRd/provideFeedback')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/edit-profile',
        component: lazy(() => import('../../views/pages/editProfileRd')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/favorite-folders',
        component: lazy(() => import('../../views/pages/wishlistRd')),
        layout: 'VerticalLayoutRd',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/favorite-folders/:id/list',
        component: lazy(() => import('../../views/pages/wishlistRd/details')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },

    {
        path: '/dashboard/rd/brand-detail/:id',
        component: lazy(() => import('../../views/pages/brandsDetailRd')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/notifications',
        component: lazy(() => import('../../views/pages/rdNotifications')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/rd/apps/chat/chatRd',
        layout: 'VerticalLayoutRd',
        exact: true,
        appLayout: true,
        className: 'chat-application',
        component: lazy(() => import('../../views/apps/chatRd')),
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'rd'
        }
    },
    {
        path: '/rd/apps/chat/chatRd/:id',
        layout: 'VerticalLayoutRd',
        appLayout: true,
        className: 'chat-application',
        component: lazy(() => import('../../views/apps/chatRd')),
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'rd'
        }
    },
    {
        path: '/apps/calendarRd',
        layout: 'VerticalLayoutRd',
        component: lazy(() => import('../../views/apps/calendarRd')),
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/brands',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/brandsRd/shop')),
        exact: true,
        layout: 'VerticalLayoutRd',
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/brands/:id',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/pages/brandsProfileAtRD')),
        layout: 'VerticalLayoutRd',
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/brands/favorites',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/brandsRd/wishlist')),
        layout: 'VerticalLayoutRd',
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/brands/brand-detail/:product',
        exact: true,
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/brandsRd/detail')),
        meta: {
            navLink: '/apps/ecommerce/product-detail',
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/brands/checkout',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/brandsRd/checkout')),
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/my-brands',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/myBrandsAtRd/shop')),
        exact: true,
        layout: 'VerticalLayoutRd',
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/my-brands/:id',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/pages/brandsProfileAtRD')),
        layout: 'VerticalLayoutRd',
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/my-brands/favorites',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/myBrandsAtRd/wishlist')),
        layout: 'VerticalLayoutRd',
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/my-brands/brand-detail',
        exact: true,
        className: 'ecommerce-application',
        meta: {
            action: "manage",
            resource: "rd"
        },
        component: () => <Redirect to='/apps/myBrandsAtRd/product-detail/apple-i-phone-11-64-gb-black-26' />
    },
    {
        path: '/dashboard/rd/my-brands/brand-detail/:product',
        exact: true,
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/myBrandsAtRd/detail')),
        meta: {
            navLink: '/apps/ecommerce/product-detail',
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/my-brands/checkout',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/myBrandsAtRd/checkout')),
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/pricing-plan',
        component: lazy(() => import('../../views/pages/pricingPlanRD')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/resources',
        component: lazy(() => import('../../views/pages/resources-rd')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/resources-content/:id',
        component: lazy(() => import('../../views/pages/resources-content-rd')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/notification-settings',
        component: lazy(() => import('../../views/pages/rdNotificationSetting')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/rd/invoice',
        component: lazy(() => import('../../views/tables/data-tables-rd-invoices/advance')),
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    }
]

export default RDRoutes