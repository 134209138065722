import { AUTH, GENERAL, RD_SIGNUP_FINAL, BRAND_SIGNUP_FINAL } from '../../actionTypes'

// **  Initial State
const initialState = {
  userData: {},
  signupRDResponse: null,
  signupBrandResponse: null,
  verifyDTEmail: null,
  countries: null,
  regionsByCountryID: null,
  statesByRegionID: null,
  citiesByStateID: null,
  brandCompanySelectValues: null,
  brandGoalsSelectValues: null,
  rdPrimarilyGoalsValues: null,
  brandCardVerificationRes: null,
  brandCouponCodeVerificationRes: null,
  purchasePlans: null,
  activePlan: null,
  planStatus: null,
  forgetPasswordResponse: null,
  resetPasswordResponse: null,
  hearAboutOptions: null,
  error: null,

  rdSignupFinalLoading: false,
  rdSignupFinalData: null,
  rdSignupFinalError: null,

  brandSignupFinalLoading: false,
  brandSignupFinalData: null,
  brandSignupFinalError: null
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.data
      }
    case AUTH.USER_LOGOUT:
      return { ...state }
    case AUTH.BRAND_SIGNUP:
      return {
        ...state,
        signupBrandResponse: action.res.data
      }
    case AUTH.RD_SIGNUP:
      return {
        ...state,
        signupRDResponse: action.res.data
      }
    case 'SIGNUP_DT_VERIFY_EMAIL':
      return {
        ...state,
        verifyDTEmail: action.response
      }
    case 'USER_FORGET_PASSWORD_EMAIL':
      return {
        ...state,
        forgetPasswordResponse: action.forgetPasswordResponse
      }
    case 'USER_RESET_PASSWORD':
      return {
        ...state,
        resetPasswordResponse: action.resetPasswordResponse
      }
    case AUTH.COUNTRIES:
      return {
        ...state,
        countries: action.countries
      }
    case AUTH.REGIONS_BY_COUNTRY_ID:
      return {
        ...state,
        regionsByCountryID: action.regionsByCountryID
      }
    case AUTH.STATES_BY_REGIONS_ID:
      return {
        ...state,
        statesByRegionID: action.statesByRegionID
      }
    case AUTH.CITIES_BY_STATES_ID:
      return {
        ...state,
        citiesByStateID: action.citiesByStateID
      }
    case 'GET_BRAND_COMPANY_SELECT_VALUES':
      return {
        ...state,
        brandCompanySelectValues: action.response
      }
    case 'GET_BRAND_GOALS_SELECT_VALUES':
      return {
        ...state,
        brandGoalsSelectValues: action.response
      }
      break
    case 'REMOVE_VERIFY_EMAIL_RESPONSE':
      return {
        ...state,
        verifyDTEmail: null
      }
    case AUTH.RD_PRIMARILY_GOALS:
      return {
        ...state,
        rdPrimarilyGoalsValues: action.response
      }
    case AUTH.BRANDS_CARD_VERIFICATION:
      return {
        ...state,
        brandCardVerificationRes: action.brandCardVerificationRes
      }
    case AUTH.BRANDS_COUPON_CODE_VERIFICATION:
      return {
        ...state,
        brandCouponCodeVerificationRes: action.brandCouponCodeVerificationRes
      }
    case AUTH.PURCHASE_PLANS:
      return {
        ...state,
        purchasePlans: action.purchasePlans
      }
    case AUTH.ACTIVE_PLAN:
      return {
        ...state,
        activePlan: action.activePlan
      }
    case AUTH.PLAN_STATUS:
      return {
        ...state,
        planStatus: action.planStatus
      }
    case AUTH.HEAR_ABOUT_OPTIONS:
      return {
        ...state,
        hearAboutOptions: action.hearAboutOptions
      }
    case GENERAL.ERROR:
      return {
        ...state,
        error: action.error
      }
    case GENERAL.AUTH_ERROR:
    return {
      ...state,
      error: action.error
    }
    case "SERVER_ERROR_NULL":
      return {...initialState}
    // --- RD SignUp
    case RD_SIGNUP_FINAL.REQUEST:
      return {
        ...state,
        rdSignupFinalLoading: true,
        rdSignupFinalData: null,
        rdSignupFinalError: null
      }
    case RD_SIGNUP_FINAL.SUCCESS:
      return {
        ...state,
        rdSignupFinalLoading: false,
        rdSignupFinalData: action.payload,
        rdSignupFinalError: null
      }
    case RD_SIGNUP_FINAL.ERROR:
      return {
        ...state,
        rdSignupFinalLoading: false,
        rdSignupFinalData: null,
        rdSignupFinalError: action.payload
      }
    case RD_SIGNUP_FINAL.RESET:
      return {
        ...state,
        rdSignupFinalLoading: false,
        rdSignupFinalData: null,
        rdSignupFinalError: null
      }
      // --- Brand SignUp
    case BRAND_SIGNUP_FINAL.REQUEST:
      return {
        ...state,
        brandSignupFinalLoading: true,
        brandSignupFinalData: null,
        brandSignupFinalError: null
      }
    case BRAND_SIGNUP_FINAL.SUCCESS:
      return {
        ...state,
        brandSignupFinalLoading: false,
        brandSignupFinalData: action.payload,
        brandSignupFinalError: null
      }
    case BRAND_SIGNUP_FINAL.ERROR:
      return {
        ...state,
        brandSignupFinalLoading: false,
        brandSignupFinalData: null,
        brandSignupFinalError: action.payload
      }
    case BRAND_SIGNUP_FINAL.RESET:
      return {
        ...state,
        brandSignupFinalLoading: false,
        brandSignupFinalData: null,
        brandSignupFinalError: null
      }
    default:
      return state
      break
  }
}

export default authReducer
