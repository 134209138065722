import {Alert} from "reactstrap"
import {useEffect, useState} from "react"

const Maintenance = () => {
    const [show, setShow] = useState(false)
    const endDateTime = '2024-02-29 17:00:00'
    const msg = 'The RD Link will be down for scheduled maintenance on Monday 29th February 2024 from 1 AM to 5 AM.Your patience is appreciated. Thank you.'

    useEffect(() => {
        const endDate = new Date(endDateTime)
        const currentDate = new Date()
        if (currentDate <= endDate) {
            setShow(true)
        }
    }, [])

    return (
        <Alert isOpen={show} color='danger' className='mt-1'>
            <div className='p-1 text-center'>
                <strong>Alert: </strong>
                <span>{msg}</span>
            </div>
        </Alert>
    )
}
export default Maintenance