// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import outerPages from './outerPages'
import rd from './rdDashboard'
import brandsDashboardSlice from './brandsDashboard'
import adminDashboardSlice from './adminDashboard'
//import chat from '@src/views/apps/chat/store/reducer'
import chatAdmin from '@src/views/apps/chatAdmin/store/reducer'
import chatBrands from '@src/views/apps/chatBrands/store/reducer'
import chatRd from '@src/views/apps/chatRd/store/reducer'
//import todo from '@src/views/apps/todo/store/reducer'
//import todoAdmin from '@src/views/apps/todoAdmin/store/reducer'
//import todoBrands from '@src/views/apps/todoBrands/store/reducer'
//import todoRd from '@src/views/apps/todoRd/store/reducer'
//import users from '@src/views/apps/user/store/reducer'
//import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import calendarBrands from '@src/views/apps/calendarBrands/store/reducer'
import calendarRd from '@src/views/apps/calendarRd/store/reducer'
//import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import dataTablesAdmin from '@src/views/tables/data-tables-admin/store/reducer'
// import dataTablesAdminBlogs from '@src/views/tables/data-tables-admin-blogs/store/reducer' // Zubaie
import datatablesAdminManageEvents from '@src/views/tables/data-tables-admin-manage-events/store/reducer'
import dataTablesAdminContactDetails from '@src/views/tables/data-tables-admin-contact-details/store/reducer'
import datatablesAdminManageCoupons from '@src/views/tables/data-tables-admin-manage-coupons/store/reducer'
import datatablesAdminSubscribedBrands from '@src/views/tables/data-tables-admin-subscribed-brands/store/reducer'
import dataTablesAdminPending from '@src/views/tables/data-tables-admin-pending/store/reducer'
import dataTablesAdminBrands from '@src/views/tables/data-tables-admin-brands/store/reducer'
import dataTablesAdminBrandsPending from '@src/views/tables/data-tables-admin-brands-pending/store/reducer'
import adminAccountSettings from '@src/views/tables/reactstrap/admin/admin-account-settings/store/reducer'
import adminFaqsData from '@src/views/tables/data-tables-admin-faqs/store/reducer'
import adminSubscriptionReport from '@src/views/tables/data-tables-admin-subscription-reports/store/reducer'
import adminCommissionReport from '@src/views/tables/data-tables-admin-comission-reports/store/reducer'
import adminCancellSubscriptionsReport from '@src/views/tables/data-tables-admin-cancelled-subscription-reports/store/reducer'
import adminCmsSettings from '@src/views/tables/reactstrap/admin/admin-manage-cms/store/reducer'
import adminContacts from '@src/views/tables/data-tables-admin-contacts-new/store/reducer'
import brandsOppoprunities, {
  locationFromZipCodeReducer,
  campaignMsgReducer,
  campaignAddToFavReducer,
  saveCardInfoRDReducer,
  creditCardInfoRDReducer,
  creditCardSetDefaultRDReducer,
  creditCardDeleteRDReducer,
  masterFeaturesRDReducer,
  getPlanRDReducer,
  getActivePlanRReducer,
  getCancelPlanRReducer,
  resourcesRReducer,
  resourceContentRReducer,
  checkCreditCardRDReducer,
  saveSelectedPlanRDReducer,
  loadUpcomingEventsRDReducer,
  loadPastEventsRDReducer,
  verifyEmailReducer,
  brandInvoicesReducer,
  payNewInvoiceReducer
} from '@src/views/apps/opportunitiesBrands/store/reducer'
import brandDashboard from '@src/redux/reducers/brandProfile/index'
import brandManageUser from '@src/redux/reducers/brandManageUsers/index'
import rdBrands from '@src/views/apps/brandsRd/store/reducer'
import manageAch from '@src/views/pages/manageAchBrands/store/reducer'
import manageCards from '@src/views/pages/manageCards/store/reducer'
import rdCampaigns from '@src/views/pages/rdCampaigns/store/reducer'
import {getNotificationSettingReducer, notificationSettingUpdateReducer} from "@src/views/pages/notificationSetting/store/reducer"
import {addResourcesReducer, listResourcesReducer, statusResourcesReducer, deleteResourcesReducer} from '@src/views/tables/data-tables-admin-resources/store/reducer'
import {
  uploadLinkResourceReducer,
  resourcesContentReducer,
  resourceContentStatusReducer,
  resourceContentDeleteReducer,
  uploadDocsReducer,
  uploadMediaReducer
} from "@src/views/tables/data-tables-admin-resources-content/store/reducer"
import {
  getRDNotificationSettingReducer,
  rdNotificationSettingUpdateReducer
} from "@src/views/pages/rdNotificationSetting/store/reducer"
import {emailVerificationReducer} from "@src/views/pages/emailVerification/store/reducer"
import {invoiceReducer} from "@src/views/tables/data-tables-rd-invoices/store/reducer"
import unsubscribeReducer from "./unsubscribe"
import brandReducer from "@store/reducers/BrandReducer"
import rdReducer from "@store/reducers/RDReducer"
import adminReducer from "@store/reducers/AdminReducer"

const rootReducer = combineReducers({
  auth,
  outerPages,
  rd,
  brandsDashboardSlice,
  // dataTablesAdminBlogs,
  //todo,
  //todoAdmin,
  //todoBrands,
  //todoRd,
  //chat,
  chatAdmin,
  chatBrands,
  chatRd,
  //email,
  //users,
  navbar,
  layout,
  invoice,
  adminDashboardSlice,
  calendar,
  calendarBrands,
  calendarRd,
  //ecommerce,
  dataTables,
  dataTablesAdmin,
  datatablesAdminManageEvents,
  datatablesAdminSubscribedBrands,
  datatablesAdminManageCoupons,
  dataTablesAdminPending,
  dataTablesAdminBrands,
  dataTablesAdminBrandsPending,
  dataTablesAdminContactDetails,
  adminAccountSettings,
  adminFaqsData,
  adminSubscriptionReport,
  adminCommissionReport,
  adminCancellSubscriptionsReport,
  adminCmsSettings,
  adminContacts,
  brandsOppoprunities,
  brandDashboard,
  brandManageUser,
  rdBrands,
  manageAch,
  manageCards,
  rdCampaigns,
  getNotificationSettingReducer,
  notificationSettingUpdateReducer,
  locationFromZipCodeReducer,
  campaignMsgReducer,
  campaignAddToFavReducer,
  saveCardInfoRDReducer,
  creditCardInfoRDReducer,
  creditCardSetDefaultRDReducer,
  creditCardDeleteRDReducer,
  masterFeaturesRDReducer,
  getPlanRDReducer,
  getActivePlanRReducer,
  getCancelPlanRReducer,
  addResourcesReducer,
  listResourcesReducer,
  statusResourcesReducer,
  deleteResourcesReducer,
  uploadLinkResourceReducer,
  resourcesContentReducer,
  resourceContentStatusReducer,
  resourceContentDeleteReducer,
  uploadDocsReducer,
  uploadMediaReducer,
  resourcesRReducer,
  resourceContentRReducer,
  getRDNotificationSettingReducer,
  rdNotificationSettingUpdateReducer,
  checkCreditCardRDReducer,
  saveSelectedPlanRDReducer,
  loadUpcomingEventsRDReducer,
  loadPastEventsRDReducer,
  verifyEmailReducer,
  emailVerificationReducer,
  invoiceReducer,
  brandInvoicesReducer,
  payNewInvoiceReducer,
  unsubscribeReducer,
  brandReducer,
  rdReducer,
  adminReducer
})

export default rootReducer
