const API_URL = process.env.REACT_APP_BASEURL

const RD_CAMPAIGNS = {
    LISTING_DATA: '[RD] LISTING_DATA',
    ALL_LISTING_DATA: '[RD] ALL_LISTING_DATA',
    APPLIED_DETAIL: '[RD] APPLIED_DETAIL',
    SEARCH: '[RD] SEARCH',
    APPLICATION: '[RD] APPLICATION',
    ERROR: '[RD] ERROR'
}

const RD_DASHBOARD_CAMPAIGNS = {
    REQUEST: "RD_DASHBOARD_CAMPAIGNS_REQUEST",
    SUCCESS: "RD_DASHBOARD_CAMPAIGNS_SUCCESS",
    ERROR: "RD_DASHBOARD_CAMPAIGNS_ERROR"
}

const RD_DASHBOARD_CAMPAIGN_DETAIL = {
    REQUEST: "RD_DASHBOARD_CAMPAIGNS_DETAIL_REQUEST",
    SUCCESS: "RD_DASHBOARD_CAMPAIGNS_DETAIL_SUCCESS",
    ERROR: "RD_DASHBOARD_CAMPAIGNS_DETAIL_ERROR"
}

export {
    API_URL,
    RD_CAMPAIGNS,
    RD_DASHBOARD_CAMPAIGNS,
    RD_DASHBOARD_CAMPAIGN_DETAIL
}