export const BRAND_ACTION_TYPES = {
    SAVE_CREDIT_CARD_REQUEST: 'SAVE_CREDIT_CARD_REQUEST',
    SAVE_CREDIT_CARD_DATA: 'SAVE_CREDIT_CARD_DATA',
    SAVE_CREDIT_CARD_ERROR: 'SAVE_CREDIT_CARD_ERROR',
    SAVE_CREDIT_CARD_RESET: 'SAVE_CREDIT_CARD_RESET',

    VERIFY_COUPON_CODE_REQUEST: 'VERIFY_COUPON_CODE_REQUEST',
    VERIFY_COUPON_CODE_DATA: 'VERIFY_COUPON_CODE_DATA',
    VERIFY_COUPON_CODE_ERROR: 'VERIFY_COUPON_CODE_ERROR',
    VERIFY_COUPON_CODE_RESET: 'VERIFY_COUPON_CODE_RESET',

    ACTIVATE_PLAN_REQUEST: 'ACTIVATE_PLAN_REQUEST',
    ACTIVATE_PLAN_DATA: 'ACTIVATE_PLAN_DATA',
    ACTIVATE_PLAN_ERROR: 'ACTIVATE_PLAN_ERROR',
    ACTIVATE_PLAN_RESET: 'ACTIVATE_PLAN_RESET',

    VIEW_PROFILE_REQUEST: 'VIEW_PROFILE_REQUEST',
    VIEW_PROFILE_DATA: 'VIEW_PROFILE_DATA',
    VIEW_PROFILE_ERROR: 'VIEW_PROFILE_ERROR',

    SAVE_PROFILE_REQUEST: 'SAVE_PROFILE_REQUEST',
    SAVE_PROFILE_DATA: 'SAVE_PROFILE_DATA',
    SAVE_PROFILE_ERROR: 'SAVE_PROFILE_ERROR',
    SAVE_PROFILE_RESET: 'SAVE_PROFILE_RESET',

    RD_GROUP_LIST_REQUEST: 'RD_GROUP_LIST_REQUEST',
    RD_GROUP_LIST_DATA: 'RD_GROUP_LIST_DATA',
    RD_GROUP_LIST_ERROR: 'RD_GROUP_LIST_ERROR',
    RD_GROUP_LIST_RESET: 'RD_GROUP_LIST_RESET',

    RD_IG_DATA_REQUEST: 'RD_IG_DATA_REQUEST',
    RD_IG_DATA_DATA: 'RD_IG_DATA_DATA',
    RD_IG_DATA_ERROR: 'RD_IG_DATA_ERROR',

    COPY_CAMPAIGN_REQUEST: 'COPY_CAMPAIGN_REQUEST',
    COPY_CAMPAIGN_DATA: 'COPY_CAMPAIGN_DATA',
    COPY_CAMPAIGN_ERROR: 'COPY_CAMPAIGN_ERROR',
    COPY_CAMPAIGN_RESET: 'COPY_CAMPAIGN_RESET',

    MASS_MSG_TEMP_SAVE_REQUEST: 'MASS_MSG_TEMP_SAVE_REQUEST',
    MASS_MSG_TEMP_SAVE_DATA: 'MASS_MSG_TEMP_SAVE_DATA',
    MASS_MSG_TEMP_SAVE_ERROR: 'MASS_MSG_TEMP_SAVE_ERROR',
    MASS_MSG_TEMP_SAVE_RESET: 'MASS_MSG_TEMP_SAVE_RESET',

    MASS_MSG_TEMP_LIST_REQUEST: 'MASS_MSG_TEMP_LIST_REQUEST',
    MASS_MSG_TEMP_LIST_DATA: 'MASS_MSG_TEMP_LIST_DATA',
    MASS_MSG_TEMP_LIST_ERROR: 'MASS_MSG_TEMP_LIST_ERROR',

    MASS_MSG_TEMP_DELETE_REQUEST: 'MASS_MSG_TEMP_DELETE_REQUEST',
    MASS_MSG_TEMP_DELETE_DATA: 'MASS_MSG_TEMP_DELETE_DATA',
    MASS_MSG_TEMP_DELETE_ERROR: 'MASS_MSG_TEMP_DELETE_ERROR',
    MASS_MSG_TEMP_DELETE_RESET: 'MASS_MSG_TEMP_DELETE_RESET',

    MARK_AS_READ_REQUEST: 'MARK_AS_READ_REQUEST',
    MARK_AS_READ_DATA: 'MARK_AS_READ_DATA',
    MARK_AS_READ_ERROR: 'MARK_AS_READ_ERROR',
    MARK_AS_READ_RESET: 'MARK_AS_READ_RESET'
}