import {useEffect, useState} from "react"
import {Alert, Button, Form, Row, Spinner} from 'reactstrap'
import {CheckCircle, XCircle} from "react-feather"
import {Link, useHistory, useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import '@styles/base/pages/page-misc.scss'
import {unsubscribe, unsubscribeReset} from "@src/redux/actions/unsubscribe"

const Unsubscribe = () => {
    const {token} = useParams()
    const history = useHistory()

    const [successRes, setSuccessRes] = useState(null)
    const [showForm, setShowForm] = useState(true)

    const dispatch = useDispatch()

    const {
        unsubscribeLoading,
        unsubscribeFullFill,
        unsubscribeError
    } = useSelector((store) => store.unsubscribeReducer)

    useEffect(() => {
        if (unsubscribeFullFill?.status === true) {
            setSuccessRes(unsubscribeFullFill?.msg)
            setShowForm(false)
            dispatch(unsubscribeReset())
            setTimeout(() => {
                setSuccessRes(null)
                history.push("/")
            }, 5000)
        }
    }, [unsubscribeFullFill])

    const handelSubmit = () => {
        dispatch(unsubscribe({
            token
        }))
    }

    return (
        <div className='misc-wrapper'>
            <a className='brand-logo' href='/'>
                <h2 className='brand-text text-primary ml-1'>THE RD LINK</h2>
            </a>
            <div className=''>
                <div className='w-100 text-center'>
                    {unsubscribeError?.response?.status && (
                        <Alert color='danger'>
                            <div className='alert-body'>
                                <span className='fw-bold'>
                                    {unsubscribeError?.response?.status} - Invalid data found.
                                </span>
                            </div>
                        </Alert>
                    )}

                    {showForm && (
                        <>
                            <h1 className='mb-3'>Unsubscribe</h1>
                            <p className='mb-3 font-medium-4'>
                                <span>Do you really want to stop receiving emails?</span>
                            </p>
                            <Form tag={Row} className="d-flex justify-content-center">
                                <Button.Ripple color='success' className="mr-1" onClick={handelSubmit}
                                               disabled={unsubscribeLoading}>
                                    {unsubscribeLoading ? (
                                        <span><Spinner style={{height: '14px', width: '14px'}}/> Yes</span>) : (
                                        <span><CheckCircle size="14"/> Yes</span>)}
                                </Button.Ripple>
                                <Button.Ripple color='danger' tag={Link} to="/" disabled={unsubscribeLoading}>
                                    <XCircle size="14"/> No
                                </Button.Ripple>
                            </Form>
                        </>
                    )}
                    {successRes && (
                        <>
                            <h2 className='mb-1'>Thank You</h2>
                            <p className='mb-5 font-medium-5'>You have successfully unsubscribed form the email and won't receive any further emails form us.</p>
                            <p className="text-success"><CheckCircle size="150"/></p>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
export default Unsubscribe
