import axios from "axios"
import {BRAND_ACTION_TYPES} from "@store/actionTypes/BrandActionTypes"

const API_URL = `${process.env.REACT_APP_BASEURL}brand`

const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const saveCreditCard = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/save-card`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_ERROR, payload: error})
    }
}

export const saveCreditCardReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_RESET})
}

export const verifyCouponCode = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/verify-coupon`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_ERROR, payload: error})
    }
}

export const verifyCouponCodeReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_RESET})
}

export const activatePlan = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.ACTIVATE_PLAN_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/get-plan`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.ACTIVATE_PLAN_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.ACTIVATE_PLAN_ERROR, payload: error})
    }
}

export const activatePlanReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.ACTIVATE_PLAN_RESET})
}

export const viewProfile = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.VIEW_PROFILE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/view-profile`, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.VIEW_PROFILE_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.VIEW_PROFILE_ERROR, payload: error})
    }
}

export const saveProfile = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.SAVE_PROFILE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/update-profile`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.SAVE_PROFILE_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.SAVE_PROFILE_ERROR, payload: error})
    }
}

export const saveProfileReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.SAVE_PROFILE_RESET})
}

export const rdGroupList = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.RD_GROUP_LIST_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/all-rds-list`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.RD_GROUP_LIST_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.RD_GROUP_LIST_ERROR, payload: error})
    }
}

export const rdGroupListReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.RD_GROUP_LIST_RESET})
}

export const rdIgData = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.RD_IG_DATA_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/facebook-ig-data`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.RD_IG_DATA_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.RD_IG_DATA_ERROR, payload: error})
    }
}

export const copyCampaign = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/copy-campaign`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_ERROR, payload: error})
    }
}

export const copyCampaignReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_RESET})
}

export const massMessageTempSave = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/save-msg-template`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_ERROR, payload: error})
    }
}

export const massMessageTempSaveReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_RESET})
}

export const massMessageTempLoad = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_LIST_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/load-msg-template${dataObj}`, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_LIST_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_LIST_ERROR, payload: error})
    }
}

export const massMessageTempDelete = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/delete-msg-template`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_ERROR, payload: error})
    }
}

export const massMessageTempDeleteReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_RESET})
}

export const markAsRead = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MARK_AS_READ_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/mark-as-read`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.MARK_AS_READ_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.MARK_AS_READ_ERROR, payload: error})
    }
}

export const markAsReadReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MARK_AS_READ_RESET})
}