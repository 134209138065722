import {useEffect, Fragment} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {
    getFaqsPageData
} from '@src/redux/actions/outerPages'
import Main from '../Main'
import Footer from '../Footer'
import TrustedBrands from '../TrustedBrands'
import HowItWorks from '../HowItWorks'
import TextAndImage from '../TextAndImage'
import Testimonial from '../Testimonial'
import FAQ from '../FAQ'

const Home = () => {
    const dispatch = useDispatch()
    const outerPagesStoreSlice = useSelector(store => store.outerPages)
    useEffect(() => {
        dispatch(getFaqsPageData())
    }, [])

    return (
        <>
            <Main/>
            <HowItWorks/>
            <TextAndImage/>
            <Testimonial/>
            {outerPagesStoreSlice?.faqsPageInfo?.data && (
                <FAQ faqsInfo={outerPagesStoreSlice?.faqsPageInfo?.data}/>
            )}
            <TrustedBrands/>
            <Footer/>
        </>
    )
}

export default Home
