const CREATE_INVOICE = {
    REQUEST: 'CREATE_INVOICE_REQUEST',
    SUCCESS: 'CREATE_INVOICE_SUCCESS',
    ERROR: 'CREATE_INVOICE_ERROR',
    RESET: 'CREATE_INVOICE_RESET'
}

const ACTIVE_BRANDS = {
    REQUEST: 'ACTIVE_BRANDS_REQUEST',
    SUCCESS: 'ACTIVE_BRANDS_SUCCESS',
    ERROR: 'ACTIVE_BRANDS_ERROR'
}

const ALL_INVOICES = {
    REQUEST: 'ALL_INVOICES_REQUEST',
    SUCCESS: 'ALL_INVOICES_SUCCESS',
    ERROR: 'ALL_INVOICES_ERROR'
}

const DELETE_INVOICE = {
    REQUEST: 'DELETE_INVOICE_REQUEST',
    SUCCESS: 'DELETE_INVOICE_SUCCESS',
    ERROR: 'DELETE_INVOICE_ERROR',
    RESET: 'DELETE_INVOICE_RESET'
}

export { CREATE_INVOICE, ACTIVE_BRANDS, ALL_INVOICES, DELETE_INVOICE }