import {useEffect, useState} from "react"
import {Link, useHistory} from "react-router-dom"
import Avatar from "@components/avatar"
import {useDispatch} from "react-redux"
import {handleLogout} from "@store/actions/auth"
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem
} from "reactstrap"
import {User, Settings, CreditCard, Power, PhoneCall, Key, DollarSign} from "react-feather"
import BlankUser from "@src/assets/images/avatars/avatar-blank.png"

const UserDropdown = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [userData, setUserData] = useState(null)

    useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`)))
    }, [])

    const logout = () => {
        dispatch(handleLogout())
        history.push('/login?target=web')
    }

    return (
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item profile-tour">
            <DropdownToggle href="/"
                            tag="a"
                            className="nav-link dropdown-user-link"
                            onClick={(e) => e.preventDefault()}
            >
                <div className="user-nav d-sm-flex d-none">
                    <span className="user-name font-weight-bold">{userData?.name}</span>
                </div>
                <Avatar img={userData?.profile_image || BlankUser} imgHeight="40" imgWidth="40" status="online"/>
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem tag={Link} to="/dashboard/rd/view-profile">
                    <User size={14} className="mr-75"/>
                    <span className="align-middle">View Profile</span>
                </DropdownItem>
                <DropdownItem divider/>
                <DropdownItem tag={Link} to="/dashboard/rd/notification-settings">
                    <Settings size={14} className="mr-75"/>
                    <span className="align-middle">Notification Settings</span>
                </DropdownItem>
                <DropdownItem divider/>
                <DropdownItem tag={Link} to="/dashboard/rd/payment-settings">
                    <DollarSign size={14} className="mr-75"/>
                    <span className="align-middle">Payment Settings</span>
                </DropdownItem>
                <DropdownItem divider/>
                <DropdownItem tag={Link} to="/dashboard/rd/manage-cards">
                    <CreditCard size={14} className="mr-75"/>
                    <span className="align-middle">Manage Cards</span>
                </DropdownItem>
                <DropdownItem divider/>
                <DropdownItem tag={Link} to="/dashboard/rd/change-password">
                    <Key size={14} className="mr-75"/>
                    <span className="align-middle">Change Password</span>
                </DropdownItem>
                <DropdownItem divider/>
                <a className="text-body" href="mailto:hello@therdlink.com">
                    <DropdownItem>
                        <PhoneCall size={14} className='mr-75'/>
                        <span className='align-middle'>Contact Us</span>
                    </DropdownItem>
                </a>
                <DropdownItem divider/>
                <DropdownItem onClick={logout}>
                    <Power size={14} className="mr-75"/>
                    <span className="align-middle">Logout</span>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default UserDropdown
