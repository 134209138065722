// ** Config
import themeConfig from '@configs/themeConfig'

// ** React Imports
import { useEffect, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'

// ** Custom Components
import Avatar from '@components/avatar'
// import logo
// import logo from '../../../../../assets/images/logo.png'
// import {logo }from '../../../assets/images/logo.png'
import logo from '@src/assets/images/logo.png'

// ** Utils
import { isUserLoggedIn } from '@utils'

// ** Store & Actions
import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/actions/auth'

// ** Third Party Components
import { 
  UncontrolledDropdown, 
  DropdownMenu, 
  DropdownToggle, 
  DropdownItem
} from 'reactstrap'
import { 
  User, 
  Mail, 
  CheckSquare, 
  MessageSquare, 
  Settings, 
  CreditCard, 
  HelpCircle, 
  Power, 
  Menu, 
  Disc, 
  X, 
  Circle, 
  LogOut
} from 'react-feather'

import defaultAvatar from "@src/assets/images/avatars/avatar-blank.png"

const VerticalMenuHeader = props => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [userData, setUserData] = useState(null)
  
    //** ComponentDidMount
    useEffect(() => {
      if (isUserLoggedIn() !== null) {
        setUserData(JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`)))
      }
    }, [])
        
    //** Vars
    const userAvatar = (userData && userData.profile_image) || defaultAvatar

  // ** Props
  const { menuCollapsed, setMenuCollapsed, setMenuVisibility, setGroupOpen, menuHover } = props

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <X
          size={20}
          data-tour='toggle-icon'
          className='text-black toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(true)}
        />
      )
    } else {
      return (
        <Menu
          size={20}
          data-tour='toggle-icon'
          className='text-black toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(false)}
        />
      )
    }
  }

  const logoutUser = () => {
    // localStorage.removeItem(`${process.env.REACT_APP_USERTOKEN}`)
    // localStorage.removeItem(`${process.env.REACT_APP_USERDATA}`)
    dispatch(handleLogout())
    history.push('/login')
  }
  return (
    <>
    <div className='navbar-header'>
      <ul className='nav navbar-nav flex-row'>
        <li className='nav-item mr-auto'>
          <NavLink to='/' className='navbar-brand'>
            <span className='brand-logo'>
              <img src={logo} alt='logo' />
            </span>
            {/* <h2 className='brand-text mb-0'>{themeConfig.app.appName}</h2> */}
          </NavLink>
        </li>
        <li className='nav-item nav-toggle'>
          <div className='nav-link modern-nav-toggle cursor-pointer'>
            <Toggler />
            <X onClick={() => setMenuVisibility(false)} className='toggle-icon icon-x d-block d-xl-none' size={20} />
          </div>
        </li>
      </ul>
    </div>
    <div className="userDropDown">
      <ul> 
      <div className='user-nav d-sm-flex d-none flex-row align-items-center'>
    <div className="col-auto">
    <Avatar className="avatar-img-fit-contain" img={userAvatar} imgHeight='91' imgWidth='91' status='online' />
      </div>
      <div className="col">
      <Link to="/loginrd" className='user-name font-weight-medium text-white'>{userData?.name}</Link>
      <ul>
        <li className="d-inline-block mr-1" style={{marginTop: '3px'}}>
          <NavLink to="/dashboard/admin/edit-profile" className="text-white"><User size={18} /></NavLink>
          </li>
          <li className="d-inline-block mr-1">
          {/* <NavLink to="#" className="text-white" onClick={logoutUser}><LogOut size={18} /></NavLink> */}
          <LogOut className="text-white" size={18} onClick={logoutUser} />
          </li>
      </ul>
      </div>
    </div>
      </ul>
    </div>
      </>
  )
}

export default VerticalMenuHeader
