import {CANLCELLED_REPORT} from '../actionTypes/index'
// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  canclledSubscriptions: [],
  plansListings: [],
  searchCancelSubscriptionReports: [],
  error: null
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CANLCELLED_REPORT.LISTING:
      return {
        ...state,
        canclledSubscriptions: action.allData
      }
    case CANLCELLED_REPORT.PLANS_DATA:
      return {
        ...state,
        plansListings: action.allData
      }
    case CANLCELLED_REPORT.SEARCH:
      return {
        ...state,
        searchCancelSubscriptionReports: action.searchCancelSubscriptionReports
      }
    case CANLCELLED_REPORT.ERROR:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export default DataTablesReducer
