import {RD} from "../actionTypes"

const initialState = {
    chats: [],
    contacts: [],
    userProfile: {},
    selectedUser: {},
    rdChatWithBrands: null,
    rdContacts: [],
    chatRdContacts: {data: []},
    selectedUserRdChat: {data: []},
    selectedUserRDChatLoading: false,
    selectedRdContactDetail: null,
    rdUserProfile: null,
    rdMessageTempletes: null,
    rdMessageResponse: null,
    rdContactLoadError: null,
    rdChatLoadError: null
}

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case RD.CHAT_GET_USER_PROFILE_FROM_SERVER:
            return {
                ...state,
                rdUserProfile: action.rdUserProfile
            }
        case RD.CHAT_ADD_CONTACT:
            state?.chatRdContacts.data.unshift(action.rdContact)
            return {
                ...state
            }
        case RD.CHAT_CONTACTS:
            const newArray = state?.chatRdContacts.data.concat(action.chatRdContacts.data)
            if (newArray && newArray?.length !== 0) {
                const arrayUniqueByKey = [
                    ...new Map(newArray?.map(item => [
                        item['chat_user'] || item['user_id'],
                        item
                    ])).values()
                ]
                const finalarrayContacts = {data: arrayUniqueByKey}
                return {
                    ...state,
                    chatRdContacts: finalarrayContacts
                }
            }
            return {
                ...state,
                chatRdContacts: action.chatRdContacts
            }
        case RD.NEW_MESSAGE:
            state.selectedUserRdChat.data.push(action.message)
            return {
                ...state
            }
        case RD.CHAT_SELECTED_CONTACT:
            return {
                ...state,
                selectedRdContactDetail: action.selectedRdContactDetail
            }
        case RD.LOAD_SELECTED_USER_CHAT:
            return {
                ...state,
                selectedUserRDChatLoading: action.selectedUserRDChatLoading
            }
        case 'GET_USER_PROFILE':
            return {
                ...state,
                userProfile: action.userProfile
            }
        case 'GET_CHAT_CONTACTS':
            return {
                ...state,
                chats: action.data.chatsContacts,
                contacts: action.data.contacts
            }
        case 'SELECT_CHAT':
            return {
                ...state,
                selectedUser: action.data
            }
        case 'SEND_MSG':
            const newMsg = action.data.response.chat
            return {
                ...state,
                selectedUser: {...state.selectedUser, rdChatWithBrands: newMsg}
            }
        case 'RECEIVE_NEW_CHAT_MESSAGE': {
            return {
                ...state,
                selectedUser: {...state.selectedUser, chat: newMsg}
            }
        }
        case 'CHAT_RD_WITH_BRANDS':
            return {
                ...state,
                rdChatWithBrands: action.rdChatWithBrands
            }
        case 'SELECT_CHAT_FROM_SERVER':
            return {
                ...state,
                selectedUserRdChat: action.selectedUserChat
            }
        case 'SELECTED_CONTACT_DETAIL':
            return {
                ...state,
                selectedRdContactDetail: action.selectedContactDetail
            }
        case RD.CHAT_GET_MESSAGE_TEMPLETES:
            return {
                ...state,
                rdMessageTempletes: action.messageTemplete
            }
        case "SEND_MSG_SUCCESS_RESPONSE_RD":
            return {
                ...state,
                rdMessageResponse: action.payload
            }
        case "SEND_MSG_SUCCESS_RESPONSE_RD_NULL":
            return {
                ...state,
                rdMessageResponse: null
            }
        case 'CLEAR_RD_CHAT_COUNT_ON_CLICK':
            const data = action.payload
            const contacts = state?.chatRdContacts?.data?.map((item) => {
                if (item.user_id === data.user_id) {
                    return {...item, unread_messages: 0}
                } else {
                    return item
                }
            })
            return {
                ...state,
                chatRdContacts: {data: contacts}
            }
        case 'RD_CONTACT_LOAD_ERROR':
            return {
                ...state,
                rdContactLoadError: action.payload
            }
        case 'RD_CONTACT_LOAD_ERROR_RESET':
            return {
                ...state,
                rdContactLoadError: null
            }
        case 'RD_CHAT_LOAD_ERROR':
            return {
                ...state,
                rdChatLoadError: action.payload
            }
        case "RD_CHAT_LOAD_ERROR_RESET":
            return {
                ...state,
                rdChatLoadError: null
            }
        case "RD_SELECTED_USER_CHAT_LOADER_RESET":
            return {
                ...state,
                selectedUserRDChatLoading: false
            }
        default:
            return state
    }
}

export default chatReducer
