import {useEffect} from 'react'
import VerticalNavMenuLink from './VerticalNavMenuLink'
import VerticalNavMenuGroup from './VerticalNavMenuGroup'
import {useSelector, useDispatch} from 'react-redux'
import {getRdUnreadMessages} from '../../../../../redux/actions/rdDashboard'
import {
    resolveVerticalNavMenuItemComponent as resolveNavItemComponent,
    canViewMenuGroup,
    canViewMenuItem
} from '@layouts/utils'
//import {Lock} from "react-feather"

const VerticalMenuNavItems = props => {
    const {rdUnreadMessages} = useSelector(state => state.rd)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getRdUnreadMessages())
    }, [])

    const Components = {
        VerticalNavMenuGroup,
        VerticalNavMenuLink
    }

    const RenderNavItems = props.items.map((item, index) => {
        const TagName = Components[resolveNavItemComponent(item)]
        if (item.children) {
            return canViewMenuGroup(item) && <TagName item={item} index={index} key={item.id} {...props} />
        }
        if (item.id === "messagesRd") {
            item.badgeText = rdUnreadMessages?.data > 0 ? rdUnreadMessages?.data.toString() : ''
            return canViewMenuItem(item) && <TagName key={item.id || item.header} item={item} {...props} />
        } else {
            if (item.id === "resourcesRD") {
                //item.badgeText = (userData?.is_rd_plan === false) ? (<Lock style={{width: '15px', height: '15px'}}/>) : ""
                return canViewMenuItem(item) && <TagName key={item.id || item.header} item={item} {...props} />
            } else {
                return canViewMenuItem(item) && <TagName key={item.id || item.header} item={item} {...props} />
            }
        }
    })
    return RenderNavItems
}

export default VerticalMenuNavItems
