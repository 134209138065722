import { lazy } from 'react'
import { Redirect } from 'react-router-dom'

const AdminRoutes = [
    {
        path: '/pages/faqRd',
        component: lazy(() => import('../../views/tables/data-tables-admin-faqs/advance')),
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/contacts',
        exact: true,
        component: lazy(() => import('../../views/tables/reactstrap/admin/contacts/Contacts')),
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/contacts/:id',
        component: lazy(() => import('../../views/tables/reactstrap/admin/contacts/ContactDetails')),
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/dashboard/admin/blogs',
        exact: true,
        component: lazy(() => import('../../views/tables/data-tables-admin-blogs/advance')),
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/dashboard/admin/blogs/add',
        exact: true,
        component: lazy(() => import('../../views/tables/data-tables-admin-blogs/update')),
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/dashboard/admin/blogs/:id',
        component: lazy(() => import('../../views/tables/data-tables-admin-blogs/update')),
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/dashboard/admin/change-password',
        component: lazy(() => import('../../views/pages/userResetForm/AdminChangePasswordForm')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/dashboard/admin/edit-profile',
        component: lazy(() => import('../../views/pages/userResetForm/AdminEditProfile')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-rd',
        component: lazy(() => import('../../views/tables/data-tables-admin/advance')),
        layout: 'VerticalLayout',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-rd/:id',
        component: lazy(() => import('../../views/tables/data-tables-admin/view/ViewRD')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-events',
        component: lazy(() => import('../../views/tables/data-tables-admin-manage-events/advance')),
        layout: 'VerticalLayout',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-events/:id',
        component: lazy(() => import('../../views/tables/data-tables-admin-manage-events/view/ProfileViewManageEvents')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/subscribed-brands',
        component: lazy(() => import('../../views/tables/data-tables-admin-subscribed-brands/advance')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-coupons',
        component: lazy(() => import('../../views/tables/data-tables-admin-manage-coupons/advance')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/subscription-reports',
        component: lazy(() => import('../../views/tables/data-tables-admin-subscription-reports/advance')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/comission-reports',
        component: lazy(() => import('../../views/tables/data-tables-admin-comission-reports/advance')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/cancelled-subscription-reports',
        component: lazy(() => import('../../views/tables/data-tables-admin-cancelled-subscription-reports/advance')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/account-settings',
        component: lazy(() => import('../../views/tables/reactstrap/admin/admin-account-settings/AccountSettings')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-rd-pending',
        component: lazy(() => import('../../views/tables/data-tables-admin-pending/advance')),
        layout: 'VerticalLayout',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-rd-pending/:id',
        component: lazy(() => import('../../views/tables/data-tables-admin-pending/view/ViewPendingRD')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-brands',
        component: lazy(() => import('../../views/tables/data-tables-admin-brands/advance')),
        layout: 'VerticalLayout',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-brands/:id',
        component: lazy(() => import('../../views/tables/data-tables-admin-brands/view/ProfileViewManageBrands')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/cms',
        component: lazy(() => import('../../views/tables/reactstrap/admin/admin-manage-cms/ManageCms')),
        layout: 'VerticalLayout',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/cms/edit/:id',
        component: lazy(() => import('../../views/tables/reactstrap/admin/admin-manage-cms/edit/EditCMSPage')),
        layout: 'VerticalLayout',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/manage-event',
        component: lazy(() => import('../../views/pages/manageEventAdmin')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/subscribed-brands',
        component: lazy(() => import('../../views/pages/subscribedBrandsAdmin')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/dashboard/admin/notifications',
        component: lazy(() => import('../../views/pages/adminNotifications')),
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/apps/chat/chatAdmin',
        appLayout: true,
        className: 'chat-application',
        component: lazy(() => import('../../views/apps/admin-view-conversation')),
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'admin'
        }
    },
    {
        path: '/apps/calendar',
        component: lazy(() => import('../../views/apps/calendar')),
        meta: {
            action: "manage",
            resource: "admin"
        }
    },
    {
        path: '/apps/invoice/preview/:id',
        component: lazy(() => import('../../views/apps/invoice/preview')),
        meta: {
            navLink: '/apps/invoice/preview',
            action: "manage",
            resource: "admin"
        }
    },
    {
        path: '/apps/invoice/preview',
        exact: true,
        meta: {
            action: "manage",
            resource: "admin"
        },
        component: () => <Redirect to='/apps/invoice/preview/4987' />
    },
    {
        path: '/apps/invoice/edit/:id',
        component: lazy(() => import('../../views/apps/invoice/edit')),
        meta: {
            navLink: '/apps/invoice/edit',
            action: "manage",
            resource: "admin"
        }
    },
    {
        path: '/apps/invoice/edit',
        exact: true,
        meta: {
            action: "manage",
            resource: "admin"
        },
        component: () => <Redirect to='/apps/invoice/edit/4987' />
    },
    {
        path: '/apps/invoice/add',
        component: lazy(() => import('../../views/apps/invoice/add')),
        meta: {
            action: "manage",
            resource: "admin"
        }
    },
    {
        path: '/apps/invoice/print',
        layout: 'BlankLayout',
        component: lazy(() => import('../../views/apps/invoice/print')),
        meta: {
            action: "manage",
            resource: "admin"
        }
    },
    {
        path: '/admin/resources',
        component: lazy(() => import('../../views/tables/data-tables-admin-resources/advance')),
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/resources-content/:id',
        component: lazy(() => import('../../views/tables/data-tables-admin-resources-content/advance')),
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/host-video',
        component: lazy(() => import('../../views/tables/data-tables-admin-host-video/advance')),
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/users-permissions',
        component: lazy(() => import('../../views/tables/admin-user-permissions/advance')),
        layout: 'VerticalLayout',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    }
]

export default AdminRoutes