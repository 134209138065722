import {BRANDS} from "../actionTypes"

const initialState = {
    chats: [],
    contacts: [],
    userProfile: {},
    selectedUser: {},
    brandsChatWithRd: null,
    brandContacts: [],
    chatContacts: {data: []},
    selectedUserBrandChat: {data: []},
    selectedUserBrandChatLoading: false,
    selectedContactDetail: null,
    brandUserProfile: null,
    brandMessageTempletes: null,
    braodcastContactList: null,
    broadcastResponse: null,
    sendMessageResponse: null,
    contactLoadError: null,
    chatLoadError: null
}

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case BRANDS.CHAT_GET_USER_PROFILE_FROM_SERVER:
            return {
                ...state,
                brandUserProfile: action.brandUserProfile
            }
        case BRANDS.CHAT_ADD_CONTACT:
            state?.chatContacts.data.unshift(action.chatContact)
            return {
                ...state
            }
        case BRANDS.CHAT_CONTACTS:
            const array3 = state?.chatContacts.data?.concat(action.chatContacts?.data)
            if (array3?.length !== 0) {
                const arrayUniqueByKey = [
                    ...new Map(array3?.map(item => [
                        item['chat_user'],
                        item
                    ])).values()
                ]
                const finalarray = {data: arrayUniqueByKey}
                return {
                    ...state,
                    chatContacts: finalarray
                }
            }
            return {
                ...state,
                chatContacts: action.chatContacts.data
            }
        case BRANDS.CHAT_GET_MESSAGE_TEMPLETES:
            return {
                ...state,
                brandMessageTempletes: action.brandMessageTempletes
            }
        case BRANDS.SELECTED_USER_CHAT:
            return {
                ...state,
                selectedUserBrandChat: action.selectedUserBrandChat
            }
        case BRANDS.LOAD_SELECTED_USER_CHAT:
            return {
                ...state,
                selectedUserBrandChatLoading: action.selectedUserBrandChatLoading
            }
        case BRANDS.NEW_MESSAGE:
            state.selectedUserBrandChat.data.push(action.message)
            return {
                ...state
            }
        case BRANDS.CHAT_SELECTED_CONTACT:
            return {
                ...state,
                selectedContactDetail: action.selectedContactDetail
            }
        case BRANDS.CHAT_BRAODCAST_CONTACTS:
            return {
                ...state,
                braodcastContactList: action.braodcastContactList
            }
        case 'GET_USER_PROFILE':
            return {
                ...state,
                userProfile: action.userProfile
            }
        case 'GET_CHAT_CONTACTS':
            return {
                ...state,
                chats: action.data.chatsContacts,
                contacts: action.data.contacts
            }
        case 'SELECT_CHAT':
            return {
                ...state,
                selectedUser: action.data
            }
        case 'SEND_MSG':
            const newMsg = action.data.response.chat
            return {
                ...state,
                selectedUser: {...state.selectedUser, chat: newMsg}
            }
        case 'CHAT_BRAND_WITH_RD':
            return {
                ...state,
                brandsChatWithRd: action.brandsChatWithRd
            }
        case 'CLEAT_BROADCAST_LIST':
            return {
                ...state,
                braodcastContactList: null
            }
        case 'CLEAR_CHAT_ON_LOAD':
            return {
                ...state,
                chatContacts: {data: []}
            }
        case 'CLEAR_CHAT_CONTENT_ON_LOAD':
            return {
                ...state,
                selectedUserBrandChat: {data: []}
            }
        case 'CLEAR_CHAT_CONTACT_ON_LOAD':
            return {
                ...state,
                selectedContactDetail: null
            }
        case 'BROADCAST_SUCCESS_RESPONSE':
            return {
                ...state,
                broadcastResponse: action.payload
            }
        case 'CLEAT_BROADCAST_SUCCESS_RESPONSE':
            return {
                ...state,
                broadcastResponse: null
            }
        case 'SEND_MSG_SUCCESS_RESPONSE':
            return {
                ...state,
                sendMessageResponse: action.payload
            }
        case 'SEND_MSG_NULL_RESPONSE':
            return {
                ...state,
                sendMessageResponse: null
            }
        case 'CLEAR_BRAND_CHAT_COUNT_ON_CLICK':
            const data = action.payload
            const contacts = state?.chatContacts?.data?.map((item) => {
                if (item.user_id === data.user_id) {
                    return {...item, unread_messages: 0}
                } else {
                    return item
                }
            })
            return {
                ...state,
                chatContacts: {data: contacts}
            }
        case 'BRAND_CONTACT_LOAD_ERROR':
            return {
                ...state,
                contactLoadError: action.payload
            }
        case 'BRAND_CONTACT_LOAD_ERROR_RESET':
            return {
                ...state,
                contactLoadError: null
            }
        case 'BRAND_CHAT_LOAD_ERROR':
            return {
                ...state,
                chatLoadError: action.payload
            }
        case 'BRAND_CHAT_LOAD_ERROR_RESET':
            return {
                ...state,
                chatLoadError: null
            }
        case "SELECTED_CHAT_LOADER_RESET":
            return {
                ...state,
                selectedUserBrandChatLoading: false
            }
        default:
            return state
    }
}

export default chatReducer
