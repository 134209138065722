import React from 'react'
import { Link } from 'react-router-dom'
import style from './style.module.css'
import FooterLogo from '../../../assets/images/logo.png'
import fbIcon from '../../../assets/images/fbIcon.png'
import twitterIcon from '../../../assets/images/twitterIcon.png'
import youtubeIcon from '../../../assets/images/youtubeIcon.png'
import googleIcon from '../../../assets/images/googleIcon.png'
import { Facebook, Twitter, Linkedin, Instagram } from 'react-feather'

const Footer = () => {
  return (
    <div className={style.footerWrap}>
      <div className={style.container}>
        <div className={style.innerContainer}>
          <div className={style.logo}>
            <img src={FooterLogo} alt='footerlogo' />
          </div>
          <div className={style.footerNav}>
            <div className={style.navHeading}>
              <Link to='/features'>Features</Link>
            </div>
          </div>
          {/*<div className={style.footerNav}>*/}
          {/*  <div className={style.navHeading}>*/}
          {/*    <Link to='/pricing'>Pricing</Link>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={style.footerNav}>
            <div className={style.navHeading}>
              <Link to='/blog'>Blog</Link>
            </div>
          </div>
          <div className={style.footerNav}>
            <div className={style.navHeading}>
              <Link to='/contact'>Contact</Link>
            </div>
          </div>
          <div className={style.footerNav2}>
            <div className={style.navHeading}>Follow</div>
            <div className={style.socialmediicons}>
              {/*<a className="white-icon" href='javascript:;'>*/}
              {/*  <Facebook size={16} color="white" strokeWidth="3px" />*/}
              {/*</a>*/}
              {/*<a className="white-icon" href='javascript:;'>*/}
              {/*  <Twitter size={16} color="white" strokeWidth="3px" />*/}
              {/*</a>*/}
              <a className="white-icon" href='https://www.linkedin.com/company/therdlink/?viewAsMember=true' target="_blank">
                <Linkedin size={16} color="white" strokeWidth="3px" />
              </a>
              <a className="white-icon" href='https://www.instagram.com/therdlink' target="_blank">
                <Instagram size={16} color="white" strokeWidth="3px" />
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div className={style.bottomFooter}>
          <div className={style.rightReservedText}>
            © 2024 All rights reserved. <Link to='/'>LINK RD</Link>
          </div>
          <div className={style.termsText}>
            <Link to='/terms-and-conditions'>Terms & Conditions</Link>
            <Link to='/privacy-policy'>Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
