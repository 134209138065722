import { BRAND } from "../../actionTypes"

const initialState = {
  brandCreateUser: null,
  notifications: null,
  childUsersOfBrand: null,
  deleteChildUserOfBrand: null,
  updateStatusOfChildUserOfBrand: null,
  error: null
}

const brandManageUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case BRAND.BRAND_CREATE_USER:
      return {
        ...state,
        brandCreateUser: action.brandCreateUser
      }
    case BRAND.CHILD_USERS_LIST:
      return {
        ...state,
        childUsersOfBrand: action.childUsersOfBrand
      }
    case BRAND.DELETE_CHILD_USER:
      return {
        ...state,
        deleteChildUserOfBrand: action.deleteChildUserOfBrand
      }
    case BRAND.UPDATE_CHILD_USER_STATUS:
      return {
        ...state,
        updateStatusOfChildUserOfBrand: action.updateStatusOfChildUserOfBrand
      }
    case BRAND.BRAND_DASHBOARD_ERROR:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export default brandManageUserReducer
