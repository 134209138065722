import axios from 'axios'
import { API_URL, GENERAL, ADMIN_DASHBOARD } from '../../actionTypes'

const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getDashboardStatsValues = (filter) => {
    return dispatch => {
        axios.get(`${API_URL}admin/admin-dashboard-stats?stat=${filter}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: ADMIN_DASHBOARD.STATS_VALUES, stats: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.ADMIN_DASHBOARD_ERROR, error })
        })
    }
}

export const getDashboardNotifications = (isPagination, pageUrl = '') => {
    let endpoint 
    isPagination ? endpoint = `${pageUrl}` : endpoint = `${API_URL}admin/notifications`
    return dispatch => {
        axios.get(endpoint, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: ADMIN_DASHBOARD.NOTIFICATIONS, notifications: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.ADMIN_DASHBOARD_ERROR, error })
        })
    }
}

export const getadminUnreadNotifications = () => {

    return dispatch => {
        axios.get(`${API_URL}unread-notifications`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: ADMIN_DASHBOARD.ADMIN_UNREAD_NOTIFICATIONS, adminUnreadNotifications: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.ADMIN_DASHBOARD_ERROR, error })
        })
    }
}

export const getAllRdsChartData = (body) => {
    const formData = new FormData()
    formData.append("filter", body.filter)
    return dispatch => {
        axios.post(`${API_URL}admin/rd-graph`, formData, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: ADMIN_DASHBOARD.RD_GRAPH_DATA, rdGraphData: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.ADMIN_DASHBOARD_ERROR, error })
        })
    }
}
