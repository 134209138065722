import { PENDING_RD } from '../actionType'

// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  pendingRds: [],
  searchPendingRds: [],
  countries: [],
  states: [],
  industries: [],
  specialities: [],
  profileInfo: null,
  profileApproval: null,
  pendingRDUpdatedStatus: null,
  error: null
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'MANAGE_PENDING_RD_DATA':
      return {
        ...state,
        pendingRds: action.pendingRds
      }
    case 'MANAGE_PENDING_RD_SEARCH_DATA':
      return {
        ...state,
        pendingRds: action.searchPendingRds
      }
    case 'COUNTRIES_DATA':
      return {
        ...state,
        countries: action.countries
      }
    case 'STATES_DATA':
      return {
        ...state,
        states: action.states
      }
    case 'INDUSTRIES_DATA':
      return {
        ...state,
        industries: action.industries
      }
    case 'SPECIALITIES_DATA':
      return {
        ...state,
        specialities: action.specialities
      }
    case PENDING_RD.PROFILE_INFO:
      return {
        ...state,
        profileInfo: action.profileInfo
      }
    case PENDING_RD.PROFILE_APPROVE:
      return {
        ...state,
        profileApproval: action.profileApproval
      }
    case PENDING_RD.PENDING_RD_EXPORT:
      return {
        ...state,
        exportPendingRds: action.exportPendingRds
      }
    case PENDING_RD.PROFILE_STATUS:
      return {
        ...state,
        pendingRDUpdatedStatus: action.pendingRDUpdatedStatus
      }
    case PENDING_RD.ERROR:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export default DataTablesReducer
