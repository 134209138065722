
// AUTH 
const PENDING_RD = {
    PROFILE_INFO: '[Admin] PENDING_RD_PROFILE',
    PROFILE_APPROVE: '[Admin] PENDING_RD_PROFILE_APPROVE',
    PENDING_RD_EXPORT: '[Admin] PENDING_RD_EXPORT',
    ERROR: '[Admin] PENDING_RD_ERROR',
    PROFILE_STATUS: '[ADMIN] PENDING_RD_PROFILE_STATUS'
}

const NULL = {
    PROFILE_STATUS: '[NULL] PENDING_RD_PROFILE_STATUS'
}


export { PENDING_RD, NULL }