import NavbarUser from './NavbarUser'
import NavbarBookmarks from "./NavbarBookmarks"

const ThemeNavbar = (props) => {
    const {setMenuVisibility} = props

    return (
        <>
            <div className='bookmark-wrapper d-flex align-items-center'>
                <NavbarBookmarks setMenuVisibility={setMenuVisibility}/>
            </div>
            <NavbarUser/>
        </>
    )
}

export default ThemeNavbar
