import { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from "react-redux"
import {
  getAboutPageData
} from '@src/redux/actions/outerPages'
import {
  Spinner, CardBody, Card, CardHeader
} from "reactstrap"
import style from './style.module.css'
import Header from '../Header'
import Footer from '../Footer'
import TrustedBrands from '../TrustedBrands'
import UsesandFeatures from '../UsesandFeatures'

const AboutUs = () => {
  const [isPageLoading, setIsPageLoading] = useState(false)

  const dispatch = useDispatch()
  const outerPagesStoreSlice = useSelector(store => store.outerPages)

  useEffect(() => {
    if (!!outerPagesStoreSlice?.aboutPageInfo) {
      setIsPageLoading(false)
    }
  }, [outerPagesStoreSlice])

  useEffect(() => {
    setIsPageLoading(true)
    dispatch(getAboutPageData())
  }, [])

  return (
    <Fragment>
      <Header />
      <div className={style.container}>
        <Card className="mb-0">
          <CardHeader>
            <h3>About Us</h3>
          </CardHeader>
          <CardBody id="about-us-img-center">
            {isPageLoading && (
              <div className="w-100 my-4 text-center">
                <Spinner color='primary' />
              </div>)}
            {!isPageLoading && (
              <div dangerouslySetInnerHTML={{ __html: outerPagesStoreSlice?.aboutPageInfo?.data }} />
            )}
          </CardBody>
        </Card>
      </div>
      <Footer />
    </Fragment>
  )
}

export default AboutUs
