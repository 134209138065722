import {Fragment, useState} from 'react'
import navigation from '@src/navigation/verticalRd'
import classnames from 'classnames'
import VerticalMenuHeader from './VerticalMenuHeader'
import VerticalNavMenuItems from './VerticalNavMenuItems'

const Sidebar = props => {
    const {menuCollapsed, routerProps, currentActiveItem} = props

    const [groupOpen, setGroupOpen] = useState([])
    const [groupActive, setGroupActive] = useState([])
    const [activeItem, setActiveItem] = useState(null)

    const [menuHover, setMenuHover] = useState(false)

    const onMouseEnter = () => {
        if (menuCollapsed) {
            setMenuHover(true)
        }
    }

    return (
        <Fragment>
            <div className={classnames('main-menu menu-fixed menu-accordion menu-shadow menu-light', {
                expanded: menuHover || menuCollapsed === false
            })}
                 onMouseEnter={onMouseEnter}
                 onMouseLeave={() => setMenuHover(false)}
            >
                <Fragment>
                    <VerticalMenuHeader setGroupOpen={setGroupOpen} menuHover={menuHover} {...props} />
                    <ul className='navigation navigation-main'>
                        <VerticalNavMenuItems
                            items={navigation}
                            groupActive={groupActive}
                            setGroupActive={setGroupActive}
                            activeItem={activeItem}
                            setActiveItem={setActiveItem}
                            groupOpen={groupOpen}
                            setGroupOpen={setGroupOpen}
                            routerProps={routerProps}
                            menuCollapsed={menuCollapsed}
                            menuHover={menuHover}
                            currentActiveItem={currentActiveItem}
                        />
                    </ul>
                </Fragment>
            </div>
        </Fragment>
    )
}

export default Sidebar
