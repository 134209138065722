import {SUBSCRIPTION_REPORT} from '../actionTypes/index'

const initialState = {
  data: [],
  total: 1,
  params: {},
  subscriptionReports: [],
  subscriptionSearchReports: [],
  error: null
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_REPORT.LISTING:
      return {
        ...state,
        subscriptionReports: action.allData
      }
    case SUBSCRIPTION_REPORT.SEARCH:
      return {
        ...state,
        subscriptionSearchReports: action.searchSubscriptionReports
      }
    case SUBSCRIPTION_REPORT.ERROR:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export default DataTablesReducer
