import {COMMISSION_REPORT} from '../actionTypes/index'
// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  commissionReports: [],
  commissionSearchReports: [],
  error: null
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMISSION_REPORT.LISTING:
      return {
        ...state,
        commissionReports: action.allData
      }
    case COMMISSION_REPORT.SEARCH:
      return {
        ...state,
        commissionSearchReports: action.searchCommissionReports
      }
    case COMMISSION_REPORT.ERROR:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export default DataTablesReducer
