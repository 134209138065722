import { lazy } from 'react'
import RDSignup from '../../views/pages/rd/RDSignup'
import BrandSignup from '../../views/pages/rd/BrandSignup'
import SetPassword from '../../views/pages/misc/SetPassword'
import Login from '../../views/pages/authentication/Login'


const PagesRoutes = [
  {
    path: '/about',
    component: lazy(() => import('../../views/outerPages/AboutUs')),
    layout: 'BlankLayout',
    meta: {
      authRoute: false,
      publicRoute: true
    }
  },
  {
    path: '/terms-and-conditions',
    component: lazy(() => import('../../views/outerPages/TermsAndConditions')),
    layout: 'BlankLayout',
    meta: {
      authRoute: false,
      publicRoute: true
    }
  },
  {
    path: '/contact',
    component: lazy(() => import('../../views/outerPages/Contact')),
    layout: 'BlankLayout',
    meta: {
      authRoute: false,
      publicRoute: true
    }
  },
  {
    path: '/privacy-policy',
    component: lazy(() => import('../../views/outerPages/PrivacyPolicy')),
    layout: 'BlankLayout',
    meta: {
      authRoute: false,
      publicRoute: true
    }
  },
  {
    path: '/pricing',
    component: lazy(() => import('../../views/outerPages/Pricing')),
    layout: 'BlankLayout',
    meta: {
      authRoute: false,
      publicRoute: true
    }
  },
  {
    path: '/features',
    component: lazy(() => import('../../views/outerPages/Features')),
    layout: 'BlankLayout',
    meta: {
      authRoute: false,
      publicRoute: true
    }
  },
  {
    path: '/blog',
    component: lazy(() => import('../../views/outerPages/Blog')),
    layout: 'BlankLayout',
    exact: true,
    meta: {
      authRoute: false,
      publicRoute: true
    }
  },
  {
    path: '/blog/:id',
    component: lazy(() => import('../../views/outerPages/DetailBlog')),
    layout: 'BlankLayout',
    meta: {
      authRoute: false,
      publicRoute: true
    }
  },
  {
    path: '/login',
    component: Login,
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/register',
    component: lazy(() => import('../../views/pages/authentication/Register')),
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/forgot-password',
    component: lazy(() => import('../../views/pages/authentication/ForgotPassword')),
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/reset/password/:token',
    component: lazy(() => import('../../views/pages/authentication/ResetPasswordV2')),
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/misc/coming-soon',
    component: lazy(() => import('../../views/pages/misc/ComingSoon')),
    layout: 'BlankLayout',
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/misc/not-authorized',
    component: lazy(() => import('../../views/pages/misc/NotAuthorized')),
    layout: 'BlankLayout',
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/misc/maintenance',
    component: lazy(() => import('../../views/pages/misc/Maintenance')),
    layout: 'BlankLayout',
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/misc/error',
    component: lazy(() => import('../../views/pages/misc/Error')),
    layout: 'BlankLayout',
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/createloginrd',
    component: lazy(() => import('../../views/pages/rd/CreateLoginrd')),
    layout: 'BlankLayout',
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/register-brand',
    component: BrandSignup,
    layout: 'BlankLayout',
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/register-rd',
    component: RDSignup,
    layout: 'BlankLayout',
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/unsubscribe/:token',
    component: lazy(() => import('../../views/pages/misc/Unsubscribe')),
    layout: 'BlankLayout',
    meta: {
      authRoute: false,
      publicRoute: true
    }
  },
  {
    path: '/admin/set-password/:token',
    component: SetPassword,
    layout: 'BlankLayout',
    meta: {
      authRoute: false,
      publicRoute: true
    }
  }
]

export default PagesRoutes
