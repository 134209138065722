const API_URL = process.env.REACT_APP_BASEURL

const CONTACT = {
    GET_NEW_CONTACTS: '[Admin] GET_NEW_CONTACTS',
    GET_REPLIED_CONTACTS: '[Admin] GET_REPLIED_CONTACTS',
    GET_SEARCH_NEW_CONTACTS: '[Admin] GET_SEARCH_NEW_CONTACTS',
    GET_SEARCH_REPLIED_CONTACTS: '[Admin] GET_SEARCH_REPLIED_CONTACTS',
    SUBMIT_REPLY: '[Admin] SUBMIT_REPLY',
    DELETE_CONTACT: '[Admin] DELETE_CONTACT',
    LOAD_REPLIES: '[Admin] LOAD_REPLIES',
    SEARCH_LOAD_REPLIES: '[Admin] SEARCH_LOAD_REPLIES',
    ERROR: '[Admin] CONTACT_ERROR'
}

export { API_URL, CONTACT }