
const API_URL = process.env.REACT_APP_BASEURL

const MANAGE_ACH = {
    MANAGE_ACH_LISTING: '[BRAND] MANAGE_RD_LISTING',
    COUNTRIES: '[BRAND] COUNTRIES',
    SUBMIT_BANK_CARD: '[BRAND] SUBMIT_BANK_CARD',
    BANK_ACCOUNT_DETAILS: '[BRAND] BANK_ACCOUNT_DETAILS',
    VERIFY_ACCOUNT: '[BRAND] VERIFY_ACCOUNT',
    DELETE_ACCOUNT: '[BRAND] DELETE_ACCOUNT',
    ERROR: '[BRAND] MANAGE_RD_ERROR'
}

const NULL = {
    COUNTRIES: '[BRAND] COUNTRIES',
    SUBMIT_BANK_CARD: '[BRAND] SUBMIT_BANK_CARD',
    BANK_ACCOUNT_DETAILS: '[BRAND] BANK_ACCOUNT_DETAILS',
    VERIFY_ACCOUNT: '[BRAND] VERIFY_ACCOUNT',
    DELETE_ACCOUNT: '[BRAND] DELETE_ACCOUNT',
    ERROR: '[BRAND] MANAGE_RD_ERROR'
}

export { API_URL, MANAGE_ACH, NULL }