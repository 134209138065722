import {
    API_URL,
    BRANDS,
    BRAND,
    GENERAL,
    NULL,
    BRAND_MY_PARTNERSHIP,
    BRAND_STATS,
    REC_RDS,
    REC_OPPORTUNITIES
} from '../../actionTypes'
import axios from 'axios'

const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getGoalsAttributes = () => {
    return dispatch => {
        axios.get(`${API_URL}get-goals-attr`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then((res) => {
            dispatch({type: BRANDS.GOALS_ATTRIBUTES, goalsAttributes: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getCountries = () => {
    return dispatch => {
        axios.get(`${API_URL}get-countries`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then((res) => {
            dispatch({type: BRANDS.COUNTRIES, countries: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getRegionsByCountryId = ({id}) => {
    return dispatch => {
        axios.post(`${API_URL}get-regions`, {
            country_id: id
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then((res) => {
            dispatch({type: BRANDS.REGIONS_BY_COUNTRY_ID, regionsByCountryID: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getStatesByRegionId = ({id}) => {
    return dispatch => {
        axios.post(`${API_URL}get-states`, {
            region_id: id
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then((res) => {
            dispatch({type: BRANDS.STATES_BY_REGIONS_ID, statesByRegionID: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getCitiesByStateId = ({id}) => {
    return dispatch => {
        axios.post(`${API_URL}cities`, {
            state_id: [id]
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then((res) => {
            dispatch({type: BRANDS.CITIES_BY_STATES_ID, citiesByStateID: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getCitiesByStatesIds = ({statesIds}) => {
    return dispatch => {
        axios.post(`${API_URL}cities`, {
            state_id: statesIds
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then((res) => {
            dispatch({type: BRANDS.CITIES_BY_STATES_ID, citiesByStateID: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.RD_DASHBOARD_ERROR, error})
        })
    }
}

export const getEventTypes = () => {
    return dispatch => {
        axios.get(`${API_URL}get-event-type`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then((res) => {
            dispatch({type: BRANDS.EVENT_TYPES, eventTypes: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getAudienceTypes = () => {
    return dispatch => {
        axios.get(`${API_URL}get-audience-type`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then((res) => {
            dispatch({type: BRANDS.AUDIENCE_TYPES, audienceTypes: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getNutritionExperts = (isPagination, pageUrl = '') => {
    let endpoint = ''
    isPagination ? endpoint = pageUrl : endpoint = `${API_URL}brand/nutrition-expert`
    return dispatch => {
        axios.get(endpoint, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.NUTRITION_EXPERTS, nutritionExperts: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const searchNutritionExperts = (isPagination, body, pageUrl = '') => {
    let endpoint = ''
    isPagination ? endpoint = pageUrl : endpoint = `${API_URL}brand/nutrition-expert`
    return dispatch => {
        axios.post(endpoint, {
            speciality: body?.speciality,
            industry: body?.industry,
            personal_attr: body?.personal_attr,
            gender: body?.gender,
            country: body?.country,
            region: body?.region,
            state: body?.state,
            city: body?.city,
            name: body?.name,
            followers: body?.followers,
            type: body?.type
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.NUTRITION_EXPERTS, nutritionExperts: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getMyRDsAtBrands = () => {
    return dispatch => {
        axios.get(`${API_URL}brand/my-rds`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.MY_RDS, myRDsAtBrands: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const searchMyRDsAtBrands = (isPagination, body, pageUrl = '') => {
    let endpoint = ''
    isPagination ? endpoint = pageUrl : endpoint = `${API_URL}brand/my-rds`
    return dispatch => {
        axios.post(endpoint, {
            speciality: body.speciality,
            industry: body.industry,
            personal_attr: body.personal_attr,
            gender: body.gender,
            country: body.country,
            region: body.region,
            state: body.state,
            city: body.city,
            name: body.name
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.MY_RDS, myRDsAtBrands: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getFavoriteFolders = (isPagination, pageUrl = '') => {
    let endpoint = ''
    isPagination ? endpoint = pageUrl : endpoint = `${API_URL}brand/load-folder-list`
    return dispatch => {
        axios.get(endpoint, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.FAVORITE_FOLDERS, favoriteFoldersRes: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const searchFavoriteFolders = (isPagination, {searchFolderValue}, pageUrl = '') => {
    let endpoint = ''
    isPagination ? endpoint = pageUrl : endpoint = `${API_URL}brand/load-folder-list`
    return dispatch => {
        axios.post(endpoint, {
            folder_name: searchFolderValue
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.FAVORITE_FOLDERS, favoriteFoldersRes: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const createFavoriteFolder = ({folderName}) => {
    return dispatch => {
        axios.post(`${API_URL}brand/submit-folder`, {
            folder_name: folderName
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.CREATE_FAVORITE_FOLDER, createfavoriteFolderRes: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const updateFavoriteFolder = ({id, folderName}) => {
    return dispatch => {
        axios.post(`${API_URL}brand/update-folder`, {
            id,
            folder_name: folderName
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.UPDATE_FAVORITE_FOLDER, updatefavoriteFolderRes: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const deleteFavoriteFolder = ({id}) => {
    return dispatch => {
        axios.post(`${API_URL}brand/delete-folder`, {
            id
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.DELETE_FAVORITE_FOLDER, deletefavoriteFolderRes: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const addToFavorite = ({id, folder_id, type}) => {
    return dispatch => {
        axios.post(`${API_URL}brand/add-to-favorite`, {
            id,
            folder_id,
            type
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.ADD_TO_FAVORITE, addToFavroiteRes: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getFavoriteRDProfiles = (isPagination, pageUrl = '', folderId) => {
    let endpoint = ''
    isPagination ? endpoint = pageUrl : endpoint = `${API_URL}brand/favorites-profiles?folder_id=${folderId}`
    return dispatch => {
        axios.get(endpoint, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.FAVORITE_RD_PROFILES, favoriteRDProfilesRes: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const searchFavoriteRDProfiles = (isPagination, pageUrl = '', folderId, body) => {
    let endpoint = ''
    isPagination ? endpoint = `${pageUrl}&folder_id=${folderId}` : endpoint = `${API_URL}brand/favorites-profiles?folder_id=${folderId}`
    return dispatch => {
        axios.post(endpoint, {
            speciality: body.speciality,
            industry: body.industry,
            personal_attr: body.personal_attr,
            country: body.country,
            region: body.region,
            state: body.state,
            city: body.city,
            rd_name: body.rd_name
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.FAVORITE_RD_PROFILES, favoriteRDProfilesRes: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getFavoriteRDOpportunities = (isPagination, pageUrl = '', folderId) => {
    let endpoint = ''
    isPagination ? endpoint = pageUrl : endpoint = `${API_URL}brand/favorites-opportunities?folder_id=${folderId}`
    return dispatch => {
        axios.get(endpoint, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.FAVORITE_RD_OPPORTUNITIES, favoriteRDOpportunitiesRes: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const searchFavoriteRDOpportunities = (isPagination, pageUrl = '', folderId, body) => {
    let endpoint = ''
    isPagination ? endpoint = `${pageUrl}&folder_id=${folderId}` : endpoint = `${API_URL}brand/favorites-opportunities?folder_id=${folderId}`
    return dispatch => {
        axios.post(endpoint, {
            country_id: body.country_id,
            state_id: body.state_id,
            city: body.city,
            event_type: body.event_type,
            audience_type: body.audience_type,
            from_date: body.from_date,
            to_date: body.to_date,
            txt_search: body.txt_search
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.FAVORITE_RD_OPPORTUNITIES, favoriteRDOpportunitiesRes: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getRdDetailsAtBrand = (id) => {
    return dispatch => {
        axios.get(`${API_URL}brand/view-rd-profile?id=${id}`, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(res => {
            dispatch({
                type: BRANDS.RD_PROFILE,
                rdDetailsAtBrand: res.data
            })
        }).catch((error) => {
            dispatch({
                type: GENERAL.BRANDS_DASHBOARD_ERROR, error
            })
        })
    }
}

export const getRecommendedRdsAtBrandsDashboard = (qryString) => async (dispatch) => {
    dispatch({ type: REC_RDS.REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}brand/dashboard-rds${qryString}`, {headers: header})
        dispatch({type: REC_RDS.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: REC_RDS.ERROR, payload: error})
    }
}

export const updateBrandProfile = (body) => {
    const formData = new FormData()
    formData.append("email", body.email ?? null)
    formData.append("name", body.firstAndLastName ?? null)
    formData.append("brand_name", body.companyName ?? null)
    formData.append("person_title", body.personTitle ?? null)
    formData.append("phone_number", body.phoneNumber ?? null)
    formData.append("facebook_url", body.facebookLinkBrand ?? null)
    formData.append("instagram_url", body.instagramLinkBrand ?? null)
    formData.append("twitter_url", body.twitterLinkBrand ?? null)
    formData.append("pinterest_url", body.pinterestLinkBrand ?? null)
    formData.append("linkedin_url", body.linkedinLinkBrand ?? null)
    formData.append("tiktok_url", body.tiktokLinkBrand ?? null)
    formData.append("website", body.websiteBrand ?? null)
    formData.append("about_company", body.aboutCompany ?? null)
    formData.append("company_logo", body.companyLogoFile ?? null)
    formData.append("country", body?.country?.id ?? null)
    formData.append("region", body?.region?.id ?? "")
    formData.append("goals", body.goals ?? null)
    formData.append("is_other_hear_about", body.hereAboutOtherInput ?? null)
    formData.append("re_captcha_token", body.reCaptchaToken ?? null)

    if (body?.state?.length === 0) {
        formData.append("state[]", null)
    } else {
        body?.state?.forEach(id => formData.append("state[]", id?.id))
    }

    if (body?.city?.length === 0) {
        formData.append("city[]", null)
    } else {
        body?.city?.forEach(id => formData.append("city[]", id?.id))
    }

    if (body?.personalAttributes?.length === 0) {
        formData.append("personal_attributes[]", null)
    } else {
        body?.personalAttributes?.forEach(id => formData.append("personal_attributes[]", id.id ?? null))
    }

    if (body?.industry?.length === 0) {
        formData.append("industry[]", null)
    } else {
        body?.industry?.forEach(id => formData.append("industry[]", id.id ?? null))
    }

    if (body?.speciality?.length === 0) {
        formData.append("speciality[]", null)
    } else {
        body?.speciality?.forEach(id => formData.append("speciality[]", id.id ?? null))
    }

    if (body?.companyValues?.length === 0) {
        formData.append("values[]", null)
    } else {
        body?.companyValues?.forEach(id => formData.append("values[]", id.id ?? null))
    }

    if (body?.specialDiet?.length === 0) {
        formData.append("special_diet[]", null)
    } else {
        body?.specialDiet?.forEach(id => formData.append("special_diet[]", id.id ?? null))
    }

    if (body?.foodCategory?.length === 0) {
        formData.append("category[]", null)
    } else {
        body?.foodCategory?.forEach(id => formData.append("category[]", id.id ?? null))
    }

    if (body?.hearAboutUs?.id) {
        formData.append("here_about[]", body.hearAboutUs?.id ?? null)
    } else {
        formData.append("here_about[]", null)
    }

    if (body?.documentsUpload) {
        formData.append("media_documents[]", body.documentsUpload ?? null)
    }

    return dispatch => {
        return axios.post(`${API_URL}brand/update-profile`, formData, {
            headers: {
                Accept: "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(res => {
            dispatch({type: BRANDS.UPDATE_PROFILE, updateBrandProfile: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const removeMediaFromProfile = (media_id) => {
    return dispatch => {
        axios.post(`${API_URL}brand/delete-product-image`, {media_id}, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(res => {
            dispatch({
                type: BRANDS.REMOVE_MEDIA_ON_PROFILE,
                removeMediaAtProfile: res.data
            })
        }).catch((error) => {
            dispatch({
                type: GENERAL.BRANDS_DASHBOARD_ERROR, error
            })
        })
    }
}

export const nullResponse = (property) => {
    return dispatch => {
        switch (property) {
            case NULL.BRANDS_GET_FOLDERS:
                dispatch({type: BRANDS.FAVORITE_FOLDERS, favoriteFoldersRes: null})
                break
            case NULL.BRANDS_CREATE_FOLDER:
                dispatch({type: BRANDS.CREATE_FAVORITE_FOLDER, createfavoriteFolderRes: null})
                break
            case NULL.BRANDS_UPDATE_FOLDER:
                dispatch({type: BRANDS.UPDATE_FAVORITE_FOLDER, updatefavoriteFolderRes: null})
                break
            case NULL.BRANDS_DELETE_FOLDER:
                dispatch({type: BRANDS.DELETE_FAVORITE_FOLDER, deletefavoriteFolderRes: null})
                break
            case NULL.BRANDS_ADD_TO_FAVORITE:
                dispatch({type: BRANDS.ADD_TO_FAVORITE, addToFavroiteRes: null})
                break
            case NULL.BRANDS_NUTRITION_EXPERTS:
                dispatch({type: BRANDS.NUTRITION_EXPERTS, nutritionExperts: null})
                break
            case NULL.BRANDS_REGIONS_BY_COUNTRY_ID:
                dispatch({type: BRANDS.REGIONS_BY_COUNTRY_ID, regionsByCountryID: null})
                break
            case NULL.BRANDS_STATES_BY_REGIONS_ID:
                dispatch({type: BRANDS.STATES_BY_REGIONS_ID, statesByRegionID: null})
                break
            case NULL.BRANDS_CITIES_BY_STATES_ID:
                dispatch({type: BRANDS.CITIES_BY_STATES_ID, citiesByStateID: null})
                break
            case NULL.BRANDS_MY_RDS:
                dispatch({type: BRANDS.MY_RDS, myRDsAtBrands: null})
                break
            case NULL.BRANDS_PROFILE:
                dispatch({type: BRAND.BRAND_PROFILE, brandProfile: null})
                break
            case NULL.BRANDS_UPDATE_PROFILE:
                dispatch({type: BRANDS.UPDATE_PROFILE, updateBrandProfile: null})
                break
            case NULL.BRANDS_REMOVE_MEDIA_ON_PROFILE:
                dispatch({type: BRANDS.REMOVE_MEDIA_ON_PROFILE, removeMediaAtProfile: null})
                break
            case NULL.ADD_NOTE:
                dispatch({type: BRANDS.ADD_NOTE, addedNote: null})
                break
            case NULL.UPDATE_NOTE:
                dispatch({type: BRANDS.UPDATE_NOTE, updatedNote: null})
                break
        }
    }
}

export const getBrandDashboardNotifications = (isPagination, pageUrl = '') => {
    let endpoint
    isPagination ? endpoint = `${pageUrl}` : endpoint = `${API_URL}brand/notifications`
    return dispatch => {
        axios.get(endpoint, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.BRAND_NOTIFICATIONS, brandNotifications: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getBrandDashboardStatsValues = (filter) => async (dispatch) => {
    dispatch({ type: BRAND_STATS.REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}brand/dashboard-statistics?stat=${filter}`, {headers: header})
        dispatch({type: BRAND_STATS.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: BRAND_STATS.ERROR, payload: error})
    }
}

export const getMyPartnerships = (qryString) => async (dispatch) => {
    dispatch({type: BRAND_MY_PARTNERSHIP.REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}brand/my-partnerships${qryString}`, {headers: header})
        dispatch({type: BRAND_MY_PARTNERSHIP.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: BRAND_MY_PARTNERSHIP.ERROR, payload: error})
    }
}

export const getMyCurrentOpportunities = (qryString) => async (dispatch) => {
    dispatch({type: REC_OPPORTUNITIES.REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}brand/dashboard-opportunities${qryString}`, {headers: header})
        dispatch({type: REC_OPPORTUNITIES.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: REC_OPPORTUNITIES.ERROR, payload: error})
    }
}

// Zubair
// export const getMyCurrentOpportunities = (isPagination, pageUrl = '') => {
//     let endpoint = ''
//     isPagination ? endpoint = pageUrl : endpoint = `${API_URL}brand/dashboard-opportunities`
//     return dispatch => {
//         axios.get(endpoint, {
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//                 "X-Requested-With": "XMLHttpRequest"
//             }
//         }).then((res) => {
//             dispatch({type: BRANDS.CURRENT_OPPORTUNITIES, currentOpportunities: res.data})
//         }).catch(error => {
//             dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
//         })
//     }
// }

export const postMyPartnerships = (isPagination, body, pageUrl = '') => {
    let endpoint
    isPagination ? endpoint = `${pageUrl}` : endpoint = `${API_URL}brand/my-partnerships`

    return dispatch => {

        axios.post(endpoint, {

            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            status: body.status,
            search: body.search

        }).then((res) => {
            dispatch({type: BRANDS.MY_PARTNERSHIPS, partnerships: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const addPartnershipNote = (parmas) => {
    return dispatch => {
        axios.post(`${API_URL}brand/notes`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            id: parmas.id,
            notes: parmas.notes
        }).then((res) => {
            dispatch({type: BRANDS.ADD_NOTE, addedNote: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getBrandUnreadMessages = () => {

    return dispatch => {
        axios.get(`${API_URL}unread-messages`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.BRAND_UNREAD_MESSAGES, brandUnreadMessages: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getBrandUnreadNotifications = () => {

    return dispatch => {
        axios.get(`${API_URL}unread-notifications`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({type: BRANDS.BRAND_UNREAD_NOTIFICATIONS, brandUnreadNotifications: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const getRecommendedRd = (isPagination, pageUrl = '') => {
    let endpoint
    isPagination ? endpoint = `${pageUrl}` : endpoint = `${API_URL}brand/dashboard-rds`
    return dispatch => {

        axios.get(endpoint, {

            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            }
        }).then((res) => {
            dispatch({type: BRANDS.RECOMMENDED_RD, recommendedRd: res.data})
        }).catch(error => {
            dispatch({type: GENERAL.BRANDS_DASHBOARD_ERROR, error})
        })
    }
}

export const profileLinks = (dataObj) => {
    return dispatch => {
        axios.post(`${API_URL}rd/profile-links`, dataObj, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
        }).catch(error => {
        })
    }
}
