import React, {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Card, CardBody, Form, FormGroup, Label, Input, CustomInput, Button, Spinner} from 'reactstrap'
import Logo from '../../../assets/images/logo.png'
import '@styles/base/pages/page-auth.scss'
import classnames from "classnames"
import {ArrowRight, FileText, Layout, MapPin, User, Users} from "react-feather"
import {Formik} from "formik"
import * as yup from "yup"
import Select from "react-select"
import {useSelector, useDispatch} from "react-redux"
import {
    getBrandCompanySelectValues,
    getBrandGoalsSelectValues,
    signUpRDFinal,
    signUpRDFinalReset
} from '@store/actions/auth'
import {
    getCountries,
    getRegions,
    getStates,
    getCities,
    locationFromZipCodeAction,
    locationFromZipCodeActionReset,
    saveSelectedPlanRDAction
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useHistory} from 'react-router'
import InputPasswordToggle from '@components/input-password-toggle'
import {AbilityContext} from '@src/utility/context/Can'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"

const RDSignup = () => {

    const {executeRecaptcha} = useGoogleReCaptcha()

    const genderOption = [
        {value: 'Male', label: 'Male'},
        {value: 'Female', label: 'Female'},
        {value: 'Non-Binary', label: 'Non-Binary'},
        {value: 'Transgender', label: 'Transgender'}
    ]

    const rdOption = [
        {value: 'RD', label: 'RD'},
        {value: 'RD2Be', label: 'RD2Be'},
        {value: 'Other', label: 'Other'}
    ]

    const hearAboutOption = [
        {value: 'SocialMedia', label: 'Social Media'},
        {value: 'Colleague', label: 'Colleague'},
        {value: 'Other', label: 'Other'}
    ]

    const licenceOption = [
        {value: 'CDR', label: 'CDR Number'},
        {value: 'DOC', label: 'Proof of Certification'}
    ]

    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

    const onlyCharRegex = /^[aA-zZ\s]+$/

    const CERTIFICATE_FORMATS = ["image/png", "image/jpeg", "image/jpg", "application/pdf"]

    const CERTIFICATE_MAX_SIZE = 5 * 1024 * 1024

    const PROFILE_PIC_FORMATS = ["image/png", "image/jpeg", "image/jpg"]

    const PROFILE_PIC_MAX_SIZE = 2 * 1024 * 1024

    const ability = useContext(AbilityContext)

    const sweetAlert = withReactContent(Swal)

    const history = useHistory()

    const [country, setCountry] = useState([])
    const [region, setRegion] = useState([])
    const [state, setState] = useState([])
    const [city, setCity] = useState([])

    const [countryValue, setCountryValue] = useState(null)
    const [regionValue, setRegionValue] = useState(null)
    const [stateValue, setStateValue] = useState(null)
    const [cityValue, setCityValue] = useState(null)

    const [timeZone, setTimeZone] = useState("")
    const [location, setLocation] = useState("")

    const dispatch = useDispatch()

    const {
        brandGoalsSelectValues,
        brandCompanySelectValues,
        rdSignupFinalLoading,
        rdSignupFinalData,
        rdSignupFinalError
    } = useSelector(state => state.auth)

    const {
        countries,
        regions,
        states,
        cities
    } = useSelector((store) => store.brandsOppoprunities)

    const {
        locationFromZipCodeLoading,
        locationFromZipCodeData
    } = useSelector((store) => store.locationFromZipCodeReducer)

    useEffect(() => {
        if (countries) {
            setCountry(countries)
            dispatch({type: 'CLEAR_LOCATION_DATA_ON_SIGNUP'})
        }
        if (regions) {
            setRegion(regions)
            dispatch({type: 'CLEAR_LOCATION_DATA_ON_SIGNUP'})
        }
        if (states) {
            setState(states)
            dispatch({type: 'CLEAR_LOCATION_DATA_ON_SIGNUP'})
        }
        if (cities) {
            setCity(cities)
            dispatch({type: 'CLEAR_LOCATION_DATA_ON_SIGNUP'})
        }
        if (locationFromZipCodeData?.data?.city_id) {
            const countryObj = {
                id: locationFromZipCodeData.data.country_id,
                value: locationFromZipCodeData.data.country_name,
                label: locationFromZipCodeData.data.country_name
            }
            setCountryValue(countryObj)

            const regionObj = {
                id: locationFromZipCodeData.data.region_id,
                value: locationFromZipCodeData.data.region_name,
                label: locationFromZipCodeData.data.region_name
            }
            setRegionValue(regionObj)
            setRegion([regionObj])

            const stateObj = {
                id: locationFromZipCodeData.data.state_id,
                value: locationFromZipCodeData.data.state_name,
                label: locationFromZipCodeData.data.state_name
            }
            setStateValue(stateObj)
            setState([stateObj])

            const cityObj = {
                id: locationFromZipCodeData.data.city_id,
                value: locationFromZipCodeData.data.city_name,
                label: locationFromZipCodeData.data.city_name
            }
            setCityValue(cityObj)
            setCity([cityObj])

            dispatch(locationFromZipCodeActionReset())
        }
        if (rdSignupFinalError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (rdSignupFinalError?.response?.data?.email) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${rdSignupFinalError?.response?.data?.email[0]}</li>`)
            }
            if (rdSignupFinalError?.response?.data?.password) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${rdSignupFinalError?.response?.data?.password[0]}</li>`)
            }
            if (rdSignupFinalError?.response?.data?.confirm_password) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${rdSignupFinalError?.response?.data?.confirm_password[0]}</li>`)
            }
            if (rdSignupFinalError?.response?.data?.name) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${rdSignupFinalError?.response?.data?.name[0]}</li>`)
            }
            if (rdSignupFinalError?.response?.data?.profile_pic) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${rdSignupFinalError?.response?.data?.profile_pic[0]}</li>`)
            }
            if (rdSignupFinalError?.response?.data?.proof_of_certification) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${rdSignupFinalError?.response?.data?.proof_of_certification[0]}</li>`)
            }
            if (rdSignupFinalError?.response?.data?.re_captcha_token) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${rdSignupFinalError?.response?.data?.re_captcha_token[0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(signUpRDFinalReset())
        }
        if (rdSignupFinalData?.success) {
            ability.update([{action: "manage", subject: "rd"}])
            // sweetAlert.fire('Congratulations!', 'Your profile has been created successfully.', 'success')
            dispatch(signUpRDFinalReset())
            //history.push('/rd/upgrade-membership')
            dispatch(saveSelectedPlanRDAction({plan_id: 3}))
            history.push('/pending-account-msg')
        }
    }, [countries, regions, states, cities, locationFromZipCodeData, rdSignupFinalError, rdSignupFinalData])

    useEffect(() => {
        const tDate = new Date()
        setTimeZone(tDate.toString())
        setLocation(Intl.DateTimeFormat().resolvedOptions().timeZone)
        dispatch(getBrandGoalsSelectValues())
        dispatch(getBrandCompanySelectValues())
        dispatch(getCountries())
    }, [])

    const getRegionsById = (e) => {
        setRegion([])
        setState([])
        setCity([])
        dispatch(getRegions(e.id))
    }

    const getStatesById = (e) => {
        setState([])
        setCity([])
        dispatch(getStates(e.id))
    }

    const getCitiesById = (e) => {
        setCity([])
        dispatch(getCities(e.id))
    }

    const getLocationFromPostalCode = (e) => {
        setRegion([])
        setState([])
        setCity([])
        setCountryValue(null)
        setRegionValue(null)
        setStateValue(null)
        setCityValue(null)
        dispatch(locationFromZipCodeAction({
            zip_code: e.target.value
        }))
    }

    const registerRD = async (data) => {
        const token = await executeRecaptcha('rd_signup')
        if (token) {
            const formData = new FormData()
            formData.append("email", data.email)
            formData.append("password", data.password)
            formData.append("confirm_password", data.confirmPassword)
            formData.append("name", data.firstLastName)
            formData.append("is_rd", data.registeredDietitian.value === 'RD' ? 1 : 0)
            formData.append("is_dietetic_intern", data.registeredDietitian.value === 'RD2Be' ? 1 : 0)
            formData.append("is_other_rd", data.otherRD)
            if (data?.cdrNo) {
                formData.append("cdr_number", data.cdrNo)
            }
            if (data?.proofOfCertification) {
                formData.append("proof_of_certification", data.proofOfCertification)
            }
            formData.append("gender", data.gender.value)
            formData.append("about_me", data.about)
            if (data.profilePicture) {
                formData.append("profile_pic", data.profilePicture)
            }
            if (data.attributes) {
                data.attributes.forEach((item) => {
                    formData.append("personal_attributes[]", item.id)
                })
            }
            if (data.workIn) {
                data.workIn.forEach((item) => {
                    formData.append("industry[]", item.id)
                })
            }
            if (data?.specializeIn) {
                data.specializeIn.forEach((item) => {
                    formData.append("speciality[]", item.id)
                })
            }
            formData.append("postal_code", data.postalCode)
            formData.append("country_id", countryValue?.id ?? '')
            formData.append("region_id", regionValue?.id ?? '')
            formData.append("state_id", stateValue?.id ?? '')
            formData.append("city_id", cityValue?.id ?? '')
            if (data.companyValues) {
                data.companyValues.forEach((item) => {
                    formData.append("values[]", item.id)
                })
            }
            if (data.specialDiet) {
                data.specialDiet.forEach((item) => {
                    formData.append("special_diet[]", item.id)
                })
            }
            if (data.foodCategory) {
                data.foodCategory.forEach((item) => {
                    formData.append("category[]", item.id)
                })
            }
            formData.append("here_about", data?.hearAbout?.value ?? '')
            formData.append("is_other_hear_about", data?.otherPlatform)
            formData.append("re_captcha_token", token)
            formData.append("timezone", timeZone)
            formData.append("location", location)
            dispatch(signUpRDFinal(formData))
        } else {
            sweetAlert.fire({
                title: 'Captcha Error',
                icon: 'error',
                html: '<p>Execute recaptcha not yet available.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
    }

    return (
        <div className='auth-wrapper auth-v2' id='rd-signup-form-final'>
            <Row className='auth-inner m-0'>
                <Col md={12} className="d-flex align-items-center justify-content-between mt-1">
                    <Link to='/'><img src={Logo} alt='logo' width="150"/></Link>
                    <h2 className='font-medium-4 font-weight-bold'><Users size='20'/> Create RD Account</h2>
                    <Link to='/login'><h2 className='font-medium-1 text-primary text-underline'>Already have an account?</h2></Link>
                </Col>
                <Col className='d-flex align-items-center auth-bg pt-2 mt-1' lg='12' sm='12'>
                    <div className="w-75 mx-auto">
                        <Card>
                            <CardBody>
                                <Formik
                                    initialValues={{
                                        email: '',
                                        password: '',
                                        confirmPassword: '',
                                        firstLastName: '',
                                        registeredDietitian: '',
                                        otherRD: '',
                                        licenceType: '',
                                        cdrNo: '',
                                        proofOfCertification: '',
                                        gender: '',
                                        about: '',
                                        profilePicture: '',
                                        attributes: '',
                                        workIn: '',
                                        specializeIn: '',
                                        postalCode: '',
                                        companyValues: '',
                                        specialDiet: '',
                                        foodCategory: '',
                                        hearAbout: '',
                                        otherPlatform: '',
                                        termsAndConditions: ''
                                    }}
                                    validationSchema={yup.object().shape({
                                        email: yup.string().email("Email format is invalid.").required("This field is required"),
                                        password: yup.string().min(8).max(15).matches(passwordRegex, "Should be 8 characters, Should contain Number and Special Character").required("This field is required"),
                                        confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match.").required("This field is required"),
                                        firstLastName: yup.string().trim().required("This field is required").matches(onlyCharRegex, "Only alphabets are allowed."),
                                        registeredDietitian: yup.object().required("This field is required"),
                                        otherRD: yup.string().when('registeredDietitian', {
                                            is: (e) => e && e.value === 'Other',
                                            then: yup.string().trim().required('This field is required')
                                        }),
                                        licenceType: yup.object().required("This field is required"),
                                        cdrNo: yup.string().when('licenceType', {
                                            is: (e) => e?.value === 'CDR',
                                            then: yup.string().trim().required('CDR Number is required.'),
                                            otherwise: yup.string()
                                        }),
                                        proofOfCertification: yup.mixed().when('licenceType', (licenceType, schema, certificate) => {
                                            if (licenceType?.value === 'DOC' || certificate.value) {
                                                return schema.required("Certificate is required.").test(
                                                    "fileFormat",
                                                    "Certificate is empty or invalid. Only allowed PNG, JPG, JPEG, PDF",
                                                    value => (value && CERTIFICATE_FORMATS.includes(value.type))
                                                ).test(
                                                    "fileSize",
                                                    "Certificate size too large. Max 5MB.",
                                                    value => (value && value.size <= CERTIFICATE_MAX_SIZE)
                                                )
                                            }
                                        }),
                                        gender: yup.object().required("This field is required"),
                                        profilePicture: yup.mixed().test(
                                            "fileFormat",
                                            "Picture is empty or invalid.",
                                            value => (value && PROFILE_PIC_FORMATS.includes(value.type))
                                        ).test(
                                            "fileSize",
                                            "Picture size too large. Max 2MB.",
                                            value => (value && value.size <= PROFILE_PIC_MAX_SIZE)
                                        ),
                                        workIn: yup.array().max(3, 'Selection should be up to 3.').required("This field is required"),
                                        specializeIn: yup.array().max(3, 'Selection should be up to 3.').required("This field is required"),
                                        hearAbout: yup.object(),
                                        otherPlatform: yup.string().when('hearAbout', {
                                            is: (e) => e && e.value === 'Other',
                                            then: yup.string().trim().required('This field is required.'),
                                            otherwise: yup.string()
                                        }),
                                        termsAndConditions: yup.string().required("Terms & Conditions should be accepted.")
                                    })}
                                    onSubmit={(values) => {
                                        registerRD(values)
                                    }}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                          setFieldValue
                                      }) => (
                                        <Form onSubmit={handleSubmit} autoComplete="off">
                                            <Row>
                                                <FormGroup tag={Col} md='12'>
                                                    <h4 className="font-italic text-primary">
                                                        <FileText size={18}/> Create Account
                                                    </h4>
                                                    <hr/>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='email'
                                                           className={classnames({'is-invalid': touched.email && errors.email, 'form-label': true})}>
                                                        Email <span className="is-invalid">*</span>
                                                    </Label>
                                                    <Input type='email'
                                                           onBlur={handleBlur}
                                                           onChange={handleChange}
                                                           value={values.email}
                                                           name='email'
                                                           id='email'
                                                           placeholder='Email'
                                                           className={classnames({'is-invalid': touched.email && errors.email})}
                                                           autoComplete="off"
                                                           disabled={rdSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.email && errors.email}</div>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='password'
                                                           className={classnames({'is-invalid': touched.password && errors.password, 'form-label': true})}>
                                                        Password <span className="is-invalid">*</span>
                                                    </Label>
                                                    <InputPasswordToggle onBlur={handleBlur} onChange={handleChange}
                                                                         value={values.password}
                                                                         name='password'
                                                                         id='password'
                                                                         placeholder='Password'
                                                                         className={classnames({'is-invalid': touched.password && errors.password})}
                                                                         disabled={rdSignupFinalLoading}
                                                    />
                                                    <div className="validation-err text-capitalize">{touched.password && errors.password}</div>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='confirmPassword'
                                                           className={classnames({'is-invalid': touched.confirmPassword && errors.confirmPassword, 'form-label': true})}>
                                                        Confirm Password <span className="is-invalid">*</span>
                                                    </Label>
                                                    <InputPasswordToggle onBlur={handleBlur}
                                                                         onChange={handleChange}
                                                                         value={values.confirmPassword}
                                                                         name='confirmPassword'
                                                                         id='confirmPassword'
                                                                         placeholder='Confirm Password'
                                                                         className={classnames({'is-invalid': touched.confirmPassword && errors.confirmPassword})}
                                                                         disabled={rdSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.confirmPassword && errors.confirmPassword}</div>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='12'>
                                                    <h4 className="font-italic text-primary pt-2">
                                                        <Layout size={18}/> Licensure Verification
                                                    </h4>
                                                    <hr/>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='firstLastName'
                                                           className={classnames({'is-invalid': touched.firstLastName && errors.firstLastName, 'form-label': true})}>
                                                        First and Last Name <span className="is-invalid">*</span>
                                                    </Label>
                                                    <Input name='firstLastName'
                                                           id='firstLastName'
                                                           onBlur={handleBlur}
                                                           onChange={handleChange}
                                                           value={values.firstLastName}
                                                           placeholder='First and Last Name'
                                                           className={classnames({'is-invalid': touched.firstLastName && errors.firstLastName})}
                                                           disabled={rdSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.firstLastName && errors.firstLastName}</div>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='registeredDietitian'
                                                           className={classnames({
                                                               'is-invalid': touched.registeredDietitian && errors.registeredDietitian,
                                                               'form-label': true
                                                           })}>
                                                        Are you a Registered Dietitian? <span className="is-invalid">*</span>
                                                    </Label>
                                                    <Select options={rdOption}
                                                            className='react-select'
                                                            classNamePrefix='select'
                                                            placeholder="Please Select..."
                                                            value={values.registeredDietitian}
                                                            onChange={(e) => setFieldValue('registeredDietitian', e)}
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: touched.registeredDietitian && errors.registeredDietitian ? 'red' : baseStyles.borderColor
                                                                })
                                                            }}
                                                            isDisabled={rdSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.registeredDietitian && errors.registeredDietitian}</div>
                                                </FormGroup>
                                                {values.registeredDietitian.value === 'Other' && (
                                                    <FormGroup tag={Col} md='4'>
                                                        <Label htmlFor='otherRD'
                                                               className={classnames({'is-invalid': touched.otherRD && errors.otherRD, 'form-label': true})}>
                                                            Other <span className="is-invalid">*</span>
                                                        </Label>
                                                        <Input name='otherRD'
                                                               id='otherRD'
                                                               onBlur={handleBlur}
                                                               onChange={handleChange}
                                                               value={values.otherRD}
                                                               placeholder='Other'
                                                               className={classnames({'is-invalid': touched.otherRD && errors.otherRD})}
                                                               disabled={rdSignupFinalLoading}
                                                        />
                                                        <div className="validation-err">{touched.otherRD && errors.otherRD}</div>
                                                    </FormGroup>
                                                )}
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='registeredDietitian'
                                                           className={classnames({'is-invalid': touched.licenceType && errors.licenceType, 'form-label': true})}>
                                                        Licence Type <span className="is-invalid">*</span>
                                                    </Label>
                                                    <Select options={licenceOption}
                                                            className='react-select'
                                                            classNamePrefix='select'
                                                            placeholder="Please Select..."
                                                            value={values.licenceType}
                                                            onChange={(e) => setFieldValue('licenceType', e)}
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: touched.licenceType && errors.licenceType ? 'red' : baseStyles.borderColor
                                                                })
                                                            }}
                                                            isDisabled={rdSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.licenceType && errors.licenceType}</div>
                                                </FormGroup>
                                                {values.licenceType.value === 'CDR' && (
                                                    <FormGroup tag={Col} md='4'>
                                                        <Label htmlFor='cdrNo'
                                                               className={classnames({'is-invalid': touched.cdrNo && errors.cdrNo, 'form-label': true})}>
                                                            CDR Number <span className="is-invalid">*</span>
                                                        </Label>
                                                        <Input name='cdrNo'
                                                               id='cdrNo'
                                                               onBlur={handleBlur}
                                                               onChange={handleChange}
                                                               value={values.cdrNo}
                                                               placeholder='CDR Number'
                                                               className={classnames({'is-invalid': touched.cdrNo && errors.cdrNo})}
                                                               disabled={rdSignupFinalLoading}
                                                        />
                                                        <div className="validation-err">{touched.cdrNo && errors.cdrNo}</div>
                                                    </FormGroup>
                                                )}
                                                {values.licenceType.value === 'DOC' && (
                                                    <FormGroup tag={Col} md='4'>
                                                        <Label htmlFor='proofOfCertification'
                                                               className={classnames({
                                                                   'is-invalid': touched.proofOfCertification && errors.proofOfCertification,
                                                                   'form-label': true
                                                               })}
                                                        >
                                                            Proof of Certification <span className="is-invalid">*</span>
                                                        </Label>
                                                        <CustomInput accept=".png, .jpg, .jpeg, .pdf"
                                                                     type="file"
                                                                     name="proofOfCertification"
                                                                     id='proofOfCertification'
                                                                     onBlur={handleBlur}
                                                                     onChange={(e) => setFieldValue('proofOfCertification', e.target.files[0])}
                                                                     className={classnames({
                                                                         'input-file-error': touched.proofOfCertification && errors.proofOfCertification,
                                                                         'form-control-file': true
                                                                     })}
                                                                     disabled={rdSignupFinalLoading}
                                                                     label='Choose file (PNG, JPG, JPEG, PDF - Max 5MB)'
                                                        />
                                                        <div className="validation-err">{touched.proofOfCertification && errors.proofOfCertification}</div>
                                                    </FormGroup>
                                                )}
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='12'>
                                                    <h4 className="font-italic text-primary pt-2">
                                                        <User size={18}/> Create Profile
                                                    </h4>
                                                    <hr/>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='12'>
                                                    <Label htmlFor='about'
                                                           className={classnames({
                                                               'is-invalid': touched.about && errors.about,
                                                               'form-label': true
                                                           })}
                                                    >
                                                        About Me
                                                    </Label>
                                                    <Input type='textarea'
                                                           onBlur={handleBlur}
                                                           onChange={handleChange}
                                                           value={values.about}
                                                           name='about'
                                                           id='about'
                                                           placeholder='About Me...'
                                                           rows="5"
                                                           className={classnames({'is-invalid': touched.about && errors.about})}
                                                           disabled={rdSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.about && errors.about}</div>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='12'>
                                                    <Label htmlFor='profilePicture'
                                                           className={classnames({
                                                               'is-invalid': touched.profilePicture && errors.profilePicture,
                                                               'form-label': true
                                                           })}
                                                    >
                                                        Upload Profile Picture <span className="is-invalid">*</span>
                                                    </Label>
                                                    <CustomInput accept=".png, .jpg, .jpeg"
                                                                 type="file"
                                                                 className="form-control-file"
                                                                 id="profilePicture"
                                                                 onBlur={handleBlur}
                                                                 onChange={(e) => setFieldValue('profilePicture', e.target.files[0])}
                                                                 className={classnames({
                                                                     'input-file-error': touched.profilePicture && errors.profilePicture,
                                                                     'form-control-file': true
                                                                 })}
                                                                 disabled={rdSignupFinalLoading}
                                                                 label="Choose file (PNG, JPG, JPEG - Max 2MB)"
                                                    />
                                                    <div className="d-flex justify-content-between">
                                                        <div className="validation-err">{touched.profilePicture && errors.profilePicture}</div>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='name'
                                                           className={classnames({
                                                               'is-invalid': touched.gender && errors.gender,
                                                               'form-label': true
                                                           })}
                                                    >
                                                        Gender <span className="is-invalid">*</span>
                                                    </Label>
                                                    <Select
                                                        options={genderOption}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        placeholder="Please Select..."
                                                        value={values.gender}
                                                        onChange={(e) => setFieldValue('gender', e)}
                                                        styles={{
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                borderColor: touched.gender && errors.gender ? 'red' : baseStyles.borderColor
                                                            })
                                                        }}
                                                        isDisabled={rdSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.gender && errors.gender}</div>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='attributes'>
                                                        Attributes
                                                    </Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            placeholder="Please Select..."
                                                            options={brandGoalsSelectValues?.personal_attributes}
                                                            isMulti={true}
                                                            isClearable={false}
                                                            value={values.attributes}
                                                            onChange={(e) => {
                                                                setFieldValue('attributes', e)
                                                            }}
                                                            isDisabled={rdSignupFinalLoading}
                                                    />
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='workIn'
                                                           className={classnames({
                                                               'is-invalid': touched.workIn && errors.workIn,
                                                               'form-label': true,
                                                               'd-flex justify-content-between': true
                                                           })}
                                                    >
                                                        <span>I work in <span className="is-invalid">*</span></span>
                                                        <span className="font-weight-bold text-dark">(Select up to 3)</span>
                                                    </Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            placeholder="Please Select..."
                                                            options={brandGoalsSelectValues?.industry}
                                                            value={values.workIn}
                                                            onChange={(e) => {
                                                                setFieldValue('workIn', e)
                                                            }}
                                                            isMulti={true}
                                                            isClearable={false}
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: touched.workIn && errors.workIn ? 'red' : baseStyles.borderColor
                                                                })
                                                            }}
                                                            isDisabled={rdSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.workIn && errors.workIn}</div>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='specializeIn'
                                                           className={classnames({
                                                               'is-invalid': touched.specializeIn && errors.specializeIn,
                                                               'form-label': true,
                                                               'd-flex justify-content-between': true
                                                           })}
                                                    >
                                                        <span>I specialize in <span className="is-invalid">*</span></span>
                                                        <span className="font-weight-bold text-dark">(Select up to 3)</span>
                                                    </Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            placeholder="Please Select..."
                                                            options={brandGoalsSelectValues?.speciality}
                                                            isMulti={true}
                                                            isClearable={false}
                                                            value={values.specializeIn}
                                                            onChange={(e) => setFieldValue('specializeIn', e)}
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: touched.specializeIn && errors.specializeIn ? 'red' : baseStyles.borderColor
                                                                })
                                                            }}
                                                            isDisabled={rdSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.specializeIn && errors.specializeIn}</div>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='postalCode' className="d-flex justify-content-between">
                                                        <span>Postal Code</span>
                                                        {locationFromZipCodeLoading && (
                                                            <span className="text-success font-weight-bold">
                                                                <Spinner style={{width: '14px', height: '14px'}}/> Finding Location...
                                                            </span>
                                                        )}
                                                    </Label>
                                                    <Input type='text'
                                                           value={values.postalCode}
                                                           name='postalCode'
                                                           id='postalCode'
                                                           placeholder='Postal Code'
                                                           onChange={handleChange}
                                                           onBlur={(e) => {
                                                               handleBlur(e)
                                                               getLocationFromPostalCode(e)
                                                           }}
                                                           disabled={locationFromZipCodeLoading || rdSignupFinalLoading}
                                                    />
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='country'>
                                                        Country
                                                    </Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            placeholder="Please Select..."
                                                            options={country}
                                                            value={countryValue}
                                                            onChange={(e) => {
                                                                setCountryValue(e)
                                                                setRegionValue(null)
                                                                setStateValue(null)
                                                                setCityValue(null)
                                                                getRegionsById(e)
                                                            }}
                                                            isDisabled={locationFromZipCodeLoading || rdSignupFinalLoading}
                                                    />
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='regions'>
                                                        Regions
                                                    </Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            placeholder="Please Select..."
                                                            options={region}
                                                            value={regionValue}
                                                            onChange={(e) => {
                                                                setRegionValue(e)
                                                                setStateValue(null)
                                                                setCityValue(null)
                                                                getStatesById(e)
                                                            }}
                                                            isDisabled={locationFromZipCodeLoading || rdSignupFinalLoading}
                                                    />
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='state'>
                                                        State
                                                    </Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            placeholder="Please Select..."
                                                            options={state}
                                                            value={stateValue}
                                                            onChange={(e) => {
                                                                setStateValue(e)
                                                                setCityValue(null)
                                                                getCitiesById(e)
                                                            }}
                                                            isDisabled={locationFromZipCodeLoading || rdSignupFinalLoading}
                                                    />
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='city'>
                                                        City
                                                    </Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            placeholder="Please Select..."
                                                            options={city}
                                                            value={cityValue}
                                                            onChange={(e) => {
                                                                setCityValue(e)
                                                            }}
                                                            isDisabled={locationFromZipCodeLoading || rdSignupFinalLoading}
                                                    />
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='12'>
                                                    <h4 className="font-italic text-primary pt-1">
                                                        <MapPin size={18}/> Goals
                                                    </h4>
                                                    <hr/>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='12'>
                                                    <p className="font-weight-bold">Are there certain types of brands that you are particularly interested in connecting
                                                        with?</p>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='companyValues'>
                                                        Company Values
                                                    </Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            placeholder="Please Select..."
                                                            options={brandCompanySelectValues?.values}
                                                            isMulti={true}
                                                            isClearable={false}
                                                            value={values.companyValues}
                                                            onChange={(e) => {
                                                                setFieldValue('companyValues', e)
                                                            }}
                                                            isDisabled={rdSignupFinalLoading}
                                                    />
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='specialDiet'>
                                                        Special Diet
                                                    </Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            placeholder="Please Select..."
                                                            options={brandCompanySelectValues?.special_diet}
                                                            isMulti={true}
                                                            isClearable={false}
                                                            value={values.specialDiet}
                                                            onChange={(e) => {
                                                                setFieldValue('specialDiet', e)
                                                            }}
                                                            isDisabled={rdSignupFinalLoading}
                                                    />
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='foodCategory'>
                                                        Food Category
                                                    </Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            placeholder="Please Select..."
                                                            options={brandCompanySelectValues?.category}
                                                            isMulti={true}
                                                            isClearable={false}
                                                            value={values.foodCategory}
                                                            onChange={(e) => {
                                                                setFieldValue('foodCategory', e)
                                                            }}
                                                            isDisabled={rdSignupFinalLoading}
                                                    />
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='hearAbout'>
                                                        How did you hear about The RD Link?
                                                    </Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            placeholder="Please Select..."
                                                            value={values.hearAbout}
                                                            onChange={(e) => {
                                                                setFieldValue('hearAbout', e)
                                                                setFieldValue('otherPlatform', '')
                                                            }}
                                                            options={hearAboutOption}
                                                            isDisabled={rdSignupFinalLoading}
                                                    />
                                                </FormGroup>
                                                {values.hearAbout.value === 'Other' && (
                                                    <FormGroup tag={Col} md='4'>
                                                        <Label htmlFor='otherPlatform'
                                                               className={classnames({
                                                                   'is-invalid': touched.otherPlatform && errors.otherPlatform,
                                                                   'form-label': true
                                                               })}
                                                        >
                                                            Where did you hear about us? <span className="is-invalid">*</span>
                                                        </Label>
                                                        <Input type='text'
                                                               onBlur={handleBlur}
                                                               onChange={handleChange}
                                                               value={values.otherPlatform}
                                                               name='otherPlatform'
                                                               id='otherPlatform'
                                                               placeholder='Your answer...'
                                                               className={classnames({'is-invalid': touched.otherPlatform && errors.otherPlatform})}
                                                               disabled={rdSignupFinalLoading}
                                                        />
                                                        <div className="validation-err">{touched.otherPlatform && errors.otherPlatform}</div>
                                                    </FormGroup>
                                                )}
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='12'>
                                                    <div className="d-flex bg-light p-1">
                                                        <CustomInput id="termsAndConditions"
                                                                     type='checkbox'
                                                                     name='termsAndConditions'
                                                                     label='I agree to the'
                                                                     className={classnames({'checkbox-error': touched.termsAndConditions && errors.termsAndConditions})}
                                                                     onChange={(e) => {
                                                                         setFieldValue('termsAndConditions', e.target.checked ? 'Agreed' : '')
                                                                     }}
                                                                     disabled={rdSignupFinalLoading}
                                                        />
                                                        <a href='/terms-and-conditions' target="_blank" className="ml-1">Terms & Conditions</a>
                                                    </div>
                                                    <div className="validation-err ml-1">{touched.termsAndConditions && errors.termsAndConditions}</div>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='12' className="align-items-center d-flex justify-content-end">
                                                    <Button.Ripple type='submit' color='primary' className='btn btn-success' disabled={rdSignupFinalLoading}>
                                                        {rdSignupFinalLoading ? (
                                                            <span>
                                                                <Spinner style={{width: '16px', height: '16px'}}/> Creating Profile...
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                Create RD Account <ArrowRight size="18"/>
                                                            </span>
                                                        )}
                                                    </Button.Ripple>
                                                </FormGroup>
                                            </Row>
                                        </Form>)}
                                </Formik>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default RDSignup
