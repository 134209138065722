import { OUTER_PAGES, GENERAL } from '../../actionTypes'

const initialState = {
  faqsPageInfo: null,
  termsAndConditionPageInfo: null,
  privacyPolicyPageInfo: null,
  aboutPageInfo: null,
  purchasePlans: null,
  blogs: null,
  blogDetails: null,
  contactFormRes: null,
  addBrandPaymentCardRes: null,
  error: null
}

const outerPagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case OUTER_PAGES.FAQS:
      return {
        ...state,
        faqsPageInfo: action.faqsPageInfo
      }
    case OUTER_PAGES.CONTACT_FORM:
      return {
        ...state,
        contactFormRes: action.contactFormRes
      }
    case OUTER_PAGES.TERMS_AND_CONDITION:
      return {
        ...state,
        termsAndConditionPageInfo: action.termsAndConditionPageInfo
      }
    case OUTER_PAGES.PRIVACY_POLICY:
      return {
        ...state,
        privacyPolicyPageInfo: action.privacyPolicyPageInfo
      }
    case OUTER_PAGES.ABOUT_US:
      return {
        ...state,
        aboutPageInfo: action.aboutPageInfo
      }
    case OUTER_PAGES.PURCHASE_PLANS:
      return {
        ...state,
        purchasePlans: action.purchasePlans
      }
    case OUTER_PAGES.BLOGS_LIST:
      return {
        ...state,
        blogs: action.blogs
      }
    case OUTER_PAGES.BLOG_DETAILS:
      return {
        ...state,
        blogDetails: action.blogDetails
      }
    case GENERAL.OUTER_PAGES_ERROR:
      return {
        ...state,
        error: action.error
      }
    case OUTER_PAGES.ADD_BRAND_PAYMENT_CARD:
      return {
        ...state,
        addBrandPaymentCardRes: action.addBrandPaymentCardRes
      }
    default:
      return state
  }
}

export default outerPagesReducer
