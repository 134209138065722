import { lazy } from 'react'
import PurchasePackages from '../../views/outerPages/PurchasePackages'
import SubscriptionPlans from '../../views/outerPages/SubscriptionPlans'
import SubscriptionPlanSuccess from '../../views/outerPages/SubscriptionPlanSuccess'

const BrandRoutes = [
    {
        path: '/subscription-plans',
        component: SubscriptionPlans,
        layout: 'BlankLayout',
        meta: {
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/subscription-plan-success',
        component: SubscriptionPlanSuccess,
        layout: 'BlankLayout',
        meta: {
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/purchase-plan',
        component: PurchasePackages,
        layout: 'BlankLayout',
        meta: {
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/favorite-folders',
        component: lazy(() => import('../../views/pages/wishlistNutritionBrands')),
        layout: 'VerticalLayoutBrands',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/favorite-folders/:id',
        component: lazy(() => import('../../views/pages/wishlistNutritionBrands/details')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/favorite-folders/:id/list/:profileId',
        component: lazy(() => import('../../views/pages/RDProfileAtBrands')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/subscription-plan',
        component: lazy(() => import('../../views/pages/subscriptionPlanBrands')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/manage-ach',
        component: lazy(() => import('../../views/pages/manageAchBrands')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/manage-card',
        component: lazy(() => import('../../views/pages/manageCards')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/manage-users',
        component: lazy(() => import('../../views/pages/manageUsers')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/profile',
        component: lazy(() => import('../../views/pages/brandProfile')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/edit-profile',
        component: lazy(() => import('../../views/pages/brandProfile/editBrandProfile')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/boost-profile',
        component: lazy(() => import('../../views/forms/wizardBoostProfile/WizardModern')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/nutrition-detail/:id',
        component: lazy(() => import('../../views/pages/oppoDetailBrands')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/rd-profile-view/:id',
        component: lazy(() => import('../../views/pages/oppoDetailBrands/opportunityRdDetail')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/change-password',
        component: lazy(() => import('../../views/pages/userResetForm/BrandChangePasswordForm')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/pricing-plan',
        component: lazy(() => import('../../views/pages/pricingPlan')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/payment-history',
        component: lazy(() => import('../../views/pages/paymentsHistoryBrands')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/invoice/view/:id',
        component: lazy(() => import('../../views/pages/paymentsHistoryBrands/viewPaymentInvoice')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/view/rd-invoice/:id',
        component: lazy(() => import('../../views/pages/paymentsHistoryBrands/viewRdPaymentInvoice')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/notifications',
        component: lazy(() => import('../../views/pages/brandNotifications')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/brands/apps/chat/chatBrands',
        layout: 'VerticalLayoutBrands',
        appLayout: true,
        exact: true,
        className: 'chat-application',
        component: lazy(() => import('../../views/apps/chatBrands')),
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'brand'
        }
    },
    {
        path: '/brands/apps/chat/chatBrands/:id',
        layout: 'VerticalLayoutBrands',
        appLayout: true,
        className: 'chat-application',
        component: lazy(() => import('../../views/apps/chatBrands')),
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/nutritions',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/nutritionExpertsBrands/shop')),
        exact: true,
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/nutritions/:id',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/pages/RDProfileAtBrands')),
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/my-rds',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/myRDsAtBrands/shop')),
        exact: true,
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/my-rds/:id',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/pages/RDProfileAtBrands')),
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/nutrition/favorites',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/nutritionExpertsBrands/wishlist')),
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/nutrition/detail/:product',
        exact: true,
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/nutritionExpertsBrands/detail')),
        meta: {
            navLink: '/apps/ecommerce/product-detail',
            action: 'manage',
            resource: 'brand',
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/nutrition/checkout',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/nutritionExpertsBrands/checkout')),
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/manage-partnerships',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/opportunitiesBrands/managePartnerships/index')),
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/view-invoice/:id',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/opportunitiesBrands/managePartnerships/viewInvoice')),
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/my-campaigns',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/opportunitiesBrands/compaigns/index')),
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/campaign-detail/:id',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/opportunitiesBrands/compaigns/manageCampaign')),
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/opportunities',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/opportunitiesBrands/shop')),
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/opportunities/favorites',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/opportunitiesBrands/wishlist')),
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/opportunities/detail/:product',
        exact: true,
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/opportunitiesBrands/detail')),
        meta: {
            navLink: '/apps/ecommerce/product-detail',
            action: 'manage',
            resource: 'brand',
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/opportunities/checkout',
        className: 'ecommerce-application',
        component: lazy(() => import('../../views/apps/opportunitiesBrands/checkout')),
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/notification-setting',
        component: lazy(() => import('../../views/pages/notificationSetting')),
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    }
]

export default BrandRoutes