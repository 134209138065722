// Main
const API_URL = process.env.REACT_APP_BASEURL

// CANLCELLED_REPORT 
const CANLCELLED_REPORT = {
    LISTING: '[ADMIN] LISTING',
    SEARCH: '[ADMIN] SEARCH',
    PLANS_DATA: '[ADMIN] PLANS_DATA',
    ERROR: '[ADMIN] ERROR'
}

export { API_URL, CANLCELLED_REPORT }