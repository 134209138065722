const API_URL = process.env.REACT_APP_BASEURL
const AUTH = {
    LOGIN: '[AUTH] LOGIN',
    USER_LOGOUT: '[AUTH] USER_LOGOUT',
    RD_SIGNUP: '[AUTH] RD_SIGNUP',
    BRAND_SIGNUP: '[AUTH] BRAND_SIGNUP',
    RD_PRIMARILY_GOALS: 'RD_PRIMARILY_GOALS',
    COUNTRIES: '[BRAND] COUNTRIES',
    REGIONS_BY_COUNTRY_ID: '[AUTH] REGIONS_BY_COUNTRY_ID',
    STATES_BY_REGIONS_ID: '[AUTH] STATES_BY_REGIONS_ID',
    CITIES_BY_STATES_ID: '[AUTH] CITIES_BY_STATES_ID',
    BRANDS_CARD_VERIFICATION: '[AUTH] BRANDS_CARD_VERIFICATION',
    BRANDS_COUPON_CODE_VERIFICATION: '[AUTH] BRANDS_COUPON_CODE_VERIFICATION',
    PURCHASE_PLANS: '[AUTH] PURCHASE_PLANS',
    ACTIVE_PLAN: '[AUTH] ACTIVE_PLAN',
    PLAN_STATUS: '[AUTH] PLAN_STATUS',
    HEAR_ABOUT_OPTIONS: '[AUTH] HEAR_ABOUT_OPTIONS'
}

const RD = {
    OPPORTUNITIES_LIST: '[RD] OPPORTUNITIES_LIST',
    ADD_OPPORTUNITIY: '[RD] ADD_OPPORTUNITIY',
    FAVORITE_FOLDERS: '[RD] FAVORITE_FOLDERS',
    CREATE_FAVORITE_FOLDER: '[RD] CREATE_FAVORITE_FOLDER',
    UPDATE_FAVORITE_FOLDER: '[RD] UPDATE_FAVORITE_FOLDER',
    DELETE_FAVORITE_FOLDER: '[RD] DELETE_FAVORITE_FOLDER',
    CHANGE_PASSWORD: '[RD] CHANGE_PASSWORD',
    FAVORITE_BRANDS_PROFILES: '[RD] FAVORITE_BRANDS_PROFILES',
    BRANDS_PRODUCT_ATTRIBUTES: '[RD] BRANDS_PRODUCT_ATTRIBUTES',
    RD_NOTIFICATIONS: '[RD] RD_DASHBOARD_NOTIFICATIONS',
    ALL_BRANDS: '[RD] ALL_BRANDS',
    MY_BRANDS: '[RD] MY_BRANDS',
    COUNTRIES: '[RD] COUNTRIES',
    REGIONS_BY_COUNTRY_ID: '[RD] REGIONS_BY_COUNTRY_ID',
    STATES_BY_REGIONS_ID: '[RD] STATES_BY_REGIONS_ID',
    CITIES_BY_STATES_ID: '[RD] CITIES_BY_STATES_ID',
    ADD_TO_FAVORITE: '[RD] ADD_TO_FAVORITE',
    BRAND_PROFILE: '[RD] BRAND_PROFILE',
    VIEW_PROFILE: '[RD] VIEW_PROFILE',
    UPDATE_PROFILE: '[RD] UPDATE_PROFILE',
    REMOVE_MEDIA_ON_PROFILE: '[RD] REMOVE_MEDIA_ON_PROFILE',
    STATS_VALUES: '[RD] BRANDS_DASHBOARD_STATS_VALUES',
    RD_UNREAD_MESSAGES: '[RD] RD_UNREAD_MESSAGES',
    RD_UNREAD_NOTIFICATIONS: '[RD] RD_UNREAD_NOTIFICATIONS',
    UPCOMMING_OPPORTUNITIES_LIST_AT_PROFILE: '[RD] [AT PROFILE] UPCOMMING_OPPORTUNITIES_LIST',
    PAST_OPPORTUNITIES_LIST_AT_PROFILE: '[RD] [AT PROFILE] PAST_OPPORTUNITIES_LIST',
    BRAND_CAMPAIGNS_AT_PROFILE: '[RD] [AT PROFILE] BRAND_CAMPAIGNS',
    BRANDS_BY_PAST_EVENT_ID: '[RD] [AT PROFILE] BRANDS_BY_PAST_EVENT_ID',
    MY_PARTNERSHIPS: '[RD] RD_DASHBOARD_MY_PARTNERSHIPS',
    RECOMMENDED_BRANDS_AT_RD: '[RD] RECOMMENDED_BRANDS_AT_RD'
}

const BRAND = {
    OPPORTUNITIES_LIST: '[BRAND] OPPORTUNITIES_LIST',
    ADD_OPPORTUNITIY: '[BRAND] ADD_OPPORTUNITIY',
    BRAND_PROFILE: '[BRAND] BRAND_PROFILE',
    CURRENT_PLAN: '[BRAND] CURRENT_PLAN',
    SUBSCRIPTION_PLAN: '[BRAND] SUBSCRIPTION_PLAN',
    //CANCEL_PLAN: '[BRAND] CANCEL_PLAN',
    BRAND_CREATE_USER: '[BRAND] BRAND_CREATE_USER',
    BRAND_DASHBOARD_ERROR: '[BRAND] [ERROR] BRAND_DASHBOARD',
    CHILD_USERS_LIST: '[BRANDS] CHILD_USERS_LIST',
    DELETE_CHILD_USER: '[BRANDS] DELETE_CHILD_USER',
    UPDATE_CHILD_USER_STATUS: '[BRANDS] UPDATE_CHILD_USER_STATUS'
}

const BRANDS = {
    OPPORTUNITIES_LIST: '[BRANDS] OPPORTUNITIES_LIST',
    ADD_OPPORTUNITIY: '[BRANDS] ADD_OPPORTUNITIY',
    ADD_TO_FAVORITE: '[BRANDS] ADD_TO_FAVORITE',
    FAVORITE_FOLDERS: '[BRANDS] FAVORITE_FOLDERS',
    CREATE_FAVORITE_FOLDER: '[BRANDS] CREATE_FAVORITE_FOLDER',
    UPDATE_FAVORITE_FOLDER: '[BRANDS] UPDATE_FAVORITE_FOLDER',
    DELETE_FAVORITE_FOLDER: '[BRANDS] DELETE_FAVORITE_FOLDER',
    NUTRITION_EXPERTS: '[BRANDS] NUTRITION_EXPERTS',
    COUNTRIES: '[BRANDS] COUNTRIES',
    REGIONS_BY_COUNTRY_ID: '[BRANDS] REGIONS_BY_COUNTRY_ID',
    STATES_BY_REGIONS_ID: '[BRANDS] STATES_BY_REGIONS_ID',
    CITIES_BY_STATES_ID: '[BRANDS] CITIES_BY_STATES_ID',
    GOALS_ATTRIBUTES: '[BRAND] GOALS_ATTRIBUTES',
    FAVORITE_RD_PROFILES: '[BRANDS] FAVORITE_RD_PROFILES',
    FAVORITE_RD_OPPORTUNITIES: '[BRANDS] FAVORITE_RD_OPPORTUNITIES',
    AUDIENCE_TYPES: '[BRANDS] AUDIENCE_TYPES',
    EVENT_TYPES: '[BRANDS] EVENT_TYPES',
    MY_RDS: '[BRANDS] MY_RDS',
    BRAND_NOTIFICATIONS: '[BRAND] BRAND_DASHBOARD_NOTIFICATIONS',
    RD_PROFILE: '[BRANDS] RD_PROFILE',
    UPDATE_PROFILE: '[BRANDS] UPDATE_PROFILE',
    REMOVE_MEDIA_ON_PROFILE: '[BRANDS] REMOVE_MEDIA_ON_PROFILE',
    STATS_VALUES: '[BRANDS] BRANDS_DASHBOARD_STATS_VALUES',
    MY_PARTNERSHIPS: '[BRANDS] BRANDS_DASHBOARD_MY_PARTNERSHIPS',
    CURRENT_OPPORTUNITIES: '[BRANDS] BRANDS_DASHBOARD_CURRENT_OPPORTUNITIES',
    ADD_NOTE: '[BRANDS] BRANDS_DASHBOARD_ADD_NOTE',
    BRAND_UNREAD_MESSAGES: '[BRANDS] BRANDS_UNREAD_MESSAGES',
    BRAND_UNREAD_NOTIFICATIONS: '[BRANDS] BRANDS_UNREAD_NOTIFICATIONS',
    UPDATE_PASSWORD: '[BRANDS] BRANDS_DASHBOARD_UPDATE_PASSWORD',
    RECOMMENDED_RD: '[BRANDS] BRANDS_RECOMMENDED_RD',
    RECOMMENDED_RDS_AT_BRAND: '[BRANDS] RECOMMENDED_RDS_AT_BRAND',

    CANCEL_PLAN_REQUEST: "CANCEL_PLAN_REQUEST",
    CANCEL_PLAN_SUCCESS: "CANCEL_PLAN_SUCCESS",
    CANCEL_PLAN_ERROR: "CANCEL_PLAN_ERROR",
    CANCEL_PLAN_RESET: "CANCEL_PLAN_RESET"
}

const ADMIN_DASHBOARD = {
    STATS_VALUES: '[ADMIN] ADMIN_DASHBOARD_STATS_VALUES',
    EDIT_PROFILE_INFO: '[ADMIN] ADMIN_DASHBOARD_EDIT_PROFILE_INFO',
    UPDATE_PROFILE: '[ADMIN] ADMIN_DASHBOARD_UPDATE_PROFILE',
    NOTIFICATIONS: '[ADMIN] ADMIN_DASHBOARD_NOTIFICATIONS',
    UPDATE_PASSWORD: '[ADMIN] ADMIN_DASHBOARD_UPDATE_PASSWORD',
    ADMIN_UNREAD_NOTIFICATIONS: '[ADMIN] ADMIN_UNREAD_NOTIFICATIONS',
    RD_GRAPH_DATA: '[ADMIN] RD_GRAPH_DATA'
}

const NULL = {
    BRANDS_GET_FOLDERS: '[BRANDS] [NULL] GET_FOLDERS',
    BRANDS_CREATE_FOLDER: '[BRANDS] [NULL] CREATE_FOLDER',
    BRANDS_UPDATE_FOLDER: '[BRANDS] [NULL] UPDATE_FOLDER',
    BRANDS_DELETE_FOLDER: '[BRANDS] [NULL] DELETE_FOLDER',
    BRANDS_ADD_TO_FAVORITE: '[BRANDS] [NULL] ADD_TO_FAVORITE',
    BRANDS_NUTRITION_EXPERTS: '[BRANDS] [NULL] NUTRITION_EXPERTS',
    BRANDS_FAVORITE_RD_PROFILES: '[BRANDS] [NULL] FAVORITE_RD_PROFILES',
    BRANDS_FAVORITE_RD_OPPORTUNITIES: '[BRANDS] [NULL] FAVORITE_RD_OPPORTUNITIES',
    RD_GET_FOLDERS: '[RD] [NULL] GET_FOLDERS',
    RD_CREATE_FOLDER: '[RD] [NULL] CREATE_FOLDER',
    RD_UPDATE_FOLDER: '[RD] [NULL] UPDATE_FOLDER',
    RD_DELETE_FOLDER: '[RD] [NULL] DELETE_FOLDER',
    RD_FAVORITE_BRANDS_PROFILES: '[RD] [NULL] FAVORITE_BRANDS_PROFILES',
    BRANDS_COUNTRIES: '[BRANDS] [NULL] COUNTRIES',
    BRANDS_REGIONS_BY_COUNTRY_ID: '[BRANDS] [NULL] REGIONS_BY_COUNTRY_ID',
    BRANDS_STATES_BY_REGIONS_ID: '[BRANDS] [NULL] STATES_BY_REGIONS_ID',
    BRANDS_CITIES_BY_STATES_ID: '[BRANDS] [NULL] CITIES_BY_STATES_ID',
    RD_ALL_BRANDS: '[RD] [NULL] ALL_BRANDS',
    RD_MY_BRANDS: '[RD] [NULL] MY_BRANDS',
    RD_ADD_TO_FAVORITE: '[RD] [NULL] ADD_TO_FAVORITE',
    BRANDS_MY_RDS: '[BRANDS] [NULL] MY_RDS',
    RD_PROFILE: '[RD] [NULL] RD_PROFILE',
    RD_UPDATE_PROFILE: '[RD] [NULL] RD_UPDATE_PROFILE',
    BRANDS_PROFILE: '[BRANDS] [NULL] BRANDS_PROFILE',
    BRANDS_UPDATE_PROFILE: '[BRANDS] [NULL] BRANDS_UPDATE_PROFILE',
    BRANDS_REMOVE_MEDIA_ON_PROFILE: '[BRANDS] [NULL] BRANDS_REMOVE_MEDIA_ON_PROFILE',
    RD_REMOVE_MEDIA_ON_PROFILE: '[RD] [NULL] RD_REMOVE_MEDIA_ON_PROFILE',
    PLAN_STATUS: '[BRAND] [NULL] PLAN_STATUS',
    ACTIVE_PLAN: '[BRAND] [NULL] ACTIVE_PLAN',
    BRANDS_COUPON_CODE_VERIFICATION: '[BRAND] [NULL] BRANDS_COUPON_CODE_VERIFICATION',
    CANCEL_PLAN: '[BRAND] CANCEL_PLAN',
    SUBSCRIPTION_PLAN: '[BRAND] SUBSCRIPTION_PLAN',
    CURRENT_PLAN: '[BRAND] CURRENT_PLAN',
    VERIFY_EMAIL: '[BRAND] VERIFY_EMAIL',
    ADD_NOTE: '[BRANDS] BRANDS_DASHBOARD_ADD_NOTE',
    OUTER_PAGES_ADD_BRAND_PAYMENT_CARD: '[OUTER_PAGES] [NULL] ADD_BRAND_PAYMENT_CARD',
    CHILD_USERS_LIST: '[BRANDS] [NULL] CHILD_USERS_LIST',
    DELETE_CHILD_USER: '[BRANDS] [NULL] DELETE_CHILD_USER',
    UPDATE_CHILD_USER_STATUS: '[BRANDS] [NULL] UPDATE_CHILD_USER_STATUS',
    RD_CHANGE_PASSWORD: '[RD] [NULL] CHANGE_PASSWORD',
    CONTACT_FORM: '[OUTER_PAGES] [NULL] CONTACT_FORM'
}

const OUTER_PAGES = {
    FAQS: '[OUTER_PAGES] FAQS',
    PRIVACY_POLICY: '[OUTER_PAGES] PRIVACY_POLICY',
    TERMS_AND_CONDITION: '[OUTER_PAGES] TERMS_AND_CONDITION',
    ABOUT_US: '[OUTER_PAGES] ABOUT_US',
    PURCHASE_PLANS: '[OUTER_PAGES] PURCHASE_PLANS',
    BLOGS_LIST: '[OUTER_PAGES] BLOGS_LIST',
    BLOG_DETAILS: '[OUTER_PAGES] BLOG_DETAILS',
    CONTACT_FORM: '[OUTER_PAGES] CONTACT_FORM',
    ADD_BRAND_PAYMENT_CARD: '[OUTER_PAGES] ADD_BRAND_PAYMENT_CARD'
}

const GENERAL = {
    AUTH_ERROR: 'AUTH_ERROR',
    ADMIN_DASHBOARD_ERROR: '[ADMIN] [ERROR] ADMIN_DASHBOARD',
    BRANDS_DASHBOARD_ERROR: '[BRANDS] [ERROR] BRANDS_DASHBOARD',
    RD_DASHBOARD_ERROR: '[RD] [ERROR] RD_DASHBOARD',
    OUTER_PAGES_ERROR: '[OUTER_PAGES] [ERROR] OUTER_PAGES'
}

const RD_MY_PARTNERSHIP = {
    REQUEST: "RD_MY_PARTNERSHIP_REQUEST",
    SUCCESS: "RD_MY_PARTNERSHIP_SUCCESS",
    ERROR: "RD_MY_PARTNERSHIP_ERROR"
}

const BRAND_MY_PARTNERSHIP = {
    REQUEST: "BRAND_MY_PARTNERSHIP_REQUEST",
    SUCCESS: "BRAND_MY_PARTNERSHIP_SUCCESS",
    ERROR: "BRAND_MY_PARTNERSHIP_ERROR"
}

const UNSUBSCRIBE = {
    REQUEST: "UNSUBSCRIBE_REQUEST",
    SUCCESS: "UNSUBSCRIBE_SUCCESS",
    ERROR: "UNSUBSCRIBE_ERROR",
    RESET: "UNSUBSCRIBE_RESET"
}

const USER_BY_TOKEN = {
    REQUEST: "USER_BY_TOKEN_REQUEST",
    SUCCESS: "USER_BY_TOKEN_SUCCESS",
    ERROR: "USER_BY_TOKEN_ERROR"
}

const RD_STATS = {
    REQUEST: "RD_STATS_REQUEST",
    SUCCESS: "RD_STATS_SUCCESS",
    ERROR: "RD_STATS_ERROR"
}

const REC_BRANDS = {
    REQUEST: "REC_BRANDS_REQUEST",
    SUCCESS: "REC_BRANDS_SUCCESS",
    ERROR: "REC_BRANDS_ERROR"
}

const BRAND_STATS = {
    REQUEST: "BRAND_STATS_REQUEST",
    SUCCESS: "BRAND_STATS_SUCCESS",
    ERROR: "BRAND_STATS_ERROR"
}

const REC_RDS = {
    REQUEST: "REC_RDS_REQUEST",
    SUCCESS: "REC_RDS_SUCCESS",
    ERROR: "REC_RDS_ERROR"
}

const REC_OPPORTUNITIES = {
    REQUEST: "REC_OPPORTUNITIES_REQUEST",
    SUCCESS: "REC_OPPORTUNITIES_SUCCESS",
    ERROR: "REC_OPPORTUNITIES_ERROR"
}

const RD_SIGNUP_FINAL = {
    REQUEST: "RD_SIGNUP_FINAL_REQUEST",
    SUCCESS: "RD_SIGNUP_FINAL_SUCCESS",
    ERROR: "RD_SIGNUP_FINAL_ERROR",
    RESET: "RD_SIGNUP_FINAL_RESET"
}

const BRAND_SIGNUP_FINAL = {
    REQUEST: "BRAND_SIGNUP_FINAL_REQUEST",
    SUCCESS: "BRAND_SIGNUP_FINAL_SUCCESS",
    ERROR: "BRAND_SIGNUP_FINAL_ERROR",
    RESET: "BRAND_SIGNUP_FINAL_RESET"
}

export {
    API_URL,
    RD,
    BRANDS,
    AUTH,
    GENERAL,
    ADMIN_DASHBOARD,
    NULL,
    BRAND,
    OUTER_PAGES,
    RD_MY_PARTNERSHIP,
    BRAND_MY_PARTNERSHIP,
    UNSUBSCRIBE,
    USER_BY_TOKEN,
    RD_STATS,
    REC_BRANDS,
    BRAND_STATS,
    REC_RDS,
    REC_OPPORTUNITIES,
    RD_SIGNUP_FINAL,
    BRAND_SIGNUP_FINAL
}
