import * as Icon from 'react-feather'

export default [
    {
        id: 'admin-dashboard',
        title: 'Dashboard',
        icon: <Icon.Circle/>,
        navLink: '/dashboard/admin',
        action: 'manage',
        resource: 'admin',
        meta: {
            publicRoute: false

        }
    },
    {
        id: 'admin-manage-rd',
        title: "Manage RDs",
        icon: <Icon.Circle/>,
        navLink: '/admin/manage-rd',
        resource: 'admin',
        action: 'manage',
        meta: {
            publicRoute: false

        }
    },
    {
        id: 'admin-pending-rd',
        title: "Pending RDs",
        icon: <Icon.Circle/>,
        navLink: '/admin/manage-rd-pending',
        resource: 'admin',
        action: 'manage'
    },
    {
        id: 'admin-manage-brands',
        title: 'Manage Brands',
        icon: <Icon.Circle/>,
        navLink: '/admin/manage-brands',
        resource: 'admin',
        action: 'manage'
    },
    {
        id: 'admin-manage-events',
        title: 'Manage Events',
        icon: <Icon.Circle/>,
        navLink: '/admin/manage-events',
        resource: 'admin',
        action: 'manage'
    },
    {
        id: 'admin-subscribed-brands',
        title: 'Subscribed Brands',
        icon: <Icon.Circle/>,
        navLink: '/admin/subscribed-brands',
        resource: 'admin',
        action: 'manage'
    },
    {
        id: 'admin-manage-coupons',
        title: 'Manage Coupons',
        icon: <Icon.Circle/>,
        navLink: '/admin/manage-coupons',
        resource: 'admin',
        action: 'manage'
    },
    {
        id: 'admin-manage-reports',
        title: 'Manage Reports',
        icon: <Icon.Circle/>,
        children: [
            {
                id: 'subscriptionReports',
                title: 'Subscription Reports',
                icon: <Icon.Circle/>,
                navLink: '/admin/subscription-reports',
                resource: 'admin',
                action: 'manage'
            },
            {
                id: 'comissionReports',
                title: 'Commission Reports',
                icon: <Icon.Circle/>,
                navLink: '/admin/comission-reports',
                resource: 'admin',
                action: 'manage'
            },
            {
                id: 'cancelledSubscription',
                title: 'Cancelled Subscription',
                icon: <Icon.Circle/>,
                navLink: '/admin/cancelled-subscription-reports',
                resource: 'admin',
                action: 'manage'
            }
        ]
    },
    {
        id: 'admin-view-conversations',
        title: 'View Conversations',
        icon: <Icon.Circle/>,
        navLink: '/admin/apps/chat/chatAdmin',
        resource: 'admin',
        action: 'manage'
    },
    {
        id: 'admin-account-settings',
        title: "Account Settings",
        icon: <Icon.Circle/>,
        navLink: '/admin/account-settings',
        action: 'manage',
        resource: 'admin'
    },
    {
        id: 'admin-manage-csm',
        title: "Manage CMS",
        icon: <Icon.Circle/>,
        navLink: '/admin/cms',
        action: 'manage',
        resource: 'admin'
    },
    {
        id: 'admin-manage-blog',
        title: 'Manage Blog',
        icon: <Icon.Circle/>,
        navLink: '/dashboard/admin/blogs',
        action: 'manage',
        resource: 'admin'
    },
    {
        id: 'admin-faqs',
        title: "FAQs",
        icon: <Icon.Circle/>,
        navLink: '/pages/faqRd',
        action: 'manage',
        resource: 'admin'
    },
    {
        id: 'admin-resources',
        title: "Resources",
        icon: <Icon.Circle/>,
        navLink: '/admin/resources',
        action: 'manage',
        resource: 'admin'
    },
    {
        id: 'admin-users-permissions',
        title: "Permissions",
        icon: <Icon.Circle/>,
        navLink: '/admin/users-permissions',
        action: 'manage',
        resource: 'admin'
    }
]
