const ADD_RESOURCE = {
    REQUEST: 'ADD_RESOURCE_REQUEST',
    SUCCESS: 'ADD_RESOURCE_SUCCESS',
    ERROR: 'ADD_RESOURCE_ERROR',
    RESET: 'ADD_RESOURCE_RESET'
}

const LIST_RESOURCE = {
    REQUEST: 'LIST_RESOURCE_REQUEST',
    SUCCESS: 'LIST_RESOURCE_SUCCESS',
    ERROR: 'LIST_RESOURCE_ERROR'
}

const STATUS_RESOURCE = {
    REQUEST: 'STATUS_RESOURCE_REQUEST',
    SUCCESS: 'STATUS_RESOURCE_SUCCESS',
    ERROR: 'STATUS_RESOURCE_ERROR',
    RESET: 'STATUS_RESOURCE_RESET'
}

const DELETE_RESOURCE = {
    REQUEST: 'DELETE_RESOURCE_REQUEST',
    SUCCESS: 'DELETE_RESOURCE_SUCCESS',
    ERROR: 'DELETE_RESOURCE_ERROR',
    RESET: 'DELETE_RESOURCE_RESET'
}

export { ADD_RESOURCE, LIST_RESOURCE, STATUS_RESOURCE, DELETE_RESOURCE }