import { GENERAL, ADMIN_DASHBOARD } from '../../actionTypes'

const initialState = {
    stats: null,
    notifications: null,
    error: null,
    adminUnreadNotifications:null,
    rdGraphData:null
}

const adminDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_DASHBOARD.STATS_VALUES:
            return {
                ...state,
                stats: action.stats
            }
        case ADMIN_DASHBOARD.NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications
            }
        case ADMIN_DASHBOARD.ADMIN_UNREAD_NOTIFICATIONS:
            return {
                ...state,
                adminUnreadNotifications: action.adminUnreadNotifications
            }
        case ADMIN_DASHBOARD.RD_GRAPH_DATA:
            return {
                ...state,
                rdGraphData: action.rdGraphData
            }
        case GENERAL.ERROR:
            return {
                ...state,
                error: action.error
            }
        default:
            return state
    }
}

export default adminDashboardReducer
