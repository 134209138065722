// ** Initial State
const initialState = {
  accountSettings: []
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ACCOUNT_SETTINGS_DATA':
      return {
        ...state,
        accountSettings: action.accountSettings
      }
    default:
      return state
  }
}

export default DataTablesReducer
