import { Fragment } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import TrustedBrands from '../TrustedBrands'
import UsesandFeatures from '../UsesandFeatures'

const Features = () => {
  return (
    <Fragment>
      <Header />
      <UsesandFeatures />
      <TrustedBrands />
      <Footer />
    </Fragment>
  )
}

export default Features
