import { COUPON } from "../actionsTypes"

// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  couponsListing: null,
  couponsSearchListing: [],
  couponCreated: null,
  deleteCoupon: {},
  addCoupon: {},
  statusData: {},
  error: null
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case COUPON.COUPONS_LISTING_DATA:
      return {
        ...state,
        couponsListing: action.allData
      }
    case COUPON.NEW_COUPON_ADDED:
      return {
        ...state,
        couponCreated: action.couponCreated
      }
    case COUPON.COUPON_STATUS_CHANGE:
      return {
        ...state,
        statusData: action.statusData
      }
    case COUPON.COUPONS_SEARCH_LISTING_DATA:
      return {
        ...state,
        couponsSearchListing: action.allSearchData
      }
    case COUPON.DELETE_COUPON_DATA:
      return {
        ...state,
        deleteCoupon: action.deleteCoupon
      }
    case COUPON.ERROR:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export default DataTablesReducer
