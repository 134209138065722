import { API_URL, UNSUBSCRIBE, USER_BY_TOKEN } from '../../actionTypes'
import axios from 'axios'

export const unsubscribe = (dataObj) => async (dispatch) => {
    dispatch({type: UNSUBSCRIBE.REQUEST})
    const header = {
        Accept: "application/json"
    }
    try {
        const {data} = await axios.post(`${API_URL}unsubscribe`, dataObj, {headers: header})
        dispatch({type: UNSUBSCRIBE.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: UNSUBSCRIBE.ERROR, payload: error})
    }
}

export const unsubscribeReset = () => async (dispatch) => {
    dispatch({type: UNSUBSCRIBE.RESET})
}

export const userByToken = (dataObj) => async (dispatch) => {
    dispatch({type: USER_BY_TOKEN.REQUEST})
    const header = {
        Accept: "application/json"
    }
    try {
        const {data} = await axios.post(`${API_URL}get-user-by-token`, dataObj, {headers: header})
        dispatch({type: USER_BY_TOKEN.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: USER_BY_TOKEN.ERROR, payload: error})
    }
}
