const RD_NOTIFICATION_SETTING = {
    REQUEST: 'RD_NOTIFICATION_SETTING_REQUEST',
    SUCCESS: 'RD_NOTIFICATION_SETTING_SUCCESS',
    ERROR: 'RD_NOTIFICATION_SETTING_ERROR'
}

const RD_NOTIFICATION_SETTING_UPDATE = {
    REQUEST: 'RD_NOTIFICATION_SETTING_UPDATE_REQUEST',
    SUCCESS: 'RD_NOTIFICATION_SETTING_UPDATE_SUCCESS',
    ERROR: 'RD_NOTIFICATION_SETTING_UPDATE_ERROR',
    RESET: 'RD_NOTIFICATION_SETTING_UPDATE_RESET'
}

export {RD_NOTIFICATION_SETTING, RD_NOTIFICATION_SETTING_UPDATE}