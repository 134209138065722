import {useEffect, useState} from "react"
import {Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Spinner} from 'reactstrap'
import {useHistory, useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import '@styles/base/pages/page-misc.scss'
import {useFormik} from "formik"
import * as Yup from "yup"
import InputPasswordToggle from '@components/input-password-toggle'
import {
    adminUserSetPasswordAction,
    adminUserSetPasswordResetAction
} from "@src/redux/actions/AdminActions"
import * as ICON from 'react-feather'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const SetPassword = () => {
    const {token} = useParams()
    const history = useHistory()
    const sweetAlert = withReactContent(Swal)
    const dispatch = useDispatch()

    const {
        adminUserSetPasswordLoading,
        adminUserSetPasswordData,
        adminUserSetPasswordError
    } = useSelector((store) => store.adminReducer)

    const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d\s]).{8,}$/

    const form = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().min('8', 'Password must be at least 8 characters').matches(passwordRegx, 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character').required('Password is required'),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required')
        }),
        onSubmit: (values) => {
            dispatch(adminUserSetPasswordAction({
                token,
                password: values.confirmPassword
            }))
        }
    })

    useEffect(() => {
        if (adminUserSetPasswordData?.status) {
            sweetAlert.fire('Congratulations!', adminUserSetPasswordData?.msg, 'success')
            sweetAlert.fire({
                title: 'Congratulations!',
                icon: 'success',
                html: `<p>${adminUserSetPasswordData?.msg}</p>`,
                confirmButtonText: 'Login Now',
                showCancelButton: false,
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    history.push('/login')
                }
            })
            dispatch(adminUserSetPasswordResetAction())
        }

        if (adminUserSetPasswordError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (adminUserSetPasswordError?.response?.data?.msg) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminUserSetPasswordError?.response?.data?.msg}</li>`)
            }
            if (adminUserSetPasswordError?.response?.data?.password) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminUserSetPasswordError?.response?.data?.password[0]}</li>`)
            }
            if (adminUserSetPasswordError?.response?.data?.token) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminUserSetPasswordError?.response?.data?.token[0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(adminUserSetPasswordResetAction())
        }
    }, [
        adminUserSetPasswordData,
        adminUserSetPasswordError
    ])

    return (
        <div className='misc-wrapper'>
            <a className='brand-logo' href='/'>
                <h2 className='brand-text text-primary ml-1'>THE RD LINK</h2>
            </a>
            <Card className='w-25'>
                <CardBody>
                    <h1 className='mb-3'><ICON.Key/> Setup Your password</h1>
                    <Form onSubmit={form.handleSubmit}>
                        <Row>
                            <FormGroup tag={Col} md='12'>
                                <Label htmlFor="password">Password<span className="text-danger">*</span></Label>
                                <InputPasswordToggle onBlur={form.handleBlur}
                                                     onChange={form.handleChange}
                                                     value={form.values.password}
                                                     name='password'
                                                     id='password'
                                                     placeholder='Password'
                                                     disabled={adminUserSetPasswordLoading}
                                />
                                <div className="validation-err">{form.touched.password && form.errors.password}</div>
                            </FormGroup>
                            <FormGroup tag={Col} md='12'>
                                <Label htmlFor="confirmPassword">Confirm Password<span className="text-danger">*</span></Label>
                                <InputPasswordToggle onBlur={form.handleBlur}
                                                     onChange={form.handleChange}
                                                     value={form.values.confirmPassword}
                                                     name='confirmPassword'
                                                     id='confirmPassword'
                                                     placeholder='Confirm Password'
                                                     disabled={adminUserSetPasswordLoading}
                                />
                                <div className="validation-err">{form.touched.confirmPassword && form.errors.confirmPassword}</div>
                            </FormGroup>
                            <FormGroup tag={Col} md='12' className='text-right'>
                                <Button.Ripple type="submit" color="primary" disabled={adminUserSetPasswordLoading} className='mt-1'>
                                    {adminUserSetPasswordLoading ? (<Spinner style={{width: '20px', height: '20px'}}/>) : "Setup Account"}
                                </Button.Ripple>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}
export default SetPassword
