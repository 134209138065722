import {
    RD_CAMPAIGNS,
    RD_DASHBOARD_CAMPAIGNS,
    RD_DASHBOARD_CAMPAIGN_DETAIL
} from '../actionTypes/index'

const initialState = {
    allCampaignsListing: null,
    application: null,
    error: null,

    rdRecommendedCampaignsLoading: false,
    rdRecommendedCampaignsFullFill: null,
    rdRecommendedCampaignsError: null,

    rdRecommendedCampaignsDetailLoading: false,
    rdRecommendedCampaignsDetailFullFill: null,
    rdRecommendedCampaignsDetailError: null
}

const DataTablesReducer = (state = initialState, action) => {
    switch (action.type) {
        case RD_CAMPAIGNS.ALL_LISTING_DATA:
            return {...state, allCampaignsListing: action.allCampaignsListing}
        case RD_CAMPAIGNS.APPLICATION:
            return {...state, application: action.application}
        case RD_CAMPAIGNS.ERROR:
            return {...state, error: action.error}
        case "CAMPAIGN_MSG_RESET":
            return {...state, application: null}
        // --- RD Dashboard Campaigns ---
        case RD_DASHBOARD_CAMPAIGNS.REQUEST:
            return {
                ...state,
                rdRecommendedCampaignsLoading: true,
                rdRecommendedCampaignsFullFill: null,
                rdRecommendedCampaignsError: null
            }
        case RD_DASHBOARD_CAMPAIGNS.SUCCESS:
            return {
                ...state,
                rdRecommendedCampaignsLoading: false,
                rdRecommendedCampaignsFullFill: action.payload,
                rdRecommendedCampaignsError: null
            }
        case RD_DASHBOARD_CAMPAIGNS.ERROR:
            return {
                ...state,
                rdRecommendedCampaignsLoading: false,
                rdRecommendedCampaignsFullFill: null,
                rdRecommendedCampaignsError: action.payload
            }
        // --- RD Dashboard Campaign Details ---
        case RD_DASHBOARD_CAMPAIGN_DETAIL.REQUEST:
            return {
                ...state,
                rdRecommendedCampaignsDetailLoading: true,
                rdRecommendedCampaignsDetailFullFill: null,
                rdRecommendedCampaignsDetailError: null
            }
        case RD_DASHBOARD_CAMPAIGN_DETAIL.SUCCESS:
            return {
                ...state,
                rdRecommendedCampaignsDetailLoading: false,
                rdRecommendedCampaignsDetailFullFill: action.payload,
                rdRecommendedCampaignsDetailError: null
            }
        case RD_DASHBOARD_CAMPAIGN_DETAIL.ERROR:
            return {
                ...state,
                rdRecommendedCampaignsDetailLoading: false,
                rdRecommendedCampaignsDetailFullFill: null,
                rdRecommendedCampaignsDetailError: action.payload
            }
        default:
            return state
    }
}

export default DataTablesReducer
