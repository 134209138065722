import { useEffect, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import Avatar from '@components/avatar'

import { isUserLoggedIn } from '@utils'

import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/actions/auth'

import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'
import { User, Mail, CheckSquare, MessageSquare, Settings, CreditCard, HelpCircle, Power } from 'react-feather'

import BlankUser from "@src/assets/images/avatars/avatar-blank.png"

const UserDropdown = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [userData, setUserData] = useState(null)

  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`)))
    }
  }, [])

  const logoutUser = () => {
    // localStorage.removeItem(`${process.env.REACT_APP_USERTOKEN}`)
    // localStorage.removeItem(`${process.env.REACT_APP_USERDATA}`)
    dispatch(handleLogout())
    history.push('/login')
  }
 
  const userAvatar = (userData && userData.profile_image) || BlankUser

  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-sm-flex d-none'>
          <span className='user-name font-weight-bold'>{userData?.name}</span>
        </div>
        <Avatar className="avatar-img-fit-contain" img={userAvatar} imgHeight='40' imgWidth='40' status='online' />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem tag={Link} to='/dashboard/admin/edit-profile'>
          <User size={14} className='mr-75' />
          <span className='align-middle'>Edit Profile</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/dashboard/admin/change-password'>
          <CreditCard size={14} className='mr-75' />
          <span className='align-middle'>Change Password</span>
        </DropdownItem>
        <DropdownItem onClick={logoutUser}>
          <Power size={14} className='mr-75' />
          <span className='align-middle'>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    // <div className='user-nav d-sm-flex d-none flex-row align-items-center'>
    // <div className="col-auto">
    // <Avatar img={userAvatar} imgHeight='41' imgWidth='41' status='online' />
    //   </div>
    //   <div className="col">
    //   <Link to="/loginrd" className='user-name font-weight-medium text-white'>Jenna Gorham</Link>
    //   <ul>
    //     <li className="d-inline-block mr-1" style={{marginTop: '3px'}}>
    //       <NavLink to="#" className="text-white"><User size={18} /></NavLink>
    //       </li>
    //       <li className="d-inline-block mr-1">
    //       <NavLink to="#" className="text-white"><Mail size={18} /></NavLink>
    //       </li>
    //       <li className="d-inline-block">
    //       <NavLink to="#" className="text-white"><CheckSquare size={18} /></NavLink>
    //       </li>
    //   </ul>
    //   </div>

    //   <span className='user-status'>{(userData && userData.role) || 'Admin'}</span> 
    // </div>
  )
}

export default UserDropdown
