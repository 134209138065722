import React from 'react'
import style from './style.module.css'
import Header from '../Header'
import laptop from '../../../assets/images/laptop.png'
import mainLogos from '../../../assets/images/mainLogos.png'
import Maintenance from '../../../maintenance'

const Main = () => {
    return (
        <>
            <Maintenance/>
            <div className={style.mainbg}>
                <div className={style.mainWrap}>
                    <Header/>
                    <div className={style.innerMainWrap}>
                        <div className={style.TextSec}>
                            <div className={style.Heading}>
                                The nutrition expert directory <br/>
                                for health & wellness brands
                            </div>
                            <div className={style.Text}>
                                Put value behind your partnerships
                                <br/>
                                to build a brand people trust.
                            </div>
                            <a className={style.mainBtn} href="https://calendly.com/therdlink" rel="noopener noreferrer" target="_blank">Free Strategy Session</a>
                        </div>
                        <div className={style.ImgSec}>
                            <img src={laptop} alt='laptop'></img>
                        </div>
                    </div>
                </div>
                <div className={style.bottomSec}>
                    <div className={style.btmsec2}>
                        <div className={style.btmssec2txt}>Trusted by top CPG brands</div>
                        <img src={mainLogos} alt='logos'/>
                    </div>
                    <div className={style.btmText}>
                        "I would highly recommend Link to brands looking to connect with RDs
                        but don't know where to start."
                    </div>
                </div>
            </div>
        </>
    )
}
export default Main
