import React, {useState, useEffect, Fragment, useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import Cleave from 'cleave.js/react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Spinner,
    Card,
    CardBody,
    Form, Table, CustomInput
} from 'reactstrap'
import Header from '../Header'
import style from './style.module.css'
import {getPurchasePlans, handleLogout} from '@store/actions/auth'
import PowerByStripe from '@src/assets/images/stripeIcon.png'
import MoneyBackGurantee from '@src/assets/images/30-day-money.jpg'
import * as Icon from "react-feather"
import {useFormik} from "formik"
import * as yup from "yup"
import classnames from "classnames"
import {
    getCountries
} from "@src/views/apps/opportunitiesBrands/store/actions/index"

import {
    saveCreditCard,
    saveCreditCardReset,
    verifyCouponCode,
    verifyCouponCodeReset,
    activatePlan,
    activatePlanReset
} from "@store/actions/BrandActions"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {AbilityContext} from '@src/utility/context/Can'

const PurchasePackages = () => {

    const onlyCharRegex = /^[aA-zZ\s]+$/

    const ability = useContext(AbilityContext)

    const sweetAlert = withReactContent(Swal)

    const history = useHistory()

    const dispatch = useDispatch()

    const [isPageLoading, setIsPageLoading] = useState(false)
    const [isOpenAddCardModal, setIsOpenAddCardModal] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [checkoutModal, setCheckoutModal] = useState(false)
    const [saveCardData, setSaveCardData] = useState(null)

    const creditCardForm = useFormik({
        initialValues: {
            owner_name: "",
            card_number: "",
            expiry: "",
            cvv: "",
            captchaToken: ''
        },
        validationSchema: yup.object().shape({
            owner_name: yup.string().trim().required("This field is required.").matches(onlyCharRegex, "Only alphabets are allowed."),
            card_number: yup.number().required("This field is required").typeError('Only numbers are allowed.'),
            expiry: yup.string().trim().min(5, 'Card expiry is invalid.').required("This field is required"),
            cvv: yup.string().trim().required("This field is required"),
            captchaToken: yup.string().required("Please verify the captcha.").typeError("Please verify the captcha.")
        }),
        onSubmit: (values) => {
            dispatch(saveCreditCard({
                card_number: values.card_number,
                expiry_month: values?.expiry.split('/')[0],
                expiry_year: values?.expiry.split('/')[1],
                cvc: values.cvv,
                owner_name: values?.owner_name,
                re_captcha_token: values.captchaToken
            }))
        }
    })

    const checkoutForm = useFormik({
        initialValues: {
            isCoupon: "No",
            coupon: "",
            captchaToken: ''
        },
        validationSchema: yup.object().shape({
            isCoupon: yup.string(),
            coupon: yup.string().when('isCoupon', {
                is: 'Yes',
                then: yup.string().trim().required("This field is required.")
            }),
            captchaToken: yup.string().required("Please verify the captcha.").typeError("Please verify the captcha.")
        }),
        onSubmit: (values) => {
            if (values.isCoupon === 'Yes') {
                dispatch(verifyCouponCode({
                    coupon_code: values.coupon
                }))
            } else {
                dispatch(activatePlan({
                    plan_id: selectedPlan?.type_id,
                    coupon_code: values.coupon,
                    source: saveCardData?.id,
                    re_captcha_token: values.captchaToken
                }))
            }
        }
    })

    const {
        purchasePlans
    } = useSelector(state => state.auth)

    const {
        countries
    } = useSelector((store) => store.brandsOppoprunities)

    const {
        saveCreditCardLoading,
        saveCreditCardData,
        saveCreditCardError,

        verifyCouponCodeLoading,
        verifyCouponCodeData,
        verifyCouponCodeError,

        activatePlanLoading,
        activatePlanData,
        activatePlanError
    } = useSelector((store) => store.brandReducer)

    useEffect(() => {
        if (purchasePlans) {
            setIsPageLoading(false)
        }
        if (saveCreditCardError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (saveCreditCardError?.response?.data?.msg) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${saveCreditCardError?.response?.data?.msg}</li>`)
            }
            if (saveCreditCardError?.response?.data?.re_captcha_token) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${saveCreditCardError?.response?.data?.re_captcha_token[0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(saveCreditCardReset())
            creditCardForm.setFieldValue('captchaToken', '')
        }
        if (verifyCouponCodeError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (verifyCouponCodeError?.response?.data?.msg) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${verifyCouponCodeError?.response?.data?.msg}</li>`)
            }
            if (saveCreditCardError?.response?.data?.coupon_code) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${saveCreditCardError?.response?.data?.coupon_code[0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(verifyCouponCodeReset())
            checkoutForm.setFieldValue('captchaToken', '')
        }
        if (saveCreditCardData?.status) {
            setSaveCardData(saveCreditCardData?.card)
            setIsOpenAddCardModal(false)
            setCheckoutModal(true)
            dispatch(saveCreditCardReset())
        }
        if (activatePlanError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (activatePlanError?.response?.data?.plan_id) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${activatePlanError?.response?.data?.plan_id[0]}</li>`)
            }
            if (activatePlanError?.response?.data?.source) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${activatePlanError?.response?.data?.source[0]}</li>`)
            }
            if (activatePlanError?.response?.data?.re_captcha_token) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${activatePlanError?.response?.data?.re_captcha_token[0]}</li>`)
            }
            if (activatePlanError?.response?.data?.msg) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${activatePlanError?.response?.data?.msg}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(activatePlanReset())
            checkoutForm.setFieldValue('captchaToken', '')
        }
        if (activatePlanData?.status) {
            const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))
            userData.subscription_exp = false
            userData.subscriptionexp = false
            localStorage.setItem(process.env.REACT_APP_USERDATA, JSON.stringify(userData))
            ability.update([{action: "manage", subject: "brand"}])
            sweetAlert.fire('Congratulations!', activatePlanData?.msg, 'success')
            setSelectedPlan(null)
            setSaveCardData(null)
            setCheckoutModal(false)
            dispatch(activatePlanReset())
            history.push('/dashboard/brands')
        }
        if (verifyCouponCodeData?.success) {
            dispatch(verifyCouponCodeReset())
            dispatch(activatePlan({
                plan_id: selectedPlan?.type_id,
                coupon_code: checkoutForm.values.coupon,
                source: saveCardData?.id,
                re_captcha_token: checkoutForm.values.captchaToken
            }))
        }
    }, [saveCreditCardError, verifyCouponCodeError, purchasePlans, saveCreditCardData, activatePlanError, activatePlanData, verifyCouponCodeData])

    useEffect(() => {
        setIsPageLoading(true)
        dispatch(getPurchasePlans('monthly'))
        dispatch(getCountries())
    }, [])

    const loginNow = () => {
        dispatch(handleLogout())
        history.push('/login?target=web')
    }

    return (
        <>
            <Header/>
            <div className="text-center mt-5">
                <div className={style.heading}>
                    Thank You
                </div>
                <div className="font-large-1 text-primary mb-2 mt-2">
                    Thank you for subscribing to our plan! Your subscription has been successfully activated.
                </div>
                <div className="font-large-1 text-primary">
                    You can now log in to your account.
                </div>
                <div>
                    <button className="btn btn-primary mt-2" onClick={loginNow}>Log In</button>
                </div>
            </div>
        </>
    )
}

export default PurchasePackages
