import { lazy } from 'react'

const DashboardRoutes = [
  {
    path: '/dashboard/admin',
    exact: true,
    component: lazy(() => import('../../views/dashboard/admin')),
    meta: {
      layout: 'full',
      action: 'manage',
      resource: 'admin'
    }
  },
  {
    path: '/dashboard/brands',
    component: lazy(() => import('../../views/dashboard/brands')),
    exact: true,
    layout: 'VerticalLayoutBrands',
    meta: {
      layout: 'full',
      action: 'manage',
      resource: 'brand'
    }
  },
  {
    path: '/dashboard/rd',
    component: lazy(() => import('../../views/dashboard/rd')),
    exact: true,
    layout: 'VerticalLayoutRd',
    meta: {
      layout: 'full',
      action: 'manage',
      resource: 'rd'
    }
  },
  {
    path: '/dashboard/rd/campaign-application/:id?',
    component: lazy(() => import('../../views/pages/rdCampaigns/index')),
    layout: 'VerticalLayoutRd',
    meta: {
      publicRoute: false,
      action: 'manage',
      resource: 'rd'
    }
  }
]

export default DashboardRoutes
