import { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from "react-redux"
import {
  getBlogDetails
} from '@src/redux/actions/outerPages'
import {
  Spinner
} from "reactstrap"
import style from './style.module.css'
import { Link, useParams } from 'react-router-dom'
import Header from '../Header'
import TrustedBrands from '../TrustedBrands'
import Footer from '../Footer'
import blogdetailimg from '../../../assets/images/blogdetailimg.png'
import DummyBlogImage from '@src/assets/images/banner/dummy-blog.jpg'
import { ReactComponent as Twitter } from '../../../assets/images/twitter.svg'
import { ReactComponent as Facebook } from '../../../assets/images/facebook.svg'
import { ReactComponent as Gplus } from '../../../assets/images/google-plus.svg'
import { ReactComponent as Youtube } from '../../../assets/images/youtube.svg'

const DeatilBlog = () => {
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [blogDetails, setBlogDetails] = useState(null)

  const { id } = useParams()
  const dispatch = useDispatch()
  const outerPagesStoreSlice = useSelector(store => store.outerPages)

  useEffect(() => {
    if (!!outerPagesStoreSlice?.blogDetails) {
      setBlogDetails(outerPagesStoreSlice.blogDetails.data)
      setIsPageLoading(false)
    }
  }, [outerPagesStoreSlice])

  useEffect(() => {
    setIsPageLoading(true)
    dispatch(getBlogDetails(id))
  }, [])

  return (
    <Fragment>
      <Header />
      {isPageLoading && (
        <div className="w-100 my-4 text-center">
          <Spinner color='primary' />
        </div>)}
      {!isPageLoading && !!blogDetails &&
        (<div className={style.Deatilwrap}>
          <div className={style.blogimg}>
            <img src={blogDetails.banner_image || DummyBlogImage} alt='detail image' />
            <div className={style.datewrap}>{blogDetails.created_at}</div>
          </div>
          <div className={style.desc}>
            <div className={style.heading}>
              {blogDetails.title} 
            </div>
            <div className={style.text}>
              <div dangerouslySetInnerHTML={{ __html: blogDetails.description }} />
            </div>
          </div>
          <div className={style.tagswrap}>
            {blogDetails?.tags?.length > 0 && <div className={style.taginnerwrap}>
              <div className={style.taghd}>Tags: </div>
              <div className={style.tags}>
                {blogDetails?.tags?.map((tag, i) => <span>{tag}{blogDetails.tags.length - 1 !== i && ", "}</span>)}
              </div>
            </div>}
            {/* <div className={style.socialMediaWrap}>
              <Link to=''>
                <Twitter />
              </Link>
              <Link to=''>
                <Facebook />
              </Link>
              <Link to=''>
                <Gplus />
              </Link>
              <Link to=''>
                <Youtube />
              </Link>
            </div> */}
          </div>
        </div>)}
      <TrustedBrands />
      <Footer />
    </Fragment>
  )
}

export default DeatilBlog
