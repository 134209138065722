import React from 'react'
import TextTransition, { presets } from 'react-text-transition'
import style from './style.module.css'
import Circles1 from '../../../assets/images/Circles1.png'
import Circles2 from '../../../assets/images/Circles2.png'
import Circles3 from '../../../assets/images/Circles3.png'

const TextAndImage = () => {
  const TEXTS = ['Google', 'LinkedIn', 'Instagram']

  const [index, setIndex] = React.useState(0)

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000
    )
    return () => clearTimeout(intervalId)
  }, [])

  return (
    <div className={style.textImageWrap}>
      <div className={style.txtimgInnerWrap}>
        <div className={style.textimg}>
          <div className={style.text}>
            <div className={style.textWrp}>
              <div className={style.heading}>
                <span>Use</span>{' '}
                <TextTransition
                  text={TEXTS[index % TEXTS.length]}
                  springConfig={presets.wobbly}
                />{' '}
                <span>Link</span>{' '}to find the right nutrition experts to represent your brand
              </div>
              <div className={style.content}>
                Filter through our nutrition experts by industry, specialty, and
                location to{' '}
                <b>drive credibility and earn trust among consumers.</b>
              </div>
            </div>
          </div>
          <div className={style.img}>
            <img src={Circles1} alt='circles' />
          </div>
        </div>

        <div className={style.textimg}>
          <div className={style.img}>
            <img src={Circles2} alt='circles' />
          </div>
          <div className={style.text}>
            <div className={style.textWrp}>
              <div className={style.heading}>
                Drive brand awareness and <span>product trial</span>
              </div>
              <div className={style.content}>
                Pursue more strategic partnerships in no time at all. Filter
                through our nutrition experts by industry, specialty, and
                location to easily find the right nutrition experts for your
                brand.
              </div>
            </div>
          </div>
        </div>

        <div className={style.textimg}>
          <div className={style.text}>
            <div className={style.textWrp}>
              <div className={style.heading}>
                Pursue strategic partnerships to <span>drive results</span>
              </div>
              <div className={style.content}>
                Manage and track your dietitian relationships all in one place.
              </div>
            </div>
          </div>
          <div className={style.img}>
            <img src={Circles3} alt='circles' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextAndImage
