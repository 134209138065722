import {EMAIL_VERIFICATION} from '../actionTypes/index'

const emailVerificationDefState = {
    emailVerificationLoading: false,
    emailVerificationData: {},
    emailVerificationError: {}
}

export const emailVerificationReducer = (state = emailVerificationDefState, action) => {
    switch (action.type) {
        case EMAIL_VERIFICATION.REQUEST:
            return {
                emailVerificationLoading: true,
                emailVerificationData: {},
                emailVerificationError: {}
            }
        case EMAIL_VERIFICATION.SUCCESS:
            return {
                emailVerificationLoading: false,
                emailVerificationData: action.payload,
                emailVerificationError: {}
            }
        case EMAIL_VERIFICATION.ERROR:
            return {
                emailVerificationLoading: false,
                emailVerificationData: {},
                emailVerificationError: action.payload
            }
        case EMAIL_VERIFICATION.RESET:
            return {
                emailVerificationLoading: false,
                emailVerificationData: {},
                emailVerificationError: {}
            }
        default:
            return state
    }
}