import {Modal, ModalBody, ModalHeader} from "reactstrap"

const PdfViewer = ({show, hide, url, filename}) => {
    return (
        <>
            <Modal isOpen={show} className='modal-dialog-centered' size="xl">
                <ModalHeader toggle={hide}>
                    {filename}
                </ModalHeader>
                <ModalBody>
                    <div className='pdf-viewer-height'>
                        <iframe src={url} width="100%" height="100%" className='border-0'/>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default PdfViewer
