import {ACTIVE_BRANDS, ALL_INVOICES, CREATE_INVOICE, DELETE_INVOICE} from "../actionsTypes"

const invoiceDefState = {
    createInvoiceLoading: false,
    createInvoiceData: {},
    createInvoiceError: {},

    activeBrandsLoading: false,
    activeBrandsData: {},
    activeBrandsError: {},

    allInvoicesLoading: false,
    allInvoicesData: [],
    allInvoicesError: {},

    deleteInvoiceLoading: false,
    deleteInvoiceData: {},
    deleteInvoiceError: {}
}

export const invoiceReducer = (state = invoiceDefState, action) => {
    switch (action.type) {
        case CREATE_INVOICE.REQUEST:
            return {
                ...state,
                createInvoiceLoading: true,
                createInvoiceData: {},
                createInvoiceError: {}
            }
        case CREATE_INVOICE.SUCCESS:
            return {
                ...state,
                createInvoiceLoading: false,
                createInvoiceData: action.payload,
                createInvoiceError: {}
            }
        case CREATE_INVOICE.ERROR:
            return {
                ...state,
                createInvoiceLoading: false,
                createInvoiceData: {},
                createInvoiceError: action.payload
            }
        case CREATE_INVOICE.RESET:
            return {
                ...state,
                createInvoiceLoading: false,
                createInvoiceData: {},
                createInvoiceError: {}
            }

        case ACTIVE_BRANDS.REQUEST:
            return {
                ...state,
                activeBrandsLoading: true,
                activeBrandsData: {},
                activeBrandsError: {}
            }
        case ACTIVE_BRANDS.SUCCESS:
            return {
                ...state,
                activeBrandsLoading: false,
                activeBrandsData: action.payload,
                activeBrandsError: {}
            }
        case ACTIVE_BRANDS.ERROR:
            return {
                ...state,
                activeBrandsLoading: false,
                activeBrandsData: {},
                activeBrandsError: action.payload
            }

        case ALL_INVOICES.REQUEST:
            return {
                ...state,
                allInvoicesLoading: true,
                allInvoicesData: [],
                allInvoicesError: {}
            }
        case ALL_INVOICES.SUCCESS:
            return {
                ...state,
                allInvoicesLoading: false,
                allInvoicesData: action.payload,
                allInvoicesError: {}
            }
        case ALL_INVOICES.ERROR:
            return {
                ...state,
                allInvoicesLoading: false,
                allInvoicesData: [],
                allInvoicesError: action.payload
            }

        case DELETE_INVOICE.REQUEST:
            return {
                ...state,
                deleteInvoiceLoading: true,
                deleteInvoiceData: {},
                deleteInvoiceError: {}
            }
        case DELETE_INVOICE.SUCCESS:
            return {
                ...state,
                deleteInvoiceLoading: false,
                deleteInvoiceData: action.payload,
                deleteInvoiceError: {}
            }
        case DELETE_INVOICE.ERROR:
            return {
                ...state,
                deleteInvoiceLoading: false,
                deleteInvoiceData: {},
                deleteInvoiceError: action.payload
            }
        case DELETE_INVOICE.RESET:
            return {
                ...state,
                deleteInvoiceLoading: false,
                deleteInvoiceData: {},
                deleteInvoiceError: {}
            }
        default:
            return state
    }
}