import axios from 'axios'
import { API_URL, GENERAL, ADMIN_DASHBOARD, BRANDS } from '../../actionTypes'
// ** Get Bookmarks Array from @fakeDB
export const getBookmarks = () => {
  return dispatch => {
    return axios.get('/api/bookmarks/data').then(response => {
      dispatch({
        type: 'GET_BOOKMARKS',
        data: response.data.suggestions,
        bookmarks: response.data.bookmarks
      })
    })
  }
}

// ** Update & Get Updated Bookmarks Array
export const updateBookmarked = id => {
  return dispatch => {
    return axios.post('/api/bookmarks/update', { id }).then(() => {
      dispatch({ type: 'UPDATE_BOOKMARKED', id })
    })
  }
}

// ** Handle Bookmarks & Main Search Queries
export const handleSearchQuery = val => dispatch => dispatch({ type: 'HANDLE_SEARCH_QUERY', val })

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getAdminProfileInfo = () => {
  return dispatch => {
    axios.get(`${API_URL}admin/edit-profile`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${getToken()}`
      }
    }).then((res) => {
      dispatch({ type: ADMIN_DASHBOARD.EDIT_PROFILE_INFO, editProfileInfo: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.ADMIN_DASHBOARD_ERROR, error })
    })
  }
}


export const updateAdminProfile = (body) => {
  const formData = new FormData()
  formData.append("name", body.name)
  formData.append("profile_img", body.profile_img)
  formData.append("re_captcha_token", body.re_captcha_token)
  return dispatch => {
    axios.post(`${API_URL}admin/update-admin-profile`, formData, {
      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${getToken()}`
      }
    }).then((res) => {
      dispatch({ type: ADMIN_DASHBOARD.UPDATE_PROFILE, updateProfileRes: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.ADMIN_DASHBOARD_ERROR, error })
    })
  }
}


export const updateAdminPassword = (body) => {
  return dispatch => {
    axios.post(`${API_URL}admin/update-password`, {
      current_password: body.current_password,
      new_password: body.new_password,
      confirm_password: body.confirm_password,
      re_captcha_token: body.re_captcha_token
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${getToken()}`
      }
    }).then((res) => {
      dispatch({ type: ADMIN_DASHBOARD.UPDATE_PASSWORD, updatePasswordRes: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.ADMIN_DASHBOARD_ERROR, error })
    })
  }
}

export const updateBrandPassword = (body) => {
  return dispatch => {
    axios.post(`${API_URL}brand/update-password`, {
      current_password: body.current_password,
      new_password: body.new_password,
      confirm_password: body.confirm_password,
      re_captcha_token: body.re_captcha_token
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${getToken()}`
      }
    }).then((res) => {
      dispatch({ type: BRANDS.UPDATE_PASSWORD, updateBrandPasswordRes: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.BRANDS_DASHBOARD_ERROR, error })
    })
  }
}

export const nullResponse = (property) => {
  return dispatch => {
    switch (property) {
      case 'UpdateProfile':
        dispatch({ type: ADMIN_DASHBOARD.UPDATE_PROFILE, updateProfileRes: null })
        break
      case 'EditProfile':
        dispatch({ type: ADMIN_DASHBOARD.EDIT_PROFILE_INFO, editProfileInfo: null })
        break
      case 'UpdateAdminPassword':
        dispatch({ type: ADMIN_DASHBOARD.UPDATE_PASSWORD, updatePasswordRes: null })
        break
    }
  }
}
