import { ADMIN_DASHBOARD, BRANDS, RD } from '../../actionTypes'

const initialState = {
  suggestions: [],
  bookmarks: [],
  query: '',
  editProfileInfo: null,
  updateProfileRes: null,
  updatePasswordRes: null,
  changePasswordRes: null,
  updateBrandPasswordRes: null
}

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_DASHBOARD.EDIT_PROFILE_INFO:
      return {
        ...state,
        editProfileInfo: action.editProfileInfo
      }
    case ADMIN_DASHBOARD.UPDATE_PROFILE:
      return {
        ...state,
        updateProfileRes: action.updateProfileRes
      }
    case ADMIN_DASHBOARD.UPDATE_PASSWORD:
      return {
        ...state,
        updatePasswordRes: action.updatePasswordRes
      }
    case BRANDS.UPDATE_PASSWORD:
      return {
        ...state,
        updateBrandPasswordRes: action.updateBrandPasswordRes
      }
    case RD.CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordRes: action.changePasswordRes
      }
    case 'HANDLE_SEARCH_QUERY':
      return { ...state, query: action.val }
    case 'GET_BOOKMARKS':
      return { ...state, suggestions: action.data, bookmarks: action.bookmarks }
    case 'UPDATE_BOOKMARKED':
      let objectToUpdate

      // ** find & update object
      state.suggestions.find(item => {
        if (item.id === action.id) {
          item.isBookmarked = !item.isBookmarked
          objectToUpdate = item
        }
      })

      // ** Get index to add or remove bookmark from array
      const bookmarkIndex = state.bookmarks.findIndex(x => x.id === action.id)

      if (bookmarkIndex === -1) {
        state.bookmarks.push(objectToUpdate)
      } else {
        state.bookmarks.splice(bookmarkIndex, 1)
      }

      return { ...state }
    default:
      return state
  }
}

export default navbarReducer
