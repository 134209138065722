const BRANDS = {
    CHAT_ADD_CONTACT: '[BRANDS][CHAT] ADD_CONTACT',
    CHAT_GET_USER_PROFILE_FROM_SERVER: '[BRANDS][CHAT] GET_USER_PROFILE_FROM_SERVER',
    CHAT_CONTACTS: '[BRANDS][CHAT] GET_CONTACTS',
    CHAT_GET_MESSAGE_TEMPLETES: '[BRANDS][CHAT] GET_MESSAGE_TEMPLETES',
    SELECTED_USER_CHAT: '[BRANDS][CHAT] SELECTED_USER_CHAT',
    LOAD_SELECTED_USER_CHAT: '[BRANDS] [CHAT] LOAD_SELECTED_USER_CHAT',
    NEW_MESSAGE: '[BRANDS][CHAT] NEW_MESSAGE',
    CHAT_SELECTED_CONTACT: '[BRANDS][CHAT] SELECTED_CONTACT',
    CHAT_BRAODCAST_CONTACTS: '[BRANDS][CHAT] CHAT_BRAODCAST_CONTACTS'
}

const NULL = {
    BRAND_SIDE_LOAD_CHAT_MESSAGES: '[NULL] [BRAND] Load Chat Messages',
    LOAD_SELECTED_USER_CHAT: '[NULL] [BRANDS] [CHAT] LOAD_SELECTED_USER_CHAT'
}

export { BRANDS, NULL }
