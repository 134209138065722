import {CONTACT} from '../actionTypes/index'
// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  allNewContacts: [],
  allRepliedContacts: [],
  searchedNewContact: [],
  searchedRepliedData: [],
  submitYourReply: {},
  deleteContact: {},
  loadUserReplies: null,
  searchLoadUserReplies: null
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT.GET_NEW_CONTACTS:
      return {
        ...state,
        allNewContacts: action.allData
      }
    case CONTACT.GET_SEARCH_NEW_CONTACTS:
      return {
        ...state,
        searchedNewContact: action.searchedNewContacts
      }
    case CONTACT.GET_REPLIED_CONTACTS:
      return {
        ...state,
        allRepliedContacts: action.allRepliedData
      }
    case CONTACT.GET_SEARCH_REPLIED_CONTACTS:
      return {
        ...state,
        searchedRepliedData: action.searchedRepliedDatas
      }
    case CONTACT.SUBMIT_REPLY:
      return {
        ...state,
        submitYourReply: action.submitYourReply
      }
    case CONTACT.DELETE_CONTACT:
      return {
        ...state,
        deleteContact: action.deleteContact
      }
    case CONTACT.LOAD_REPLIES:
      return {
        ...state,
        loadUserReplies: action.loadUserReplies
      }
    case CONTACT.SEARCH_LOAD_REPLIES:
      return {
        ...state,
        searchLoadUserReplies: action.searchLoadUserReplies
      }
    default:
      return state
  }
}

export default DataTablesReducer
