import {useState, useEffect, Fragment} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {
    submitContactForm, nullResponseOuterPages
} from '@src/redux/actions/outerPages'
import {
    Card,
    CardBody,
    FormGroup,
    Row,
    Col,
    Input,
    Form,
    Button,
    Label,
    Alert
} from 'reactstrap'
import style from './style.module.css'
import Header from '../Header'
import TrustedBrands from '../TrustedBrands'
import Footer from '../Footer'
import {NULL} from '@src/redux/actionTypes'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

const EmailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/

const Contact = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const [visible, setVisible] = useState(false)
    const [serverMsg, setServerMsg] = useState('')
    const [isErrorMessage, setIsErrorMessage] = useState(false)

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [formErrors, setFormErrors] = useState([])

    const [captchaValue, setCaptchaValue] = useState(null)
    const [captchaError, setCaptchaError] = useState(false)

    const sweetAlert = withReactContent(Swal)

    const dispatch = useDispatch()
    const outerPagesStoreSlice = useSelector(store => store.outerPages)

    useEffect(() => {
        if (!!outerPagesStoreSlice?.contactFormRes) {
            setCaptchaError(false)
            setCaptchaValue(null)
            dispatch(nullResponseOuterPages(NULL.CONTACT_FORM))
            if (outerPagesStoreSlice?.contactFormRes.success) {
                setServerMsg(outerPagesStoreSlice?.contactFormRes.msg)
                setIsErrorMessage(false)
                setName('')
                setEmail('')
                setSubject('')
                setMessage('')
                setFormErrors([])
            }

            if (!outerPagesStoreSlice?.contactFormRes.success) {
                setServerMsg(outerPagesStoreSlice?.contactFormRes.msg)
                setIsErrorMessage(true)
            }
            setVisible(true)
            setIsSubmitting(false)
            const timer = setTimeout(() => {
                setVisible(false)
                clearTimeout(timer)
            }, 4000)
        }
    }, [outerPagesStoreSlice])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleSubmitForm = async () => {
        let errorList = []
        name === '' ? errorList.push('name') : errorList = errorList.filter(x => x !== 'name')
        email === '' ? errorList.push('email') : errorList = errorList.filter(x => x !== 'email')
        !EmailPattern.test(email) ? errorList.push('invalidEmail') : errorList = errorList.filter(x => x !== 'invalidEmail')
        message === '' ? errorList.push('message') : errorList = errorList.filter(x => x !== 'message')
        subject === '' ? errorList.push('subject') : errorList = errorList.filter(x => x !== 'subject')
        setFormErrors(errorList)
        setVisible(false)
        if (formErrors.length === 0 && name !== '' && email !== '' && message !== '' && subject !== '') {
            setIsSubmitting(true)
            const token = await executeRecaptcha('contact_us_form')
            if (token) {
                dispatch(submitContactForm({
                    name,
                    email,
                    subject,
                    message,
                    re_captcha_token: token
                }))
            } else {
                sweetAlert.fire({
                    title: 'Captcha Error',
                    icon: 'error',
                    html: '<p>Execute recaptcha not yet available.</p>',
                    confirmButtonText: 'Reload',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                })
            }
        }
    }

    const handleInputChange = (name, value) => {
        switch (name) {
            case 'name':
                setName(value)
                setFormErrors(formErrors.filter(x => x !== 'name'))
                break
            case 'email':
                setEmail(value)
                setFormErrors(formErrors.filter(x => x !== 'email'))
                if (EmailPattern.test(value)) setFormErrors(formErrors.filter(x => x !== 'invalidEmail'))
                break
            case 'subject':
                setSubject(value)
                setFormErrors(formErrors.filter(x => x !== 'subject'))
                break
            case 'message':
                setMessage(value)
                setFormErrors(formErrors.filter(x => x !== 'message'))
                break
        }
    }

    return (
        <Fragment>
            <Header/>
            <div className={style.contactwrap}>
                <div className={style.heading}>Contact Us</div>
                <Card>
                    <CardBody className="mt-1">
                        <Alert color={isErrorMessage ? 'danger' : 'success'} isOpen={visible} toggle={() => setVisible(false)}>
                            <div className='alert-body'>
                                {serverMsg}
                            </div>
                        </Alert>
                        <Form>
                            <Row>
                                <Col sm='12'>
                                    <FormGroup>
                                        <Label htmlFor='nameVertical'>Name<span className="is-invalid">*</span></Label>
                                        <Input type='text' name='name' id='nameVertical' placeholder='Name' value={name}
                                               onChange={(e) => handleInputChange(e.target.name, e.target.value)}/>
                                        {formErrors.includes('name') && <div className="validation-err">This field is required</div>}
                                    </FormGroup>
                                </Col>
                                <Col sm='12'>
                                    <FormGroup>
                                        <Label htmlFor='EmailVertical'>Email<span className="is-invalid">*</span></Label>
                                        <Input type='email' name='email' value={email} id='EmailVertical' placeholder='Email'
                                               onChange={(e) => handleInputChange(e.target.name, e.target.value)}/>
                                        {formErrors.includes('email') && <div className="validation-err">This field is required</div>}
                                        {formErrors.includes('invalidEmail') && <div className="validation-err">Invalid Email</div>}
                                    </FormGroup>
                                </Col>
                                <Col sm='12'>
                                    <FormGroup>
                                        <Label htmlFor='subjectVertical'>Subject<span className="is-invalid">*</span></Label>
                                        <Input type='text' name='subject' value={subject} id='subjectVertical' placeholder='Subject'
                                               onChange={(e) => handleInputChange(e.target.name, e.target.value)}/>
                                        {formErrors.includes('subject') && <div className="validation-err">This field is required</div>}
                                    </FormGroup>
                                </Col>
                                <Col sm='12'>
                                    <FormGroup>
                                        <Label htmlFor='subjectVertical'>Message<span className="is-invalid">*</span></Label>
                                        <Input type='textarea' name='message' value={message} rows="4" id='subjectVertical' placeholder='Message'
                                               onChange={(e) => handleInputChange(e.target.name, e.target.value)}/>
                                        {formErrors.includes('message') && <div className="validation-err">This field is required</div>}
                                    </FormGroup>
                                </Col>
                                <Col sm='12'>
                                    <FormGroup className='d-flex justify-content-end mt-2 mb-0'>
                                        <Button.Ripple className='' color='primary' disabled={isSubmitting} onClick={handleSubmitForm}>
                                            {isSubmitting ? 'Submitting...' : 'Submit'}
                                        </Button.Ripple>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </div>
            <TrustedBrands/>
            <Footer/>
        </Fragment>
    )
}

export default Contact
