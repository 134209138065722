// Main
const API_URL = process.env.REACT_APP_BASEURL

// COMMISSION_REPORT 
const COMMISSION_REPORT = {
    LISTING: '[ADMIN] LISTING',
    SEARCH: '[ADMIN] SEARCH',
    ERROR: '[ADMIN] ERROR'
}

export { API_URL, COMMISSION_REPORT }