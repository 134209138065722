// Main
const API_URL = process.env.REACT_APP_BASEURL

// COUPON 
const COUPON = {
  COUPONS_LISTING_DATA: '[ADMIN] COUPONS_LISTING_DATA',
  NEW_COUPON_ADDED: '[ADMIN] NEW_COUPON_ADDED',
  COUPONS_SEARCH_LISTING_DATA: '[ADMIN] COUPONS_SEARCH_LISTING_DATA',
  COUPON_STATUS_CHANGE: '[ADMIN] COUPON_STATUS_CHANGE',
  DELETE_COUPON_DATA: '[ADMIN] DELETE_COUPON_DATA',
  ERROR: '[ADMIN] ERROR'
}

const NULL = {
  COUPONS_LISTING_DATA: '[ADMIN] COUPONS_LISTING_DATA',
  NEW_COUPON_ADDED: '[ADMIN] NEW_COUPON_ADDED',
  COUPONS_SEARCH_LISTING_DATA: '[ADMIN] COUPONS_SEARCH_LISTING_DATA',
  COUPON_STATUS_CHANGE: '[ADMIN] COUPON_STATUS_CHANGE',
  DELETE_COUPON_DATA: '[ADMIN] DELETE_COUPON_DATA',
  ERROR: '[ADMIN] ERROR'
}

export { API_URL, COUPON, NULL }