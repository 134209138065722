import {BRAND_ACTION_TYPES} from "@store/actionTypes/BrandActionTypes"

const initialState = {
    saveCreditCardLoading: false,
    saveCreditCardData: null,
    saveCreditCardError: null,

    verifyCouponCodeLoading: false,
    verifyCouponCodeData: null,
    verifyCouponCodeError: null,

    activatePlanLoading: false,
    activatePlanData: null,
    activatePlanError: null,

    viewProfileLoading: false,
    viewProfileData: null,
    viewProfileError: null,

    saveProfileLoading: false,
    saveProfileData: null,
    saveProfileError: null,

    rdGroupListLoading: false,
    rdGroupListData: null,
    rdGroupListError: null,

    rdIgDataLoading: false,
    rdIgDataData: null,
    rdIgDataError: null,

    copyCampaignLoading: false,
    copyCampaignData: null,
    copyCampaignError: null,

    massMsgTempSaveLoading: false,
    massMsgTempSaveData: null,
    massMsgTempSaveError: null,

    massMsgTempListLoading: false,
    massMsgTempListData: null,
    massMsgTempListError: null,

    massMsgTempDeleteLoading: false,
    massMsgTempDeleteData: null,
    massMsgTempDeleteError: null,

    markAsReadLoading: false,
    markAsReadData: null,
    markAsReadError: null
}

const brandReducer = (state = initialState, action) => {
    switch (action.type) {
        case BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_REQUEST:
            return {
                ...state,
                saveCreditCardLoading: true,
                saveCreditCardData: null,
                saveCreditCardError: null
            }
        case BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_DATA:
            return {
                ...state,
                saveCreditCardLoading: false,
                saveCreditCardData: action.payload,
                saveCreditCardError: null
            }
        case BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_ERROR:
            return {
                ...state,
                saveCreditCardLoading: false,
                saveCreditCardData: null,
                saveCreditCardError: action.payload
            }
        case BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_RESET:
            return {
                ...state,
                saveCreditCardLoading: false,
                saveCreditCardData: null,
                saveCreditCardError: null
            }

        case BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_REQUEST:
            return {
                ...state,
                verifyCouponCodeLoading: true,
                verifyCouponCodeData: null,
                verifyCouponCodeError: null
            }
        case BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_DATA:
            return {
                ...state,
                verifyCouponCodeLoading: false,
                verifyCouponCodeData: action.payload,
                verifyCouponCodeError: null
            }
        case BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_ERROR:
            return {
                ...state,
                verifyCouponCodeLoading: false,
                verifyCouponCodeData: null,
                verifyCouponCodeError: action.payload
            }
        case BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_RESET:
            return {
                ...state,
                verifyCouponCodeLoading: false,
                verifyCouponCodeData: null,
                verifyCouponCodeError: null
            }

        case BRAND_ACTION_TYPES.ACTIVATE_PLAN_REQUEST:
            return {
                ...state,
                activatePlanLoading: true,
                activatePlanData: null,
                activatePlanError: null
            }
        case BRAND_ACTION_TYPES.ACTIVATE_PLAN_DATA:
            return {
                ...state,
                activatePlanLoading: false,
                activatePlanData: action.payload,
                activatePlanError: null
            }
        case BRAND_ACTION_TYPES.ACTIVATE_PLAN_ERROR:
            return {
                ...state,
                activatePlanLoading: false,
                activatePlanData: null,
                activatePlanError: action.payload
            }
        case BRAND_ACTION_TYPES.ACTIVATE_PLAN_RESET:
            return {
                ...state,
                activatePlanLoading: false,
                activatePlanData: null,
                activatePlanError: null
            }

        case BRAND_ACTION_TYPES.VIEW_PROFILE_REQUEST:
            return {
                ...state,
                viewProfileLoading: true,
                viewProfileData: null,
                viewProfileError: null
            }
        case BRAND_ACTION_TYPES.VIEW_PROFILE_DATA:
            return {
                ...state,
                viewProfileLoading: false,
                viewProfileData: action.payload,
                viewProfileError: null
            }
        case BRAND_ACTION_TYPES.VIEW_PROFILE_ERROR:
            return {
                ...state,
                viewProfileLoading: false,
                viewProfileData: null,
                viewProfileError: action.payload
            }

        case BRAND_ACTION_TYPES.SAVE_PROFILE_REQUEST:
            return {
                ...state,
                saveProfileLoading: true,
                saveProfileData: null,
                saveProfileError: null
            }
        case BRAND_ACTION_TYPES.SAVE_PROFILE_DATA:
            return {
                ...state,
                saveProfileLoading: false,
                saveProfileData: action.payload,
                saveProfileError: null
            }
        case BRAND_ACTION_TYPES.SAVE_PROFILE_ERROR:
            return {
                ...state,
                saveProfileLoading: false,
                saveProfileData: null,
                saveProfileError: action.payload
            }
        case BRAND_ACTION_TYPES.SAVE_PROFILE_RESET:
            return {
                ...state,
                saveProfileLoading: false,
                saveProfileData: null,
                saveProfileError: null
            }

        case BRAND_ACTION_TYPES.RD_GROUP_LIST_REQUEST:
            return {
                ...state,
                rdGroupListLoading: true,
                rdGroupListData: null,
                rdGroupListError: null
            }
        case BRAND_ACTION_TYPES.RD_GROUP_LIST_DATA:
            return {
                ...state,
                rdGroupListLoading: false,
                rdGroupListData: action.payload,
                rdGroupListError: null
            }
        case BRAND_ACTION_TYPES.RD_GROUP_LIST_ERROR:
            return {
                ...state,
                rdGroupListLoading: false,
                rdGroupListData: null,
                rdGroupListError: action.payload
            }
        case BRAND_ACTION_TYPES.RD_GROUP_LIST_RESET:
            return {
                ...state,
                rdGroupListLoading: false,
                rdGroupListData: null,
                rdGroupListError: null
            }

        case BRAND_ACTION_TYPES.RD_IG_DATA_REQUEST:
            return {
                ...state,
                rdIgDataLoading: true,
                rdIgDataData: null,
                rdIgDataError: null
            }
        case BRAND_ACTION_TYPES.RD_IG_DATA_DATA:
            return {
                ...state,
                rdIgDataLoading: false,
                rdIgDataData: action.payload,
                rdIgDataError: null
            }
        case BRAND_ACTION_TYPES.RD_IG_DATA_ERROR:
            return {
                ...state,
                rdIgDataLoading: false,
                rdIgDataData: null,
                rdIgDataError: action.payload
            }

        case BRAND_ACTION_TYPES.COPY_CAMPAIGN_REQUEST:
            return {
                ...state,
                copyCampaignLoading: true,
                copyCampaignData: null,
                copyCampaignError: null
            }
        case BRAND_ACTION_TYPES.COPY_CAMPAIGN_DATA:
            return {
                ...state,
                copyCampaignLoading: false,
                copyCampaignData: action.payload,
                copyCampaignError: null
            }
        case BRAND_ACTION_TYPES.COPY_CAMPAIGN_ERROR:
            return {
                ...state,
                copyCampaignLoading: false,
                copyCampaignData: null,
                copyCampaignError: action.payload
            }
        case BRAND_ACTION_TYPES.COPY_CAMPAIGN_RESET:
            return {
                ...state,
                copyCampaignLoading: false,
                copyCampaignData: null,
                copyCampaignError: null
            }

        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_REQUEST:
            return {
                ...state,
                massMsgTempSaveLoading: true,
                massMsgTempSaveData: null,
                massMsgTempSaveError: null
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_DATA:
            return {
                ...state,
                massMsgTempSaveLoading: false,
                massMsgTempSaveData: action.payload,
                massMsgTempSaveError: null
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_ERROR:
            return {
                ...state,
                massMsgTempSaveLoading: false,
                massMsgTempSaveData: null,
                massMsgTempSaveError: action.payload
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_RESET:
            return {
                ...state,
                massMsgTempSaveLoading: false,
                massMsgTempSaveData: null,
                massMsgTempSaveError: null
            }

        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_LIST_REQUEST:
            return {
                ...state,
                massMsgTempListLoading: true,
                massMsgTempListData: null,
                massMsgTempListError: null
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_LIST_DATA:
            return {
                ...state,
                massMsgTempListLoading: false,
                massMsgTempListData: action.payload,
                massMsgTempListError: null
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_LIST_ERROR:
            return {
                ...state,
                massMsgTempListLoading: false,
                massMsgTempListData: null,
                massMsgTempListError: action.payload
            }

        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_REQUEST:
            return {
                ...state,
                massMsgTempDeleteLoading: true,
                massMsgTempDeleteData: null,
                massMsgTempDeleteError: null
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_DATA:
            return {
                ...state,
                massMsgTempDeleteLoading: false,
                massMsgTempDeleteData: action.payload,
                massMsgTempDeleteError: null
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_ERROR:
            return {
                ...state,
                massMsgTempDeleteLoading: false,
                massMsgTempDeleteData: null,
                massMsgTempDeleteError: action.payload
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_RESET:
            return {
                ...state,
                massMsgTempDeleteLoading: false,
                massMsgTempDeleteData: null,
                massMsgTempDeleteError: null
            }

        case BRAND_ACTION_TYPES.MARK_AS_READ_REQUEST:
            return {
                ...state,
                markAsReadLoading: true,
                markAsReadData: null,
                markAsReadError: null
            }
        case BRAND_ACTION_TYPES.MARK_AS_READ_DATA:
            return {
                ...state,
                markAsReadLoading: false,
                markAsReadData: action.payload,
                markAsReadError: null
            }
        case BRAND_ACTION_TYPES.MARK_AS_READ_ERROR:
            return {
                ...state,
                markAsReadLoading: false,
                markAsReadData: null,
                markAsReadError: action.payload
            }
        case BRAND_ACTION_TYPES.MARK_AS_READ_RESET:
            return {
                ...state,
                markAsReadLoading: false,
                markAsReadData: null,
                markAsReadError: null
            }
        default:
            return state
    }
}
export default brandReducer