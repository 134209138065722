import ImgsViewer from "react-images-viewer"

const ImageViewer = ({show, hide, images}) => {
    return (
        <>
            {show && (
                <style>{`.fade:not(.show){opacity: 1;} .fade{transition: opacity 0s linear;}`}</style>
            )}
            <ImgsViewer imgs={images} isOpen={show} onClose={hide} theme={{opacity: 1}}/>
        </>
    )
}

export default ImageViewer
