import React, {useState, useEffect, Fragment, useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import Cleave from 'cleave.js/react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Spinner,
    Card,
    CardBody,
    Form, Table, CustomInput
} from 'reactstrap'
import Header from '../Header'
import style from './style.module.css'
import {getPurchasePlans} from '@store/actions/auth'
import PowerByStripe from '@src/assets/images/stripeIcon.png'
import MoneyBackGurantee from '@src/assets/images/30-day-money.jpg'
import * as Icon from "react-feather"
import {useFormik} from "formik"
import * as yup from "yup"
import classnames from "classnames"
import {
    getCountries
} from "@src/views/apps/opportunitiesBrands/store/actions/index"

import {
    saveCreditCard,
    saveCreditCardReset,
    verifyCouponCode,
    verifyCouponCodeReset,
    activatePlan,
    activatePlanReset
} from "@store/actions/BrandActions"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {AbilityContext} from '@src/utility/context/Can'

const PurchasePackages = () => {

    const onlyCharRegex = /^[aA-zZ\s]+$/

    const ability = useContext(AbilityContext)

    const sweetAlert = withReactContent(Swal)

    const history = useHistory()

    const dispatch = useDispatch()

    const [isPageLoading, setIsPageLoading] = useState(false)
    const [isOpenAddCardModal, setIsOpenAddCardModal] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [checkoutModal, setCheckoutModal] = useState(false)
    const [saveCardData, setSaveCardData] = useState(null)

    const creditCardForm = useFormik({
        initialValues: {
            owner_name: "",
            card_number: "",
            expiry: "",
            cvv: ""
        },
        validationSchema: yup.object().shape({
            owner_name: yup.string().trim().required("This field is required.").matches(onlyCharRegex, "Only alphabets are allowed."),
            card_number: yup.number().required("This field is required").typeError('Only numbers are allowed.'),
            expiry: yup.string().trim().min(5, 'Card expiry is invalid.').required("This field is required"),
            cvv: yup.string().trim().required("This field is required")
        }),
        onSubmit: (values) => {
            dispatch(saveCreditCard({
                card_number: values.card_number,
                expiry_month: values?.expiry.split('/')[0],
                expiry_year: values?.expiry.split('/')[1],
                cvc: values.cvv,
                owner_name: values?.owner_name,
                re_captcha_token: values.captchaToken
            }))
        }
    })

    const checkoutForm = useFormik({
        initialValues: {
            isCoupon: "No",
            coupon: ""
        },
        validationSchema: yup.object().shape({
            isCoupon: yup.string(),
            coupon: yup.string().when('isCoupon', {
                is: 'Yes',
                then: yup.string().trim().required("This field is required.")
            })
        }),
        onSubmit: (values) => {
            if (values.isCoupon === 'Yes') {
                dispatch(verifyCouponCode({
                    coupon_code: values.coupon
                }))
            } else {
                dispatch(activatePlan({
                    plan_id: selectedPlan?.type_id,
                    coupon_code: values.coupon,
                    source: saveCardData?.id,
                    re_captcha_token: values.captchaToken
                }))
            }
        }
    })

    const {
        purchasePlans
    } = useSelector(state => state.auth)

    const {
        countries
    } = useSelector((store) => store.brandsOppoprunities)

    const {
        saveCreditCardLoading,
        saveCreditCardData,
        saveCreditCardError,

        verifyCouponCodeLoading,
        verifyCouponCodeData,
        verifyCouponCodeError,

        activatePlanLoading,
        activatePlanData,
        activatePlanError
    } = useSelector((store) => store.brandReducer)

    useEffect(() => {
        if (purchasePlans) {
            setIsPageLoading(false)
        }
        if (saveCreditCardError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (saveCreditCardError?.response?.data?.msg) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${saveCreditCardError?.response?.data?.msg}</li>`)
            }
            if (saveCreditCardError?.response?.data?.re_captcha_token) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${saveCreditCardError?.response?.data?.re_captcha_token[0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(saveCreditCardReset())
            creditCardForm.setFieldValue('captchaToken', '')
        }
        if (verifyCouponCodeError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (verifyCouponCodeError?.response?.data?.msg) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${verifyCouponCodeError?.response?.data?.msg}</li>`)
            }
            if (saveCreditCardError?.response?.data?.coupon_code) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${saveCreditCardError?.response?.data?.coupon_code[0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(verifyCouponCodeReset())
            checkoutForm.setFieldValue('captchaToken', '')
        }
        if (saveCreditCardData?.status) {
            setSaveCardData(saveCreditCardData?.card)
            setIsOpenAddCardModal(false)
            setCheckoutModal(true)
            dispatch(saveCreditCardReset())
        }
        if (activatePlanError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (activatePlanError?.response?.data?.plan_id) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${activatePlanError?.response?.data?.plan_id[0]}</li>`)
            }
            if (activatePlanError?.response?.data?.source) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${activatePlanError?.response?.data?.source[0]}</li>`)
            }
            if (activatePlanError?.response?.data?.re_captcha_token) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${activatePlanError?.response?.data?.re_captcha_token[0]}</li>`)
            }
            if (activatePlanError?.response?.data?.msg) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${activatePlanError?.response?.data?.msg}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(activatePlanReset())
            checkoutForm.setFieldValue('captchaToken', '')
        }
        if (activatePlanData?.status) {
            const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))
            userData.subscription_exp = false
            userData.subscriptionexp = false
            localStorage.setItem(process.env.REACT_APP_USERDATA, JSON.stringify(userData))
            ability.update([{action: "manage", subject: "brand"}])
            sweetAlert.fire('Congratulations!', activatePlanData?.msg, 'success')
            setSelectedPlan(null)
            setSaveCardData(null)
            setCheckoutModal(false)
            dispatch(activatePlanReset())
            history.push('/dashboard/brands')
        }
        if (verifyCouponCodeData?.success) {
            dispatch(verifyCouponCodeReset())
            dispatch(activatePlan({
                plan_id: selectedPlan?.type_id,
                coupon_code: checkoutForm.values.coupon,
                source: saveCardData?.id,
                re_captcha_token: checkoutForm.values.captchaToken
            }))
        }
    }, [saveCreditCardError, verifyCouponCodeError, purchasePlans, saveCreditCardData, activatePlanError, activatePlanData, verifyCouponCodeData])

    useEffect(() => {
        setIsPageLoading(true)
        dispatch(getPurchasePlans('monthly'))
        dispatch(getCountries())
    }, [])

    return (
        <Fragment>
            <Header/>
            {isPageLoading ? (
                <div className="w-100 text-center mt-40vh">
                    <Spinner color='primary'/>
                </div>
            ) : (
                <div className={style.pricingWrap}>
                    <div className={style.heading}>One All Access Plan</div>
                    <div className={style.subheading}>Two Payment Options</div>
                    <div className="d-flex justify-content-center">
                        {purchasePlans?.data.map((selectedPlan, index) => (
                            <div className={style.infoWrap} key={index}>
                                <div style={{border: '1px solid #dddde4', borderBottom: 'none'}}>
                                    <div className="bg-primary font-medium-5 text-white font-weight-bold" style={{padding: '0.5rem'}}>
                                        {selectedPlan?.name === "Monthly" ? (
                                            <>
                                                <div>Pay Monthly</div>
                                                <span className="font-small-3">(Commit Annually)</span>
                                            </>
                                        ) : selectedPlan?.name === "Annual" ? (
                                            <>
                                                <div>Pay Upfront</div>
                                                <span className="font-small-3">(Save 10%, Commit Annually)</span>
                                            </>
                                        ) : selectedPlan?.name}
                                    </div>
                                    <div className={style.price}>
                                        <div className={style.Dsign}>$</div>
                                        {selectedPlan?.pricing}
                                        <h6 className="text-dark mb-0">{selectedPlan?.description}</h6>
                                    </div>
                                    <p className="other-price m-0"
                                       dangerouslySetInnerHTML={{__html: selectedPlan?.other_desc}}></p>
                                </div>
                                <ul>
                                    {selectedPlan?.features?.map(item => (
                                        <li key={item.title} dangerouslySetInnerHTML={{__html: item.title}}></li>
                                    ))}
                                    {selectedPlan?.type === 'yearly' && (
                                        <li className="mt-2"></li>
                                    )}
                                    <button className={style.buttonPricing} onClick={() => {
                                        setSelectedPlan(selectedPlan)
                                        setIsOpenAddCardModal(true)
                                    }}>
                                        Join Now
                                    </button>
                                    <li style={{padding: '0.5rem 0', fontSize: '18px'}}>
                                        <img src={MoneyBackGurantee} width={60} className="mr-2"/>
                                        <img src={PowerByStripe} width="145" height={35}/>
                                    </li>
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* --- Add Credit Card Modal --- */}
            <Modal isOpen={isOpenAddCardModal} className='modal-dialog-centered modal-lg'>
                <ModalHeader toggle={() => {
                    setIsOpenAddCardModal(false)
                    setSaveCardData(null)
                }}>
                    Pay with Credit Card
                </ModalHeader>
                <ModalBody>
                    <Card className="m-0">
                        <CardBody>
                            <Form className="mt-2" onSubmit={creditCardForm.handleSubmit} autoComplete="off">
                                <Row>
                                    <FormGroup tag={Col} md='6'>
                                        <Label htmlFor="owner-name"
                                               className={classnames({
                                                   'is-invalid': creditCardForm.touched.owner_name && creditCardForm.errors.owner_name,
                                                   'form-label': true
                                               })}
                                        >
                                            <span>Card Owner Name</span>
                                            <span className="text-danger"> *</span>
                                        </Label>
                                        <Input onBlur={creditCardForm.handleBlur}
                                               onChange={creditCardForm.handleChange}
                                               value={creditCardForm.values.owner_name}
                                               name="owner_name"
                                               id="owner-name"
                                               placeholder="Card Owner Name"
                                               className={classnames({'is-invalid': creditCardForm.touched.owner_name && creditCardForm.errors.owner_name})}
                                               disabled={saveCreditCardLoading}
                                        />
                                        <div className="validation-err">{creditCardForm.touched.owner_name && creditCardForm.errors.owner_name}</div>
                                    </FormGroup>
                                    <FormGroup tag={Col} md='6'>
                                        <Label htmlFor="card-number"
                                               className={classnames({
                                                   'is-invalid': creditCardForm.touched.card_number && creditCardForm.errors.card_number,
                                                   'form-label': true
                                               })}
                                        >
                                            <span>Card Number</span>
                                            <span className="text-danger"> *</span>
                                        </Label>
                                        <Input type='number'
                                               onBlur={creditCardForm.handleBlur}
                                               onChange={creditCardForm.handleChange}
                                               value={creditCardForm.values.card_number}
                                               name="card_number"
                                               id="card-number"
                                               placeholder="Card Number"
                                               className={classnames({'is-invalid': creditCardForm.touched.card_number && creditCardForm.errors.card_number})}
                                               disabled={saveCreditCardLoading}
                                        />
                                        <div className="validation-err">{creditCardForm.touched.card_number && creditCardForm.errors.card_number}</div>
                                    </FormGroup>
                                    <FormGroup tag={Col} md='6'>
                                        <Label htmlFor="expiry"
                                               className={classnames({
                                                   'is-invalid': creditCardForm.touched.expiry && creditCardForm.errors.expiry,
                                                   'form-label': true
                                               })}
                                        >
                                            <span>Card Expiry</span>
                                            <span className="text-danger"> *</span>
                                        </Label>
                                        <Cleave onBlur={creditCardForm.handleBlur}
                                                onChange={creditCardForm.handleChange}
                                                placeholder='MM/YY'
                                                value={creditCardForm.values.expiry}
                                                name="expiry"
                                                options={{date: true, delimiter: '/', datePattern: ['m', 'y']}}
                                                id='expiry'
                                                className={classnames({'is-invalid': creditCardForm.touched.expiry && creditCardForm.errors.expiry, 'form-control': true})}
                                                disabled={saveCreditCardLoading}
                                        />
                                        <div className="validation-err">{creditCardForm.touched.expiry && creditCardForm.errors.expiry}</div>
                                    </FormGroup>
                                    <FormGroup tag={Col} md='6'>
                                        <Label htmlFor="cvv"
                                               className={classnames({
                                                   'is-invalid': creditCardForm.touched.cvv && creditCardForm.errors.cvv,
                                                   'form-label': true
                                               })}
                                        >
                                            <span>CVV / CVC</span>
                                            <span className="text-danger"> *</span>
                                        </Label>
                                        <Input onBlur={creditCardForm.handleBlur}
                                               onChange={creditCardForm.handleChange}
                                               value={creditCardForm.values.cvv}
                                               name="cvv"
                                               id="cvv"
                                               placeholder="CVV / CVC"
                                               className={classnames({'is-invalid': creditCardForm.touched.cvv && creditCardForm.errors.cvv})}
                                               disabled={saveCreditCardLoading}
                                        />
                                        <div className="validation-err">{creditCardForm.touched.cvv && creditCardForm.errors.cvv}</div>
                                    </FormGroup>
                                    <FormGroup tag={Col} md='6' className="d-flex justify-content-end align-items-center">
                                        <Button.Ripple type='submit' color='primary' disabled={saveCreditCardLoading}>
                                            {saveCreditCardLoading ? (
                                                <span>
                                                            Verifying Card... <Spinner style={{width: '16px', height: '16px'}}/>
                                                        </span>
                                            ) : (
                                                <span>
                                                            Proceed to Checkout <Icon.ArrowRightCircle size="16"/>
                                                        </span>
                                            )}
                                        </Button.Ripple>
                                    </FormGroup>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>

            {/* --- Checkout Modal --- */}
            <Modal isOpen={checkoutModal} className='modal-dialog-centered' size="lg">
                <ModalHeader toggle={() => {
                    setCheckoutModal(false)
                    setSelectedPlan(null)
                    setSaveCardData(null)
                }}>
                    Checkout
                </ModalHeader>
                <ModalBody>
                    <Card className="mb-0">
                        <CardBody>
                            <Form onSubmit={checkoutForm.handleSubmit} autoComplete="off">
                                <Row>
                                    <FormGroup tag={Col} md='12'>
                                        <div className="bg-light p-1">
                                            <CustomInput id="termsAndConditions"
                                                         type='checkbox'
                                                         name='termsAndConditions'
                                                         label='Coupon Code (Optional)'
                                                         onChange={(e) => {
                                                             checkoutForm.setFieldValue('isCoupon', e.target.checked ? 'Yes' : 'No')
                                                         }}
                                                         defaultChecked={false}
                                                         disabled={verifyCouponCodeLoading || activatePlanLoading}
                                            />
                                        </div>
                                    </FormGroup>
                                    {checkoutForm.values.isCoupon === 'Yes' && (
                                        <FormGroup tag={Col} md='12'>
                                            <Input onBlur={checkoutForm.handleBlur}
                                                   onChange={checkoutForm.handleChange}
                                                   value={checkoutForm.values.coupon}
                                                   name="coupon"
                                                   id="card-coupon"
                                                   placeholder="Coupon Code..."
                                                   className={classnames({'is-invalid': checkoutForm.touched.coupon && checkoutForm.errors.coupon})}
                                                   disabled={verifyCouponCodeLoading || activatePlanLoading}
                                            />
                                            <div className="validation-err">{checkoutForm.touched.coupon && checkoutForm.errors.coupon}</div>
                                        </FormGroup>
                                    )}
                                </Row>
                                <div className="bg-light  p-1  mb-1">
                                    <strong>Invoice to Pay</strong>
                                </div>
                                <Table bordered={true} className="mb-1">
                                    <thead>
                                    <tr>
                                        <th width="200">Plan</th>
                                        <td>
                                            {selectedPlan?.name === "Monthly" ? (
                                                <span>Pay Monthly <span>(Commit Annually)</span></span>
                                            ) : selectedPlan?.name === "Annual" ? (
                                                <span>Pay Upfront <span>(Save 10%, Commit Annually)</span></span>
                                            ) : selectedPlan?.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Price</th>
                                        <td>{`$${selectedPlan?.pricing}`}</td>
                                    </tr>
                                    <tr>
                                        <th>Total</th>
                                        <td><strong>{`$${selectedPlan?.pricing}`}</strong></td>
                                    </tr>
                                    </thead>
                                </Table>
                                <div className="bg-light  p-1  mb-1">
                                    <strong>Credit Card Information</strong>
                                </div>
                                <Table bordered={true} className="mb-1">
                                    <thead>
                                    <tr>
                                        <th width="200">Brand</th>
                                        <td>{saveCardData?.brand}</td>
                                    </tr>
                                    <tr>
                                        <th>Name</th>
                                        <td>{saveCardData?.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Ending Number</th>
                                        <td>{saveCardData?.last4}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td className="text-success">Card Verified</td>
                                    </tr>
                                    </thead>
                                </Table>
                                <Row>
                                    <FormGroup tag={Col} md='6' className="d-flex justify-content-end align-items-center">
                                        <Button.Ripple type='submit' color='primary' disabled={verifyCouponCodeLoading || activatePlanLoading}>
                                            {(verifyCouponCodeLoading || activatePlanLoading) ? (
                                                <span>
                                                    <Spinner style={{width: '16px', height: '16px'}}/> Activate Plan...
                                                </span>
                                            ) : (
                                                <span>
                                                    Activate Plan <Icon.ArrowRightCircle size="16"/>
                                                </span>
                                            )}
                                        </Button.Ripple>
                                    </FormGroup>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default PurchasePackages
