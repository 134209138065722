import {UNSUBSCRIBE, USER_BY_TOKEN} from '../../actionTypes'

const initialState = {
    unsubscribeLoading: false,
    unsubscribeFullFill: null,
    unsubscribeError: null,

    userByTokenLoading: false,
    userByTokenFullFill: null,
    userByTokenError: null
}

const unsubscribeReducer = (state = initialState, action) => {
    switch (action.type) {
        case UNSUBSCRIBE.REQUEST:
            return {
                ...state,
                unsubscribeLoading: true,
                unsubscribeFullFill: null,
                unsubscribeError: null
            }
        case UNSUBSCRIBE.SUCCESS:
            return {
                ...state,
                unsubscribeLoading: false,
                unsubscribeFullFill: action.payload,
                unsubscribeError: null
            }
        case UNSUBSCRIBE.ERROR:
            return {
                ...state,
                unsubscribeLoading: false,
                unsubscribeFullFill: null,
                unsubscribeError: action.payload
            }
        case UNSUBSCRIBE.RESET:
            return {
                ...state,
                unsubscribeLoading: false,
                unsubscribeFullFill: null,
                unsubscribeError: null
            }
        // --- User By Token ---
        case USER_BY_TOKEN.REQUEST:
            return {
                ...state,
                userByTokenLoading: true,
                userByTokenFullFill: null,
                userByTokenError: null
            }
        case USER_BY_TOKEN.SUCCESS:
            return {
                ...state,
                userByTokenLoading: false,
                userByTokenFullFill: action.payload,
                userByTokenError: null
            }
        case USER_BY_TOKEN.ERROR:
            return {
                ...state,
                userByTokenLoading: false,
                userByTokenFullFill: null,
                userByTokenError: action.payload
            }
        default:
            return state
    }
}

export default unsubscribeReducer
