// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  subscrioptionList: [],
  subscriptionPlans: [],
  subscriptionSearchedPlans: []
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SUBSCRIPTION_LIST_DATA':
      return {
        ...state,
        subscrioptionList: action.subscriptions
      }
    case 'SUBSCRIPTION_PLANS_DATA':
      return {
        ...state,
        subscriptionPlans: action.subscriptionPlans
      }
    case 'SUBSCRIBED_BRANDS_SEARCH_DATA':
      return {
        ...state,
        subscriptionSearchedPlans: action.searchSubscribedBrands
      }
    default:
      return state
  }
}

export default DataTablesReducer
