import axios from 'axios'
import { API_URL, GENERAL, OUTER_PAGES, NULL } from '../../actionTypes'

export const getFaqsPageData = () => {
  return dispatch => {
    axios.get(`${API_URL}faqs`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    }).then((res) => {
      dispatch({ type: OUTER_PAGES.FAQS, faqsPageInfo: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.OUTER_PAGES_ERROR, error })
    })
  }
}

export const getTermsAndConditionPageData = () => {
  return dispatch => {
    axios.get(`${API_URL}terms-and-condition`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    }).then((res) => {
      dispatch({ type: OUTER_PAGES.TERMS_AND_CONDITION, termsAndConditionPageInfo: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.OUTER_PAGES_ERROR, error })
    })
  }
}

export const getPrivacyPolicyPageData = () => {
  return dispatch => {
    axios.get(`${API_URL}privacy-policy`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    }).then((res) => {
      dispatch({ type: OUTER_PAGES.PRIVACY_POLICY, privacyPolicyPageInfo: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.OUTER_PAGES_ERROR, error })
    })
  }
}

export const getAboutPageData = () => {
  return dispatch => {
    axios.get(`${API_URL}about-us`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    }).then((res) => {
      dispatch({ type: OUTER_PAGES.ABOUT_US, aboutPageInfo: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.OUTER_PAGES_ERROR, error })
    })
  }
}

export const getPurchasePlans = (type) => {
  return dispatch => {
    axios.get(`${API_URL}plan-list/${type}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: OUTER_PAGES.PURCHASE_PLANS, purchasePlans: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.OUTER_PAGES_ERROR, error })
    })
  }
}

export const getBlogList = (isPagination, pageUrl = '') => {
  let endpoint = ''
  isPagination ? endpoint = pageUrl : endpoint = `${API_URL}blogs`
  return dispatch => {
    axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: OUTER_PAGES.BLOGS_LIST, blogs: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.OUTER_PAGES_ERROR, error })
    })
  }
}

export const getBlogDetails = (slug) => {
  return dispatch => {
    axios.get(`${API_URL}blogs-details?slug=${slug}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: OUTER_PAGES.BLOG_DETAILS, blogDetails: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.OUTER_PAGES_ERROR, error })
    })
  }
}

export const submitContactForm = ({ name, email, subject, message, re_captcha_token }) => {
  return dispatch => {
    axios.post(`${API_URL}submit-contact`, {
      name,
      email,
      subject,
      message,
      re_captcha_token
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: OUTER_PAGES.CONTACT_FORM, contactFormRes: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.OUTER_PAGES_ERROR, error })
    })
  }
}

export const addBrandPaymentCard = ({ card_number, owner_name, expiry_month, expiry_year, cvc, re_captcha_token, email }) => {
  return dispatch => {
    axios.post(`${API_URL}brand/save-card`, {
      card_number,
      owner_name,
      expiry_month,
      expiry_year,
      cvc,
      re_captcha_token
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    }).then((res) => {
      dispatch({ type: OUTER_PAGES.ADD_BRAND_PAYMENT_CARD, addBrandPaymentCardRes: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.OUTER_PAGES_ERROR, error })
    })
  }
}

export const nullResponseOuterPages = (property) => {
  return dispatch => {
    switch (property) {
      case NULL.OUTER_PAGES_ADD_BRAND_PAYMENT_CARD:
        dispatch({ type: OUTER_PAGES.ADD_BRAND_PAYMENT_CARD, addBrandPaymentCardRes: null })
        break
      case NULL.CONTACT_FORM:
        dispatch({ type: OUTER_PAGES.CONTACT_FORM, contactFormRes: null })
        break
    }
  }
}
