import FormRoutes from './Forms'
import PagesRoutes from './Pages'
import DashboardRoutes from './Dashboards'
import RDRoutes from './RD'
import AdminRoutes from './Admin'
import BrandRoutes from './Brands'

const TemplateTitle = '%s - RD Link'
const DefaultRoute = ''

const Routes = [
  ...DashboardRoutes,
  ...PagesRoutes,
  ...FormRoutes,
  ...RDRoutes, 
  ...AdminRoutes, 
  ...BrandRoutes
]

export { DefaultRoute, TemplateTitle, Routes }
