import {
    Home,
    MessageCircle,
    CheckSquare,
    Circle,
    Users,
    Trello, Activity
} from 'react-feather'

export default [
    {
        id: 'rdDash',
        title: 'Dashboard',
        icon: <Home size={20}/>,
        navLink: '/dashboard/rd',
        resource: 'rd',
        action: 'manage'
    },
    {
        id: 'messagesRd',
        title: 'Messages',
        icon: <MessageCircle size={20}/>,
        badge: 'danger',
        badgeText: '9',
        navLink: '/rd/apps/chat/chatRd',
        resource: 'rd',
        action: 'manage',
        tourClass: 'msg-tour'
    },
    {
        id: 'viewBrands',
        title: "Brands",
        navLink: '/dashboard/rd/brands',
        resource: 'rd',
        action: 'manage',
        icon: <Users size={20}/>,
        tourClass: 'brands-tour'
    },
    {
        id: 'opportunities',
        title: 'Opportunities',
        icon: <Trello size={20}/>,
        tourClass: 'opportunities-tour',
        children: [
            {
                id: 'viewAllOpportunities',
                title: 'My Opportunities',
                icon: <Circle size={12}/>,
                navLink: '/dashboard/rd/opportunities',
                resource: 'rd',
                action: 'manage'
            },
            {
                id: 'myBrandCampaigns',
                title: 'Brand Campaigns',
                icon: <Circle size={12}/>,
                navLink: '/dashboard/rd/campaigns',
                resource: 'rd',
                action: 'manage'
            }
        ]
    },
    {
        id: 'myPartnerships',
        title: 'Partnerships',
        icon: <CheckSquare size={20}/>,
        tourClass: 'my-partnerships-tour',
        children: [
            {
                id: 'rd-invoices',
                title: 'Invoices',
                icon: <Circle size={12}/>,
                navLink: '/rd/invoice',
                resource: 'rd',
                action: 'manage'
            },
            {
                id: 'managePartnerships',
                title: 'Manage',
                icon: <Circle size={12}/>,
                navLink: '/dashboard/rd/partnerships/manage',
                resource: 'rd',
                action: 'manage'
            },
            {
                id: 'partnershipsPayment',
                title: 'Payments',
                icon: <Circle size={12}/>,
                navLink: '/dashboard/rd/partnerships/payment',
                resource: 'rd',
                action: 'manage'
            },
            {
                id: 'partnershipsFeedback',
                title: 'Feedback',
                icon: <Circle size={12}/>,
                navLink: '/dashboard/rd/partnerships/feedback',
                resource: 'rd',
                action: 'manage'
            }
        ]
    },
    {
        id: 'resourcesRD',
        title: 'Resources',
        icon: <Activity size={20}/>,
        navLink: '/dashboard/rd/resources',
        resource: 'rd',
        action: 'manage',
        badgeText: "",
        badge: 'no-badge',
        tourClass: 'resources-tour'
    }
]