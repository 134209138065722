import axios from 'axios'
import {
    API_URL,
    RD,
    GENERAL,
    NULL,
    RD_MY_PARTNERSHIP,
    RD_STATS,
    REC_BRANDS
} from '../../actionTypes'

const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getAllBrandsAtRD = () => {
    return dispatch => {
        return axios.get(`${API_URL}rd/rd-brand-list`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(res => {
            dispatch({ type: RD.ALL_BRANDS, allbrandsAtRd: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const searchBrandsAtRD = (isPaginate, { value, category, special_diet, name }, pageUrl = '') => {
    let endpoint = ''
    isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}rd/rd-brand-list`
    return dispatch => {
        const filtetData = [...value, ...category, ...special_diet]
        return axios.post(endpoint, {
            filters: filtetData,
            name
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(res => {
            dispatch({ type: RD.ALL_BRANDS, allbrandsAtRd: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

// Zubair
export const getRdProfile = () => {
    return dispatch => {
        return axios.get(`${API_URL}rd/view-profile`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(res => {
            dispatch({ type: RD.VIEW_PROFILE, viewRdProfile: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getRecommendedBrandsAtRdDashboard = (qryString) => async (dispatch) => {
    dispatch({ type: REC_BRANDS.REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}rd/dashboard-brands${qryString}`, {headers: header})
        dispatch({type: REC_BRANDS.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: REC_BRANDS.ERROR, payload: error})
    }
}

export const updateRdProfile = (body) => {
    const formData = new FormData()
    formData.append("email", body.user_email)
    formData.append("name", body.name)
    formData.append("profile_pic", body.profile_pic)
    formData.append("is_rd", body.is_rd)
    formData.append("is_dietetic_intern", body.is_dietetic_intern)
    formData.append("is_other_rd", body.is_other_rd)
    formData.append("website", body.website)
    formData.append("cdr_number", body.cdr_number)
    formData.append("proof_of_certification", body.proof_of_certification)
    formData.append("country_id", body.country_id)
    formData.append("gender", body.gender)
    formData.append("region_id", body.region_id)
    formData.append("state_id", body.state_id)
    formData.append("city_id", body.city_id)
    formData.append("goals", body.goals)
    formData.append("here_about", body.here_about)
    formData.append("primarily_interest", body.primarily_interest)
    // formData.append("values_id", body.values_id)
    // formData.append("special_diet_id", body.special_diet_id)
    // formData.append("category_id", body.category_id)
    formData.append("about_me", body.about_me)
    // formData.append("personal_attributes_id", body.personal_attributes_id)
    // formData.append("industry_id", body.industry_id)
    // formData.append("specialty_id", body.specialty_id)
    // formData.append("facebook_url[]", body.facebook_url)
    // formData.append("instagram_url[]", body.instagram_url)
    formData.append("twitter_url[]", body.twitter_url)
    formData.append("pinterest_url[]", body.pinterest_url)
    formData.append("linkedin_url[]", body.linkedin_url)
    formData.append("tiktok_url[]", body.tiktok_url)
    formData.append("followers", body.followers)
    formData.append("media_documents", body.media_documents)
    formData.append("is_other_hear_about", body.is_other_hear_about)
    formData.append("re_captcha_token", body.re_captcha_token)

    body.facebook_url.forEach(link => {
        formData.append("facebook_url[]", link)
    })

    body.instagram_url.forEach(link => {
        formData.append("instagram_url[]", link)
    })

    body.media_documents.forEach(doc => {
        formData.append("media_documents[]", doc)
    })

    if (body.values_id.length === 0) {
        formData.append("values_id[]", [])
    }

    body.values_id.forEach(id => {
        formData.append("values_id[]", id)
    })

    body.special_diet_id.forEach(id => {
        formData.append("special_diet_id[]", id)
    })

    if (body.category_id.length === 0) {
        formData.append("category_id[]", [])
    }

    body.category_id.forEach(id => {
        formData.append("category_id[]", id)
    })

    body.personal_attributes_id.forEach(id => {
        formData.append("personal_attributes_id[]", id)
    })

    body.industry_id.forEach(id => {
        formData.append("industry_id[]", id)
    })

    body.specialty_id.forEach(id => {
        formData.append("specialty_id[]", id)
    })

    return dispatch => {
        return axios.post(`${API_URL}rd/update-profile`, formData, {
            headers: {
                Accept: "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(res => {
            dispatch({ type: RD.UPDATE_PROFILE, updateRdProfile: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

// Zubair
export const removeMediaFromProfile = (doc_id) => {
    return dispatch => {
        axios.post(`${API_URL}rd/delete-media-document`, {doc_id}, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(res => {
            dispatch({
                type: RD.REMOVE_MEDIA_ON_PROFILE,
                removeMediaAtProfile: res.data
            })
        }).catch((error) => {
            dispatch({
                type: GENERAL.RD_DASHBOARD_ERROR, error
            })
        })
    }
}

export const getBrandDetailsAtRD = (userId) => {
    return dispatch => {
        return axios.get(`${API_URL}rd/view-brand-profile?id=${userId}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(res => {
            dispatch({ type: RD.BRAND_PROFILE, brandProfileAtRd: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getMyBrandsOnRD = (type) => {
    return dispatch => {
        axios.get(`${API_URL}rd/my-brand-list?type=${type}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: RD.MY_BRANDS, myBrands: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const searchMyBrandsOnRD = (isPagination, type, body, pageUrl = '') => {
    let endpoint
    isPagination ? endpoint = `${pageUrl}&type=${type}` : endpoint = `${API_URL}rd/my-brand-list?type=${type}`
    return dispatch => {
        axios.post(endpoint, {
            name: body.name,
            filters: body.filters,
            type: body.type,
            country: body.country,
            region: body.region,
            state: body.state,
            city: body.city
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: RD.MY_BRANDS, myBrands: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getFavoriteFolders = (isPagination, pageUrl = '') => {
    let endpoint = ''
    isPagination ? endpoint = pageUrl : endpoint = `${API_URL}rd/load-folder-list`
    return dispatch => {
        axios.get(endpoint, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: RD.FAVORITE_FOLDERS, favoriteFoldersRes: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const searchFavoriteFolders = ({ searchFolderValue }) => {
    return dispatch => {
        axios.post(`${API_URL}rd/load-folder-list`, {
            folder_name: searchFolderValue
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: RD.FAVORITE_FOLDERS, favoriteFoldersRes: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const createFavoriteFolder = ({ folderName }) => {
    return dispatch => {
        axios.post(`${API_URL}rd/submit-folder`, {
            folder_name: folderName
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: RD.CREATE_FAVORITE_FOLDER, createfavoriteFolderRes: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const updateFavoriteFolder = ({ id, folderName }) => {
    return dispatch => {
        axios.post(`${API_URL}rd/update-folder`, {
            id,
            folder_name: folderName
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: RD.UPDATE_FAVORITE_FOLDER, updatefavoriteFolderRes: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const deleteFavoriteFolder = ({ id }) => {
    return dispatch => {
        axios.post(`${API_URL}rd/delete-folder`, {
            id
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: RD.DELETE_FAVORITE_FOLDER, deletefavoriteFolderRes: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getFavoriteBrandsProfiles = (isPagination, pageUrl = '', folderId) => {
    let endpoint = ''
    isPagination ? endpoint = pageUrl : endpoint = `${API_URL}rd/favorites-profiles?folder_id=${folderId}`
    return dispatch => {
        axios.get(endpoint, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: RD.FAVORITE_BRANDS_PROFILES, favoriteBrandsProfilesRes: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const searchFavoriteBrandsProfiles = (isPagination, pageUrl = '', folderId, body) => {
    let endpoint = ''
    isPagination ? endpoint = `${pageUrl}&folder_id=${folderId}` : endpoint = `${API_URL}rd/favorites-profiles?folder_id=${folderId}`
    return dispatch => {
        axios.post(endpoint, {
            brand_name: body.brand_name,
            filters: body.filters
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: RD.FAVORITE_BRANDS_PROFILES, favoriteBrandsProfilesRes: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.BRANDS_DASHBOARD_ERROR, error })
        })
    }
}

export const getBrandProductAttributes = () => {
    return dispatch => {
        axios.get(`${API_URL}brand-product-attr`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            }
        }).then((res) => {
            dispatch({ type: RD.BRANDS_PRODUCT_ATTRIBUTES, brandProductAttributesRes: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getCountries = () => {
    return dispatch => {
        axios.get(`${API_URL}get-countries`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then((res) => {
            dispatch({ type: RD.COUNTRIES, countries: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getRegionsByCountryId = ({ id }) => {
    return dispatch => {
        axios.post(`${API_URL}get-regions`, {
            country_id: id
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then((res) => {
            dispatch({ type: RD.REGIONS_BY_COUNTRY_ID, regionsByCountryID: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getStatesByRegionId = ({ id }) => {
    return dispatch => {
        axios.post(`${API_URL}get-states`, {
            region_id: id
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then((res) => {
            dispatch({ type: RD.STATES_BY_REGIONS_ID, statesByRegionID: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getCitiesByStateId = ({ id }) => {
    return dispatch => {
        axios.post(`${API_URL}cities`, {
            state_id: [id]
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then((res) => {
            dispatch({ type: RD.CITIES_BY_STATES_ID, citiesByStateID: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getCitiesByStatesIds = ({ statesIds }) => {
    return dispatch => {
        axios.post(`${API_URL}cities`, {
            state_id: statesIds
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then((res) => {
            dispatch({ type: RD.CITIES_BY_STATES_ID, citiesByStateID: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const addToFavorite = ({ id, folder_id, type }) => {
    return dispatch => {
        axios.post(`${API_URL}rd/add-to-favorite`, {
            id,
            folder_id,
            type
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: RD.ADD_TO_FAVORITE, addToFavroiteRes: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.BRANDS_DASHBOARD_ERROR, error })
        })
    }
}

export const getUpcommingOpportunitiesAtProfile = (isPagination, pageUrl = '') => {
    let endpoint
    isPagination ? endpoint = pageUrl : endpoint = `${API_URL}rd/load-upcoming-event-list`
    return dispatch => {
        return axios.get(endpoint, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(res => {
            dispatch({
                type: RD.UPCOMMING_OPPORTUNITIES_LIST_AT_PROFILE,
                upcommingOpportunitiesAtProfile: res.data
            })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getPastOpportunitiesAtProfile = (isPagination, pageUrl = '') => {
    let endpoint
    isPagination ? endpoint = pageUrl : endpoint = `${API_URL}rd/load-past-event-list`
    return dispatch => {
        return axios.get(endpoint, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(res => {
            dispatch({
                type: RD.PAST_OPPORTUNITIES_LIST_AT_PROFILE,
                pastOpportunitiesAtProfile: res.data
            })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getBrandCampaignsAtProfile = (isPagination, pageUrl = '') => {
    let endpoint
    isPagination ? endpoint = pageUrl : endpoint = `${API_URL}rd/my-campaigns`
    return dispatch => {
        return axios.get(endpoint, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(res => {
            dispatch({
                type: RD.BRAND_CAMPAIGNS_AT_PROFILE,
                brandCampaignsAtProfile: res.data
            })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getBrandsByEventId = (id) => {
    return dispatch => {
        axios.get(`${API_URL}rd/get-brands-for-feedback?event_id=${id}`, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => {
            dispatch({
                type: RD.BRANDS_BY_PAST_EVENT_ID,
                brandsByPastEventId: response.data
            })
        }).catch((error) => {
            dispatch({
                type: GENERAL.RD_DASHBOARD_ERROR,
                error
            })
        })
    }
}

export const nullResponse = (property) => {
    return dispatch => {
        switch (property) {
            case NULL.RD_ADD_TO_FAVORITE:
                dispatch({ type: RD.ADD_TO_FAVORITE, addToFavroiteRes: null })
                break
            case NULL.RD_GET_FOLDERS:
                dispatch({ type: RD.FAVORITE_FOLDERS, favoriteFoldersRes: null })
                break
            case NULL.RD_CREATE_FOLDER:
                dispatch({ type: RD.CREATE_FAVORITE_FOLDER, createfavoriteFolderRes: null })
                break
            case NULL.RD_UPDATE_FOLDER:
                dispatch({ type: RD.UPDATE_FAVORITE_FOLDER, updatefavoriteFolderRes: null })
                break
            case NULL.RD_DELETE_FOLDER:
                dispatch({ type: RD.DELETE_FAVORITE_FOLDER, deletefavoriteFolderRes: null })
                break
            case NULL.FAVORITE_BRANDS_PROFILES:
                dispatch({ type: RD.FAVORITE_BRANDS_PROFILES, favoriteBrandsProfilesRes: null })
                break
            case NULL.RD_ALL_BRANDS:
                dispatch({ type: RD.ALL_BRANDS, allbrandsAtRd: null })
                break
            case NULL.RD_MY_BRANDS:
                dispatch({ type: RD.MY_BRANDS, myBrands: null })
                break
            case NULL.RD_PROFILE:
                dispatch({ type: RD.VIEW_PROFILE, viewRdProfile: null })
                break
            case NULL.RD_UPDATE_PROFILE:
                dispatch({ type: RD.UPDATE_PROFILE, updateRdProfile: null })
                break
            case NULL.RD_REMOVE_MEDIA_ON_PROFILE:
                dispatch({ type: RD.REMOVE_MEDIA_ON_PROFILE, removeMediaAtProfile: null })
                break
            case NULL.RD_CHANGE_PASSWORD:
                dispatch({ type: RD.CHANGE_PASSWORD, changePasswordRes: null })
                break
        }
    }
}

export const updateRdPassword = (body) => {
    return dispatch => {
        axios.post(`${API_URL}rd/update-password`, {
            current_password: body.current_password,
            new_password: body.new_password,
            confirm_password: body.confirm_password,
            re_captcha_token: body.re_captcha_token
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: RD.CHANGE_PASSWORD, changePasswordRes: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.ADMIN_DASHBOARD_ERROR, error })
        })
    }
}

export const getRDashboardNotifications = (isPagination, pageUrl = '') => {
    let endpoint
    isPagination ? endpoint = `${pageUrl}` : endpoint = `${API_URL}rd/notifications`
    return dispatch => {
        axios.get(endpoint, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: RD.RD_NOTIFICATIONS, rdNotifications: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getRdDashboardStatsValues = (param) => async (dispatch) => {
    dispatch({type: RD_STATS.REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}rd/dashboard-statistics?stat=${param}`, {headers: header})
        dispatch({type: RD_STATS.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: RD_STATS.ERROR, payload: error})
    }
}

// Zubair
export const getRdUnreadMessages = () => {

    return dispatch => {
        axios.get(`${API_URL}unread-messages`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: RD.RD_UNREAD_MESSAGES, rdUnreadMessages: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getRdUnreadNotifications = () => {

    return dispatch => {
        axios.get(`${API_URL}unread-notifications`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: RD.RD_UNREAD_NOTIFICATIONS, rdUnreadNotifications: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}

export const getMyBrandPartnerships = (qryString) => async (dispatch) => {
    dispatch({ type: RD_MY_PARTNERSHIP.REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}rd/my-partnerships${qryString}`, {headers: header})
        dispatch({type: RD_MY_PARTNERSHIP.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: RD_MY_PARTNERSHIP.ERROR, payload: error})
    }
}

export const postMyBrandPartnerships = (isPagination, body, pageUrl = '') => {
    let endpoint
    isPagination ? endpoint = pageUrl : endpoint = `${API_URL}rd/my-partnerships`
    return dispatch => {
        axios.post(endpoint, {
            search: body.search
        }, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            }
        }).then((res) => {
            dispatch({ type: RD.MY_PARTNERSHIPS, rdMyPartnerships: res.data })
        }).catch(error => {
            dispatch({ type: GENERAL.RD_DASHBOARD_ERROR, error })
        })
    }
}
