import axios from 'axios'
import {
  API_URL,
  OPPORTUNITIES,
  NULL,
  LOCATION_FROM_ZIP,
  CAMPAIGN_MSG,
  CAMPAIGN_ADD_TO_FAV,
  RD_CARD_INFO_SAVE,
  RD_CREDIT_CARD_INFO,
  RD_CREDIT_CARD_SET_DEFAULT,
  RD_CREDIT_CARD_DELETE,
  RD_MASTER_FEATURES,
  RD_GET_PLAN,
  RD_ACTIVE_PLAN,
  RD_CANCEL_PLAN,
  RD_RESOURCES,
  RD_RESOURCE_CONTENT,
  RD_CHECK_CREDIT_CARD,
  RD_SAVE_PLAN_INFO,
  RD_LOAD_UPCOMING_EVENTS,
  RD_LOAD_PAST_EVENTS,
  VERIFY_EMAIL,
  BRAND_INVOICES,
  PAY_NEW_INVOICE,
  RD_ACCOUNT_SUCCESS,
  RD_ACCOUNT_FAIL,
  RD_ACCOUNT_DELETE,
  RD_ACCOUNT_CREATE,
  RD_ACCOUNT_DETAILS,
  RD_ACCOUNT_VERIFY,
  RD_ACCOUNT_MARK_DEFAULT,
  UPLOAD_BRAND_AGREEMENT,
  BRAND_CREDIT_CARDS,
  BRAND_BANK_ACCOUNTS,
  BRAND_INVOICE_DETAILS,
  BRAND_SUBMIT_INVOICE,
  BRAND_UPCOMING_PARTNERSHIPS,
  BRAND_PAST_PARTNERSHIPS,
  RD_CREATE_OPPORTUNITY,
  RD_GET_OPPORTUNITY,
  RD_UPDATE_OPPORTUNITY,
  RD_DELETE_OPPORTUNITY_DOC,
  BRAND_CAMPAIGNS_LISTING,
  BRAND_CREATE_CAMPAIGN,
  BRAND_EDIT_CAMPAIGN,
  BRAND_CAMPAIGN_QUESTIONS,
  BRAND_CAMPAIGN_UPDATE,
  BRAND_CAMPAIGN_DELETE,
  BRAND_CAMPAIGN_STATUS,
  BRAND_CAMPAIGN_LIVE,
  BRAND_CAMPAIGN_FORM,
  BRAND_OPP_DETAILS,
  BRAND_OPP_PARTICIPATE,
  BRAND_CAMPAIGNS_ALL_LISTING,
  INVITE_CAMPAIGN_ON_EMAIL,
  INVITE_CAMPAIGN_ON_MSG,
  CAMP_APPLICANT_LIST
} from '../actionType/index'

// ** Get Token
const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getOpportunitiesListing = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}brand/upcoming-events`
  return dispatch => {
    axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.OPPORTUNITIES_LISTING,
        opportunitiesData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const searchOpportunities = (isPagination, body, pageUrl = '') => {
  let endpoint = ''
  isPagination ? endpoint = pageUrl : endpoint = `${API_URL}brand/upcoming-events`
  return dispatch => {
    axios.post(endpoint, {
      from_date: body.from_date,
      to_date: body.to_date,
      country_id: body.country,
      region_id: body.region,
      state_id: body.state,
      city_id: body.city,
      event_type: body.event_type,
      audience_type: body.audience_type,
      txt_search: body.name
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.OPPORTUNITIES_LISTING,
        opportunitiesData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const getUpcommingOpportunitiesListing = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}rd/load-upcoming-event-list`
  return dispatch => {
    axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.UPCOMMING_LISTING,
        upcommingOpportunitiesData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const getViewFeedback = (eventId, brandId) => {
  return dispatch => {
    axios.get(`${API_URL}rd/view-feedback?event_id=${eventId}&user_id=${brandId}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.FEEDBACK_DETAIL,
        feedbackDetail: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const getOpportunityDetail = (id) => async (dispatch) => {
  dispatch({type: BRAND_OPP_DETAILS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${API_URL}brand/get-event-details/${id}`, {headers: header})
    dispatch({type: BRAND_OPP_DETAILS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_OPP_DETAILS.ERROR, payload: error})
  }
}

export const participateToOpportunity = (dataObj) => async (dispatch) => {
  dispatch({type: BRAND_OPP_PARTICIPATE.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}brand/send-participate-request`, dataObj, {headers: header})
    dispatch({type: BRAND_OPP_PARTICIPATE.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_OPP_PARTICIPATE.ERROR, payload: error})
  }
}

export const participateToOpportunityReset = () => async (dispatch) => {
  dispatch({type: BRAND_OPP_PARTICIPATE.RESET})
}

export const getRdDetail = (id) => {
  return dispatch => {
    axios.get(`${API_URL}brand/view-rd-profile?id=${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.RD_DETAIL,
        opportunityRdDetail: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const createNewOpportunity = (dataObj) => async (dispatch) => {
  dispatch({type: RD_CREATE_OPPORTUNITY.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}rd/rd-create-opportunity`, dataObj, {headers: header})
    dispatch({type: RD_CREATE_OPPORTUNITY.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_CREATE_OPPORTUNITY.ERROR, payload: error})
  }
}

export const createNewOpportunityReset = (dataObj) => async (dispatch) => {
  dispatch({type: RD_CREATE_OPPORTUNITY.RESET})
}

export const updateNewOpportunity = (dataObj) => async (dispatch) => {
  dispatch({type: RD_UPDATE_OPPORTUNITY.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}rd/update-event`, dataObj, {headers: header})
    dispatch({type: RD_UPDATE_OPPORTUNITY.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_UPDATE_OPPORTUNITY.ERROR, payload: error})
  }
}

export const updateNewOpportunityReset = () => async (dispatch) => {
  dispatch({type: RD_UPDATE_OPPORTUNITY.RESET})
}

export const uploadAgrement = (dataObj) => async (dispatch) => {
  dispatch({type: UPLOAD_BRAND_AGREEMENT.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}brand/add-agreement`, dataObj, {headers: header})
    dispatch({type: UPLOAD_BRAND_AGREEMENT.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: UPLOAD_BRAND_AGREEMENT.ERROR, payload: error})
  }
}

export const uploadAgrementReset = (dataObj) => async (dispatch) => {
  dispatch({type: UPLOAD_BRAND_AGREEMENT.RESET})
}

export const removeListingOpportunity = (id) => {
  return dispatch => {
    const formData = new FormData()
    formData.append("event_id", id)
    axios.post(`${API_URL}rd/delete-event`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.DELETE_LISTING_OPPORTUNITY,
        delListingOpportunity: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const getPastEvents = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}rd/load-past-event-list`
  return dispatch => {
    axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.PAST_EVENT_LISTING,
        pastEvents: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const getEditOpportunityDetail = (qryString) => async (dispatch) => {
  dispatch({type: RD_GET_OPPORTUNITY.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${API_URL}rd/event-detail${qryString}`, {headers: header})
    dispatch({type: RD_GET_OPPORTUNITY.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_GET_OPPORTUNITY.ERROR, payload: error})
  }
}

export const getEditOpportunityDetailReset = () => async (dispatch) => {
  dispatch({type: RD_GET_OPPORTUNITY.RESET})
}

export const deleteEditDocument = (dataObj) => async (dispatch) => {
  dispatch({type: RD_DELETE_OPPORTUNITY_DOC.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}rd/delete-event-document`, dataObj, {headers: header})
    dispatch({type: RD_DELETE_OPPORTUNITY_DOC.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_DELETE_OPPORTUNITY_DOC.ERROR, payload: error})
  }
}

export const deleteEditDocumentReset = () => async (dispatch) => {
  dispatch({type: RD_DELETE_OPPORTUNITY_DOC.RESET})
}

export const getBrandsOfEvent = (id) => {
  // export const getBrandsOfEvent = (isPaginate, pageUrl = '') => {
  // let endpoint = ''
  // isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}rd/load-past-event-list`
  return dispatch => {
    axios.get(`${API_URL}rd/get-brands-for-feedback?event_id=${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.EVENT_BRANDS,
        eventBrands: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const getBrandsOfEventDetail = (eventId, brandId) => {
  return dispatch => {
    axios.get(`${API_URL}rd/event-feedback-form?event_id=${eventId}&user_id=${brandId}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.EVENT_BRANDS_DETAIL,
        eventBrandsDetail: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const getCountries = () => {
  return dispatch => {
    axios.get(`${API_URL}get-countries`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.COUNTRIES_LIST,
        countries: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const getRegions = (country_id) => {
  return dispatch => {
    const formData = new FormData()
    if (country_id) {
      formData.append('country_id', country_id)
    }
    axios.post(`${API_URL}get-regions`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.REGIONS_LIST,
        regions: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}
 
export const getStates = (region_id) => {
  return dispatch => {
    const formData = new FormData()
    if (region_id) {
      formData.append('region_id', region_id)
    }
    axios.post(`${API_URL}get-states`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.STATES_LIST,
        states: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const getCities = (state_id) => {
  return dispatch => {
    const formData = new FormData()
    if (state_id) {
      formData.append('state_id[]', state_id)
    }
    axios.post(`${API_URL}cities`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.CITIES_LIST,
        cities: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const getMultipleCities = (dataObj) => async (dispatch) => {
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}cities`, dataObj, {headers: header})
    dispatch({type: OPPORTUNITIES.CITIES_LIST, cities: data})
  } catch (error) {
    dispatch({type: OPPORTUNITIES.ERROR, payload: error})
  }
}

export const locationFromZipCodeAction = (dataObj) => async (dispatch) => {
  dispatch({ type: LOCATION_FROM_ZIP.REQUEST})
  const header = {
    Accept: "application/json"
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}get-location-from-zip`, dataObj, {headers: header})
    dispatch({type: LOCATION_FROM_ZIP.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: LOCATION_FROM_ZIP.ERROR, payload: error.message})
  }
}

export const locationFromZipCodeActionReset = () => async (dispatch) => {
  dispatch({ type: LOCATION_FROM_ZIP.RESET})
}

export const campaignMsgAction = (dataObj) => async (dispatch) => {
  dispatch({ type: CAMPAIGN_MSG.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}brand/save-bulk-msg`, dataObj, {headers: header})
    dispatch({type: CAMPAIGN_MSG.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: CAMPAIGN_MSG.ERROR, payload: error.message})
  }
}

export const campaignMsgResetAction = () => async (dispatch) => {
  dispatch({ type: CAMPAIGN_MSG.RESET})
}

export const campaignAddToFavAction = (dataObj) => async (dispatch) => {
  dispatch({ type: CAMPAIGN_ADD_TO_FAV.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}brand/add-to-fav-from-campaign`, dataObj, {headers: header})
    dispatch({type: CAMPAIGN_ADD_TO_FAV.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: CAMPAIGN_ADD_TO_FAV.ERROR, payload: error.message})
  }
}

export const campaignAddToFavResetAction = () => async (dispatch) => {
  dispatch({ type: CAMPAIGN_ADD_TO_FAV.RESET})
}

export const getEventTypes = () => {
  return dispatch => {
    axios.get(`${API_URL}get-event-type`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.EVENT_TYPE_LIST,
        eventTypes: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const getAudienceType = () => {
  return dispatch => {
    axios.get(`${API_URL}get-audience-type`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.AUDIENCE_TYPE_LIST,
        audienceType: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const submitFeedBack = (params) => {
  return dispatch => {
    const formData = new FormData()
    formData.append("event_id", params.event_id)
    formData.append("participate_request_id", params.participate_request_id)
    formData.append("description", params.description)
    formData.append("overall_response", params.overall_response)
    formData.append("helpful", params.helpful)
    formData.append("social_media_url", params.social_media_url)
    formData.append("no_of_attendees", params.no_of_attendees)
    formData.append("impressions", params.impressions)
    formData.append("shared_sample", params.shared_sample)
    formData.append("re_captcha_token", params.re_captcha_token)
    
    params.feedback_media?.forEach(element => {
      formData.append("feedback_media[]", element)
    })

    axios.post(`${API_URL}rd/submit-feedback-form`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.PROVIDE_FEEDBACK,
        providedFeedback: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

// ** GET Products
export const getProducts = params => {
  return dispatch => {
    return dispatch({ type: 'GET_PRODUCTS', data: [], params: null })
    // return axios.get('/apps/opportunitiesBrands/products', { params }).then(res => {
    //   dispatch({ type: 'GET_PRODUCTS', data: res.data, params })
    // })
  }
}

// ** Add Item to Cart
export const addToCart = id => {
  return (dispatch, getState) => {
    return axios.post('/apps/opportunitiesBrands/cart', { productId: id }).then(res => {
      dispatch({ type: 'ADD_TO_CART', data: res.data })
      dispatch(getProducts(getState().ecommerce.params))
    })
  }
}

// ** GET Wishlist Items
export const getWishlistItems = () => {
  return dispatch => {
    return axios.get('/apps/opportunitiesBrands/wishlist').then(res => {
      dispatch({ type: 'GET_WISHLIST', data: res.data })
    })
  }
}

// ** DELETE Wishlist Item
export const deleteWishlistItem = id => {
  return dispatch => {
    return axios.delete(`/apps/opportunitiesBrands/wishlist/${id}`).then(res => {
      dispatch({ type: 'DELETE_WISHLIST_ITEM', data: res.data })
      dispatch(getWishlistItems())
    })
  }
}

// ** GET Cart Items
export const getCartItems = () => {
  return dispatch => {
    return axios.get('/apps/opportunitiesBrands/cart').then(res => {
      dispatch({ type: 'GET_CART', data: res.data })
    })
  }
}

// ** GET Single Product
export const getProduct = slug => {
  return dispatch => {
    return dispatch({ type: 'GET_PRODUCT', data: [] })
    // return axios.get(`/apps/opportunitiesBrands/products/${slug}`).then(res => {
    //   dispatch({ type: 'GET_PRODUCT', data: res.data })
    // })
  }
}

// ** Add Item to Wishlist
export const addToWishlist = id => {
  return dispatch => {
    return axios.post('/apps/opportunitiesBrands/wishlist', { productId: id }).then(() => {
      dispatch({ type: 'ADD_TO_WISHLIST' })
    })
  }
}

// ** DELETE Cart Items
export const deleteCartItem = id => {
  return dispatch => {
    return axios.delete(`/apps/opportunitiesBrands/cart/${id}`).then(res => {
      dispatch({ type: 'DELETE_CART_ITEM', data: res.data })
      dispatch(getCartItems())
    })
  }
}

export const submitContactUs = (params) => {
  return dispatch => {
    const formData = new FormData()
    formData.append('subject', params.subject)
    formData.append('message', params.message)
    axios.post(`${API_URL}brand/submit-contact`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.CONTACT_US,
        contactUs: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const createAccount = (dataObj) => async (dispatch) => {
  dispatch({ type: RD_ACCOUNT_CREATE.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}rd/create-bank-account`, dataObj, {headers: header})
    dispatch({type: RD_ACCOUNT_CREATE.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_ACCOUNT_CREATE.ERROR, payload: error})
  }
}

export const createAccountReset = () => async (dispatch) => {
  dispatch({ type: RD_ACCOUNT_CREATE.RESET})
}

export const getAccountDetails = () => async (dispatch) => {
  dispatch({ type: RD_ACCOUNT_DETAILS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${process.env.REACT_APP_BASEURL}rd/list-bank-account`, {headers: header})
    dispatch({type: RD_ACCOUNT_DETAILS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_ACCOUNT_DETAILS.ERROR, payload: error})
  }
}

export const onAccountVerificationSuccess = (dataObj) => async (dispatch) => {
  dispatch({ type: RD_ACCOUNT_SUCCESS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}rd/stripe-account-success`, dataObj, {headers: header})
    dispatch({type: RD_ACCOUNT_SUCCESS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_ACCOUNT_SUCCESS.ERROR, payload: error})
  }
}

export const onAccountVerificationSuccessReset = () => async (dispatch) => {
  dispatch({ type: RD_ACCOUNT_SUCCESS.RESET})
}

export const onAccountVerificationError = (dataObj) => async (dispatch) => {
  dispatch({ type: RD_ACCOUNT_FAIL.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}rd/stripe-account-failure`, dataObj, {headers: header})
    dispatch({type: RD_ACCOUNT_FAIL.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_ACCOUNT_FAIL.ERROR, payload: error})
  }
}

export const onAccountVerificationErrorReset = () => async (dispatch) => {
  dispatch({ type: RD_ACCOUNT_FAIL.RESET})
}

export const getVerificationLink = () => async (dispatch) => {
  dispatch({ type: RD_ACCOUNT_VERIFY.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${process.env.REACT_APP_BASEURL}rd/account-verify-link`, {headers: header})
    if (data?.status) {
      const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))
      userData.stripe_account_status = true
      localStorage.setItem(process.env.REACT_APP_USERDATA, JSON.stringify(userData))
  }
    dispatch({type: RD_ACCOUNT_VERIFY.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_ACCOUNT_VERIFY.ERROR, payload: error})
  }
}

export const getVerificationLinkReset = () => async (dispatch) => {
  dispatch({ type: RD_ACCOUNT_VERIFY.RESET})
}

export const deleteAccount = (dataObj) => async (dispatch) => {
  dispatch({ type: RD_ACCOUNT_DELETE.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}rd/delete-bank-account`, dataObj, {headers: header})
    dispatch({type: RD_ACCOUNT_DELETE.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_ACCOUNT_DELETE.ERROR, payload: error})
  }
}

export const deleteAccountReset = () => async (dispatch) => {
  dispatch({ type: RD_ACCOUNT_DELETE.RESET})
}

export const markDefaultAccount = (dataObj) => async (dispatch) => {
  dispatch({ type: RD_ACCOUNT_MARK_DEFAULT.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}rd/mark-default-account`, dataObj, {headers: header})
    dispatch({type: RD_ACCOUNT_MARK_DEFAULT.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_ACCOUNT_MARK_DEFAULT.ERROR, payload: error})
  }
}

export const markDefaultAccountReset = () => async (dispatch) => {
  dispatch({ type: RD_ACCOUNT_MARK_DEFAULT.RESET})
}

export const createCampaings = (dataObj) => async (dispatch) => {
  dispatch({ type: BRAND_CREATE_CAMPAIGN.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}brand/save-campaign`, dataObj, {headers: header})
    dispatch({type: BRAND_CREATE_CAMPAIGN.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_CREATE_CAMPAIGN.ERROR, payload: error})
  }
}

export const createCampaingsReset = () => async (dispatch) => {
  dispatch({ type: BRAND_CREATE_CAMPAIGN.RESET})
}

export const campaingsListing = (params) => async (dispatch) => {
  dispatch({ type: BRAND_CAMPAIGNS_LISTING.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${API_URL}brand/my-campaign${params}`, {headers: header})
    dispatch({type: BRAND_CAMPAIGNS_LISTING.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_CAMPAIGNS_LISTING.ERROR, payload: error})
  }
}

export const campaingsAllListing = (params) => async (dispatch) => {
  dispatch({type: BRAND_CAMPAIGNS_ALL_LISTING.REQUEST})
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${API_URL}brand/my-campaign${params}`, {headers})
    dispatch({type: BRAND_CAMPAIGNS_ALL_LISTING.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_CAMPAIGNS_ALL_LISTING.ERROR, payload: error})
  }
}

export const inviteCampaignOnEmail = (dataObj) => async (dispatch) => {
  dispatch({type: INVITE_CAMPAIGN_ON_EMAIL.REQUEST})
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}brand/invite-on-email`, dataObj, {headers})
    dispatch({type: INVITE_CAMPAIGN_ON_EMAIL.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: INVITE_CAMPAIGN_ON_EMAIL.ERROR, payload: error})
  }
}

export const inviteCampaignOnEmailReset = () => async (dispatch) => {
  dispatch({type: INVITE_CAMPAIGN_ON_EMAIL.RESET})
}

export const inviteCampaignOnMsg = (dataObj) => async (dispatch) => {
  dispatch({type: INVITE_CAMPAIGN_ON_MSG.REQUEST})
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}brand/invite-on-msg`, dataObj, {headers})
    dispatch({type: INVITE_CAMPAIGN_ON_MSG.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: INVITE_CAMPAIGN_ON_MSG.ERROR, payload: error})
  }
}

export const inviteCampaignOnMsgReset = () => async (dispatch) => {
  dispatch({type: INVITE_CAMPAIGN_ON_MSG.RESET})
}

export const getEditCampaingsData = (dataObj) => async (dispatch) => {
  dispatch({ type: BRAND_EDIT_CAMPAIGN.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}brand/load-campaign`, dataObj, {headers: header})
    dispatch({type: BRAND_EDIT_CAMPAIGN.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_EDIT_CAMPAIGN.ERROR, payload: error})
  }
}

export const getEditCampaingsDataReset = () => async (dispatch) => {
  dispatch({ type: BRAND_EDIT_CAMPAIGN.RESET})
}

export const updateCampaingsData = (dataObj) => async (dispatch) => {
  dispatch({ type: BRAND_CAMPAIGN_UPDATE.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}brand/edit-campaign`, dataObj, {headers: header})
    dispatch({type: BRAND_CAMPAIGN_UPDATE.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_CAMPAIGN_UPDATE.ERROR, payload: error})
  }
}

export const updateCampaingsDataReset = () => async (dispatch) => {
  dispatch({ type: BRAND_CAMPAIGN_UPDATE.RESET})
}

export const deleteCampaingsData = (dataObj) => async (dispatch) => {
  dispatch({ type: BRAND_CAMPAIGN_DELETE.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}brand/delete-campaign`, dataObj, {headers: header})
    dispatch({type: BRAND_CAMPAIGN_DELETE.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_CAMPAIGN_DELETE.ERROR, payload: error})
  }
}

export const deleteCampaingsDataReset = () => async (dispatch) => {
  dispatch({ type: BRAND_CAMPAIGN_DELETE.RESET})
}

export const campaingsSearching = (isPagination, { searchInputValue }, pageUrl = '') => {
  let endpoint = ''
  isPagination ? endpoint = pageUrl : endpoint = `${API_URL}brand/my-campaign`
  return dispatch => {
    axios.post(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      },
      search: searchInputValue
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.CAMPAIGN_LISTINGS,
        campaingsListingData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const campaingsFormOptions = () => async (dispatch) => {
  dispatch({ type: BRAND_CAMPAIGN_FORM.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${API_URL}brand/campaign-forms`, {headers: header})
    dispatch({type: BRAND_CAMPAIGN_FORM.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_CAMPAIGN_FORM.ERROR, payload: error})
  }
}

export const campaingsFormOptionsReset = () => async (dispatch) => {
  dispatch({ type: BRAND_CAMPAIGN_FORM.RESET})
}

export const getCampaignsDetail = (params) => async (dispatch) => {
  dispatch({type: CAMP_APPLICANT_LIST.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${API_URL}brand/my-campaign-details${params}`, {headers: header})
    dispatch({type: CAMP_APPLICANT_LIST.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: CAMP_APPLICANT_LIST.ERROR, payload: error})
  }
}

export const getCampaignsDetailForExport = (id) => {
  return dispatch => {
    axios.get(`${API_URL}brand/my-campaign-details?campaign_id=${id}&export=all`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.CAMPAIGN_EXPORT,
        campaignDetailExport: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const brandUpcomingPartnerships = (qryString) => async (dispatch) => {
  dispatch({type: BRAND_UPCOMING_PARTNERSHIPS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${API_URL}brand/load-partnership-list${qryString}`, {headers: header})
    dispatch({type: BRAND_UPCOMING_PARTNERSHIPS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_UPCOMING_PARTNERSHIPS.ERROR, payload: error})
  }
}

export const brandPastPartnerships = (qryString) => async (dispatch) => {
  dispatch({type: BRAND_PAST_PARTNERSHIPS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${API_URL}brand/load-past-partnership-list${qryString}`, {headers: header})
    dispatch({type: BRAND_PAST_PARTNERSHIPS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_PAST_PARTNERSHIPS.ERROR, payload: error})
  }
}

export const getPaymentDetailData = (dataObj) => async (dispatch) => {
  dispatch({ type: BRAND_INVOICE_DETAILS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}brand/invoice-details`, dataObj, {headers: header})
    dispatch({type: BRAND_INVOICE_DETAILS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_INVOICE_DETAILS.ERROR, payload: error})
  }
}

export const submitAmount = (dataObj) => async (dispatch) => {
  dispatch({ type: BRAND_SUBMIT_INVOICE.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}brand/pay-invoice`, dataObj, {headers: header})
    dispatch({type: BRAND_SUBMIT_INVOICE.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_SUBMIT_INVOICE.ERROR, payload: error})
  }
}

export const submitAmountReset = () => async (dispatch) => {
  dispatch({ type: BRAND_SUBMIT_INVOICE.RESET})
}

export const getInvoiceDetail = (request_id) => {
  return dispatch => {

    axios.post(`${API_URL}brand/get-invoice`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      },
      request_id
    }).then(response => {
      dispatch({
        type: OPPORTUNITIES.INVOICE_DETAIL,
        invoiceDetailData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: OPPORTUNITIES.ERROR,
        error
      })
    })
  }
}

export const addPartnershipNote = (parmas) => {
  return dispatch => {
    axios.post(`${API_URL}brand/notes`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      },
      id: parmas.id,
      notes: parmas.notes
    }).then((res) => {
      dispatch({ type: OPPORTUNITIES.ADD_NOTE, addedNote: res.data })
    }).catch(error => {
      dispatch({ type: OPPORTUNITIES.ERROR, error })
    })
  }
}

export const getCreateCampaignFormQuestions = (dataObj) => async (dispatch) => {
  dispatch({ type: BRAND_CAMPAIGN_QUESTIONS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}brand/form-questions`, dataObj, {headers: header})
    dispatch({type: BRAND_CAMPAIGN_QUESTIONS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_CAMPAIGN_QUESTIONS.ERROR, payload: error})
  }
}

export const getCreateCampaignFormQuestionsReset = () => async (dispatch) => {
  dispatch({ type: BRAND_CAMPAIGN_QUESTIONS.RESET})
}

export const updateCampaignStatus = (dataObj) => async (dispatch) => {
  dispatch({ type: BRAND_CAMPAIGN_STATUS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}brand/campaign-status`, dataObj, {headers: header})
    dispatch({type: BRAND_CAMPAIGN_STATUS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_CAMPAIGN_STATUS.ERROR, payload: error})
  }
}

export const updateCampaignStatusReset = () => async (dispatch) => {
  dispatch({ type: BRAND_CAMPAIGN_STATUS.RESET})
}

export const updateCampaignLiveStatus = (dataObj) => async (dispatch) => {
  dispatch({ type: BRAND_CAMPAIGN_LIVE.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}brand/live-campaign`, dataObj, {headers: header})
    dispatch({type: BRAND_CAMPAIGN_LIVE.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_CAMPAIGN_LIVE.ERROR, payload: error})
  }
}

export const updateCampaignLiveStatusReset = () => async (dispatch) => {
  dispatch({ type: BRAND_CAMPAIGN_LIVE.RESET})
}

export const nullResponse = (property) => {
  return dispatch => {
    switch (property) {
      case NULL.ADD_NOTE:
        dispatch({ type: OPPORTUNITIES.ADD_NOTE, addedNote: null })
        break
      case NULL.CAMPAIGN_LISTINGS:
        dispatch({ type: OPPORTUNITIES.CAMPAIGN_LISTINGS, campaingsListingData: null })
        break
      case NULL.DELETE_ACCOUNT:
        dispatch({ type: OPPORTUNITIES.DELETE_ACCOUNT, accountDelete: null })
        break
      case NULL.SUBMIT_BANK_CARD:
        dispatch({ type: OPPORTUNITIES.SUBMIT_BANK_CARD, submitBankCard: null })
        break
      case NULL.CAMPAIGN_EDIT_DATA:
        dispatch({ type: OPPORTUNITIES.CAMPAIGN_EDIT_DATA, campaingsEditData: null })
        break
      case NULL.CAMPAIGN_UPDATE_DATA:
        dispatch({ type: OPPORTUNITIES.CAMPAIGN_UPDATE_DATA, campaingsUpdatedData: null })
        break
      case NULL.CAMPAIGN_DELETE_DATA:
        dispatch({ type: OPPORTUNITIES.CAMPAIGN_DELETE_DATA, campaingsDeletedData: null })
        break
      case NULL.CREATING_CAMPAIGN:
        dispatch({ type: OPPORTUNITIES.CREATING_CAMPAIGN, creatingCampaing: null })
        break
      case NULL.SUBMIT_PAYMENT:
        dispatch({ type: OPPORTUNITIES.SUBMIT_PAYMENT, submentPaymentAmount: null })
        break
      case NULL.UPDATE_OPPORTUNITY:
        dispatch({ type: OPPORTUNITIES.UPDATE_OPPORTUNITY, updateOpportunity: null })
        break
      case NULL.OPPO_VERIFICATION_LINK:
        dispatch({
          type: OPPORTUNITIES.VERIFICATION_LINK,
          bankAccountVerificationLink: null
        })
        break
      case NULL.EDIT_OPPORTUNITY_DETAIL:
        dispatch({ type: OPPORTUNITIES.EDIT_DETAIL, editDetail: null })
        break
      case NULL.BRAND_OPPORTUNITIES_UPLOAD_AGREEMENT:
        dispatch({
          type: OPPORTUNITIES.UPLOAD_AGREEMENT,
          uploadAgreementResponse: null
        })
        break
      case NULL.BRAND_OPPORTUNITIES_PROVIDE_FEEDBACK:
        dispatch({
          type: OPPORTUNITIES.PROVIDE_FEEDBACK,
          providedFeedback: null
        })
        break
    }
  }
}

export const nullResponseOppoBrands = (property) => {
  return dispatch => {
    switch (property) {
      case NULL.ADD_NOTE:
        dispatch({ type: OPPORTUNITIES.ADD_NOTE, addedNote: null })
        break
      case NULL.CAMPAIGN_LISTINGS:
        dispatch({ type: OPPORTUNITIES.CAMPAIGN_LISTINGS, campaingsListingData: null })
        break
      case NULL.DELETE_ACCOUNT:
        dispatch({ type: OPPORTUNITIES.DELETE_ACCOUNT, accountDelete: null })
        break
      case NULL.SUBMIT_BANK_CARD:
        dispatch({ type: OPPORTUNITIES.SUBMIT_BANK_CARD, submitBankCard: null })
        break
      case NULL.CAMPAIGN_EDIT_DATA:
        dispatch({ type: OPPORTUNITIES.CAMPAIGN_EDIT_DATA, campaingsEditData: null })
        break
      case NULL.CAMPAIGN_UPDATE_DATA:
        dispatch({ type: OPPORTUNITIES.CAMPAIGN_UPDATE_DATA, campaingsUpdatedData: null })
        break
      case NULL.CAMPAIGN_DELETE_DATA:
        dispatch({ type: OPPORTUNITIES.CAMPAIGN_DELETE_DATA, campaingsDeletedData: null })
        break
      case NULL.CREATING_CAMPAIGN:
        dispatch({ type: OPPORTUNITIES.CREATING_CAMPAIGN, creatingCampaing: null })
        break
      case NULL.SUBMIT_PAYMENT:
        dispatch({ type: OPPORTUNITIES.SUBMIT_PAYMENT, submentPaymentAmount: null })
        break
      case NULL.UPDATE_OPPORTUNITY:
        dispatch({ type: OPPORTUNITIES.UPDATE_OPPORTUNITY, updateOpportunity: null })
        break
      case NULL.OPPO_VERIFICATION_LINK:
        dispatch({
          type: OPPORTUNITIES.VERIFICATION_LINK,
          bankAccountVerificationLink: null
        })
        break
      case NULL.EDIT_OPPORTUNITY_DETAIL:
        dispatch({ type: OPPORTUNITIES.EDIT_DETAIL, editDetail: null })
        break
      case NULL.BRAND_OPPORTUNITIES_UPLOAD_AGREEMENT:
        dispatch({
          type: OPPORTUNITIES.UPLOAD_AGREEMENT,
          uploadAgreementResponse: null
        })
        break
      case NULL.BRAND_OPPORTUNITIES_PROVIDE_FEEDBACK:
        dispatch({
          type: OPPORTUNITIES.PROVIDE_FEEDBACK,
          providedFeedback: null
        })
        break
    }
  }
}

export const saveCardInfoRDAction = (dataObj) => async (dispatch) => {
  dispatch({ type: RD_CARD_INFO_SAVE.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}rd/save-credit-card`, dataObj, {headers: header})
    dispatch({type: RD_CARD_INFO_SAVE.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_CARD_INFO_SAVE.ERROR, payload: error.message})
  }
}

export const saveCardInfoRDResetAction = () => async (dispatch) => {
  dispatch({ type: RD_CARD_INFO_SAVE.RESET})
}

export const creditCardInfoRDAction = () => async (dispatch) => {
  dispatch({ type: RD_CREDIT_CARD_INFO.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${process.env.REACT_APP_BASEURL}rd/credit-cards`, {headers: header})
    dispatch({type: RD_CREDIT_CARD_INFO.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_CREDIT_CARD_INFO.ERROR, payload: error.message})
  }
}

export const creditCardSetDefaultRDAction = (dataObj) => async (dispatch) => {
  dispatch({type: RD_CREDIT_CARD_SET_DEFAULT.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}rd/set-default-card`, dataObj, {headers: header})
    dispatch({type: RD_CREDIT_CARD_SET_DEFAULT.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_CREDIT_CARD_SET_DEFAULT.ERROR, payload: error.message})
  }
}

export const creditCardSetDefaultRDResetAction = () => async (dispatch) => {
  dispatch({ type: RD_CREDIT_CARD_SET_DEFAULT.RESET})
}

export const creditCardDeleteRDAction = (dataObj) => async (dispatch) => {
  dispatch({type: RD_CREDIT_CARD_DELETE.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}rd/delete-card`, dataObj, {headers: header})
    dispatch({type: RD_CREDIT_CARD_DELETE.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_CREDIT_CARD_DELETE.ERROR, payload: error.message})
  }
}

export const creditCardDeleteRDResetAction = () => async (dispatch) => {
  dispatch({ type: RD_CREDIT_CARD_DELETE.RESET})
}

export const masterFeaturesRDAction = () => async (dispatch) => {
  dispatch({type: RD_MASTER_FEATURES.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${process.env.REACT_APP_BASEURL}rd/plan-features`, {headers: header})
    dispatch({type: RD_MASTER_FEATURES.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_MASTER_FEATURES.ERROR, payload: error.message})
  }
}

export const getPlanRDAction = (dataObj) => async (dispatch) => {
  dispatch({type: RD_GET_PLAN.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}rd/get-plan`, dataObj, {headers: header})
    dispatch({type: RD_GET_PLAN.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_GET_PLAN.ERROR, payload: error.message})
  }
}

export const getPlanRDResetAction = () => async (dispatch) => {
  dispatch({ type: RD_GET_PLAN.RESET})
}

export const getActivePlanRDAction = () => async (dispatch) => {
  dispatch({type: RD_ACTIVE_PLAN.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${process.env.REACT_APP_BASEURL}rd/my-plan`, {headers: header})
    dispatch({type: RD_ACTIVE_PLAN.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_ACTIVE_PLAN.ERROR, payload: error.message})
  }
}

export const getCancelPlanRDAction = (dataObj) => async (dispatch) => {
  dispatch({type: RD_CANCEL_PLAN.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}rd/cancel-plan`, dataObj, {headers: header})
    dispatch({type: RD_CANCEL_PLAN.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_CANCEL_PLAN.ERROR, payload: error.message})
  }
}

export const getCancelPlanRDResetAction = () => async (dispatch) => {
  dispatch({ type: RD_CANCEL_PLAN.RESET})
}

export const resourcesRDAction = (dataObj) => async (dispatch) => {
  dispatch({type: RD_RESOURCES.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}rd/resources`, dataObj, {headers: header})
    dispatch({type: RD_RESOURCES.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_RESOURCES.ERROR, payload: error.message})
  }
}

export const resourcesRDResetAction = () => async (dispatch) => {
  dispatch({ type: RD_RESOURCES.RESET})
}

export const resourceContentRDAction = (dataObj) => async (dispatch) => {
  dispatch({type: RD_RESOURCE_CONTENT.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}rd/resource-content`, dataObj, {headers: header})
    dispatch({type: RD_RESOURCE_CONTENT.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_RESOURCE_CONTENT.ERROR, payload: error.message})
  }
}

export const checkCreditCardRDAction = () => async (dispatch) => {
  dispatch({type: RD_CHECK_CREDIT_CARD.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${process.env.REACT_APP_BASEURL}rd/check-rd-card`, {headers: header})
    dispatch({type: RD_CHECK_CREDIT_CARD.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_CHECK_CREDIT_CARD.ERROR, payload: error.message})
  }
}

export const checkCreditCardRDResetAction = () => async (dispatch) => {
  dispatch({ type: RD_CHECK_CREDIT_CARD.RESET})
}

export const saveSelectedPlanRDAction = (dataObj) => async (dispatch) => {
  dispatch({type: RD_SAVE_PLAN_INFO.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}rd/save-selected-plan`, dataObj, {headers: header})
    dispatch({type: RD_SAVE_PLAN_INFO.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_SAVE_PLAN_INFO.ERROR, payload: error.message})
  }
}

export const saveSelectedPlanRDResetAction = () => async (dispatch) => {
  dispatch({ type: RD_SAVE_PLAN_INFO.RESET})
}

export const loadUpcomingEventsRDAction = (dataObj) => async (dispatch) => {
  dispatch({type: RD_LOAD_UPCOMING_EVENTS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}brand/rd-profile-upcoming-events`, dataObj, {headers: header})
    dispatch({type: RD_LOAD_UPCOMING_EVENTS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_LOAD_UPCOMING_EVENTS.ERROR, payload: error.message})
  }
}

export const loadPastEventsRDAction = (dataObj) => async (dispatch) => {
  dispatch({type: RD_LOAD_PAST_EVENTS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}brand/rd-profile-past-events`, dataObj, {headers: header})
    dispatch({type: RD_LOAD_PAST_EVENTS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_LOAD_PAST_EVENTS.ERROR, payload: error.message})
  }
}

export const sendVerifyEmailLinkAction = () => async (dispatch) => {
  dispatch({type: VERIFY_EMAIL.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${process.env.REACT_APP_BASEURL}send-verify-email-link`, {headers: header})
    dispatch({type: VERIFY_EMAIL.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: VERIFY_EMAIL.ERROR, payload: error.message})
  }
}

export const sendVerifyEmailLinkResetAction = () => async (dispatch) => {
  dispatch({type: VERIFY_EMAIL.RESET})
}

export const brandAllInvoicesAction = (dataObj) => async (dispatch) => {
  dispatch({type: BRAND_INVOICES.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}brand/invoices`, dataObj, {headers: header})
    dispatch({type: BRAND_INVOICES.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_INVOICES.ERROR, payload: error.message})
  }
}

export const payNewInvoiceAction = (dataObj) => async (dispatch) => {
  dispatch({type: PAY_NEW_INVOICE.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}brand/pay-new-invoices`, dataObj, {headers: header})
    dispatch({type: PAY_NEW_INVOICE.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: PAY_NEW_INVOICE.ERROR, payload: error.message})
  }
}

export const payNewInvoiceResetAction = () => async (dispatch) => {
  dispatch({type: PAY_NEW_INVOICE.RESET})
}

export const brandCreditCards = () => async (dispatch) => {
  dispatch({type: BRAND_CREDIT_CARDS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${process.env.REACT_APP_BASEURL}brand/credit-cards`, {headers: header})
    dispatch({type: BRAND_CREDIT_CARDS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_CREDIT_CARDS.ERROR, payload: error})
  }
}

export const brandBankAccounts = () => async (dispatch) => {
  dispatch({type: BRAND_BANK_ACCOUNTS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${process.env.REACT_APP_BASEURL}brand/bank-account-details`, {headers: header})
    dispatch({type: BRAND_BANK_ACCOUNTS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_BANK_ACCOUNTS.ERROR, payload: error})
  }
}