// Main
const API_URL = process.env.REACT_APP_BASEURL

// FAQ 
const FAQ = {
    FAQ_DATA: '[ADMIN] FAQ_DATA',
    SEARCH_FAQ_DATA: '[ADMIN] SEARCH_FAQ_DATA',
    ADD_NEW_FAQ_DATA: '[ADMIN] ADD_NEW_FAQ_DATA',
    ADD_NEW_FAQ_DATA: '[ADMIN] ADD_NEW_FAQ_DATA',
    ADD_NEW_FAQ_DATA: '[ADMIN] ADD_NEW_FAQ_DATA',
    ADD_NEW_FAQ_DATA: '[ADMIN] ADD_NEW_FAQ_DATA',
    ERROR: '[ADMIN] ERROR'
}

export { API_URL, FAQ }