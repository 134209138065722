import {
    Mail,
    MessageSquare,
    CheckSquare,
    FileText,
    Circle
} from 'react-feather'

export default [
    {
        id: 'brandsDash',
        title: 'Dashboard',
        icon: <Mail size={20}/>,
        navLink: '/dashboard/brands',
        resource: 'brand',
        action: 'manage'
    },
    {
        id: 'messagesBrands',
        title: 'Messages',
        icon: <FileText size={20}/>,
        badge: 'danger',
        badgeText: '9',
        navLink: '/brands/apps/chat/chatBrands',
        resource: 'brand',
        action: 'manage',
        tourClass: 'messages-brands-tour'
    },
    {
        id: 'nutritionExperts',
        title: "Nutrition Experts",
        icon: <MessageSquare size={20}/>,
        navLink: "/dashboard/brands/nutritions",
        resource: 'brand',
        action: 'manage',
        tourClass: 'nutrition-experts-tour'
    },
    {
        id: 'opportunities',
        title: 'Opportunities',
        icon: <CheckSquare size={20}/>,
        tourClass: 'opportunities-brands-tour',
        children: [
            {
                id: 'favoritesExperts',
                title: 'My Campaigns',
                icon: <Circle size={12}/>,
                navLink: '/dashboard/brands/my-campaigns',
                resource: 'brand',
                action: 'manage'
            },
            {
                id: 'viewAllOpportunities',
                title: 'RD Opportunities',
                icon: <Circle size={12}/>,
                navLink: '/dashboard/brands/opportunities',
                resource: 'brand',
                action: 'manage'
            }
        ]
    },
    {
        id: 'managePartnership',
        title: 'Manage Partnerships',
        icon: <FileText size={20}/>,
        navLink: '/dashboard/brands/manage-partnerships',
        resource: 'brand',
        action: 'manage',
        tourClass: 'manage-partnership-tour'
    }
]
