const UPLOAD_LINK = {
    REQUEST: 'UPLOAD_LINK_REQUEST',
    SUCCESS: 'UPLOAD_LINK_SUCCESS',
    ERROR: 'UPLOAD_LINK_ERROR',
    RESET: 'UPLOAD_LINK_RESET'
}

const RESOURCES_CONTENT = {
    REQUEST: 'RESOURCES_CONTENT_REQUEST',
    SUCCESS: 'RESOURCES_CONTENT_SUCCESS',
    ERROR: 'RESOURCES_CONTENT_ERROR'
}

const RESOURCES_CONTENT_STATUS = {
    REQUEST: 'RESOURCES_CONTENT_STATUS_REQUEST',
    SUCCESS: 'RESOURCES_CONTENT_STATUS_SUCCESS',
    ERROR: 'RESOURCES_CONTENT_STATUS_ERROR',
    RESET: 'RESOURCES_CONTENT_STATUS_RESET'
}

const RESOURCES_CONTENT_DELETE = {
    REQUEST: 'RESOURCES_CONTENT_DELETE_REQUEST',
    SUCCESS: 'RESOURCES_CONTENT_DELETE_SUCCESS',
    ERROR: 'RESOURCES_CONTENT_DELETE_ERROR',
    RESET: 'RESOURCES_CONTENT_DELETE_RESET'
}

const UPLOAD_DOCS = {
    REQUEST: 'UPLOAD_DOCS_REQUEST',
    SUCCESS: 'UPLOAD_DOCS_SUCCESS',
    ERROR: 'UPLOAD_DOCS_ERROR',
    RESET: 'UPLOAD_DOCS_RESET'
}

const UPLOAD_MEDIA = {
    REQUEST: 'UPLOAD_MEDIA_REQUEST',
    SUCCESS: 'UPLOAD_MEDIA_SUCCESS',
    ERROR: 'UPLOAD_MEDIA_ERROR',
    RESET: 'UPLOAD_MEDIA_RESET'
}

export { UPLOAD_LINK, RESOURCES_CONTENT, RESOURCES_CONTENT_STATUS, RESOURCES_CONTENT_DELETE, UPLOAD_DOCS, UPLOAD_MEDIA }