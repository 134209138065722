import React from 'react'
import { Link } from 'react-router-dom'
import style from './style.module.css'
import TrustedBrandsLogo from '../../../assets/images/trustedBrandsLogo.png'

const TrustedBrands = () => {
  return (
    <div className={style.TrustedBrandsWrap}>
      <div className={style.Heading}>
        Build a brand people trust with the
        <br />
        credibility and support of nutrition experts
      </div>
      <div className={style.buttonWrap}>
        <Link to='/createloginrd'>Create Account</Link>
        <Link to='/contact'>Talk to Us</Link>
      </div>

      <div className={style.TextBox}>
        <div className={style.TextHeading}>Trusted by Top CPG Brands</div>
        <img
          className={style.TrustedBrandsLogo}
          src={TrustedBrandsLogo}
          alt='logos'
        />
      </div>
    </div>
  )
}

export default TrustedBrands
