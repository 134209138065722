
// AUTH 
const API_URL = process.env.REACT_APP_BASEURL

const ADMIN_DASHBOARD = {
    MANAGE_RD_LISTING: '[Admin] DASHBOARD_MANAGE_RD_LISTING',
    MANAGE_BRANDS_LISTING: '[Admin] DASHBOARD_MANAGE_BRANDS_LISTING',
    PARTNERSHIP_PAYMENT_LISTING: '[Admin] PARTNERSHIP_PAYMENT_LISTING',
    ERROR: '[Admin] DASHBOARD ERROR'
}


const RD = {
    PARTNERSHIP_PAYMENT_LISTING: '[RD] PARTNERSHIP_PAYMENT_LISTING',
    UPLOAD_AGREEMENT: '[RD] UPLOAD_AGREEMENT',
    SEND_AGREEMENT: '[RD] SEND_AGREEMENT',
    ERROR: '[RD] ERROR'
}

const BRAND = {
    PAYMENT_HISTORY: '[BRAND] PAYMENT_HISTORY',
    RD_PAYMENT_HISTORY: '[BRAND] RD_PAYMENT_HISTORY',
    INVOICE_DETAIL: '[BRAND] INVOICE_DETAIL',
    RD_INVOICE_DETAIL: '[BRAND] RD_INVOICE_DETAIL',
    ERROR: '[BRAND] ERROR'
}

const NULL_PARTNERSHIP = {
    SEND_AGREEMENT: '[NULL] [RD] SEND_AGREEMENT',
    UPLOAD_AGREEMENT: '[NULL] [RD] UPLOAD_AGREEMENT'
}

const UPLOAD_AGREEMENT = {
    REQUEST: "UPLOAD_AGREEMENT_REQUEST",
    SUCCESS: "UPLOAD_AGREEMENT_SUCCESS",
    ERROR: "UPLOAD_AGREEMENT_ERROR",
    RESET: "UPLOAD_AGREEMENT_RESET"
}

const SEND_AGREEMENT = {
    REQUEST: "SEND_AGREEMENT_REQUEST",
    SUCCESS: "SEND_AGREEMENT_SUCCESS",
    ERROR: "SEND_AGREEMENT_ERROR",
    RESET: "SEND_AGREEMENT_RESET"
}

export { ADMIN_DASHBOARD, API_URL, RD, BRAND, NULL_PARTNERSHIP, UPLOAD_AGREEMENT, SEND_AGREEMENT }