import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import style from './style.module.css'
import Logo from '../../../assets/images/logo.png'
import { ReactComponent as Menu } from '../../../assets/images/Menu.svg'
import { handleLogout } from '@store/actions/auth'
import { useDispatch } from 'react-redux'


const Header = () => {
  const [menu, setMenu] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userRole, setUserRole] = useState('')
  const [userInfo, setUserInfo] = useState(null)

  const demoLink = 'https://calendly.com/therdlink/branddemo'

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))

    if (userData) {
      setIsLoggedIn(true)
      setUserRole(userData.role)
      setUserInfo(userData)
    }

  }, [])

  const logout = () => {
    dispatch(handleLogout())
    history.push('/login?target=web')
  }

  return (
    <div className={style.headerWrap} background="home">
      <div className={style.container}>
        <Link to='/'>
          <img className={style.logo} src={Logo} alt='logo' />
        </Link>

        <div className={style.MenuItems}>
          <Link to='/about'>About Us</Link>
          <Link to='/features'>Features</Link>
          <Link to='/pricing'>Pricing</Link>
          <Link to='/blog'>Blog</Link>
          {!isLoggedIn && <span>
            <Link to='/login?target=web'>Log In / </Link>
            <Link to='/createloginrd'>Sign Up</Link>
          </span>}
          {isLoggedIn && <Link onClick={logout}>Logout</Link>}
          {isLoggedIn && userRole === 'admin' && <Link to='/dashboard/admin'>Dashboard</Link>}
          {isLoggedIn && userRole === 'rd' && userInfo?.profile_approved && <Link to='/dashboard/rd'>Dashboard</Link>}
          {isLoggedIn && userRole === 'brand' && !userInfo?.subscriptionexp && <Link to='/dashboard/brands'>Dashboard</Link>}
          <a className={style.headerBtn} href={demoLink} rel="noopener noreferrer" target="_blank">Schedule Demo</a>
          <div className={style.menu} onClick={() => setMenu(!menu)}>
            <Menu />
          </div>
        </div>
      </div>
      {menu && (
        <div className={style.mobileMenu}>
          <Link to='/features'>Features</Link>
          <Link to='/pricing'>Pricing</Link>
          <Link to='/blog'>Blog</Link>
          {!isLoggedIn && <Link to='/createloginrd'>Sign Up</Link>}
          {!isLoggedIn && <Link to='/login?target=web'>Log In</Link>}
          {isLoggedIn && userRole === 'admin' && <Link to='/dashboard/admin'>Dashboard</Link>}
          {isLoggedIn && userRole === 'rd' && userInfo?.profile_approved && <Link to='/dashboard/rd'>Dashboard</Link>}
          {isLoggedIn && userRole === 'brand' && <Link to='/dashboard/brands'>Dashboard</Link>}
        </div>
      )}
    </div>
  )
}

export default Header
