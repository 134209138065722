import {RD, CHANGE_PARTNERSHIP_STATUS, SEND_INVOICE, RD_MANAGE_PARTNERSHIPS} from '../actionTypes/index'

const initialState = {
  products: [],
  wishlist: [],
  cart: [],
  productDetail: {},
  params: {},
  totalProducts: 0,
  brands: null,
  brandDetail: null,
  filters: null,
  searchData: null,

  changePartnershipStatusLoading: false,
  changePartnershipStatusFullFill: null,
  changePartnershipStatusError: null,

  sendInvoiceLoading: false,
  sendInvoiceFullFill: null,
  sendInvoiceError: null,

  managePartnershipsLoading: false,
  managePartnershipsFullFill: null,
  managePartnershipsError: null
}

const ecommerceReducer = (state = initialState, action) => {
  switch (action.type) {
    case RD.BRANDS:
      return { ...state, brands: action.brands }
    case RD.DETAIL:
      return { ...state, brandDetail: action.brandDetail }
    case RD.FILTERS:
      return { ...state, filters: action.filters }   
    case RD.SEARCH_DATA:
      return { ...state, searchData: action.searchData } 
    case RD.ERROR:
      return { ...state, error: action.error }
    case 'GET_PRODUCTS':
    return { ...state, products: action.data.products, params: action.params, totalProducts: action.data.total }
    case 'GET_WISHLIST':
      return { ...state, wishlist: action.data.products }
    case 'DELETE_WISHLIST_ITEM':
      return { ...state }
    case 'GET_CART':
      return { ...state, cart: action.data.products }
    case 'DELETE_CART_ITEM':
      return { ...state }
    case 'ADD_TO_WISHLIST':
      return { ...state }
    case 'ADD_TO_CART':
      return { ...state }
    case 'GET_PRODUCT':
      return { ...state, productDetail: action.data.product }
    // --- Change Partnership Status ---
    case CHANGE_PARTNERSHIP_STATUS.REQUEST:
      return {
        ...state,
        changePartnershipStatusLoading: true,
        changePartnershipStatusFullFill: null,
        changePartnershipStatusError: null
      }
    case CHANGE_PARTNERSHIP_STATUS.SUCCESS:
      return {
        ...state,
        changePartnershipStatusLoading: false,
        changePartnershipStatusFullFill: action.payload,
        changePartnershipStatusError: null
      }
    case CHANGE_PARTNERSHIP_STATUS.ERROR:
      return {
        ...state,
        changePartnershipStatusLoading: false,
        changePartnershipStatusFullFill: null,
        changePartnershipStatusError: action.payload
      }
    case CHANGE_PARTNERSHIP_STATUS.RESET:
      return {
        ...state,
        changePartnershipStatusLoading: false,
        changePartnershipStatusFullFill: null,
        changePartnershipStatusError: false
      }
    // --- Send Invoice ---
    case SEND_INVOICE.REQUEST:
      return {
        ...state,
        sendInvoiceLoading: true,
        sendInvoiceFullFill: null,
        sendInvoiceError: null
      }
    case SEND_INVOICE.SUCCESS:
      return {
        ...state,
        sendInvoiceLoading: false,
        sendInvoiceFullFill: action.payload,
        sendInvoiceError: null
      }
    case SEND_INVOICE.ERROR:
      return {
        ...state,
        sendInvoiceLoading: false,
        sendInvoiceFullFill: null,
        sendInvoiceError: action.payload
      }
    case SEND_INVOICE.RESET:
      return {
        ...state,
        sendInvoiceLoading: false,
        sendInvoiceFullFill: null,
        sendInvoiceError: null
      }
      // --- RD Manage Partnerships ---
    case RD_MANAGE_PARTNERSHIPS.REQUEST:
      return {
        ...state,
        managePartnershipsLoading: true,
        managePartnershipsFullFill: null,
        managePartnershipsError: null
      }
    case RD_MANAGE_PARTNERSHIPS.SUCCESS:
      return {
        ...state,
        managePartnershipsLoading: false,
        managePartnershipsFullFill: action.payload,
        managePartnershipsError: null
      }
    case RD_MANAGE_PARTNERSHIPS.ERROR:
      return {
        ...state,
        managePartnershipsLoading: false,
        managePartnershipsFullFill: null,
        managePartnershipsError: action.payload
      }
      default:
      return state
  }
}

export default ecommerceReducer
