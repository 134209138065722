// Main
const API_URL = process.env.REACT_APP_BASEURL

// BRAND 
const BRAND = {
    MANAGE_BRANDS_DATA: '[ADMIN] MANAGE_BRANDS_DATA',
    MANAGE_BRANDS_DATA_NULL: '[ADMIN] MANAGE_BRANDS_DATA_NULL',
    COUNTRIES_DATA: '[ADMIN] COUNTRIES_DATA',
    STATES_DATA: '[ADMIN] STATES_DATA',
    INDUSTRIES_DATA: '[ADMIN] INDUSTRIES_DATA',
    SEARCH_MANAGE_RD_GET_DATA: '[ADMIN] SEARCH_MANAGE_RD_GET_DATA',
    DELETE_MANAGE_BRAND_GET_DATA: '[ADMIN] DELETE_MANAGE_BRAND_GET_DATA',
    UPDATE_BRAND_STATUS: '[ADMIN] UPDATE_BRAND_STATUS',
    PARTICIPATION_REQUESTS: '[ADMIN] PARTICIPATION_REQUESTS',
    SEARCHED_PARTICIPATION_REQUESTS: '[ADMIN] SEARCHED_PARTICIPATION_REQUESTS',
    ASSIGN_PLAN: '[ADMIN] ASSIGN_PLAN',
    SPECIALITIES_DATA: '[ADMIN] SPECIALITIES_DATA',
    BRAND_PROFILE_DETAIL: '[ADMIN] BRAND_PROFILE_DETAIL',
    EXPORT_BRANDS: '[ADMIN] EXPORT_BRANDS',
    ERROR: '[ADMIN] BRAND_ERROR',
    SECONDARY_USER_REQUEST: '[ADMIN] SECONDARY_USER_REQUEST',
    SECONDARY_USER_SUCCESS: '[ADMIN] SECONDARY_USER_SUCCESS',
    SECONDARY_USER_FAIL: '[ADMIN] SECONDARY_USER_FAIL',
    MAKE_PRIMARY_REQUEST: '[ADMIN] MAKE_PRIMARY_REQUEST',
    MAKE_PRIMARY_SUCCESS: '[ADMIN] MAKE_PRIMARY_SUCCESS',
    MAKE_PRIMARY_FAIL: '[ADMIN] MAKE_PRIMARY_FAIL',
    MAKE_PRIMARY_RESET: '[ADMIN] MAKE_PRIMARY_RESET',
    PROFILE_VIEWS_REQUEST: '[ADMIN] PROFILE_VIEWS_REQUEST',
    PROFILE_VIEWS_SUCCESS: '[ADMIN] PROFILE_VIEWS_SUCCESS',
    PROFILE_VIEWS_FAIL: '[ADMIN] PROFILE_VIEWS_FAIL',
    CAMPAIGN_DATA_REQUEST: '[ADMIN] CAMPAIGN_DATA_REQUEST',
    CAMPAIGN_DATA_SUCCESS: '[ADMIN] CAMPAIGN_DATA_SUCCESS',
    CAMPAIGN_DATA_FAIL: '[ADMIN] CAMPAIGN_DATA_FAIL',
    PROFILE_LINKS_REQUEST: '[ADMIN] PROFILE_LINKS_REQUEST',
    PROFILE_LINKS_SUCCESS: '[ADMIN] PROFILE_LINKS_SUCCESS',
    PROFILE_LINKS_FAIL: '[ADMIN] PROFILE_LINKS_FAIL',
    RECO_VIEWS_REQUEST: '[ADMIN] RECO_VIEWS_REQUEST',
    RECO_VIEWS_SUCCESS: '[ADMIN] RECO_VIEWS_SUCCESS',
    RECO_VIEWS_FAIL: '[ADMIN] RECO_VIEWS_FAIL',
    ACTIVE_BRANDS_REQUEST: '[ADMIN] ACTIVE_BRANDS_REQUEST',
    ACTIVE_BRANDS_SUCCESS: '[ADMIN] ACTIVE_BRANDS_SUCCESS',
    ACTIVE_BRANDS_FAIL: '[ADMIN] ACTIVE_BRANDS_FAIL',
    BRAND_TOTAL_IMP_REQUEST: '[ADMIN] BRAND_TOTAL_IMP_REQUEST',
    BRAND_TOTAL_IMP_SUCCESS: '[ADMIN] BRAND_TOTAL_IMP_SUCCESS',
    BRAND_TOTAL_IMP_FAIL: '[ADMIN] BRAND_TOTAL_IMP_FAIL',
    BRAND_MASS_MSG_REQUEST: '[ADMIN] BRAND_MASS_MSG_REQUEST',
    BRAND_MASS_MSG_SUCCESS: '[ADMIN] BRAND_MASS_MSG_SUCCESS',
    BRAND_MASS_MSG_FAIL: '[ADMIN] BRAND_MASS_MSG_FAIL'
}

const NULL = {
    ADMIN_ASSIGN_PLAN: '[ADMIN] [NULL] ASSIGN_PLAN',
    ADMIN_DELETE_MANAGE_BRAND_GET_DATA: '[ADMIN] [NULL] DELETE_MANAGE_BRAND_GET_DATA'
}

export { API_URL, BRAND, NULL }
