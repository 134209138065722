import { BRANDS } from '../actionType'

const initialState = {
  cardsListingData: null,
  createBankCard: null,
  setCardAsDefaultRes: null,
  deleteCardRes: null,
  error: null
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case BRANDS.CARDS_LISTING:
      return { ...state, cardsListingData: action.cardsListingData }
    case BRANDS.CREATE_NEW_CARD:
      return { ...state, createBankCard: action.createBankCard }
    case BRANDS.SET_CARD_AS_DEFAULT:
      return { ...state, setCardAsDefaultRes: action.setCardAsDefaultRes }
    case BRANDS.DELETE_CARD:
      return { ...state, deleteCardRes: action.deleteCardRes }
    case BRANDS.ERROR:
      return { ...state, error: action.error }
    default:
      return state
  }
}

export default DataTablesReducer
