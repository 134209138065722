import {useState, useEffect, Fragment} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import Cleave from 'cleave.js/react'
import {
    Modal, ModalHeader, ModalFooter, ModalBody,
    Button, Row, Col, FormGroup, Label, Input, Alert, Spinner, Form, Table, CustomInput
} from 'reactstrap'
import Header from '../Header'
import style from './style.module.css'
import Switch from 'react-switch'
import PowerByStripe from '@src/assets/images/stripeIcon.png'
import {
    masterFeaturesRDAction,
    checkCreditCardRDAction,
    saveCardInfoRDAction,
    checkCreditCardRDResetAction,
    saveCardInfoRDResetAction,
    saveSelectedPlanRDAction,
    saveSelectedPlanRDResetAction
} from "@src/views/apps/opportunitiesBrands/store/actions"
import * as yup from "yup"
import {Formik} from "formik"
import {GoogleReCaptcha} from "react-google-recaptcha-v3"

const PurchasePackages = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [switchPlan, setSwitchPlan] = useState('yearly')
    const [confirmFreeModal, setConfirmFreeModal] = useState(false)
    const [savePlanModal, setSavePlanModal] = useState(false)
    const [planId, setPlanId] = useState(null)
    const [isOpenAddCardModal, setIsOpenAddCardModal] = useState(false)
    const [resStatus, setStatus] = useState('')
    const [resMsg, setResMsg] = useState('')
    const [visible, setVisible] = useState(false)
    const [captchaValue, setCaptchaValue] = useState(null)
    const [captchaError, setCaptchaError] = useState(false)
    const [accountStatus, setAccountStatus] = useState('')
    const [openCouponModal, setOpenCouponModal] = useState(false)
    const [couponCode, setCouponCode] = useState('')
    const [showCouponAlert, setShowCouponAlert] = useState(false)
    const [couponRes, setCouponRes] = useState('')
    const [showCouponSuccess, setShowCouponSuccess] = useState(false)
    const [applyBtn, setApplyBtn] = useState(false)
    const [showCouponForm, setShowCouponForm] = useState(true)
    const [missingCoupon, setMissingCoupon] = useState(false)

    const {
        masterFeaturesRDLoading,
        masterFeaturesRDData,
        masterFeaturesRDError
    } = useSelector(store => store.masterFeaturesRDReducer)
    const {
        checkCreditCardRDLoading,
        checkCreditCardRDData,
        checkCreditCardRDError
    } = useSelector(store => store.checkCreditCardRDReducer)
    const {
        saveCardInfoRDLoading,
        saveCardInfoRDData,
        saveCardInfoRDError
    } = useSelector((store) => store.saveCardInfoRDReducer)
    const {
        saveSelectedPlanRDLoading,
        saveSelectedPlanRDData,
        saveSelectedPlanRDError
    } = useSelector((store) => store.saveSelectedPlanRDReducer)

    const storageData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))

    useEffect(() => {
        if (checkCreditCardRDData?.status === false && checkCreditCardRDData?.is_card === false) {
            setIsOpenAddCardModal(true)
            dispatch(checkCreditCardRDResetAction())
        } else if (checkCreditCardRDData?.status === true) {
            dispatch(checkCreditCardRDResetAction())
            dispatch(saveSelectedPlanRDAction({
                plan_id: planId?.id,
                coupon_code: couponCode
            }))
        }
    }, [checkCreditCardRDData])

    useEffect(() => {
        if (saveCardInfoRDData?.status === true) {
            setIsOpenAddCardModal(false)
            dispatch(saveCardInfoRDResetAction())
            dispatch(saveSelectedPlanRDAction({
                plan_id: planId?.id,
                coupon_code: couponCode
            }))
        } else if (saveCardInfoRDData?.status === false) {
            setStatus(saveCardInfoRDData?.status)
            setResMsg(saveCardInfoRDData?.msg)
            setVisible(true)
            dispatch(saveCardInfoRDResetAction())
        }
    }, [saveCardInfoRDData])

    useEffect(() => {
        if (saveSelectedPlanRDData?.status === false && saveSelectedPlanRDData?.coupon === false) {
            setApplyBtn(false)
            setShowCouponAlert(true)
            setCouponRes(saveSelectedPlanRDData?.msg)
            setPlanId({...planId, discount: 0, total: planId?.price})
            dispatch(saveSelectedPlanRDResetAction())
        } else if (saveSelectedPlanRDData?.status === false && saveSelectedPlanRDData?.coupon === true) {
            setApplyBtn(false)
            setShowCouponSuccess(true)
            setCouponRes(saveSelectedPlanRDData?.msg)
            setPlanId({
                ...planId,
                discount: saveSelectedPlanRDData?.data?.discount,
                total: saveSelectedPlanRDData?.data?.total
            })
            setCouponCode(saveSelectedPlanRDData?.data?.coupon)
            dispatch(saveSelectedPlanRDResetAction())
        } else if (saveSelectedPlanRDData?.status === true && saveSelectedPlanRDData?.coupon === true) {
            setPlanId(null)
            setCouponCode('')
            dispatch(saveSelectedPlanRDResetAction())
            history.push('/pending-account-msg')
        }
    }, [saveSelectedPlanRDData])

    useEffect(() => {
        if (storageData && storageData.selected_rd_plan === false) {
            // const parsed = queryString.parseUrl(window.location.href)
            // setAccountStatus(parsed?.query?.account)
            // setTimeout(() => {
            //     setAccountStatus('')
            //     history.push('/rd/upgrade-membership')
            // }, 5000)
            window.fpr("referral", {email: storageData.email})
            dispatch(masterFeaturesRDAction())
        } else {
            history.push('/login?target=web')
        }
    }, [])

    const handleChangePlan = () => {
        switch (switchPlan) {
            case 'monthly':
                setSwitchPlan('yearly')
                break
            case 'yearly':
                setSwitchPlan('monthly')
                break
        }
    }

    const onFreeCancel = () => {
        setConfirmFreeModal(false)
    }

    const joinNow = (data) => {
        setPlanId({...data, discount: 0, total: data?.price})
        setOpenCouponModal(true)
    }

    const upgradePlan = () => {
        if (showCouponForm && couponCode.length === 0) {
            setMissingCoupon(true)
        } else {
            setShowCouponAlert(false)
            setShowCouponSuccess(false)
            setCouponRes('')
            setApplyBtn(false)
            dispatch(checkCreditCardRDAction())
        }
    }

    const upgradeFreePlan = () => {
        setConfirmFreeModal(false)
        setSavePlanModal(true)
        dispatch(saveSelectedPlanRDAction({plan_id: 3}))
    }

    const onCancelCard = () => {
        setIsOpenAddCardModal(false)
        dispatch(checkCreditCardRDResetAction())
    }

    const addCreditCard = (values) => {
        const params = {
            card_number: values.card_number,
            expiry_month: values.expiry?.split("/")[0] ?? "",
            expiry_year: values.expiry?.split("/")[1] ?? "",
            cvc: values.cvv,
            owner_name: values.owner_name,
            re_captcha_token: captchaValue
        }
        dispatch(saveCardInfoRDAction(params))
    }

    const onCaptchaChange = (value) => {
        setCaptchaValue(value)
        setCaptchaError(false)
    }

    const continueFree = () => {
        setConfirmFreeModal(true)
    }

    const onCouponModalCancel = () => {
        setApplyBtn(false)
        setCouponCode('')
        setPlanId(null)
        setOpenCouponModal(false)
        setShowCouponAlert(false)
        setShowCouponSuccess(false)
        setCouponRes('')
    }

    const handleCouponForm = (e) => {
        setShowCouponForm(e.target.checked)
        if (e.target.checked === false) {
            setShowCouponAlert(false)
            setShowCouponSuccess(false)
            setCouponRes('')
            setCouponCode('')
            setPlanId({...planId, discount: 0, total: planId?.price})
            setMissingCoupon(false)
        }
    }

    return (
        <Fragment>
            <Header/>
            {masterFeaturesRDLoading && (<div className="w-100 text-center mt-40vh"><Spinner color='primary'/></div>)}
            {!masterFeaturesRDLoading && (
                <div className={style.pricingWrap}>
                    {accountStatus && (
                        <div className="text-center bg-success text-white">
                            <p className="p-1 font-medium-1">Thanks for creating your Link RD profile. You will be
                                notified when your account has been approved.</p>
                        </div>
                    )}
                    <div className={style.heading}>Upgrade your account to access Link Premium</div>
                    <div className={style.subheading}>Get paid to work with brands.</div>
                    <div className="mt-2 text-break font-small-4">
                        Link Premium is for registered dietitians who are serious about getting paid to work with the
                        brands they love.
                    </div>
                    <div className="mt-2 text-break font-small-4">
                        As a Premium RD member, you get <strong>closer access to brands</strong> so that you can land
                        more brand partnerships and become a go-to partner for your favorite health and wellness brands.
                    </div>
                    <div className={style.togglewrap}>
                        <div className={switchPlan === 'yearly' ? style.active : style.notactive}>Annual</div>
                        <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onChange={handleChangePlan}
                            checked={switchPlan === 'monthly'}
                            offColor='#56b6ff'
                            onColor='#56b6ff'
                        />
                        <div className={switchPlan === 'monthly' ? style.active : style.notactive}>Monthly</div>
                        <div className="mb-5"></div>
                    </div>
                    {masterFeaturesRDData?.data?.map((val, index) => (
                        switchPlan === val.type && (
                            <div className={style.infoWrap}>
                                <ul>
                                    <li>
                                        <div>
                                            {val?.type === 'monthly' && (
                                                <div className="text-center">
                                                    <sup className="font-medium-1 font-weight-bold text-primary">$</sup>
                                                    <span
                                                        className="font-large-3 font-weight-bolder text-primary">{val?.price}</span>
                                                    <span className="">/ Month</span>
                                                </div>
                                            )}

                                            {val?.type === 'yearly' && (
                                                <div className="text-center">
                                                    {/*<sup className="font-medium-1 font-weight-bold text-primary">$</sup>*/}
                                                    {/*<span className="font-large-3 font-weight-bolder text-primary"><del>297</del></span>*/}
                                                    <sup
                                                        className="font-medium-1 font-weight-bold text-primary ml-2">$</sup>
                                                    <span
                                                        className="font-large-3 font-weight-bolder text-primary">{val?.price}</span>
                                                    <span className="">/ Year</span>
                                                    {/*<span className="font-small-1"><i>(for a limited time only!)</i></span>*/}
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                    <Fragment>
                                        {val?.features?.map((item, index) => (
                                            <li className="pl-3 pr-3" key={index} dangerouslySetInnerHTML={{
                                                __html: item?.title
                                            }}>
                                            </li>
                                        ))}
                                    </Fragment>
                                    <li className="text-center">
                                        <img src={PowerByStripe} width="100"/>
                                    </li>
                                </ul>
                                <button className={style.button} onClick={() => joinNow(val)}>
                                    Join Now
                                </button>
                                <p className="text-center mt-2 cursor-pointer text-primary" onClick={continueFree}>
                                    <u>Or Continue on the Free Plan without the perks listed above</u>
                                </p>
                            </div>
                        )
                    ))}
                </div>
            )}

            <Modal isOpen={isOpenAddCardModal} className='modal-dialog-centered modal-lg'>
                <ModalHeader>Add Card</ModalHeader>
                <Fragment>
                    <Formik
                        initialValues={{
                            owner_name: "",
                            card_number: "",
                            expiry: "",
                            cvv: ""
                        }}
                        validationSchema={yup.object().shape({
                            owner_name: yup.string().required("This field is required").min(3, "Owner name must be at least 3 characters.").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").matches(/[^\s*].*[^\s*]/g, 'This field cannot contain only white space.'),
                            card_number: yup.number().required("This field is required").typeError('Only numbers are allowed.'),
                            expiry: yup.string().required("This field is required"),
                            cvv: yup.number().required("This field is required")
                        })}
                        onSubmit={(values) => {
                            if (captchaValue) {
                                addCreditCard(values)
                            } else {
                                setCaptchaError(true)
                            }
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              touched,
                              values
                          }) => (
                            <Form className="mt-2" onSubmit={handleSubmit} autoComplete="off">
                                <ModalBody>
                                    <Alert color={resStatus === true ? "success" : "danger"} isOpen={visible}
                                           toggle={() => setVisible(false)}>
                                        <div className='alert-body'>
                                            {resMsg}
                                        </div>
                                    </Alert>
                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <Label for="owner-name">Owner Name</Label>
                                                <span className="text-danger">*</span>
                                                <Input
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.owner_name}
                                                    name="owner_name"
                                                    type="text"
                                                    id="owner-name"
                                                    placeholder="Owner Name"
                                                    disabled={saveCardInfoRDLoading}
                                                />
                                                <div className="validation-err">
                                                    {touched.owner_name && errors.owner_name}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <Label for="card-number">Card Number</Label>
                                                <span className="text-danger">*</span>
                                                <Input
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.card_number}
                                                    name="card_number"
                                                    type="text"
                                                    id="card-number"
                                                    placeholder="Card Number"
                                                    disabled={saveCardInfoRDLoading}
                                                />
                                                <div className="validation-err">
                                                    {touched.card_number && errors.card_number}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <Label for="expiry">Expiry</Label>
                                                <span className="text-danger"> *</span>
                                                <Cleave
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder='MM/YY'
                                                    value={values.expiry}
                                                    name="expiry"
                                                    options={{date: true, delimiter: '/', datePattern: ['m', 'y']}}
                                                    id='expiry'
                                                    className="form-control"
                                                    disabled={saveCardInfoRDLoading}
                                                />
                                                <div className="validation-err">
                                                    {touched.expiry && errors.expiry}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <Label for="cvv">CVV / CVC</Label>
                                                <span className="text-danger"> *</span>
                                                <Input
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.cvv}
                                                    name="cvv"
                                                    type="text"
                                                    id="cvv"
                                                    placeholder="CVV / CVC"
                                                    disabled={saveCardInfoRDLoading}
                                                />
                                                <div className="validation-err">
                                                    {touched.cvv && errors.cvv}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='danger' outline onClick={onCancelCard}
                                            disabled={saveCardInfoRDLoading}>Cancel</Button>
                                    <Button type="submit" color='primary' disabled={saveCardInfoRDLoading}>
                                        {saveCardInfoRDLoading ? (
                                            <Spinner style={{width: '14px', height: '14px'}}/>
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Fragment>
            </Modal>

            <Modal isOpen={confirmFreeModal} className='modal-dialog-centered'>
                <ModalHeader>Upgrade Plan</ModalHeader>
                <ModalBody><p>Are you sure you want to continue with free plan?</p></ModalBody>
                <ModalFooter>
                    <Button color='danger' outline onClick={onFreeCancel}>No</Button>
                    <Button className="btn btn-primary" onClick={upgradeFreePlan}>
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={savePlanModal} className='modal-dialog-centered'>
                <ModalHeader>Upgrade Plan</ModalHeader>
                <ModalBody><p>Please wait, We are saving your plan information.</p></ModalBody>
            </Modal>

            <Modal isOpen={openCouponModal} className='modal-dialog-centered'>
                <ModalHeader>Upgrade Plan</ModalHeader>
                <Fragment>
                    <ModalBody>
                        <Alert color="danger" isOpen={showCouponAlert}>
                            <div className='alert-body'>
                                {couponRes}
                            </div>
                        </Alert>

                        <Alert color="success" isOpen={showCouponSuccess}>
                            <div className='alert-body'>
                                {couponRes}
                            </div>
                        </Alert>
                        <Formik
                            initialValues={{
                                coupon: ""
                            }}
                            validationSchema={yup.object().shape({
                                coupon: yup.string().required("Coupon code field is required.")
                            })}
                            onSubmit={(values) => {
                                setApplyBtn(true)
                                dispatch(saveSelectedPlanRDAction({
                                    plan_id: planId?.id,
                                    coupon_code: values.coupon,
                                    check: 1
                                }))
                                setShowCouponAlert(false)
                                setShowCouponSuccess(false)
                                setCouponRes('')
                                setCouponCode('')
                            }}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  touched,
                                  values,
                                  resetForm
                              }) => (
                                <Form onSubmit={handleSubmit} autoComplete="off">
                                    <Row>
                                        <Col sm='12'>
                                            <FormGroup>
                                                <CustomInput
                                                    key="addCoupon"
                                                    type='checkbox'
                                                    id="addCoupon"
                                                    label="I have a coupon code."
                                                    name="addCoupon"
                                                    defaultChecked={true}
                                                    onChange={(e) => {
                                                        handleCouponForm(e)
                                                        resetForm()
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        {showCouponForm && (
                                            <>
                                                <Col sm='12'>
                                                    <FormGroup>
                                                        <Input type='text'
                                                               placeholder='Coupon Code'
                                                               id='coupon-code'
                                                               name="coupon"
                                                               onBlur={handleBlur}
                                                               onChange={(e) => {
                                                                   handleChange(e)
                                                                   setMissingCoupon(false)
                                                               }}
                                                               value={values.coupon}
                                                               disabled={saveSelectedPlanRDLoading || checkCreditCardRDLoading}
                                                        />
                                                        {missingCoupon && (
                                                            <div className="validation-err">
                                                                Please apply the coupon code.
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col sm='12'>
                                                    <FormGroup className='mb-2 text-right'>
                                                        <Button color='primary' type="submit"
                                                                disabled={saveSelectedPlanRDLoading || checkCreditCardRDLoading}>
                                                            Apply
                                                        </Button>
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                </Form>
                            )}
                        </Formik>

                        <Row>
                            <Col sm='12'>
                                <FormGroup>
                                    <p className="bg-light p-1 font-weight-bold">Invoice</p>
                                </FormGroup>
                            </Col>
                            <Col sm='12'>
                                <Table responsive>
                                    <thead>
                                    <tr>
                                        <td className="font-weight-bold">Plan</td>
                                        <td>{planId?.type === 'yearly' ? 'Annual' : 'Monthly'}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">Amount</td>
                                        <td>${planId?.price}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">Discount</td>
                                        <td>
                                            {applyBtn ? (
                                                <Spinner style={{width: '14px', height: '14px'}}/>
                                            ) : (
                                                `$${planId?.discount}`
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="bg-light">
                                        <td className="font-weight-bold">To Pay</td>
                                        <td className="font-weight-bold">
                                            {applyBtn ? (
                                                <Spinner style={{width: '14px', height: '14px'}}/>
                                            ) : (
                                                `$${planId?.total}`
                                            )}
                                        </td>
                                    </tr>
                                    </thead>
                                </Table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='danger' outline onClick={onCouponModalCancel}
                                disabled={saveSelectedPlanRDLoading || checkCreditCardRDLoading}>
                            Cancel
                        </Button>
                        <Button color='primary' onClick={upgradePlan}
                                disabled={saveSelectedPlanRDLoading || checkCreditCardRDLoading}>
                            {(saveSelectedPlanRDLoading || checkCreditCardRDLoading) && applyBtn === false ? (
                                <Spinner style={{width: '14px', height: '14px'}}/>
                            ) : (
                                "Proceed"
                            )}
                        </Button>
                    </ModalFooter>
                </Fragment>
            </Modal>
        </Fragment>
    )
}

export default PurchasePackages
