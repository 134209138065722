import {BRAND, BRANDS} from "../../actionTypes"

const initialState = {
    brandProfile: null,
    notifications: null,
    error: null,
    currentPlanData: null,
    subscriptionPlansData: null,

    cancelPlanRequest: false,
    cancelPlanData: null,
    cancelPlanError: null
}

const brandDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case BRAND.BRAND_PROFILE:
            return {
                ...state,
                brandProfile: action.brandProfile
            }
        case BRAND.CURRENT_PLAN:
            return {...state, currentPlanData: action.currentPlanData}
        case BRAND.SUBSCRIPTION_PLAN:
            return {...state, subscriptionPlansData: action.subscriptionPlansData}
        case BRAND.CANCEL_PLAN:
            return {...state, cancelPlansData: action.cancelPlansData}

        case BRANDS.CANCEL_PLAN_REQUEST:
            return {
                ...state,
                cancelPlanRequest: true,
                cancelPlanData: null,
                cancelPlanError: null
            }
        case BRANDS.CANCEL_PLAN_SUCCESS:
            return {
                ...state,
                cancelPlanRequest: false,
                cancelPlanData: action.payload,
                cancelPlanError: null
            }
        case BRANDS.CANCEL_PLAN_ERROR:
            return {
                ...state,
                cancelPlanRequest: false,
                cancelPlanData: null,
                cancelPlanError: action.payload
            }
        case BRANDS.CANCEL_PLAN_RESET:
            return {
                ...state,
                cancelPlanRequest: false,
                cancelPlanData: null,
                cancelPlanError: null
            }

        case BRAND.BRAND_DASHBOARD_ERROR:
            return {
                ...state,
                error: action.error
            }
        default:
            return state
    }
}

export default brandDashboardReducer
