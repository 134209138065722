// ** React Imports
import { useContext, useEffect } from 'react'

// ** Vertical Menu Components
import VerticalNavMenuLink from './VerticalNavMenuLink'
import VerticalNavMenuGroup from './VerticalNavMenuGroup'
import VerticalNavMenuSectionHeader from './VerticalNavMenuSectionHeader'
import { useSelector, useDispatch } from 'react-redux'
import { getBrandUnreadMessages } from '../../../../../redux/actions/brandsDashboard'
// ** Ability Context
import { AbilityContext } from '@src/utility/context/Can'

// ** Utils
import {
  resolveVerticalNavMenuItemComponent as resolveNavItemComponent,
  canViewMenuGroup,
  canViewMenuItem
} from '@layouts/utils'

const VerticalMenuNavItems = props => {
  // ** Context
  const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))
  const brandDashboardStoreSlice  = useSelector(state => state.brandsDashboardSlice)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!!brandDashboardStoreSlice?.brandUnreadMessages) {
      // console.log('store rdDashboardStoreSlice', brandDashboardStoreSlice?.brandUnreadMessages.data)

    }
    
  }, [brandDashboardStoreSlice])
  useEffect(() => {
    if (userData) {
      dispatch(getBrandUnreadMessages())
    }
  }, [])
  const ability = useContext(AbilityContext)

  // ** Components Object
  const Components = {
    VerticalNavMenuSectionHeader,
    VerticalNavMenuGroup,
    VerticalNavMenuLink
  }

  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)]
    if (item.children) {
      return canViewMenuGroup(item) && <TagName item={item} index={index} key={item.id} {...props} />
    }
    if (item.id === "messagesBrands") {
      item.badgeText  = brandDashboardStoreSlice?.brandUnreadMessages?.data > 0 ? brandDashboardStoreSlice?.brandUnreadMessages?.data.toString() : ''
    return canViewMenuItem(item) && <TagName key={item.id || item.header} item={item} {...props} />
     
    } else {
      return canViewMenuItem(item) && <TagName key={item.id || item.header} item={item} {...props} />

    }
    // return canViewMenuItem(item) && <TagName key={item.id || item.header} item={item} {...props} />
  })

  return RenderNavItems
}

export default VerticalMenuNavItems
