// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  pendigBrandsData: [],
  pendigBrandsSearchData: [],
  countries: [],
  states: [],
  industries: [],
  specialities: [],
  deletePendingData: {}
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'MANAGE_BRAND_PENDING_DATA':
      return {
        ...state,
        pendigBrandsData: action.pendigBrandsData
      }
      case 'MANAGE_BRAND_PENDING_SEARCH_DATA':
        return {
          ...state,
          pendigBrandsSearchData: action.pendigBrandsSearchData
        }
      case 'COUNTRIES_DATA':
        return {
          ...state,
          countries: action.countries
        }
      case 'STATES_DATA':
        return {
          ...state,
          states: action.states
        }
      case 'INDUSTRIES_DATA':
        return {
          ...state,
          industries: action.industries
        }
      case 'SPECIALITIES_DATA':
      return {
        ...state,
        specialities: action.specialities
      }
    case 'DELETE_PENDING_DELETE_DATA':
      return {
        ...state,
        deletePendingData: action.deletePendingData
      }
    default:
      return state
  }
}

export default DataTablesReducer
